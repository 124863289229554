import styled from 'styled-components'

const ContentWrapper = styled.div`
  // If your first child does not have ignore content top padding, we can
  // set padding top
  > :not(.ignore-content-top-padding):first-child {
    padding-top: 24px;
  }

  // This solves for the other case — if there are children that ignore content
  // top padding above this child, then we should add top padding to this one
  > .ignore-content-top-padding + :not(.ignore-content-top-padding) {
    padding-top: 24px;
  }

  > * {
    padding-left: 32px;
    padding-right: 32px;
  }

  > :last-child {
    padding-bottom: 24px;
  }
`

export default ContentWrapper
