export enum PatAssessmentQuestionnaireItem {
  // Assessment overview
  ASSESSMENT_OVERVIEW_APPOINTMENT_TIME = 'assessment-overview/appointment-time',
  ASSESSMENT_OVERVIEW_APPOINTMENT_TIME_START = 'assessment-overview/appointment-time/start',
  ASSESSMENT_OVERVIEW_APPOINTMENT_TIME_END = 'assessment-overview/appointment-time/end',

  // Patient background
  PATIENT_BACKGROUND_PATIENT_VERIFICATION_LEGAL_NAME = 'patient-background/patient-verification/legal-name',
  PATIENT_BACKGROUND_PATIENT_VERIFICATION_PREFERRED_NAME = 'patient-background/patient-verification/preferred-name',
  PATIENT_BACKGROUND_PATIENT_VERIFICATION_ADDRESS = 'patient-background/patient-verification/address',
  PATIENT_BACKGROUND_PATIENT_VERIFICATION_DOB = 'patient-background/patient-verification/dob',
  PATIENT_BACKGROUND_PATIENT_VERIFICATION_GENDER = 'patient-background/patient-verification/gender',
  PATIENT_BACKGROUND_PATIENT_VERIFICATION_MEDICAID_ID = 'patient-background/patient-verification/medicaid-id',
  PATIENT_BACKGROUND_HISTORY_AND_DIAGNOSES_PRIMARY_DIAGNOSES = 'patient-background/history-and-diagnoses/primary-diagnoses',
  PATIENT_BACKGROUND_HISTORY_AND_DIAGNOSES_OTHER_DIAGNOSES = 'patient-background/history-and-diagnoses/other-diagnoses',
  PATIENT_BACKGROUND_HISTORY_AND_DIAGNOSES_THERAPIES = 'patient-background/history-and-diagnoses/therapies',
  PATIENT_BACKGROUND_HISTORY_AND_DIAGNOSES_EQUIPMENT = 'patient-background/history-and-diagnoses/equipment',
  PATIENT_BACKGROUND_HISTORY_AND_DIAGNOSES_PCP = 'patient-background/history-and-diagnoses/pcp',
  PATIENT_BACKGROUND_HISTORY_AND_DIAGNOSES_ER = 'patient-background/history-and-diagnoses/er',
  PATIENT_BACKGROUND_HISTORY_AND_DIAGNOSES_ER_QUESTION = 'patient-background/history-and-diagnoses/er/question',
  PATIENT_BACKGROUND_HISTORY_AND_DIAGNOSES_ER_NOTES = 'patient-background/history-and-diagnoses/er/notes',
  PATIENT_BACKGROUND_HISTORY_AND_DIAGNOSES_HOSPITAL = 'patient-background/history-and-diagnoses/hospital',
  PATIENT_BACKGROUND_HISTORY_AND_DIAGNOSES_HOSPITAL_QUESTION = 'patient-background/history-and-diagnoses/hospital/question',
  PATIENT_BACKGROUND_HISTORY_AND_DIAGNOSES_HOSPITAL_NOTES = 'patient-background/history-and-diagnoses/hospital/notes',
  PATIENT_BACKGROUND_HISTORY_AND_DIAGNOSES_INJURIES = 'patient-background/history-and-diagnoses/injuries',
  PATIENT_BACKGROUND_HISTORY_AND_DIAGNOSES_INJURIES_QUESTION = 'patient-background/history-and-diagnoses/injuries/question',
  PATIENT_BACKGROUND_HISTORY_AND_DIAGNOSES_INJURIES_NOTES = 'patient-background/history-and-diagnoses/injuries/notes',
  PATIENT_BACKGROUND_HISTORY_AND_DIAGNOSES_FALLS = 'patient-background/history-and-diagnoses/falls',
  PATIENT_BACKGROUND_HISTORY_AND_DIAGNOSES_FALLS_QUESTION = 'patient-background/history-and-diagnoses/falls/question',
  PATIENT_BACKGROUND_HISTORY_AND_DIAGNOSES_FALLS_NOTES = 'patient-background/history-and-diagnoses/falls/notes',
  PATIENT_BACKGROUND_HISTORY_AND_DIAGNOSES_INFECTIONS = 'patient-background/history-and-diagnoses/infections',
  PATIENT_BACKGROUND_HISTORY_AND_DIAGNOSES_INFECTIONS_QUESTION = 'patient-background/history-and-diagnoses/infections/question',
  PATIENT_BACKGROUND_HISTORY_AND_DIAGNOSES_INFECTIONS_NOTES = 'patient-background/history-and-diagnoses/infections/notes',
  PATIENT_BACKGROUND_HISTORY_AND_DIAGNOSES_MD = 'patient-background/history-and-diagnoses/md',
  PATIENT_BACKGROUND_HISTORY_AND_DIAGNOSES_MD_QUESTION = 'patient-background/history-and-diagnoses/md/question',
  PATIENT_BACKGROUND_HISTORY_AND_DIAGNOSES_MD_NOTES = 'patient-background/history-and-diagnoses/md/notes',

  // Modifier A
  MODIFIER_A_1 = 'modifier-a/1/question',
  MODIFIER_A_2 = 'modifier-a/2/question',
  MODIFIER_A_3 = 'modifier-a/3/question',
  MODIFIER_A_4 = 'modifier-a/4/question',

  // Braden Scale
  BRADEN_SCALE_SENSORY = 'braden-scale/sensory/question',
  BRADEN_SCALE_MOISTURE = 'braden-scale/moisture/question',
  BRADEN_SCALE_ACTIVITY = 'braden-scale/activity/question',
  BRADEN_SCALE_MOBILITY = 'braden-scale/mobility/question',
  BRADEN_SCALE_NUTRITION = 'braden-scale/nutrition/question',
  BRADEN_SCALE_FRICTION_AND_SHEAR = 'braden-scale/friction-and-shear/question',

  // Modifier B
  MODIFIER_B_1 = 'modifier-b/1/question',
  MODIFIER_B_2 = 'modifier-b/2/question',
  MODIFIER_B_3 = 'modifier-b/3/question',
  MODIFIER_B_4 = 'modifier-b/4/question',
  MODIFIER_B_5 = 'modifier-b/5/question',
  MODIFIER_B_6 = 'modifier-b/6/question',
  MODIFIER_B_7 = 'modifier-b/7/question',

  // CNA Skilled Tasks
  CNA_SKILLED_TASKS_BATHING = 'cna-skilled-tasks/bathing/question',
  CNA_SKILLED_TASKS_BRACES_SPLINTS_STOCKINGS = 'cna-skilled-tasks/braces-splints-stockings/question',
  CNA_SKILLED_TASKS_BRACES_SPLINTS_STOCKINGS_ADDITIONAL = 'cna-skilled-tasks/braces-splints-stockings/question-a',
  CNA_SKILLED_TASKS_DRESSING = 'cna-skilled-tasks/dressing/question',
  CNA_SKILLED_TASKS_EATING = 'cna-skilled-tasks/eating/question',
  CNA_SKILLED_TASKS_TUBE_FEEDINGS = 'cna-skilled-tasks/tube-feedings/question',
  CNA_SKILLED_TASKS_MEDICATION = 'cna-skilled-tasks/medication/question',
  CNA_SKILLED_TASKS_MOBILITY = 'cna-skilled-tasks/mobility/question',
  CNA_SKILLED_TASKS_MOBILITY_ADDITIONAL = 'cna-skilled-tasks/mobility/question-a',
  CNA_SKILLED_TASKS_MEAL_PREP = 'cna-skilled-tasks/meal-prep/question',
  CNA_SKILLED_TASKS_GROOMING = 'cna-skilled-tasks/grooming/question',
  CNA_SKILLED_TASKS_GROOMING_ADDITIONAL = 'cna-skilled-tasks/grooming/question-a',
  CNA_SKILLED_TASKS_SKIN_CARE_APPLICATION = 'cna-skilled-tasks/skin-care-application/question',
  CNA_SKILLED_TASKS_SKIN_CARE_APPLICATION_ADDITIONAL = 'cna-skilled-tasks/skin-care-application/question-a',
  CNA_SKILLED_TASKS_SKIN_CARE = 'cna-skilled-tasks/skin-care/question',
  CNA_SKILLED_TASKS_SKIN_CARE_ADDITIONAL = 'cna-skilled-tasks/skin-care/question-a',
  CNA_SKILLED_TASKS_ROM_EXERCISE = 'cna-skilled-tasks/rom-exercise/question',
  CNA_SKILLED_TASKS_RESPIRATORY_CARE = 'cna-skilled-tasks/respiratory-care/question',
  CNA_SKILLED_TASKS_OXYGEN_THERAPY = 'cna-skilled-tasks/oxygen-therapy/question',
  CNA_SKILLED_TASKS_ORAL_SUCTIONING = 'cna-skilled-tasks/oral-suctioning/question',
  CNA_SKILLED_TASKS_BOWEL_FREQUENCY = 'cna-skilled-tasks/bowel-frequency/question',
  CNA_SKILLED_TASKS_BOWEL_HYGIENE = 'cna-skilled-tasks/bowel-hygiene/question',
  CNA_SKILLED_TASKS_BOWEL_ELIMINATION = 'cna-skilled-tasks/bowel-elimination/question',
  CNA_SKILLED_TASKS_BOWEL_PROGRAM = 'cna-skilled-tasks/bowel-program/question',
  CNA_SKILLED_TASKS_CATHETER_CARE = 'cna-skilled-tasks/catheter-care/question',
  CNA_SKILLED_TASKS_MENSES = 'cna-skilled-tasks/menses/question',
  CNA_SKILLED_TASKS_URINARY_HYGIENE = 'cna-skilled-tasks/urinary-hygiene/question',
  CNA_SKILLED_TASKS_POSITIONING = 'cna-skilled-tasks/positioning/question',
  CNA_SKILLED_TASKS_TRANSFERS = 'cna-skilled-tasks/transfers/question',
  CNA_SKILLED_TASKS_VITALS_PHYSICIAN = 'cna-skilled-tasks/vitals-physician/question',
  CNA_SKILLED_TASKS_VITALS_NURSE = 'cna-skilled-tasks/vitals-nurse/question',

  SCORE_AND_SUMMARY = 'score-and-summary',
  SCORE_AND_SUMMARY_MODIFIER_A = 'score-and-summary/modifier-a',
  SCORE_AND_SUMMARY_MODIFIER_B = 'score-and-summary/modifier-b',
  SCORE_AND_SUMMARY_CNA_SKILLED_TASKS = 'score-and-summary/cna-skilled-tasks',
  SCORE_AND_SUMMARY_TOTAL = 'score-and-summary/total',

  NEXT_STEPS = 'next-steps',
  NEXT_STEPS_QUALIFICATION_STATUS = 'next-steps/qualification-status',
  NEXT_STEPS_REQUESTED_DOCS = 'next-steps/requested-docs',
  NEXT_STEPS_SPECIFIC_NEEDS_FOR_DOCS = 'next-steps/specific-needs-for-docs',
  NEXT_STEPS_QA = 'next-steps/qa'
}

export enum SectionDependentItems {
  BRADEN_SCALE = 'modifier-b/5/question',
  MODIFIER_A = 'score-and-summary/modifier-a',
  MODIFIER_B = 'score-and-summary/modifier-b',
  CNA_SKILLED_TASKS = 'score-and-summary/cna-skilled-tasks',
  SKILLED_NEEDS_LIST = 'skilled-needs-list',
  DIAGNOSES_LIST = 'diagnoses-list',
  TOTAL_ASSESSMENT = 'score-and-summary/total',
  QUALIFICATION_STATUS = 'next-steps/qualification-status'
}
