import { StateCreator } from 'zustand'
import { GetPatientDataQuery } from 'generated/graphql'
import { RootSlice } from './rootType'

export interface PatientDataSlice {
  patientData: {
    byId: Record<string, GetPatientDataQuery>
    updatePatientDataById: (patientId: string, data: GetPatientDataQuery) => void
  }
}

const defaultState: Pick<PatientDataSlice['patientData'], 'byId'> = {
  byId: {}
}

export const createPatientDataSlice: StateCreator<
  RootSlice,
  [['zustand/devtools', never], ['zustand/immer', never]],
  [],
  PatientDataSlice
> = (set, _get) => ({
  patientData: {
    ...defaultState,
    updatePatientDataById: (patientId: string, data: GetPatientDataQuery) => {
      set((state) => {
        if (data) {
          state.patientData.byId[patientId] = data
        }
      })
    }
  }
})
