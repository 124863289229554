import { MedplumClient } from '@medplum/core'
import { Appointment, Encounter, HumanName, Task } from '@medplum/fhirtypes'
import * as Sentry from '@sentry/react'
import { Visit } from 'components/Patients/Visits/types'

import { APPOINTMENT_CHANGE_REASON_EXTENSION, AppointmentType } from 'fhir/Appointment/constants'
import {
  getCNAVisitEncounterStatus,
  getEncounterPractitionersByRole,
  getScheduledEncounterPeriod
} from 'fhir/Encounter/helpers'
import {
  BaseAppointmentFragment,
  BaseEncounterWithAppointmentsFragment,
  GetPatientVisits_VisitFragment,
  GetPatientVisitsQuery,
  GetPatientVisitsQueryVariables
} from 'generated/graphql'
import { GET_PATIENT_VISITS } from 'hooks/getPatientVisits.query'
import { castToEnum } from 'utils/enum'
import { formatName } from 'utils/names'
import { BasePatientFragment } from 'generated/graphql'
import { getValidatedFragment } from 'fhir/utils'
import { EncounterParticipantType } from 'fhir/Encounter/constants'

export const MAX_FETCH_COUNT = 1000

export type ParticipantIdsModel = {
  patientIds: string[]
  practitionerIds: string[]
}

export const parseAppointmentInfo = (
  appointment: Appointment
): {
  visitType: AppointmentType
  reasonForChange?: string
  participantIds: ParticipantIdsModel
} => {
  const visitType = appointment?.appointmentType?.coding?.[0]?.code

  if (!visitType) {
    throw new Error('Visit type is missing', { cause: appointment })
  }

  const reasonForChange = appointment?.extension?.find(
    (ext) => ext.url === APPOINTMENT_CHANGE_REASON_EXTENSION
  )?.valueCode

  const participantIds: ParticipantIdsModel = {
    patientIds: [],
    practitionerIds: []
  }
  appointment?.participant?.forEach((p) => {
    const participantRef = p.actor?.reference
    if (participantRef?.startsWith('Patient')) {
      participantIds.patientIds.push(participantRef.split('/')[1])
    }
    if (participantRef?.startsWith('Practitioner')) {
      participantIds.practitionerIds.push(participantRef.split('/')[1])
    }
  })

  return {
    visitType: castToEnum(AppointmentType, visitType) as AppointmentType,
    reasonForChange,
    participantIds
  }
}

export const transformQueryToVisits = (data: GetPatientVisitsQuery): Visit[] => {
  const encounters =
    data?.AppointmentList?.flatMap((appointment) => appointment?.EncounterList)?.filter(
      (e): e is GetPatientVisits_VisitFragment => !!e
    ) ?? []

  return encounters
    .map((e): Visit | undefined => {
      const appointments = data?.AppointmentList?.filter((a) => {
        const encounterAppointmentIds =
          e.appointment?.map((a) => a.reference?.split('/')[1] ?? null) ?? []
        return encounterAppointmentIds.includes(a?.id ?? null)
      })

      const encounterWithAppointment: BaseEncounterWithAppointmentsFragment = {
        ...e,
        appointment: appointments?.map((a) => ({
          reference: `Appointment/${a?.id}`,
          resource: a
        }))
      }
      const appointmentId = getValidatedFragment<BaseAppointmentFragment>(
        encounterWithAppointment.appointment?.at(0)?.resource
      )?.id
      if (!appointmentId) {
        Sentry.captureException(
          new Error('Appointment not found', {
            cause: {
              encounter: encounterWithAppointment
            }
          })
        )
        return
      }
      const period = getScheduledEncounterPeriod(encounterWithAppointment) ?? undefined
      const status = getCNAVisitEncounterStatus(encounterWithAppointment)
      const patient = getValidatedFragment<BasePatientFragment>(
        encounterWithAppointment.subject?.resource
      )
      const patientId = patient?.id ?? null
      const patientName = patient?.name?.at(0)
      const formattedPatientName = patientName ? formatName(patientName as HumanName) : ''
      const caregivers = getEncounterPractitionersByRole(
        encounterWithAppointment,
        EncounterParticipantType.CAREGIVER
      )

      return {
        id: e.id ?? '',
        patientName: formattedPatientName ?? '',
        patientId,
        caregivers,
        status,
        appointmentId,
        period: {
          start: period?.start ?? null,
          end: period?.end ?? null
        }
      }
    })
    .filter((v): v is Visit => !!v)
}

export const fetchAllVisits = async (
  medplumClient: MedplumClient,
  baseParams: GetPatientVisitsQueryVariables,
  maxFetchCount: number
): Promise<{ results: Array<GetPatientVisitsQuery>; errors: any }> => {
  let offset = 0
  const resultList: Array<GetPatientVisitsQuery> = []
  const errorsList: Array<any> = []
  let currentErrorsList: Array<any> = []
  let previousFetchCount = 0

  // This querying method will fetch according to the parameters until we get
  // all results, but be aware that if a visit is
  // added while querying, we may miss it since the offset is not aware of
  // Medplum state.
  while (offset === 0 || previousFetchCount === maxFetchCount) {
    const offsetParams = { ...baseParams }
    offsetParams.offset = offset
    offsetParams.count = maxFetchCount

    const { data: result, errors }: { data: GetPatientVisitsQuery; errors: any } =
      await medplumClient.graphql(GET_PATIENT_VISITS, null, offsetParams)

    currentErrorsList = errors

    if (result.AppointmentList) {
      previousFetchCount = result.AppointmentList.length
      resultList.push(result)
      errorsList.push(...(currentErrorsList ?? []))
      offset += maxFetchCount
    } else {
      // No results were returned.
      break
    }
  }
  return { results: resultList, errors: errorsList }
}

export const fetchVisitsBySearchParams = async (
  medplum: MedplumClient,
  searchParams: {
    appointment?: any
    encounter?: any
    cnaTask?: any
    visitQaTask?: any
  }
): Promise<{
  appointments: Appointment[]
  encounters: Encounter[]
  cnaTasks: Task[]
  visitQaTasks: Task[]
}> => {
  const appointmentSearchParams = new URLSearchParams(searchParams.appointment)
  // 1. fetch appointments
  const appointments: Appointment[] = await medplum.searchResources(
    'Appointment',
    appointmentSearchParams
  )

  const appointmentIds: string[] = appointments.map((appt) => appt.id as string)
  const appointmentRefs = appointmentIds.map((id) => `Appointment/${id}`)

  // 2. fetch related encounters, batch encounterIds to 250 to avoid default URL length limit 16,384
  const encounters: Encounter[] = []
  for (let i = 0; i < appointmentRefs.length; i += 250) {
    const batchAppointmentRefs = appointmentRefs.slice(i, i + 250)
    const batchEncounters = await medplum.searchResources('Encounter', {
      _count: '1000',
      appointment: batchAppointmentRefs.join(',')
    })
    encounters.push(...batchEncounters)
  }

  const encounterIds: string[] = encounters.map((encounter) => encounter.id as string)
  const encounterRefs = encounterIds.map((id) => `Encounter/${id}`)

  // 3. fetch related CNA tasks
  const cnaTasks: Task[] = []
  for (let i = 0; i < encounterRefs.length; i += 250) {
    const batchEncounterRefs = encounterRefs.slice(i, i + 250)
    const batchTasks = await medplum.searchResources('Task', {
      _count: '1000',
      encounter: batchEncounterRefs.join(','),
      ...(searchParams.cnaTask ?? {})
    })
    cnaTasks.push(...batchTasks)
  }

  // 4. fetch related Visit QA tasks
  const visitQaTasks: Task[] = []
  for (let i = 0; i < encounterRefs.length; i += 250) {
    const batchEncounterRefs = encounterRefs.slice(i, i + 250)
    const batchTasks = await medplum.searchResources('Task', {
      _count: '1000',
      encounter: batchEncounterRefs.join(','),
      ...(searchParams.visitQaTask ?? {})
    })
    visitQaTasks.push(...batchTasks)
  }

  return {
    appointments,
    encounters,
    cnaTasks,
    visitQaTasks
  }
}
