import React from 'react'
import { Form, FormItemProps } from 'antd'

export const FormItem: React.FC<FormItemProps> = ({
  children,
  required = true,
  rules = [],
  ...formItemProps
}) => {
  const requiredRule = { required, message: '' }
  return (
    <Form.Item rules={[requiredRule, ...rules]} {...formItemProps}>
      {children}
    </Form.Item>
  )
}
