import { useContext, useMemo } from 'react'
import PatientContext from 'contexts/PatientContext'
import { CareTeamMemberRole } from 'fhir/CareTeam/constants'
import {
  selectCareTeamPractitioners,
  selectCareTeamPractitionersWithRole,
  selectPrimaryCareTeam
} from 'fhir/CareTeam/helpers'
import { BasePractitionerFragment, UsePatientCareTeam_CareTeamFragment } from 'generated/graphql'
import { useStore } from 'store'

interface PatientCareTeam {
  careTeamList: UsePatientCareTeam_CareTeamFragment[]
  practitioners: BasePractitionerFragment[]
  caregivers: BasePractitionerFragment[]
  primaryCarePhysicians: BasePractitionerFragment[] | undefined
  orderingPhysicians: BasePractitionerFragment[]
  registeredNurses: BasePractitionerFragment[]
  primaryCareTeam: UsePatientCareTeam_CareTeamFragment | undefined
}

/**
 * @deprecated The hook should not be used
 * Transform in Zustand instead, it's anti-pattern to wrap Zustand state in a
 * custom hook.
 */
const usePatientCareTeam = (): PatientCareTeam => {
  const { patientId } = useContext(PatientContext)
  const { byPatientId: careTeamByPatientId } = useStore((state) => state.careTeam)

  const careTeamList = (patientId ? careTeamByPatientId[patientId] : []) ?? []

  const careTeam = selectPrimaryCareTeam(careTeamList as UsePatientCareTeam_CareTeamFragment[])

  const hasNoData = !careTeam

  const practitioners = useMemo(() => {
    if (hasNoData) return []
    return selectCareTeamPractitioners(careTeam)
  }, [careTeam, hasNoData])

  // TODO: [ENG-2158] to filter based on the service type (home-health-care) instead of the role name
  const caregivers = useMemo(() => {
    if (hasNoData) return []
    return selectCareTeamPractitionersWithRole(careTeam, CareTeamMemberRole.Caregiver)
  }, [careTeam, hasNoData])

  const orderingPhysicians = useMemo(() => {
    if (hasNoData) return []
    return selectCareTeamPractitionersWithRole(careTeam, CareTeamMemberRole.OrderingPhysician)
  }, [careTeam, hasNoData])

  const primaryCarePhysicians = useMemo(() => {
    if (!careTeam) return undefined
    return selectCareTeamPractitionersWithRole(careTeam, CareTeamMemberRole.PrimaryCarePhysician)
  }, [careTeam])

  const registeredNurses = useMemo(() => {
    if (hasNoData) return []
    return selectCareTeamPractitionersWithRole(careTeam, CareTeamMemberRole.RegisteredNurse)
  }, [careTeam, hasNoData])

  return {
    careTeamList,
    practitioners,
    caregivers,
    primaryCarePhysicians,
    orderingPhysicians,
    registeredNurses,
    primaryCareTeam: careTeam ?? undefined
  }
}

export default usePatientCareTeam
