import { ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE } from 'fhir/constants'

export enum OrganizationTypeCodes {
  ABBYCARE = 'abbycare',
  PROVIDER = 'prov',
  MEDICAID = 'med',
  INSURANCE = 'ins',
  PAYER = 'pay',
  MCO = 'mco'
}

export const ORGANIZATION_ORDER_PREFERENCES_EXTENSION = `${ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE}/order-preferences`
