import { FeatureFlagName } from 'hooks/useFeatureFlags'
import { AbbyAccessPolicy } from 'utils/accessPolicy'

export interface AntMenuItem {
  key: string
  icon?: JSX.Element | undefined
  children?: AntMenuItem[] | undefined
  label: string | JSX.Element
  type?: string | undefined
  disabled?: boolean | undefined
  roles?: AbbyAccessPolicy[] | undefined
  devOnly: boolean
  featureFlag?: FeatureFlagName
}

export enum MenuItemKey {
  HOME = '/',
  TASKS = '/tasks',
  PATIENTS = '/patients',
  EMPLOYEES = '/employees',
  CLINICAL = '/clinical',
  CLINICAL_QA = '/clinical/qa',
  CLINICAL_ASSESSMENTS = '/clinical/assessments',
  CLINICAL_PROVIDERS = '/clinical/providers',
  SCHEDULE = '/schedule',
  FLOWS = '/flows',
  PAYROLL = '/payroll',
  REPORTS = '/reports',
  ADMIN = '/admin',
  ONBOARDING = '/admin/onboarding'
}
