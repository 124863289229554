import { PAT_QUESTIONNAIRE_ITEM_TO_ANSWER_TYPE } from './PatAssessment/types/questionnaireAnswer'
import { SOC_QUESTIONNAIRE_ITEM_TO_ANSWER_TYPE } from './SocAssessment/types/questionnaireAnswer'
import { RECERT_QUESTIONNAIRE_ITEM_TO_ANSWER_TYPE } from './RecertAssessment/types/questionnaireAnswer'
import { REVISION_OF_PAR_QUESTIONNAIRE_ITEM_TO_ANSWER_TYPE } from './RevisionOfParAssessment/types/questionnaireAnswer'
import { DISCHARGE_QUESTIONNAIRE_ITEM_TO_ANSWER_TYPE } from './DischargeAssessment/types/questionnaireAnswer'
import { ROC_QUESTIONNAIRE_ITEM_TO_ANSWER_TYPE } from './RocAssessment/types/questionnaireAnswer'
import { SUPERVISORY_QUESTIONNAIRE_ITEM_TO_ANSWER_TYPE } from './SupervisoryAssessment/types/questionnaireAnswer'

export const QUESTIONNAIRE_ITEM_TO_ANSWER_TYPE = {
  ...PAT_QUESTIONNAIRE_ITEM_TO_ANSWER_TYPE,
  ...SOC_QUESTIONNAIRE_ITEM_TO_ANSWER_TYPE,
  ...RECERT_QUESTIONNAIRE_ITEM_TO_ANSWER_TYPE,
  ...REVISION_OF_PAR_QUESTIONNAIRE_ITEM_TO_ANSWER_TYPE,
  ...DISCHARGE_QUESTIONNAIRE_ITEM_TO_ANSWER_TYPE,
  ...ROC_QUESTIONNAIRE_ITEM_TO_ANSWER_TYPE,
  ...SUPERVISORY_QUESTIONNAIRE_ITEM_TO_ANSWER_TYPE
}
