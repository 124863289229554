import { Observation, Patient, Reference } from '@medplum/fhirtypes'
import { useObservationRepository } from './useObservationRepository'

export interface CreateObservationParams {
  status: Observation['status']
  subject: Reference<Patient>
  category: Observation['category']
  code: Observation['code']
  note?: Observation['note']
  valueString?: Observation['valueString']
}

interface ObservationService {
  createObservation(params: CreateObservationParams): Promise<Observation>
  getObservation(id: string): Promise<Observation[] | undefined>
}

export const useObservationService = (): ObservationService => {
  const observationRepository = useObservationRepository()

  const createObservation = async ({
    status,
    subject,
    category,
    code,
    note,
    valueString
  }: CreateObservationParams): Promise<Observation> => {
    return await observationRepository.createObservation({
      status,
      subject,
      category,
      code,
      note,
      valueString
    })
  }

  const getObservation = async (id: string): Promise<Observation[]> => {
    return await observationRepository.getObservations(
      new URLSearchParams({
        _id: id
      })
    )
  }

  return {
    createObservation,
    getObservation
  }
}
