export const formatPhoneNumber = (value): string => {
  const numeric = value.toString().replace(/[^0-9]/g, '')
  const count = numeric.length
  let beginningIndex = 0
  let middleIndex = 3
  let lastIndex = 6
  const withCountryCode = count > 10
  if (withCountryCode) {
    const countryCodeLength = count - 10
    beginningIndex += countryCodeLength
    middleIndex += countryCodeLength
    lastIndex += countryCodeLength
  }

  let formatted = ''

  if (count > beginningIndex) {
    const areaCode = numeric.substring(beginningIndex, Math.min(middleIndex, count))
    formatted += `(${areaCode})`
  }
  if (count > 3) {
    const middle = numeric.substring(middleIndex, Math.min(lastIndex, count))
    formatted += `-${middle}`
  }
  if (count > 6) {
    const last = numeric.substring(lastIndex, count)
    formatted += `-${last}`
  }

  return formatted
}

export const parsePhoneNumber = (value: string): string => {
  return value.replace(/[^0-9]/g, '')
}

export const addCountryCode = (phoneNumber: string, countryCode = DEFAULT_COUNTRY_CODE): string => {
  const numericPhoneNumber = parsePhoneNumber(phoneNumber)

  if (numericPhoneNumber.startsWith(countryCode) && numericPhoneNumber.length === 11) {
    return numericPhoneNumber
  }

  return `${countryCode}${numericPhoneNumber}`
}

export const formatPhoneNumberToSave = (
  value: string,
  countryCode = DEFAULT_COUNTRY_CODE
): string => {
  const numeric = parsePhoneNumber(value)
  return addCountryCode(numeric, countryCode)
}

export const DEFAULT_COUNTRY_CODE = '1'
