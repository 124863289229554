import { ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE } from 'fhir/constants'

export const SYMPTOMS_OBSERVATION_EXTENSION = `${ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE}/symptoms`
export const INJURY_CAUSE_OBSERVATION_EXTENSION = `${ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE}/injury-cause`
export const TRAUMA_OBSERVATION_EXTENSION = `${ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE}/trauma`
export const INFECTION_TREATMENT_OBSERVATION_EXTENSION = `${ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE}/infection-treatment`
export const GRIEVANCE_COMPLAINT_OUTCOME_COMMUNICATION_EXTENSION = `${ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE}/grievance-complaint-outcome`

export const LOSS_CONSCIOUSNESS_OBSERVATION_EXTENSION = `${ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE}/loss-consciousness`
export const SLURRED_SPEECH_OBSERVATION_EXTENSION = `${ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE}/slurred-speech`

export enum OBSERVATION_STATUS {
  PRELIMINARY = 'preliminary'
}
