import { SelectProps } from 'antd'
import { capitalize } from './helpers'

type EnumValueType = string | number

export const castToEnum = <TEnum extends Record<string, unknown>>(
  enumObject: TEnum,
  value: EnumValueType | undefined
): TEnum[keyof TEnum] | undefined => {
  if (value === undefined) return undefined

  const enumValues = Object.values(enumObject) as EnumValueType[]
  const enumEntries = Object.entries(enumObject).map(([key, val]) => ({ key, val })) as {
    key: string
    val: EnumValueType
  }[]

  const isNumericEnum = enumValues.every((v) => typeof v === 'number')

  if (isNumericEnum && typeof value === 'string') {
    const numericValue = Number(value)
    if (!isNaN(numericValue)) {
      return enumObject[enumObject[numericValue] as keyof TEnum]
    }
  }

  const match = enumEntries.find((entry) => entry.val === value || entry.key === value)
  return match ? enumObject[match.key as keyof TEnum] : undefined
}

export const enumToSelectOptions = <TEnum extends Record<string, string>>(
  enumObject: TEnum,
  capitalizeLabels = false
): SelectProps['options'] => {
  return Object.values(enumObject).map((val) => ({
    label: capitalizeLabels ? capitalize(val) : val,
    value: val
  }))
}
