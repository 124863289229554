import gql from 'gql-tag'
import { TASK_IDENTIFIER_CODE_SYSTEM, TaskCode, TaskIdentifier } from 'fhir/Task/constants'
import { BASE_ENCOUNTER_FRAGMENT } from 'fragments/encounter.query'
import { BASE_APPOINTMENT_FRAGMENT } from 'fragments/appointment.query'
import { BASE_PRACTITIONER_FRAGMENT } from 'fragments/practitioner.query'
import { BASE_PATIENT_FRAGMENT } from 'fragments/patient.query'
import { BASE_TASK_FRAGMENT } from 'fragments/task.query'
import { BASE_EPISODE_OF_CARE_FRAGMENT } from 'fragments/episodeOfCare.query'

export const GET_VISIT_FRAGMENT = gql`
  fragment GetCNAVisit_Visit on Encounter {
    ...BaseEncounter
    visitQATasks: TaskList(_reference: focus, code: "${TaskCode.CNA_VISIT_QA}", _count: 1000, _sort: "-_lastUpdated") {
      ...BaseTask
    }
    episodeOfCare {
      resource {
        ...BaseEpisodeOfCare
      }
    }
    appointment {
      resource {
        ...BaseAppointment
      }
    }
    interventions: TaskList(_reference: encounter, identifier: "${TASK_IDENTIFIER_CODE_SYSTEM}|${TaskIdentifier.CNA_VISIT}", _count: 1000, _sort: "-_lastUpdated") {
      ...BaseTask
    }
  }
`

export const GET_CNA_VISIT = gql`
  query GetCNAVisit($visitId: ID!) {
    Encounter(id: $visitId) {
      ...GetCNAVisit_Visit
    }
  }
  ${GET_VISIT_FRAGMENT}
  ${BASE_ENCOUNTER_FRAGMENT}
  ${BASE_APPOINTMENT_FRAGMENT}
  ${BASE_PRACTITIONER_FRAGMENT}
  ${BASE_PATIENT_FRAGMENT}
  ${BASE_EPISODE_OF_CARE_FRAGMENT}
  ${BASE_TASK_FRAGMENT}
`
