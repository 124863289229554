import gql from 'gql-tag'

export const BASE_ORGANIZATION_FRAGMENT = gql`
  fragment BaseOrganization on Organization {
    resourceType
    id
    orgName: name
    type {
      coding {
        system
        code
        display
      }
    }
    identifier {
      system
      value
      assigner {
        reference
      }
    }
    extension {
      url
      valueCoding {
        system
        code
      }
    }
    telecom {
      system
      value
    }
    address {
      text
      line
      city
      state
      postalCode
    }
  }
`
