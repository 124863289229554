import { QuestionnaireAnswerType } from 'components/Assessments/types'
import { PatAssessmentQuestionnaireItem } from './questionnaire'

export const PAT_QUESTIONNAIRE_ITEM_TO_ANSWER_TYPE = {
  // Assessment overview
  [PatAssessmentQuestionnaireItem.ASSESSMENT_OVERVIEW_APPOINTMENT_TIME_START]:
    QuestionnaireAnswerType.DATE_TIME,
  [PatAssessmentQuestionnaireItem.ASSESSMENT_OVERVIEW_APPOINTMENT_TIME_END]:
    QuestionnaireAnswerType.DATE_TIME,

  // Patient background
  [PatAssessmentQuestionnaireItem.PATIENT_BACKGROUND_PATIENT_VERIFICATION_LEGAL_NAME]:
    QuestionnaireAnswerType.STRING,
  [PatAssessmentQuestionnaireItem.PATIENT_BACKGROUND_PATIENT_VERIFICATION_PREFERRED_NAME]:
    QuestionnaireAnswerType.STRING,
  [PatAssessmentQuestionnaireItem.PATIENT_BACKGROUND_PATIENT_VERIFICATION_ADDRESS]:
    QuestionnaireAnswerType.STRING,
  [PatAssessmentQuestionnaireItem.PATIENT_BACKGROUND_PATIENT_VERIFICATION_DOB]:
    QuestionnaireAnswerType.DATE,
  [PatAssessmentQuestionnaireItem.PATIENT_BACKGROUND_PATIENT_VERIFICATION_GENDER]:
    QuestionnaireAnswerType.CODING,
  [PatAssessmentQuestionnaireItem.PATIENT_BACKGROUND_PATIENT_VERIFICATION_MEDICAID_ID]:
    QuestionnaireAnswerType.STRING,
  [PatAssessmentQuestionnaireItem.PATIENT_BACKGROUND_HISTORY_AND_DIAGNOSES_PRIMARY_DIAGNOSES]:
    QuestionnaireAnswerType.CODING,
  [PatAssessmentQuestionnaireItem.PATIENT_BACKGROUND_HISTORY_AND_DIAGNOSES_OTHER_DIAGNOSES]:
    QuestionnaireAnswerType.CODING,
  [PatAssessmentQuestionnaireItem.PATIENT_BACKGROUND_HISTORY_AND_DIAGNOSES_THERAPIES]:
    QuestionnaireAnswerType.CODING,
  [PatAssessmentQuestionnaireItem.PATIENT_BACKGROUND_HISTORY_AND_DIAGNOSES_EQUIPMENT]:
    QuestionnaireAnswerType.CODING,
  [PatAssessmentQuestionnaireItem.PATIENT_BACKGROUND_HISTORY_AND_DIAGNOSES_PCP]:
    QuestionnaireAnswerType.CODING,
  [PatAssessmentQuestionnaireItem.PATIENT_BACKGROUND_HISTORY_AND_DIAGNOSES_ER_QUESTION]:
    QuestionnaireAnswerType.CODING,
  [PatAssessmentQuestionnaireItem.PATIENT_BACKGROUND_HISTORY_AND_DIAGNOSES_ER_NOTES]:
    QuestionnaireAnswerType.STRING,
  [PatAssessmentQuestionnaireItem.PATIENT_BACKGROUND_HISTORY_AND_DIAGNOSES_HOSPITAL_QUESTION]:
    QuestionnaireAnswerType.CODING,
  [PatAssessmentQuestionnaireItem.PATIENT_BACKGROUND_HISTORY_AND_DIAGNOSES_HOSPITAL_NOTES]:
    QuestionnaireAnswerType.STRING,
  [PatAssessmentQuestionnaireItem.PATIENT_BACKGROUND_HISTORY_AND_DIAGNOSES_INJURIES_QUESTION]:
    QuestionnaireAnswerType.CODING,
  [PatAssessmentQuestionnaireItem.PATIENT_BACKGROUND_HISTORY_AND_DIAGNOSES_INJURIES_NOTES]:
    QuestionnaireAnswerType.STRING,
  [PatAssessmentQuestionnaireItem.PATIENT_BACKGROUND_HISTORY_AND_DIAGNOSES_FALLS_QUESTION]:
    QuestionnaireAnswerType.CODING,
  [PatAssessmentQuestionnaireItem.PATIENT_BACKGROUND_HISTORY_AND_DIAGNOSES_FALLS_NOTES]:
    QuestionnaireAnswerType.STRING,
  [PatAssessmentQuestionnaireItem.PATIENT_BACKGROUND_HISTORY_AND_DIAGNOSES_INFECTIONS_QUESTION]:
    QuestionnaireAnswerType.CODING,
  [PatAssessmentQuestionnaireItem.PATIENT_BACKGROUND_HISTORY_AND_DIAGNOSES_INFECTIONS_NOTES]:
    QuestionnaireAnswerType.STRING,
  [PatAssessmentQuestionnaireItem.PATIENT_BACKGROUND_HISTORY_AND_DIAGNOSES_MD_QUESTION]:
    QuestionnaireAnswerType.CODING,
  [PatAssessmentQuestionnaireItem.PATIENT_BACKGROUND_HISTORY_AND_DIAGNOSES_MD_NOTES]:
    QuestionnaireAnswerType.STRING,

  // Modifier A
  [PatAssessmentQuestionnaireItem.MODIFIER_A_1]: QuestionnaireAnswerType.CODING,
  [PatAssessmentQuestionnaireItem.MODIFIER_A_2]: QuestionnaireAnswerType.CODING,
  [PatAssessmentQuestionnaireItem.MODIFIER_A_3]: QuestionnaireAnswerType.CODING,
  [PatAssessmentQuestionnaireItem.MODIFIER_A_4]: QuestionnaireAnswerType.CODING,

  // Braden Scale
  [PatAssessmentQuestionnaireItem.BRADEN_SCALE_SENSORY]: QuestionnaireAnswerType.CODING,
  [PatAssessmentQuestionnaireItem.BRADEN_SCALE_MOISTURE]: QuestionnaireAnswerType.CODING,
  [PatAssessmentQuestionnaireItem.BRADEN_SCALE_ACTIVITY]: QuestionnaireAnswerType.CODING,
  [PatAssessmentQuestionnaireItem.BRADEN_SCALE_MOBILITY]: QuestionnaireAnswerType.CODING,
  [PatAssessmentQuestionnaireItem.BRADEN_SCALE_NUTRITION]: QuestionnaireAnswerType.CODING,
  [PatAssessmentQuestionnaireItem.BRADEN_SCALE_FRICTION_AND_SHEAR]: QuestionnaireAnswerType.CODING,

  // Modifier B
  [PatAssessmentQuestionnaireItem.MODIFIER_B_1]: QuestionnaireAnswerType.CODING,
  [PatAssessmentQuestionnaireItem.MODIFIER_B_2]: QuestionnaireAnswerType.CODING,
  [PatAssessmentQuestionnaireItem.MODIFIER_B_3]: QuestionnaireAnswerType.CODING,
  [PatAssessmentQuestionnaireItem.MODIFIER_B_4]: QuestionnaireAnswerType.CODING,
  [PatAssessmentQuestionnaireItem.MODIFIER_B_5]: QuestionnaireAnswerType.CODING,
  [PatAssessmentQuestionnaireItem.MODIFIER_B_6]: QuestionnaireAnswerType.CODING,
  [PatAssessmentQuestionnaireItem.MODIFIER_B_7]: QuestionnaireAnswerType.CODING,

  // CNA Skilled Tasks
  [PatAssessmentQuestionnaireItem.CNA_SKILLED_TASKS_BATHING]: QuestionnaireAnswerType.CODING,
  [PatAssessmentQuestionnaireItem.CNA_SKILLED_TASKS_BRACES_SPLINTS_STOCKINGS]:
    QuestionnaireAnswerType.CODING,
  [PatAssessmentQuestionnaireItem.CNA_SKILLED_TASKS_BRACES_SPLINTS_STOCKINGS_ADDITIONAL]:
    QuestionnaireAnswerType.CODING,
  [PatAssessmentQuestionnaireItem.CNA_SKILLED_TASKS_DRESSING]: QuestionnaireAnswerType.CODING,
  [PatAssessmentQuestionnaireItem.CNA_SKILLED_TASKS_EATING]: QuestionnaireAnswerType.CODING,
  [PatAssessmentQuestionnaireItem.CNA_SKILLED_TASKS_TUBE_FEEDINGS]: QuestionnaireAnswerType.CODING,
  [PatAssessmentQuestionnaireItem.CNA_SKILLED_TASKS_MEDICATION]: QuestionnaireAnswerType.CODING,
  [PatAssessmentQuestionnaireItem.CNA_SKILLED_TASKS_MOBILITY]: QuestionnaireAnswerType.CODING,
  [PatAssessmentQuestionnaireItem.CNA_SKILLED_TASKS_MOBILITY_ADDITIONAL]:
    QuestionnaireAnswerType.CODING,
  [PatAssessmentQuestionnaireItem.CNA_SKILLED_TASKS_MEAL_PREP]: QuestionnaireAnswerType.CODING,
  [PatAssessmentQuestionnaireItem.CNA_SKILLED_TASKS_GROOMING]: QuestionnaireAnswerType.CODING,
  [PatAssessmentQuestionnaireItem.CNA_SKILLED_TASKS_GROOMING_ADDITIONAL]:
    QuestionnaireAnswerType.CODING,
  [PatAssessmentQuestionnaireItem.CNA_SKILLED_TASKS_SKIN_CARE_APPLICATION]:
    QuestionnaireAnswerType.CODING,
  [PatAssessmentQuestionnaireItem.CNA_SKILLED_TASKS_SKIN_CARE_APPLICATION_ADDITIONAL]:
    QuestionnaireAnswerType.CODING,
  [PatAssessmentQuestionnaireItem.CNA_SKILLED_TASKS_SKIN_CARE]: QuestionnaireAnswerType.CODING,
  [PatAssessmentQuestionnaireItem.CNA_SKILLED_TASKS_SKIN_CARE_ADDITIONAL]:
    QuestionnaireAnswerType.CODING,
  [PatAssessmentQuestionnaireItem.CNA_SKILLED_TASKS_ROM_EXERCISE]: QuestionnaireAnswerType.CODING,
  [PatAssessmentQuestionnaireItem.CNA_SKILLED_TASKS_RESPIRATORY_CARE]:
    QuestionnaireAnswerType.CODING,
  [PatAssessmentQuestionnaireItem.CNA_SKILLED_TASKS_OXYGEN_THERAPY]: QuestionnaireAnswerType.CODING,
  [PatAssessmentQuestionnaireItem.CNA_SKILLED_TASKS_ORAL_SUCTIONING]:
    QuestionnaireAnswerType.CODING,
  [PatAssessmentQuestionnaireItem.CNA_SKILLED_TASKS_BOWEL_FREQUENCY]:
    QuestionnaireAnswerType.CODING,
  [PatAssessmentQuestionnaireItem.CNA_SKILLED_TASKS_BOWEL_HYGIENE]: QuestionnaireAnswerType.CODING,
  [PatAssessmentQuestionnaireItem.CNA_SKILLED_TASKS_BOWEL_ELIMINATION]:
    QuestionnaireAnswerType.CODING,
  [PatAssessmentQuestionnaireItem.CNA_SKILLED_TASKS_BOWEL_PROGRAM]: QuestionnaireAnswerType.CODING,
  [PatAssessmentQuestionnaireItem.CNA_SKILLED_TASKS_CATHETER_CARE]: QuestionnaireAnswerType.CODING,
  [PatAssessmentQuestionnaireItem.CNA_SKILLED_TASKS_MENSES]: QuestionnaireAnswerType.CODING,
  [PatAssessmentQuestionnaireItem.CNA_SKILLED_TASKS_URINARY_HYGIENE]:
    QuestionnaireAnswerType.CODING,
  [PatAssessmentQuestionnaireItem.CNA_SKILLED_TASKS_POSITIONING]: QuestionnaireAnswerType.CODING,
  [PatAssessmentQuestionnaireItem.CNA_SKILLED_TASKS_TRANSFERS]: QuestionnaireAnswerType.CODING,
  [PatAssessmentQuestionnaireItem.CNA_SKILLED_TASKS_VITALS_PHYSICIAN]:
    QuestionnaireAnswerType.CODING,
  [PatAssessmentQuestionnaireItem.CNA_SKILLED_TASKS_VITALS_NURSE]: QuestionnaireAnswerType.CODING,

  // Scores
  [PatAssessmentQuestionnaireItem.SCORE_AND_SUMMARY]: QuestionnaireAnswerType.GROUP,
  [PatAssessmentQuestionnaireItem.SCORE_AND_SUMMARY_MODIFIER_A]: QuestionnaireAnswerType.DECIMAL,
  [PatAssessmentQuestionnaireItem.SCORE_AND_SUMMARY_MODIFIER_B]: QuestionnaireAnswerType.DECIMAL,
  [PatAssessmentQuestionnaireItem.SCORE_AND_SUMMARY_CNA_SKILLED_TASKS]:
    QuestionnaireAnswerType.DECIMAL,
  [PatAssessmentQuestionnaireItem.SCORE_AND_SUMMARY_TOTAL]: QuestionnaireAnswerType.DECIMAL,

  [PatAssessmentQuestionnaireItem.NEXT_STEPS_QA]: QuestionnaireAnswerType.CODING
}
