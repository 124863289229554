import { FC, useEffect, useMemo, useState } from 'react'
import { Button, Form, FormInstance } from 'antd'
import styled from 'styled-components'
import { XClose } from 'public/assets/icons'
import IconButton from 'components/Common/IconButton/IconButton'
import { ClinicalNoteTypes, GeneralNoteTypes } from 'fhir/Communication/constants'
import { CLINICAL_NOTE_TYPE_MAP, PATIENT_CLIENT_NOTE_TYPE_MAP } from 'utils/staticLists/Notes/notes'
import NoteFormItem from './NoteFormItem'
import { clinicalNoteFields, commonFields, generalNoteFields } from './noteFields'
import useCreateClinicalNote from '../../hooks/useCreateNote'

interface NoteTypesFormContainerProps {
  form: FormInstance
  closePopupForm: VoidFunction
  source: 'navbar' | 'assessment'
  loadPatientNotesData?: VoidFunction
}

const NoteTypesFormContainer: FC<NoteTypesFormContainerProps> = ({
  form,
  closePopupForm,
  source,
  loadPatientNotesData
}) => {
  const {
    createHospitalizationNote,
    createMedicalProcedurenNote,
    createERVisitNote,
    createInfectionNote,
    createInjuryNote,
    createCareCoordinationNote,
    createGeneralNote,
    createGrievanceComplaintNote,
    createFallNote
  } = useCreateClinicalNote(source)
  const [formType, setFormType] = useState('')

  const createClinicalNoteCompletionHandlerMap = {
    [ClinicalNoteTypes.HOSPITALIZATION]: createHospitalizationNote,
    [ClinicalNoteTypes.MEDICAL_PROCEDURE]: createMedicalProcedurenNote,
    [ClinicalNoteTypes.ER_VISIT]: createERVisitNote,
    [ClinicalNoteTypes.INFECTION]: createInfectionNote,
    [ClinicalNoteTypes.INJURY]: createInjuryNote,
    [ClinicalNoteTypes.CARE_COORDINATION]: createCareCoordinationNote,
    [ClinicalNoteTypes.FALL]: createFallNote,
    [GeneralNoteTypes.GENERAL]: createGeneralNote,
    [GeneralNoteTypes.GRIEVANCE_COMPLAINT]: createGrievanceComplaintNote
  }

  const createGeneralNoteCompletionHandlerMap = {
    [GeneralNoteTypes.GENERAL]: createGeneralNote,
    [GeneralNoteTypes.GRIEVANCE_COMPLAINT]: createGrievanceComplaintNote
  }

  const onFormFinish = async (): Promise<void> => {
    const values = await form.validateFields()
    if (values) {
      const result = await {
        ...createClinicalNoteCompletionHandlerMap,
        ...createGeneralNoteCompletionHandlerMap
      }[formType](values)
      if (result) {
        loadPatientNotesData && loadPatientNotesData()
        closePopupForm()
        setFormType('')
        form.resetFields()
      }
    }
  }

  const isWithinAssessment = source === 'assessment'

  const NoteTypeFormField = useMemo((): React.ReactElement => {
    const noteList = isWithinAssessment ? CLINICAL_NOTE_TYPE_MAP : PATIENT_CLIENT_NOTE_TYPE_MAP
    const options = Object.keys(noteList).map((type) => ({
      label: noteList[type].label,
      value: type
    }))
    return NoteFormItem({
      ...commonFields.noteType,
      options,
      onChange: (e) => {
        resetFormFields()
        form.setFieldValue('noteType', e)
        setFormType(e)
      }
    })
  }, [])

  const excludeField = ['patientId']
  const resetFormFields = (): void => {
    const fields = form.getFieldsValue()
    Object.keys(fields).forEach((key) => {
      if (!excludeField.includes(key)) {
        form.setFieldValue(key, undefined)
      }
    })
  }

  const SearchablePatientFormField = useMemo(
    (): React.ReactElement => NoteFormItem(commonFields.searchablePatient),
    []
  )

  // Reset form type on unmount
  useEffect(() => setFormType(''), [])

  return (
    <>
      <IconButton
        type="ghost"
        icon={<XClose style={{ width: 20 }} />}
        onClick={() => closePopupForm()}
        style={{ position: 'absolute', top: '8px', right: '8px' }}
      >
        {''}
      </IconButton>
      <br />
      {!isWithinAssessment && SearchablePatientFormField}
      {NoteTypeFormField}
      {formType &&
        { ...clinicalNoteFields, ...generalNoteFields }[formType]?.map((inputData) => {
          let CurrentComponent: React.ReactElement | null = null
          if (Array.isArray(inputData)) {
            CurrentComponent = (
              <NoteFormSection>
                {inputData.map((data) => {
                  const CurrentComponent = NoteFormItem(data, data.name)
                  return CurrentComponent
                })}
              </NoteFormSection>
            )
          } else {
            CurrentComponent = NoteFormItem(inputData, inputData.name)
          }
          return CurrentComponent
        })}

      <Form.Item wrapperCol={{ offset: 13, span: 16 }} style={{ marginTop: '30px' }}>
        <Button style={{ marginRight: '10px' }} onClick={() => closePopupForm()}>
          Cancel
        </Button>
        <Button style={{ margin: 0 }} onClick={onFormFinish}>
          Create Note
        </Button>
      </Form.Item>
    </>
  )
}

const NoteFormSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
`

export default NoteTypesFormContainer
