import { useMedplum } from '@medplum/react'
import { useCallback, useEffect, useState } from 'react'
import { getGraphQLErrorMessage } from 'utils/helpers'
interface UseQueryResult<T> {
  isLoading: boolean
  error: string | null
  data: T | null | undefined
  reloadData: (dynamicParams?: Record<string, any>) => Promise<T>
}

interface UseQueryProps<TVariables> {
  query: string
  params?: TVariables
  dependency?: any
  skip?: boolean
}

export function useQuery<T, TVariables = Record<string, any>>({
  query,
  params,
  dependency,
  skip = false
}: UseQueryProps<TVariables>): UseQueryResult<T> {
  const medplum = useMedplum()
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const [data, setData] = useState<T>()

  const reloadData = useCallback(
    async (dynamicParams: object = {}): Promise<T> => {
      if (skip) return undefined as T
      try {
        setIsLoading(true)
        const finalParams = { ...params, ...dynamicParams }
        const { data: result, errors }: { data: T; errors: any } = await medplum.graphql(
          query,
          null,
          finalParams
        )

        // Throwing an error also logs it in sentry by the error boundary
        if (!result && errors) {
          const errorMessage = `A Graphql query error is returned from Medplum ${getGraphQLErrorMessage(
            errors
          )}`
          throw new Error(errorMessage)
        }

        setData(result)
        setIsLoading(false)
        setError(null)

        return result
      } catch (e) {
        if (e instanceof Error) {
          setError(e.message)
        }

        throw new Error('Error in useQuery', { cause: e })
      } finally {
        setIsLoading(false)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [medplum, query, dependency]
  )

  useEffect(() => {
    if (skip) {
      setIsLoading(false)
      return
    }

    reloadData()
  }, [reloadData, skip])

  return { isLoading, error, data, reloadData }
}
