import { PatchOperation } from '@medplum/core'
import { Annotation, Communication } from '@medplum/fhirtypes'
import { useMedplum } from '@medplum/react'
import { useCallback } from 'react'

interface CommunicationRepository {
  updateCommunication(communication: UpdateCommunication): Promise<Communication>
  createCommunication(communication: CreateCommunication): Promise<Communication>
  getCommunications(query: URLSearchParams): Promise<Communication[]>
}

interface UpdateCommunication {
  communicationId: string
  note?: Annotation
  hasExistingNotes?: boolean
  status?: Communication['status']
}

interface CreateCommunication {
  recipient: Communication['recipient']
  sender?: Communication['sender']
  sent?: Communication['sent']
  reasonCode?: Communication['reasonCode']
  statusReason: Communication['statusReason']
  topic: Communication['topic']
  note?: Annotation
  subject: Communication['subject']
  category: Communication['category']
  encounter?: Communication['encounter']
  reasonReference?: Communication['reasonReference']
  identifier?: Communication['identifier']
  extension?: Communication['extension']
}

export function useCommunicationRepository(): CommunicationRepository {
  const medplum = useMedplum()

  const updateCommunication = useCallback(
    async ({
      communicationId,
      hasExistingNotes,
      note,
      status
    }: UpdateCommunication): Promise<Communication> => {
      const operations: PatchOperation[] = []

      if (note) {
        operations.push({
          op: 'add',
          path: hasExistingNotes ? '/note/-' : '/note',
          value: hasExistingNotes ? note : [note]
        })
      }
      if (status) {
        operations.push({
          op: 'replace',
          path: '/status',
          value: status
        })
      }
      return await medplum.patchResource('Communication', communicationId as string, operations)
    },
    [medplum]
  )

  const createCommunication = useCallback(
    async ({
      recipient,
      sender,
      sent = new Date().toISOString(),
      statusReason,
      reasonCode,
      topic,
      note,
      subject,
      category,
      encounter,
      reasonReference,
      identifier,
      extension
    }: CreateCommunication): Promise<Communication> => {
      return await medplum.createResource({
        resourceType: 'Communication',
        status: 'in-progress',
        recipient,
        sender,
        sent,
        statusReason,
        reasonCode,
        topic,
        note: note ? [note] : undefined,
        subject,
        category,
        encounter,
        reasonReference,
        identifier,
        extension
      })
    },
    [medplum]
  )

  const getCommunications = useCallback(
    async (query: URLSearchParams): Promise<Communication[]> => {
      return await medplum.searchResources('Communication', query, {
        cache: 'reload'
      })
    },
    [medplum]
  )

  return {
    createCommunication,
    getCommunications,
    updateCommunication
  }
}
