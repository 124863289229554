import { QuestionnaireAnswerType } from 'components/Assessments/types'
import { RecertAssessmentQuestionnaireItem } from './questionnaire'

export const RECERT_QUESTIONNAIRE_ITEM_TO_ANSWER_TYPE = {
  // Assessment overview
  [RecertAssessmentQuestionnaireItem.ASSESSMENT_OVERVIEW_APPOINTMENT_TIME_START]:
    QuestionnaireAnswerType.DATE_TIME,
  [RecertAssessmentQuestionnaireItem.ASSESSMENT_OVERVIEW_APPOINTMENT_TIME_END]:
    QuestionnaireAnswerType.DATE_TIME,

  // Cert Period Recap
  [RecertAssessmentQuestionnaireItem.CERT_PERIOD_RECAP_CONSENT]: QuestionnaireAnswerType.REFERENCE,
  [RecertAssessmentQuestionnaireItem.CERT_PERIOD_RECAP_CARE_STATUS_60_DAY_ER]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.CERT_PERIOD_RECAP_CARE_STATUS_60_DAY_ER_NOTES]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.CERT_PERIOD_RECAP_CARE_STATUS_60_DAY_HOSPITAL]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.CERT_PERIOD_RECAP_CARE_STATUS_60_DAY_HOSPITAL_NOTES]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.CERT_PERIOD_RECAP_CARE_STATUS_60_DAY_FALLS]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.CERT_PERIOD_RECAP_CARE_STATUS_60_DAY_FALLS_NOTES]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.CERT_PERIOD_RECAP_CARE_STATUS_60_DAY_INFECTIONS]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.CERT_PERIOD_RECAP_CARE_STATUS_60_DAY_INFECTIONS_NOTES]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.CERT_PERIOD_RECAP_CARE_STATUS_60_DAY_MD]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.CERT_PERIOD_RECAP_CARE_STATUS_60_DAY_MD_NOTES]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.CERT_PERIOD_RECAP_CARE_STATUS_60_DAY_INJURIES]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.CERT_PERIOD_RECAP_CARE_STATUS_60_DAY_INJURIES_NOTES]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.CERT_PERIOD_RECAP_GOALS]: QuestionnaireAnswerType.GROUP,
  [RecertAssessmentQuestionnaireItem.CERT_PERIOD_RECAP_GOALS_GOAL_ID]:
    QuestionnaireAnswerType.INTEGER,
  [RecertAssessmentQuestionnaireItem.CERT_PERIOD_RECAP_GOALS_GOAL_STATUS]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.CERT_PERIOD_RECAP_INITIALS_AUTHORIZATION]:
    QuestionnaireAnswerType.STRING,

  // Authorizations
  [RecertAssessmentQuestionnaireItem.AUTHORIZATION]: QuestionnaireAnswerType.GROUP,
  [RecertAssessmentQuestionnaireItem.AUTHORIZATION_AUTHORIZATION]: QuestionnaireAnswerType.GROUP,
  [RecertAssessmentQuestionnaireItem.AUTHORIZATION_AUTHORIZATION_AUTHORIZATION_SOURCE]:
    QuestionnaireAnswerType.REFERENCE,
  [RecertAssessmentQuestionnaireItem.AUTHORIZATION_AUTHORIZATION_CURRENT_HOURS]:
    QuestionnaireAnswerType.DECIMAL,
  [RecertAssessmentQuestionnaireItem.AUTHORIZATION_AUTHORIZATION_IS_REVISING_CARE_HOURS]:
    QuestionnaireAnswerType.BOOLEAN,
  [RecertAssessmentQuestionnaireItem.AUTHORIZATION_AUTHORIZATION_REVISED_CARE_HOURS]:
    QuestionnaireAnswerType.DECIMAL,
  [RecertAssessmentQuestionnaireItem.AUTHORIZATION_AUTHORIZATION_REASON_FOR_REVISION]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.AUTHORIZATION_AUTHORIZATION_ADDITIONAL_DETAILS]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.AUTHORIZATION_AUTHORIZATION_ORDERING_PHYSICIAN]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.AUTHORIZATION_AUTHORIZATION_PCP]:
    QuestionnaireAnswerType.STRING,

  // Visit scheduling
  [RecertAssessmentQuestionnaireItem.VISIT_SCHEDULING_PATIENT_SCHEDULE]:
    QuestionnaireAnswerType.GROUP,
  [RecertAssessmentQuestionnaireItem.VISIT_SCHEDULING_PATIENT_SCHEDULE_WAKEUP_TIME]:
    QuestionnaireAnswerType.TIME,
  [RecertAssessmentQuestionnaireItem.VISIT_SCHEDULING_PATIENT_SCHEDULE_BEDTIME]:
    QuestionnaireAnswerType.TIME,
  [RecertAssessmentQuestionnaireItem.VISIT_SCHEDULING_PATIENT_SCHEDULE_SCHOOL_TIMES]:
    QuestionnaireAnswerType.GROUP,
  [RecertAssessmentQuestionnaireItem.VISIT_SCHEDULING_PATIENT_SCHEDULE_SCHOOL_TIMES_DAY]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.VISIT_SCHEDULING_PATIENT_SCHEDULE_SCHOOL_TIMES_PERIOD]:
    QuestionnaireAnswerType.GROUP,
  [RecertAssessmentQuestionnaireItem.VISIT_SCHEDULING_PATIENT_SCHEDULE_SCHOOL_TIMES_PERIOD_START]:
    QuestionnaireAnswerType.TIME,
  [RecertAssessmentQuestionnaireItem.VISIT_SCHEDULING_PATIENT_SCHEDULE_SCHOOL_TIMES_PERIOD_END]:
    QuestionnaireAnswerType.TIME,
  [RecertAssessmentQuestionnaireItem.VISIT_SCHEDULING_PATIENT_SCHEDULE_OTHER_APPOINTMENTS]:
    QuestionnaireAnswerType.GROUP,
  [RecertAssessmentQuestionnaireItem.VISIT_SCHEDULING_PATIENT_SCHEDULE_OTHER_APPOINTMENTS_NAME]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.VISIT_SCHEDULING_PATIENT_SCHEDULE_OTHER_APPOINTMENTS_PERIOD]:
    QuestionnaireAnswerType.GROUP,
  [RecertAssessmentQuestionnaireItem.VISIT_SCHEDULING_PATIENT_SCHEDULE_OTHER_APPOINTMENTS_PERIOD_START]:
    QuestionnaireAnswerType.TIME,
  [RecertAssessmentQuestionnaireItem.VISIT_SCHEDULING_PATIENT_SCHEDULE_OTHER_APPOINTMENTS_PERIOD_END]:
    QuestionnaireAnswerType.TIME,
  [RecertAssessmentQuestionnaireItem.VISIT_SCHEDULING_PATIENT_SCHEDULE_OTHER_APPOINTMENTS_REPEATS_ON_DAY]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.VISIT_SCHEDULING_CUSTOM_SCHEDULE_VALID]:
    QuestionnaireAnswerType.BOOLEAN,
  [RecertAssessmentQuestionnaireItem.VISIT_SCHEDULING_CUSTOM_SCHEDULE_VISITS]:
    QuestionnaireAnswerType.GROUP,
  [RecertAssessmentQuestionnaireItem.VISIT_SCHEDULING_CUSTOM_SCHEDULE_VISITS_PERIOD]:
    QuestionnaireAnswerType.GROUP,
  [RecertAssessmentQuestionnaireItem.VISIT_SCHEDULING_CUSTOM_SCHEDULE_VISITS_PERIOD_START]:
    QuestionnaireAnswerType.TIME,
  [RecertAssessmentQuestionnaireItem.VISIT_SCHEDULING_CUSTOM_SCHEDULE_VISITS_PERIOD_END]:
    QuestionnaireAnswerType.TIME,
  [RecertAssessmentQuestionnaireItem.VISIT_SCHEDULING_CUSTOM_SCHEDULE_VISITS_PERIOD_CAREGIVER]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.VISIT_SCHEDULING_CUSTOM_SCHEDULE_CHANGE_REASON]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.VISIT_SCHEDULING_CUSTOM_SCHEDULE_ADDITIONAL_NOTES]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.VISIT_SCHEDULING_INITIALS_AUTHORIZATION]:
    QuestionnaireAnswerType.STRING,

  // Medical background
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_BACKGROUND_PRIMARY_DIAGNOSIS]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_BACKGROUND_OTHER_DIAGNOSIS]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_BACKGROUND_EQUIPMENT]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_BACKGROUND_DME_PROVIDER]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_BACKGROUND_ALLERGIES]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_BACKGROUND_ALLERGIES_NOTES]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_BACKGROUND_WAIVERS]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_BACKGROUND_ADDITIONAL_SERVICES]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_BACKGROUND_PROGNOSIS]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_BACKGROUND_DNR]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_BACKGROUND_ADVANCE_DIRECTIVES]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_BACKGROUND_ADVANCE_DIRECTIVES_NOTES]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_HISTORY_FUNCTIONAL_LIMITATIONS]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_HISTORY_ACTIVITIES_PERMITTED]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_HISTORY_MEDICAL_HISTORY]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_HISTORY_SURGICAL_HISTORY]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_IMMUNIZATIONS_HEP_B]:
    QuestionnaireAnswerType.GROUP,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_IMMUNIZATIONS_HEP_B_HEP_B]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_IMMUNIZATIONS_HEP_B_DATE]:
    QuestionnaireAnswerType.DATE,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_IMMUNIZATIONS_MENINGOCOCCAL]:
    QuestionnaireAnswerType.GROUP,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_IMMUNIZATIONS_MENINGOCOCCAL_MENINGOCOCCAL]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_IMMUNIZATIONS_MENINGOCOCCAL_DATE]:
    QuestionnaireAnswerType.DATE,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_IMMUNIZATIONS_COVID_19]:
    QuestionnaireAnswerType.GROUP,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_IMMUNIZATIONS_COVID_19_COVID_19]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_IMMUNIZATIONS_COVID_19_DATE]:
    QuestionnaireAnswerType.DATE,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_IMMUNIZATIONS_DTAP]:
    QuestionnaireAnswerType.GROUP,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_IMMUNIZATIONS_DTAP_DTAP]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_IMMUNIZATIONS_DTAP_DATE]:
    QuestionnaireAnswerType.DATE,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_IMMUNIZATIONS_HPV]:
    QuestionnaireAnswerType.GROUP,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_IMMUNIZATIONS_HPV_HPV]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_IMMUNIZATIONS_HPV_DATE]:
    QuestionnaireAnswerType.DATE,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_IMMUNIZATIONS_RSV]:
    QuestionnaireAnswerType.GROUP,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_IMMUNIZATIONS_RSV_RSV]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_IMMUNIZATIONS_RSV_DATE]:
    QuestionnaireAnswerType.DATE,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_IMMUNIZATIONS_NOTES]:
    QuestionnaireAnswerType.STRING,

  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_RISK_ASSESSMENT_HOSPITALIZATION_RISK]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_RISK_ASSESSMENT_HOSPITALIZATION_RISK_FACTORS]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_RISK_ASSESSMENT_TRIAGE_CODE]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_RISK_ASSESSMENT_EMERGENCY_PLAN]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_RISK_ASSESSMENT_SAFE_ROOM]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_RISK_ASSESSMENT_FIRE_ASSESSMENT_OXYGEN]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_RISK_ASSESSMENT_FIRE_SAFETY_PRECAUTIONS]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_RISK_ASSESSMENT_HOMEBOUND_STATUS]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_RISK_ASSESSMENT_INFECTION_RISK]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_RISK_ASSESSMENT_INFECTION_RISK_FACTORS]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_RISK_ASSESSMENT_FALL_RISK]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_RISK_ASSESSMENT_FALL_RISK_FACTORS]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_RISK_ASSESSMENT_FALL_RISK_ASSESSMENT]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_RISK_ASSESSMENT_FINANCE_RISK]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_RISK_ASSESSMENT_ENVIRONMENT_SAFETY]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_RISK_ASSESSMENT_SAFETY_MEASURES]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_RISK_ASSESSMENT_NOTES]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_NEURO_HEADACHES]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_NEURO_HEADACHE_TYPE]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_NEURO_SEIZURES]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_NEURO_SEIZURE_DATE]:
    QuestionnaireAnswerType.DATE,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_NEURO_SEIZURE_TYPE]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_NEURO_SEIZURE_PRECAUTIONS]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_NEURO_NEURO_STATUS]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_NEURO_ALTERED_AFFECT]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_NEURO_ALTERED_AFFECT_FACTORS]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_NEURO_PHQ2_SCREENING]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_NEURO_PHQ2_NOTES]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_NEURO_INTEREST]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_NEURO_DEPRESSED]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_NEURO_CAREGIVER_DIFFICULTY]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_NEURO_SYSTEM_SUMMARY]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_COGNITIVE_PSYCHOSOCIAL_DEV_COMMUNICATION_ASSESSMENT]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_COGNITIVE_PSYCHOSOCIAL_DEV_COGNITIVE_ASSESSMENT]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_COGNITIVE_PSYCHOSOCIAL_DEV_HISTORY_SUBSTANCE_ABUSE]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_COGNITIVE_PSYCHOSOCIAL_DEV_SUBSTANCE_ABUSE_NOTES]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_COGNITIVE_PSYCHOSOCIAL_DEV_SYSTEM_SUMMARY]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_MUSCULOSKELETAL_MUSCULOSKELETAL_ASSESSMENT]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_MUSCULOSKELETAL_SYSTEM_SUMMARY]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_LINES_DRAINS_SYSTEM_SUMMARY]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_THERAPIES_THERAPIES]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_THERAPIES_THERAPY_PROVIDER]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_THERAPIES_HOME_EXERCISE_PLAN]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_THERAPIES_HOME_EXERCISE_DURATION]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_THERAPIES_HOME_EXERCISE_FREQUENCY]:
    QuestionnaireAnswerType.INTEGER,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_SENSORY_SENSORY_STATUS_EYES]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_SENSORY_SENSORY_STATUS_EARS]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_SENSORY_SENSORY_STATUS_NOSE]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_SENSORY_SENSORY_STATUS_TOUCH]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_SENSORY_SENSORY_STATUS_TASTE]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_SENSORY_SYSTEM_SUMMARY]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_PAIN_STATUS]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_PAIN_GOAL]: QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_PAIN_FLACC_SCALE_FACE]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_PAIN_FLACC_SCALE_LEGS]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_PAIN_FLACC_SCALE_ACTIVITY]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_PAIN_FLACC_SCALE_CRY]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_PAIN_FLACC_SCALE_CONSOLABILITY]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_PAIN_SYSTEM_SUMMARY]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_CARDIAC_CARDIOVASCULAR_WNL]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_CARDIAC_CARDIOVASCULAR_FACTORS]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_CARDIAC_HEART_SOUNDS]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_CARDIAC_SYSTEM_SUMMARY]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_RESPIRATORY_RESPIRATORY_WNL]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_RESPIRATORY_COUGH]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_RESPIRATORY_OXYGEN]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_RESPIRATORY_LUNG_SOUNDS]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_RESPIRATORY_SYSTEM_SUMMARY]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_ENDOCRINE_ENDOCRINE_WNL]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_ENDOCRINE_MENSTRUAL_CYCLE]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_ENDOCRINE_MENSTRUAL_DATE]:
    QuestionnaireAnswerType.DATE,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_ENDOCRINE_ENDOCRINE_NOTES]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_ENDOCRINE_SYSTEM_SUMMARY]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_GASTROINTESTINAL_GI_ASSESSMENT]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_GASTROINTESTINAL_BM_DATE]:
    QuestionnaireAnswerType.DATE,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_GASTROINTESTINAL_GU_ASSESSMENT]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_GASTROINTESTINAL_CATHETER]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_GASTROINTESTINAL_CATHETER_TYPE]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_GASTROINTESTINAL_FEEDING_TUBE]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_GASTROINTESTINAL_FEEDING_TUBE_AMOUNT]:
    QuestionnaireAnswerType.INTEGER,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_GASTROINTESTINAL_FEEDING_TOTAL_NUMBER]:
    QuestionnaireAnswerType.INTEGER,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_GASTROINTESTINAL_FEEDING_TUBE_DURATION]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_GASTROINTESTINAL_FEEDING_TUBE_TYPE]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_GASTROINTESTINAL_FEEDING_TUBE_SITE]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_GASTROINTESTINAL_FEEDING_TUBE_SIZE]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_GASTROINTESTINAL_FEEDING_TUBE_NOTES]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_GASTROINTESTINAL_NUTRITION_WNL]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_GASTROINTESTINAL_NUTRITIONAL_FACTORS]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_GASTROINTESTINAL_DIET_REQUIREMENTS]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_GASTROINTESTINAL_NUTRITION_NOTES]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_GASTROINTESTINAL_NUTRITIONAL_SCREENING]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_GASTROINTESTINAL_GASTROINTESTINAL_HISTORY_NOTES]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_GASTROINTESTINAL_SYSTEM_SUMMARY]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_INTEGUMENTARY_SKIN_COLOR]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_INTEGUMENTARY_SKIN_ISSUES]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_INTEGUMENTARY_NAILS]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_INTEGUMENTARY_IDENTIFIED_WOUNDS]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_INTEGUMENTARY_WOUND_NOTES]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_INTEGUMENTARY_SYSTEM_SUMMARY]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_TEMP_GREATER]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_TEMP_LESS]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_PULSE_GREATER]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_PULSE_LESS]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_RESPIRATIONS_GREATER]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_RESPIRATIONS_LESS]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_BP_GREATER]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_BP_LESS]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_WEIGHT_GREATER]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_WEIGHT_LESS]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_ETCO2_SATURATION_GREATER]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_ETCO2_SATURATION_LESS]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_O2_SATURATION_GREATER]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_O2_SATURATION_LESS]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_CARE_COORDINATION_PREVIOUS_APPOINTMENTS]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_CARE_COORDINATION_FUTURE_APPOINTMENTS]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_CARE_COORDINATION_CARE_TEAM_COORDINATION]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_CARE_COORDINATION_ORDERS_FOR_TREATMENT]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_INITIALS_AUTHORIZATION]:
    QuestionnaireAnswerType.STRING,

  // Medications
  [RecertAssessmentQuestionnaireItem.MEDICATIONS]: QuestionnaireAnswerType.GROUP,
  [RecertAssessmentQuestionnaireItem.MEDICATIONS_MEDICATION_LIST]: QuestionnaireAnswerType.GROUP,
  [RecertAssessmentQuestionnaireItem.MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_DRUGBANK_PCID]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO]:
    QuestionnaireAnswerType.GROUP,
  [RecertAssessmentQuestionnaireItem.MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_NAME]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_ROUTE]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_STRENGTH_NUMBER]:
    QuestionnaireAnswerType.DECIMAL,
  [RecertAssessmentQuestionnaireItem.MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_STRENGTH_UNIT]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_INDICATIONS]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_FREQUENCY]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_DOSAGE_NUMBER]:
    QuestionnaireAnswerType.DECIMAL,
  [RecertAssessmentQuestionnaireItem.MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_DOSAGE_UNIT]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_INSTRUCTIONS]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_USAGE_STATUS]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_START_DATE]:
    QuestionnaireAnswerType.DATE,
  [RecertAssessmentQuestionnaireItem.MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_DISCONTINUE_DATE]:
    QuestionnaireAnswerType.DATE,
  [RecertAssessmentQuestionnaireItem.MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_CONTROLLED]:
    QuestionnaireAnswerType.BOOLEAN,
  [RecertAssessmentQuestionnaireItem.MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_LIQUID]:
    QuestionnaireAnswerType.BOOLEAN,
  [RecertAssessmentQuestionnaireItem.MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_INFECTION]:
    QuestionnaireAnswerType.BOOLEAN,
  [RecertAssessmentQuestionnaireItem.MEDICATIONS_MEDICATION_SUMMARY]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.MEDICATIONS_INITIALS_AUTHORIZATION]:
    QuestionnaireAnswerType.STRING,

  // Skilled Needs
  [RecertAssessmentQuestionnaireItem.SKILLED_NEEDS]: QuestionnaireAnswerType.GROUP,
  [RecertAssessmentQuestionnaireItem.SKILLED_NEEDS_NEED]: QuestionnaireAnswerType.GROUP,
  [RecertAssessmentQuestionnaireItem.SKILLED_NEEDS_NEED_NAME]: QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.SKILLED_NEEDS_NEED_SCORE]: QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.SKILLED_NEEDS_NEED_NOTE]: QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.SKILLED_NEEDS_NEED_PREVIOUS_NOTE]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.SKILLED_NEED_INITIALS_AUTHORIZATION]:
    QuestionnaireAnswerType.STRING,

  // Discharge Planning
  [RecertAssessmentQuestionnaireItem.DISCHARGE_PLANNING]: QuestionnaireAnswerType.GROUP,
  [RecertAssessmentQuestionnaireItem.DISCHARGE_PLANNING_DISCHARGE_PLANS]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.DISCHARGE_PLANNING_REHABILITATION_POTENTIAL]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.DISCHARGE_PLANNING_NOTES]: QuestionnaireAnswerType.STRING,

  // Plan of Care
  [RecertAssessmentQuestionnaireItem.PLAN_OF_CARE]: QuestionnaireAnswerType.GROUP,
  [RecertAssessmentQuestionnaireItem.PLAN_OF_CARE_GOALS]: QuestionnaireAnswerType.GROUP,
  [RecertAssessmentQuestionnaireItem.PLAN_OF_CARE_GOALS_GOAL]: QuestionnaireAnswerType.INTEGER,
  [RecertAssessmentQuestionnaireItem.PLAN_OF_CARE_PATIENT_INTERVENTIONS_INTERVENTION_INFO]:
    QuestionnaireAnswerType.GROUP,
  [RecertAssessmentQuestionnaireItem.PLAN_OF_CARE_PATIENT_INTERVENTIONS_INTERVENTION_INFO_INTERVENTION]:
    QuestionnaireAnswerType.INTEGER,
  [RecertAssessmentQuestionnaireItem.PLAN_OF_CARE_PATIENT_INTERVENTIONS_INTERVENTION_INFO_CATEGORY]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.PLAN_OF_CARE_PATIENT_INTERVENTIONS_INTERVENTION_INFO_GOAL]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.PLAN_OF_CARE_PATIENT_INTERVENTIONS_INTERVENTION_INFO_TASK]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.PLAN_OF_CARE_PATIENT_INTERVENTIONS_INTERVENTION_INFO_INDICATION]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.PLAN_OF_CARE_PATIENT_INTERVENTIONS_INTERVENTION_INFO_TYPE]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.PLAN_OF_CARE_PATIENT_INTERVENTIONS_INTERVENTION_INFO_INSTRUCTION]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.PLAN_OF_CARE_PATIENT_INTERVENTIONS_INTERVENTION_INFO_PATIENT_CENTERED_GOAL]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.PLAN_OF_CARE_PATIENT_INTERVENTIONS_INTERVENTION_INFO_ADDITIONAL_DETAILS]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.PLAN_OF_CARE_PATIENT_INTERVENTIONS_INTERVENTION_INFO_VISITS]:
    QuestionnaireAnswerType.INTEGER,
  [RecertAssessmentQuestionnaireItem.PLAN_OF_CARE_PATIENT_INTERVENTIONS_INTERVENTION_INFO_FREQUENCY]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.PLAN_OF_CARE_INITIALS_AUTHORIZATION]:
    QuestionnaireAnswerType.STRING,

  // Supervisory Review
  [RecertAssessmentQuestionnaireItem.SUPERVISORY_REVIEW_ID_BADGE]: QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.SUPERVISORY_REVIEW_HONORING_RIGHTS]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.SUPERVISORY_REVIEW_EMERGENCY_PROTOCOL]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.SUPERVISORY_REVIEW_HYGIENE_PRECAUTIONS]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.SUPERVISORY_REVIEW_REPORTING_CHANGES]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.SUPERVISORY_REVIEW_GRIEVANCE_COMPLAINT]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.SUPERVISORY_REVIEW_POC_DOC_REVIEWED_WITH_CAREGIVER]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.SUPERVISORY_REVIEW_PPE_ACCESS]: QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.SUPERVISORY_REVIEW_SUPERVISION_OBSERVED]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.SUPERVISORY_REVIEW_TASK_OBSERVED]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.SUPERVISORY_REVIEW_PATIENT_STATUS]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.SUPERVISORY_REVIEW_COMMENTS]: QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.SUPERVISORY_REVIEW_EDUCATION_CAREGIVER_EDUCATION_TOPIC]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.SUPERVISORY_REVIEW_EDUCATION_EDUCATION_PROVIDED]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.SUPERVISORY_REVIEW_INITIALS_AUTHORIZATION]:
    QuestionnaireAnswerType.STRING,

  // Vitals
  [RecertAssessmentQuestionnaireItem.VITALS_BLOOD_PRESSURE]: QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.VITALS_BLOOD_SUGAR]: QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.VITALS_TEMPERATURE]: QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.VITALS_HEIGHT]: QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.VITALS_WEIGHT]: QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.VITALS_O2_SATURATION]: QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.VITALS_RESPIRATIONS]: QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.VITALS_PULSE]: QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.VITALS_INITIALS_AUTHORIZATION]: QuestionnaireAnswerType.STRING,

  // Narrative background
  [RecertAssessmentQuestionnaireItem.NARRATIVE_BACKGROUND]: QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.NARRATIVE_SYSTEM_SUMMARIES]: QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.NARRATIVE_CLINICAL_OVERVIEW]: QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.NARRATIVE_SKILLED_NEEDS]: QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.NARRATIVE_PATIENT_SERVICES]: QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.NARRATIVE_CARE_COORDINATION]: QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.NARRATIVE_PATIENT_GOALS]: QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.NARRATIVE_CLINICAL_EDUCATION]: QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.NARRATIVE_FINAL_NOTES]: QuestionnaireAnswerType.STRING,

  // Final Steps
  [RecertAssessmentQuestionnaireItem.FINAL_STEPS_PENDING_REQUIRED_DOCUMENTATION]:
    QuestionnaireAnswerType.STRING,
  [RecertAssessmentQuestionnaireItem.FINAL_STEPS_INTERNAL_NOTES]: QuestionnaireAnswerType.STRING
}
