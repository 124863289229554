import dayjs, { Dayjs } from 'dayjs'
import { Appointment, Encounter, Task } from '@medplum/fhirtypes'
import { getValidatedFragment } from 'fhir/utils'
import {
  BaseAppointmentFragment,
  BaseEncounterFragment,
  BaseEncounterParticipantFragment,
  BaseEncounterWithAppointmentsAndVisitQaTasksFragment,
  BaseEncounterWithAppointmentsFragment,
  BaseEncounterWithVisitQaTasksFragment,
  BasePractitionerFragment,
  BaseTaskFragment
} from 'generated/graphql'
import { castToEnum } from 'utils/enum'
import { CNAVisitQAOutcome, TASK_VISIT_QA_OUTCOME_CODE_SYSTEM } from 'fhir/Task/constants'
import {
  CNAVisitEncounterStatus,
  ENCOUNTER_PARTICIPANT_TYPE_CODE_SYSTEM,
  EncounterParticipantType,
  EncounterStatus
} from './constants'

export const getEncounterParticipantsByRole = <
  T extends BaseEncounterFragment,
  G extends BaseEncounterParticipantFragment
>(
  encounter: T,
  role: EncounterParticipantType
): G[] => {
  const matchingParticipants =
    encounter.participant?.filter((participant) =>
      participant.type?.some((type) =>
        type.coding?.some(
          (coding) =>
            coding.system === ENCOUNTER_PARTICIPANT_TYPE_CODE_SYSTEM && coding.code === role
        )
      )
    ) ?? []

  return matchingParticipants as G[]
}

export const getEncounterPractitionersByRole = <
  T extends BaseEncounterFragment,
  G extends BasePractitionerFragment
>(
  encounter: T,
  role: EncounterParticipantType
): G[] => {
  const matchingParticipants = getEncounterParticipantsByRole(encounter, role)

  return matchingParticipants
    .map((participant) =>
      getValidatedFragment<BasePractitionerFragment>(participant.individual?.resource)
    )
    .filter((practitioner): practitioner is G => !!practitioner)
}

export const getActualEncounterPeriod = (
  encounter: BaseEncounterFragment,
  timezone?: string
): { start: Dayjs; end: Dayjs } | null => {
  const start = encounter.period?.start
  const end = encounter.period?.end
  if (!start || !end) return null

  if (timezone) {
    return {
      start: dayjs(start).tz(timezone),
      end: dayjs(end).tz(timezone)
    }
  }

  return {
    start: dayjs(start),
    end: dayjs(end)
  }
}

export const getScheduledEncounterPeriod = (
  encounter: BaseEncounterWithAppointmentsFragment,
  timezone?: string
): { start: Dayjs; end: Dayjs } | null => {
  const appointment = getValidatedFragment<BaseAppointmentFragment>(
    encounter.appointment?.at(0)?.resource
  )
  const appointmentStart = appointment?.start
  const appointmentEnd = appointment?.end
  if (!appointmentStart || !appointmentEnd) return null

  if (timezone) {
    return {
      start: dayjs(appointmentStart).tz(timezone),
      end: dayjs(appointmentEnd).tz(timezone)
    }
  }

  return {
    start: dayjs(appointmentStart),
    end: dayjs(appointmentEnd)
  }
}

export const isLateCNAVisit = (visit: BaseEncounterWithAppointmentsFragment): boolean => {
  const appointmentEnd = getScheduledEncounterPeriod(visit)?.end
  if (!appointmentEnd) return false

  const currentTime = new Date()
  return (
    dayjs(appointmentEnd).isBefore(currentTime) && !dayjs(appointmentEnd).isSame(currentTime, 'day')
  )
}

export const isDayOfCNAVisit = (visit: BaseEncounterWithAppointmentsFragment): boolean => {
  const { start: appointmentStart, end: appointmentEnd } = getScheduledEncounterPeriod(visit) ?? {}
  if (!appointmentStart || !appointmentEnd) return false

  return dayjs().isSame(appointmentStart, 'day') && dayjs().isSame(appointmentEnd, 'day')
}

export const isRealtimeCNAVisit = (visit: BaseEncounterWithAppointmentsFragment): boolean => {
  const { start: appointmentStart, end: appointmentEnd } = getScheduledEncounterPeriod(visit) ?? {}
  if (!appointmentStart || !appointmentEnd) return false

  return dayjs().isBefore(appointmentEnd) && dayjs().isAfter(appointmentStart)
}

export const isVisitWithinChartableTime = (
  visit: BaseEncounterWithAppointmentsFragment
): boolean => {
  const appointmentStart = getScheduledEncounterPeriod(visit)?.start

  const visitStartedSinceLastWeek = appointmentStart
    ? dayjs(appointmentStart).isAfter(dayjs().subtract(7, 'day'), 'day')
    : false
  const visitStartedBeforeNow = appointmentStart ? dayjs(appointmentStart).isBefore(dayjs()) : false

  return visitStartedBeforeNow && visitStartedSinceLastWeek
}

export const visitIsCharted = (visit: BaseEncounterFragment): boolean => {
  const visitStatus = visit.status

  return visitStatus === EncounterStatus.FINISHED
}

export const visitIsCancelled = (visit: BaseEncounterFragment): boolean => {
  const visitStatus = visit.status

  return visitStatus === EncounterStatus.CANCELLED || visitStatus === EncounterStatus.ON_LEAVE
}

export const visitIsChartable = (visit: BaseEncounterWithAppointmentsFragment): boolean => {
  const isWithinChartableTime = isVisitWithinChartableTime(visit)

  const visitStatus = visit.status
  const hasChartableStatus =
    visitStatus === EncounterStatus.ARRIVED ||
    visitStatus === EncounterStatus.IN_PROGRESS ||
    visitStatus === EncounterStatus.PLANNED ||
    visitStatus === EncounterStatus.TRIAGED

  return isWithinChartableTime && hasChartableStatus
}

export const getVisitQAStatus = (
  visit: BaseEncounterWithVisitQaTasksFragment
): CNAVisitQAOutcome | undefined => {
  const visitQATask = visit.visitQATasks?.filter((task): task is BaseTaskFragment => !!task)?.at(0)
  return getVisitQATaskStatus(visitQATask)
}

export const getCNAVisitEncounterStatus = (
  visit: BaseEncounterWithAppointmentsAndVisitQaTasksFragment
): CNAVisitEncounterStatus => {
  if (visit.status === EncounterStatus.CANCELLED) {
    return CNAVisitEncounterStatus.MISSED
  } else if (visit.status === EncounterStatus.FINISHED) {
    const visitQAStatus = getVisitQAStatus(visit)

    if (visitQAStatus === CNAVisitQAOutcome.APPROVED) {
      return CNAVisitEncounterStatus.APPROVED
    } else if (visitQAStatus === CNAVisitQAOutcome.DENIED) {
      return CNAVisitEncounterStatus.DENIED
    } else {
      return CNAVisitEncounterStatus.SUBMITTED
    }
  } else {
    const { start: scheduledStart, end: scheduledEnd } = getScheduledEncounterPeriod(visit) ?? {}

    const isWeekOld = scheduledEnd
      ? dayjs(scheduledEnd).isBefore(dayjs().subtract(7, 'day'), 'day')
      : false
    if (isWeekOld) return CNAVisitEncounterStatus.MISSED

    const isLate = scheduledStart ? dayjs(scheduledStart).isBefore(dayjs(), 'day') : false
    if (isLate) return CNAVisitEncounterStatus.LATE

    return CNAVisitEncounterStatus.SCHEDULED
  }
}

/**
 * same business logic as in getCNAVisitEncounterStatus, but with different params
 * */
export const getCNAVisitStatus = (
  appointment: Appointment,
  encounter: Encounter,
  task?: Task
): CNAVisitEncounterStatus => {
  if (encounter.status === EncounterStatus.CANCELLED) {
    return CNAVisitEncounterStatus.MISSED
  } else if (encounter.status === EncounterStatus.FINISHED) {
    const visitQAStatus = getVisitQATaskStatus(task)

    if (visitQAStatus === CNAVisitQAOutcome.APPROVED) {
      return CNAVisitEncounterStatus.APPROVED
    } else if (visitQAStatus === CNAVisitQAOutcome.DENIED) {
      return CNAVisitEncounterStatus.DENIED
    } else {
      return CNAVisitEncounterStatus.SUBMITTED
    }
  } else {
    const { start: scheduledStart, end: scheduledEnd } =
      getScheduledAppointmentPeriod(appointment) ?? {}

    const isWeekOld = dayjs.utc(scheduledEnd).isBefore(dayjs.utc().subtract(7, 'day'), 'day')
    if (isWeekOld) return CNAVisitEncounterStatus.MISSED

    const isLate = dayjs(scheduledStart).isBefore(dayjs(), 'day')
    if (isLate) return CNAVisitEncounterStatus.LATE

    return CNAVisitEncounterStatus.SCHEDULED
  }
}

export const getVisitQATaskStatus = (
  task: Task | BaseTaskFragment | undefined
): CNAVisitQAOutcome | undefined => {
  const visitQAOutcome = task?.businessStatus?.coding?.find(
    (c) => c.system === TASK_VISIT_QA_OUTCOME_CODE_SYSTEM
  )?.code
  return visitQAOutcome ? castToEnum(CNAVisitQAOutcome, visitQAOutcome) : undefined
}

export const getScheduledAppointmentPeriod = (
  appointment: Appointment
): { start: string; end: string } | null => {
  const appointmentStart = appointment?.start
  const appointmentEnd = appointment?.end
  if (!appointmentStart || !appointmentEnd) return null

  return {
    start: appointmentStart,
    end: appointmentEnd
  }
}
