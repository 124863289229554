import { InsurancePlanCode, InsurancePlanType } from 'fhir/Coverage/constants'
import { BaseCoverageFragment } from 'generated/graphql'

const coverageTypeHierarchy: string[] = [InsurancePlanType.Medicaid, '']
export const sortCoverageList = <T extends BaseCoverageFragment>(
  coverageList: (T | null)[] | null | undefined
): T[] | null => {
  if (!coverageList) {
    return null
  }

  const sorted = coverageList
    .filter((coverage): coverage is T => !!coverage)
    .sort(
      (a, b) =>
        coverageTypeHierarchy.indexOf(a.type?.coding?.[0].code ?? '') -
        coverageTypeHierarchy.indexOf(b.type?.coding?.[0].code ?? '')
    )

  return sorted ?? null
}

export const getInsurancePlanType = (
  coverage: BaseCoverageFragment | null,
  type: InsurancePlanType
): string | null => {
  const code = coverage?.type?.coding?.find((coding) => coding.code === type)
  return code?.display ?? null
}

export const getCoverageByType = <T extends BaseCoverageFragment>(
  coverageList: (T | null)[] | null | undefined,
  type: InsurancePlanCode
): T | null => {
  if (!coverageList) {
    return null
  }

  return (
    coverageList?.find((coverage) => {
      return coverage?.type?.coding?.some((coding) => coding.code === type)
    }) ?? null
  )
}
