import { Menu } from 'antd'
import { useRouter } from 'next/router'
import React, { useContext, useEffect, useState } from 'react'
import useFeatureFlags from 'hooks/useFeatureFlags'
import AccessPolicyContext from 'contexts/AccessPolicyContext'
import { getAccessibleMenuItems } from './utils/helpers'

const Navigation = (): JSX.Element => {
  const { pathname } = useRouter()
  const [openSubMenus, setOpenSubMenus] = useState<string[]>([])
  const featureFlags = useFeatureFlags()
  const { accessPolicyList } = useContext(AccessPolicyContext)

  const getNestedMenuKey = (path: string): string => {
    const routes = [
      { path: '/tasks', key: '/tasks' },
      { path: '/clinical/qa', key: '/clinical/qa' },
      { path: '/clinical/providers', key: '/clinical/providers' },
      { path: '/clinical/assessments', key: '/clinical/assessments' }
    ]

    const match = routes.find((route) => path.startsWith(route.path))
    return match ? match.key : path
  }

  const accessibleMenuItems = getAccessibleMenuItems(featureFlags, accessPolicyList)

  const selectedKey = getNestedMenuKey(pathname)

  const handleResize = (): void => {
    const newWidth = window.innerWidth
    if (newWidth <= 1200) {
      setOpenSubMenus([])
    }
  }

  // need to do this otherwise antd won't be happy, and will stop tabs from working
  useEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <Menu
      style={{ borderInlineEnd: '0px', paddingRight: '8px', paddingLeft: '8px', color: '#667085' }}
      mode="inline"
      selectedKeys={[selectedKey]}
      openKeys={openSubMenus}
      onOpenChange={(keys) => setOpenSubMenus(keys as string[])}
    >
      {accessibleMenuItems.map((item) =>
        item.children ? (
          <Menu.SubMenu key={item.key} icon={item.icon} title={item.label}>
            {item.children.map((subItem) => (
              <Menu.Item key={subItem.key}>{subItem.label}</Menu.Item>
            ))}
          </Menu.SubMenu>
        ) : (
          <Menu.Item key={item.key} icon={item.icon}>
            {item.label}
          </Menu.Item>
        )
      )}
    </Menu>
  )
}

export default Navigation
