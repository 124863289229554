import { DatePicker, Input, Radio, Select } from 'antd'
import {
  CareCoordinationReasons,
  ERVisitOutcomes,
  ERVisitReasons,
  FallCauses,
  FallOutcomes,
  GrievanceComplaintOutcomes,
  HospitalizationOutcomes,
  HospitalizationReasons,
  InfectionSymptoms,
  InfectionTreatments,
  InfectionTypes,
  InjuryCauses,
  InjuryOutcomes,
  InjuryTypes,
  MedicalProcedureTypes
} from 'utils/staticLists/Notes/clinicalNoteData'
import OrgListSelect from 'components/Common/OrgListSelect/OrgListSelect'
import { enumToSelectOptions } from 'utils/enum'
import SearchablePatient from 'components/SearchableComponents/SearchablePatient'
import { ClinicalNoteTypes, GeneralNoteTypes } from 'fhir/Communication/constants'

export const commonFields = {
  searchablePatient: {
    label: 'Patient Name',
    name: 'patientId',
    component: SearchablePatient,
    placeholder: 'Search and select a patient',
    required: true,
    allowClear: true
  },
  noteType: {
    label: 'Note Types',
    name: 'noteType',
    component: Select,
    disabled: false,
    placeholder: 'Select a note type'
  },
  physicianNotified: {
    label: 'Has the patient’s primary physician been notified?',
    name: 'physicianNotified',
    component: Radio.Group,
    options: [
      { label: 'Yes', value: 'yes' },
      { label: 'No', value: 'no' }
    ],
    disabled: false,
    placeholder: 'Select an option',
    labelLeftAlign: true
  },
  comments: {
    label: 'Comments',
    name: 'comment',
    component: Input.TextArea,
    disabled: false,
    placeholder: 'Include details or notes here',
    required: false
  }
}

export const clinicalNoteFields = {
  [ClinicalNoteTypes.HOSPITALIZATION]: [
    {
      label: 'Reason for hospital admission',
      name: `reason`,
      options: enumToSelectOptions(HospitalizationReasons),
      component: Select,
      disabled: false,
      placeholder: 'Select an option'
    },
    [
      {
        label: 'Facility',
        name: 'facility',
        component: OrgListSelect,
        disabled: false,
        placeholder: 'Select a facility',
        horizontal: true
      },
      {
        label: 'Outcome',
        name: 'outcome',
        component: Select,
        disabled: false,
        options: enumToSelectOptions(HospitalizationOutcomes),
        placeholder: 'Select an option',
        optional: true,
        required: false,
        horizontal: true
      }
    ],
    [
      {
        label: 'Admission Date',
        name: 'admissionDate',
        component: DatePicker,
        format: 'MM/DD/YYYY',
        disabled: false,
        placeholder: 'Select date',
        horizontal: true
      },
      {
        label: 'Discharge Date',
        name: 'dischargeDate',
        component: DatePicker,
        format: 'MM/DD/YYYY',
        disabled: false,
        placeholder: 'Select date',
        optional: true,
        required: false,
        horizontal: true
      }
    ],
    {
      label: 'Resumption of Care order received?',
      name: 'resumptionOrderReceived',
      component: Radio.Group,
      options: [
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' },
        { label: 'N/A', value: 'n/a' }
      ],
      disabled: false,
      placeholder: 'Select an option',
      labelLeftAlign: true
    },
    { ...commonFields.comments }
  ],
  [ClinicalNoteTypes.MEDICAL_PROCEDURE]: [
    {
      label: 'Type',
      name: `medicalProcedureType`,
      options: enumToSelectOptions(MedicalProcedureTypes),
      component: Select,
      disabled: false,
      placeholder: 'Select an option'
    },
    [
      {
        label: 'Facility',
        name: 'facility',
        component: OrgListSelect,
        disabled: false,
        placeholder: 'Select a facility',
        horizontal: true
      },
      {
        label: 'Outcome',
        name: 'outcome',
        component: Select,
        disabled: false,
        options: enumToSelectOptions(HospitalizationOutcomes),
        placeholder: 'Select an option',
        optional: true,
        required: false,
        horizontal: true
      }
    ],
    [
      {
        label: 'Admission Date',
        name: 'admissionDate',
        component: DatePicker,
        format: 'MM/DD/YYYY',
        disabled: false,
        placeholder: 'Select date',
        horizontal: true
      },
      {
        label: 'Discharge Date',
        name: 'dischargeDate',
        component: DatePicker,
        format: 'MM/DD/YYYY',
        disabled: false,
        placeholder: 'Select date',
        optional: true,
        required: false,
        horizontal: true
      }
    ],
    { ...commonFields.comments }
  ],
  [ClinicalNoteTypes.ER_VISIT]: [
    {
      label: 'Reason for ER Visit',
      name: `reason`,
      component: Select,
      disabled: false,
      options: enumToSelectOptions(ERVisitReasons),
      placeholder: 'Select an option'
    },
    {
      label: 'Outcome',
      name: `outcome`,
      component: Select,
      disabled: false,
      options: enumToSelectOptions(ERVisitOutcomes),
      placeholder: 'Select an option',
      optional: true,
      required: false
    },
    {
      label: 'Visit Date',
      name: 'admissionDate',
      component: DatePicker,
      format: 'MM/DD/YYYY',
      disabled: false,
      placeholder: 'Select date'
    },
    {
      ...commonFields.physicianNotified
    },
    { ...commonFields.comments }
  ],
  [ClinicalNoteTypes.INFECTION]: [
    {
      label: 'Infection Type',
      name: 'infectionType',
      component: Select,
      disabled: false,
      options: enumToSelectOptions(InfectionTypes),
      placeholder: 'Select an option'
    },
    {
      label: 'Infection Treatment',
      name: 'infectionTreatment',
      component: Select,
      disabled: false,
      options: enumToSelectOptions(InfectionTreatments),
      placeholder: 'Select an option'
    },
    {
      label: 'Symptoms',
      name: 'symptoms',
      component: Select,
      mode: 'multiple',
      type: 'array',
      disabled: false,
      options: enumToSelectOptions(InfectionSymptoms),
      placeholder: 'Select an option'
    },
    {
      ...commonFields.physicianNotified
    },
    {
      ...commonFields.comments
    }
  ],
  [ClinicalNoteTypes.INJURY]: [
    {
      label: 'Injury Type',
      name: 'injuryType',
      component: Select,
      disabled: false,
      options: enumToSelectOptions(InjuryTypes),
      placeholder: 'Select an option'
    },
    {
      label: 'Cause of Injury',
      name: `injuryCause`,
      component: Select,
      disabled: false,
      options: enumToSelectOptions(InjuryCauses),
      placeholder: 'Select an option'
    },
    {
      label: 'Outcome',
      name: `outcome`,
      component: Select,
      disabled: false,
      options: enumToSelectOptions(InjuryOutcomes),
      placeholder: 'Select an option',
      optional: true,
      required: false
    },
    {
      label: 'Blood, open wounds, or broken bones?',
      name: 'injuryTrauma',
      component: Radio.Group,
      options: [
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' }
      ],
      disabled: false,
      placeholder: 'Select an option',
      labelLeftAlign: true
    },
    {
      ...commonFields.physicianNotified
    },
    {
      ...commonFields.comments
    }
  ],
  [ClinicalNoteTypes.CARE_COORDINATION]: [
    {
      label: 'Provider',
      name: 'provider',
      component: OrgListSelect,
      disabled: false,
      placeholder: 'Select a provider'
    },
    {
      label: 'Reason for coordination',
      name: 'reason',
      component: Select,
      disabled: false,
      options: enumToSelectOptions(CareCoordinationReasons),
      placeholder: 'Select a provider'
    },
    {
      ...commonFields.physicianNotified
    },
    {
      ...commonFields.comments
    }
  ],
  [ClinicalNoteTypes.FALL]: [
    {
      label: 'Cause of Fall',
      name: `fallCause`,
      component: Select,
      disabled: false,
      options: enumToSelectOptions(FallCauses),
      placeholder: 'Select an option'
    },
    {
      label: 'Outcome',
      name: `outcome`,
      component: Select,
      disabled: false,
      options: enumToSelectOptions(FallOutcomes),
      placeholder: 'Select an option',
      optional: true,
      required: false
    },
    {
      label: 'Blood, open wounds, or broken bones?',
      name: 'fallTrauma',
      component: Radio.Group,
      options: [
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' }
      ],
      disabled: false,
      placeholder: 'Select an option',
      labelLeftAlign: true
    },
    {
      label: 'Loss of consciousness?',
      name: 'fallLossOfConsciousness',
      component: Radio.Group,
      options: [
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' }
      ],
      disabled: false,
      placeholder: 'Select an option',
      labelLeftAlign: true
    },
    {
      label: 'Slurred words or speech?',
      name: 'fallSlurredSpeech',
      component: Radio.Group,
      options: [
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' }
      ],
      disabled: false,
      placeholder: 'Select an option',
      labelLeftAlign: true
    },
    {
      ...commonFields.physicianNotified
    },
    {
      ...commonFields.comments
    }
  ]
}

export const generalNoteFields = {
  [GeneralNoteTypes.GENERAL]: [
    {
      label: 'Comments',
      name: 'comment',
      component: Input.TextArea,
      disabled: false,
      placeholder: 'Include details or notes here',
      required: true
    }
  ],
  [GeneralNoteTypes.GRIEVANCE_COMPLAINT]: [
    {
      label: 'Outcome',
      name: `outcome`,
      component: Select,
      disabled: false,
      options: enumToSelectOptions(GrievanceComplaintOutcomes),
      placeholder: 'Select an option',
      optional: true,
      required: false
    },
    {
      ...commonFields.comments
    }
  ]
}
