import gql from 'gql-tag'

export const BASE_EPISODE_OF_CARE_FRAGMENT = gql`
  fragment BaseEpisodeOfCare on EpisodeOfCare {
    resourceType
    id
    type {
      coding {
        system
        code
        display
      }
    }
    status
    statusHistory {
      status
      period {
        start
        end
      }
      extension {
        url
        valueReference {
          reference
        }
      }
    }
    period {
      start
      end
    }
    patient {
      reference
      display
    }
    extension {
      url
      valueDecimal
    }
  }
`
