import { StateCreator } from 'zustand'
import { Location } from '@medplum/fhirtypes'
import { MedplumClient } from '@medplum/core'
import { captureException, SentrySources } from 'utils/sentry'
import { getErrorInstance } from 'utils/helpers'
import { RootSlice } from './rootType'

export interface LocationSlice {
  location: {
    isLoading: boolean
    getLocation: (medplum: MedplumClient, id: string) => Promise<Location | void>
  }
}

export const createLocationSlice: StateCreator<
  RootSlice,
  // Type `never` is used here to follow the instructions on Zustand docs
  // reference: https://github.com/pmndrs/zustand/blob/main/docs/guides/typescript.md#middlewares-and-their-mutators-reference
  // see index.ts for middlewares being used
  [['zustand/devtools', never], ['zustand/immer', never]],
  [],
  LocationSlice
> = (set) => ({
  location: {
    isLoading: false,
    getLocation: async (medplum, id) => {
      try {
        set((state) => {
          state.location.isLoading = true
        })
        const location = await medplum.readResource('Location', id)
        if (location) {
          return location
        }
      } catch (error) {
        captureException(getErrorInstance(error), {
          tags: { source: SentrySources.LOCATION_SLICE },
          extras: { params: { id } }
        })
      }
      set((state) => {
        state.location.isLoading = false
      })
    }
  }
})
