// Read more about ASTM system here: https://build.fhir.org/codesystem-astm-signature-type.html

export const SIGNATURE_CODE_SYSTEM_URL =
  'https://build.fhir.org/codesystem-astm-signature-type.html'

export enum ASTM_SIGNATURE_TYPES {
  VERIFICATION = '1.2.840.10065.1.12.1.5',
  VALIDATION = '1.2.840.10065.1.12.1.6'
}

export const SIGNATURE_TYPE_DISPLAY_NAMES = {
  [ASTM_SIGNATURE_TYPES.VERIFICATION]: 'Verification Signature',
  [ASTM_SIGNATURE_TYPES.VALIDATION]: 'Validation Signature'
}

export const signatureFormatType = 'application/signature+json'

export enum SignatureFormat {
  TEXT = 'text',
  FACE_ID = 'face-id'
}
