import { QuestionnaireAnswerType } from 'components/Assessments/types'
import { SupervisoryAssessmentQuestionnaireItem } from './questionnaire'

export const SUPERVISORY_QUESTIONNAIRE_ITEM_TO_ANSWER_TYPE = {
  // Assessment overview
  [SupervisoryAssessmentQuestionnaireItem.ASSESSMENT_OVERVIEW_APPOINTMENT_TIME_START]:
    QuestionnaireAnswerType.DATE_TIME,
  [SupervisoryAssessmentQuestionnaireItem.ASSESSMENT_OVERVIEW_APPOINTMENT_TIME_END]:
    QuestionnaireAnswerType.DATE_TIME,

  // Patient overview
  [SupervisoryAssessmentQuestionnaireItem.PATIENT_OVERVIEW_DEMOGRAPHICS_LEGAL_NAME]:
    QuestionnaireAnswerType.STRING,
  [SupervisoryAssessmentQuestionnaireItem.PATIENT_OVERVIEW_DEMOGRAPHICS_PREFERRED_NAME]:
    QuestionnaireAnswerType.STRING,
  [SupervisoryAssessmentQuestionnaireItem.PATIENT_OVERVIEW_DEMOGRAPHICS_DATE_OF_BIRTH]:
    QuestionnaireAnswerType.STRING,
  [SupervisoryAssessmentQuestionnaireItem.PATIENT_OVERVIEW_DEMOGRAPHICS_ADDRESS]:
    QuestionnaireAnswerType.STRING,
  [SupervisoryAssessmentQuestionnaireItem.PATIENT_OVERVIEW_DEMOGRAPHICS_GENDER]:
    QuestionnaireAnswerType.STRING,
  [SupervisoryAssessmentQuestionnaireItem.PATIENT_OVERVIEW_DEMOGRAPHICS_SEX]:
    QuestionnaireAnswerType.STRING,
  [SupervisoryAssessmentQuestionnaireItem.PATIENT_OVERVIEW_DEMOGRAPHICS_MEDICAID_ID]:
    QuestionnaireAnswerType.STRING,
  [SupervisoryAssessmentQuestionnaireItem.PATIENT_OVERVIEW_CARE_TEAM_CAREGIVER_NAME]:
    QuestionnaireAnswerType.STRING,
  [SupervisoryAssessmentQuestionnaireItem.PATIENT_OVERVIEW_CARE_TEAM_CAREGIVER_RELATION_TO_PATIENT]:
    QuestionnaireAnswerType.STRING,
  [SupervisoryAssessmentQuestionnaireItem.PATIENT_OVERVIEW_CARE_TEAM_LEGAL_GUARDIAN_NAME]:
    QuestionnaireAnswerType.STRING,
  [SupervisoryAssessmentQuestionnaireItem.PATIENT_OVERVIEW_CARE_TEAM_LEGAL_GUARDIAN_RELATION_TO_PATIENT]:
    QuestionnaireAnswerType.STRING,
  [SupervisoryAssessmentQuestionnaireItem.PATIENT_OVERVIEW_CARE_TEAM_EMERGENCY_CONTACT_NAME]:
    QuestionnaireAnswerType.STRING,
  [SupervisoryAssessmentQuestionnaireItem.PATIENT_OVERVIEW_CARE_TEAM_EMERGENCY_CONTACT_PHONE]:
    QuestionnaireAnswerType.STRING,
  [SupervisoryAssessmentQuestionnaireItem.PATIENT_OVERVIEW_CARE_TEAM_EMERGENCY_CONTACT_ADDRESS]:
    QuestionnaireAnswerType.STRING,
  [SupervisoryAssessmentQuestionnaireItem.PATIENT_OVERVIEW_CARE_TEAM_ALTERNATE_CAREGIVER_NAME]:
    QuestionnaireAnswerType.STRING,
  [SupervisoryAssessmentQuestionnaireItem.PATIENT_OVERVIEW_CARE_TEAM_ALTERNATE_CAREGIVER_PHONE]:
    QuestionnaireAnswerType.STRING,
  [SupervisoryAssessmentQuestionnaireItem.PATIENT_OVERVIEW_CARE_TEAM_ALTERNATE_CAREGIVER_ADDRESS]:
    QuestionnaireAnswerType.STRING,

  // Supervisory review
  [SupervisoryAssessmentQuestionnaireItem.SUPERVISORY_REVIEW_EMPLOYEE]:
    QuestionnaireAnswerType.REFERENCE,
  [SupervisoryAssessmentQuestionnaireItem.SUPERVISORY_REVIEW_SUPERVISION_OBSERVED]:
    QuestionnaireAnswerType.STRING,
  [SupervisoryAssessmentQuestionnaireItem.SUPERVISORY_REVIEW_ID_BADGE]:
    QuestionnaireAnswerType.STRING,
  [SupervisoryAssessmentQuestionnaireItem.SUPERVISORY_REVIEW_HONORING_RIGHTS]:
    QuestionnaireAnswerType.STRING,
  [SupervisoryAssessmentQuestionnaireItem.SUPERVISORY_REVIEW_EMERGENCY_PROTOCOL]:
    QuestionnaireAnswerType.STRING,
  [SupervisoryAssessmentQuestionnaireItem.SUPERVISORY_REVIEW_HYGIENE_PRECAUTIONS]:
    QuestionnaireAnswerType.STRING,
  [SupervisoryAssessmentQuestionnaireItem.SUPERVISORY_REVIEW_REPORTING_CHANGES]:
    QuestionnaireAnswerType.STRING,
  [SupervisoryAssessmentQuestionnaireItem.SUPERVISORY_REVIEW_GRIEVANCE_COMPLAINT]:
    QuestionnaireAnswerType.STRING,
  [SupervisoryAssessmentQuestionnaireItem.SUPERVISORY_REVIEW_POC_DOC_REVIEWED_WITH_CAREGIVER]:
    QuestionnaireAnswerType.STRING,
  [SupervisoryAssessmentQuestionnaireItem.SUPERVISORY_REVIEW_PPE_ACCESS]:
    QuestionnaireAnswerType.STRING,
  [SupervisoryAssessmentQuestionnaireItem.SUPERVISORY_REVIEW_EDUCATION]:
    QuestionnaireAnswerType.STRING,
  [SupervisoryAssessmentQuestionnaireItem.SUPERVISORY_REVIEW_VERBALLY_UNDERSTOOD]:
    QuestionnaireAnswerType.STRING,
  [SupervisoryAssessmentQuestionnaireItem.SUPERVISORY_REVIEW_REPEATED_BACK]:
    QuestionnaireAnswerType.STRING,
  [SupervisoryAssessmentQuestionnaireItem.SUPERVISORY_REVIEW_DEMONSTRATED_SKILL_CORRECTNESS]:
    QuestionnaireAnswerType.STRING,
  [SupervisoryAssessmentQuestionnaireItem.SUPERVISORY_REVIEW_DOCUMENTATION]:
    QuestionnaireAnswerType.STRING,
  [SupervisoryAssessmentQuestionnaireItem.SUPERVISORY_REVIEW_ADHERED_TO_CARE_PLAN]:
    QuestionnaireAnswerType.STRING,
  [SupervisoryAssessmentQuestionnaireItem.SUPERVISORY_REVIEW_PROFESSIONALISM]:
    QuestionnaireAnswerType.STRING,
  [SupervisoryAssessmentQuestionnaireItem.SUPERVISORY_REVIEW_SATISFACTION]:
    QuestionnaireAnswerType.STRING,
  [SupervisoryAssessmentQuestionnaireItem.SUPERVISORY_REVIEW_COMMENTS]:
    QuestionnaireAnswerType.STRING,

  // Final steps
  [SupervisoryAssessmentQuestionnaireItem.FINAL_STEPS_INTERNAL_NOTES]:
    QuestionnaireAnswerType.STRING
}
