import gql from 'gql-tag'

export const BASE_CARETEAM_FRAGMENT = gql`
  fragment BaseCareTeam on CareTeam {
    resourceType
    id
    status
    name
    managingOrganization {
      id
      display
      reference
    }
    subject {
      type
      display
      identifier {
        id
      }
    }
  }
`

// ————————————————————————————————————————————————————————————————————————————————
// Nested types used for helper functions
// ————————————————————————————————————————————————————————————————————————————————

export const BASE_CARETEAM_WITH_ORGANIZATION_FRAGMENT = gql`
  fragment BaseCareTeamWithOrganization on CareTeam {
    ...BaseCareTeam
    managingOrganization {
      resource {
        ...BaseOrganization
      }
    }
  }
`

// These additional types should really only be used in helper functions, not
// directly imported into queries
export const BASE_CARETEAM_WITH_PARTICIPANT_FRAGMENT = gql`
  fragment BaseCareTeamWithParticipant on CareTeam {
    ...BaseCareTeam
    participant {
      ...BaseCareTeamParticipant
    }
  }
`

export const BASE_CARETEAM_WITH_PRACTITIONER_FRAGMENT = gql`
  fragment BaseCareTeamWithPractitioner on CareTeam {
    ...BaseCareTeam
    participant {
      ...BaseCareTeamParticipantWithPractitioner
    }
  }
`

export const BASE_CARETEAM_WITH_PATIENT_FRAGMENT = gql`
  fragment BaseCareTeamWithPatient on CareTeam {
    ...BaseCareTeam
    subject {
      resource {
        ...BasePatient
      }
    }
  }
`
