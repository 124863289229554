import { StateCreator } from 'zustand'
import { HttpClient } from 'utils/httpClient'
import { BASE_API_URL } from 'utils/constants'
import { RootSlice } from './rootType'

enum InterventionCategory {
  BATHING = 'Bathing',
  BRACES_SPLINTS_AND_OR_PRESSURE_STOCKINGS = 'Braces, Splints, and/or Pressure Stockings',
  DRESSING = 'Dressing',
  FEEDING = 'Feeding',
  FEEDING_DELEGATED = 'Feeding: Delegated',
  MOBILITY_AMBULATION = 'Mobility/Ambulation',
  GROOMING = 'Grooming',
  SKIN_CARE = 'Skin Care',
  SKIN_CARE_DELEGATED = 'Skin Care: Delegated',
  ROM_HOME_EXERCISE_PROGRAM = 'ROM/Home Exercise Program',
  RESPIRATORY_DELEGATED = 'Respiratory: Delegated',
  TOILETING = 'Toileting',
  BOWEL_PROGRAM_DELEGATED = 'Bowel Program: Delegated',
  POSITIONING = 'Positioning',
  TRANSFERS = 'Transfers',
  VITAL_SIGNS_SPECIAL_INSTRUCTIONS_PARAMETERS = 'Vital Signs: Special Instructions/parameters',
  HOME_HEALTH_DEFAULT_INTERVENTION = 'Home Health Default Intervention',
  DOCUMENT = 'Document'
}

interface InterventionQuestionnaireAssociation {
  linkId: string
  score: number
  additionalInfoLinkId: string | null
  additionalInfoCode: number | null
}

export interface PatientInterventionInfo {
  id: number
  category: InterventionCategory
  task: string
  type: string[]
  indication: string[]
  instruction: string[]
  interventionGoal: string
  questionnaireInfo: InterventionQuestionnaireAssociation[]
}

export interface CertPeriodGoal {
  id: number
  category: string
  text: string
}

export interface StaticSlice {
  static: {
    interventionsInfo: {
      list: PatientInterventionInfo[]
      error: string | null
      isLoading: boolean
      getData: VoidFunction
    }
    certPeriodGoals: {
      list: CertPeriodGoal[]
      error: string | null
      isLoading: boolean
      getData: VoidFunction
    }
  }
}

export const createStaticSlice: StateCreator<
  RootSlice,
  // Type `never` is used here to follow the instructions on Zustand docs
  // reference: https://github.com/pmndrs/zustand/blob/main/docs/guides/typescript.md#middlewares-and-their-mutators-reference
  // see index.ts for middlewares being used
  [['zustand/devtools', never], ['zustand/immer', never]],
  [],
  StaticSlice
> = (set, get) => ({
  static: {
    interventionsInfo: {
      list: [],
      error: null,
      isLoading: false,
      getData: async (): Promise<void> => {
        const store = get()
        if (
          store.static.interventionsInfo.isLoading ||
          store.static.interventionsInfo.error ||
          store.static.interventionsInfo.list.length
        )
          return

        set((state) => {
          state.static.interventionsInfo.isLoading = true
        })

        try {
          const response = await new HttpClient().fetch(`${BASE_API_URL}/static/interventions_info`)
          const parsedResp = await response.json()
          if (response.ok) {
            const interventions: PatientInterventionInfo[] = parsedResp.data

            set((state) => {
              state.static.interventionsInfo.list = interventions
            })
          } else {
            set((state) => {
              state.static.certPeriodGoals.error = JSON.stringify(parsedResp)
            })
          }
        } catch (e) {
          set((state) => {
            state.static.interventionsInfo.error = e.message
          })
        } finally {
          set((state) => {
            state.static.interventionsInfo.isLoading = false
          })
        }
      }
    },
    certPeriodGoals: {
      list: [],
      error: null,
      isLoading: false,
      getData: async (options?: Record<string, string>): Promise<void> => {
        const store = get()
        if (
          store.static.certPeriodGoals.isLoading ||
          store.static.certPeriodGoals.error ||
          store.static.certPeriodGoals.list.length
        )
          return

        set((state) => {
          state.static.certPeriodGoals.isLoading = true
        })

        try {
          const params = new URLSearchParams(options)
          const response = await new HttpClient().fetch(
            `${BASE_API_URL}/static/cert-period-goals?${params.toString()}`
          )

          const parsedResp = await response.json()

          if (response.ok) {
            const goals = parsedResp.data

            set((state) => {
              state.static.certPeriodGoals.list = goals
            })
          } else {
            set((state) => {
              state.static.certPeriodGoals.error = JSON.stringify(parsedResp)
            })
          }
        } catch (e) {
          set((state) => {
            state.static.certPeriodGoals.error = e.message
          })
        } finally {
          set((state) => {
            state.static.certPeriodGoals.isLoading = false
          })
        }
      }
    }
  }
})
