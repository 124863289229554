import { ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE } from 'fhir/constants'

export const PRACTITIONER_ROLE_SUPERVISOR_PRACTITIONER_EXTENSION = `${ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE}/supervisor-practitioner`
export const PRACTITIONER_ROLE_PAY_RATE_EXTENSION = `${ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE}/pay-rate`

export enum PractitionerRoleInHealthcareService {
  RN = 'Clinical',
  CAREGIVER = 'Caregiver',
  CAREOPS = 'CareOps',
  QA_NURSE = 'QA Nurse'
}
