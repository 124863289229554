import dayjs, { Dayjs } from 'dayjs'
import { QuestionnaireResponseItem } from '@medplum/fhirtypes'
import {
  BaseQuestionnaireResponseFragment,
  BaseQuestionnaireWithResponseListFragment
} from 'generated/graphql'
import { AssessmentType } from 'components/Clinical/Assessments/constants'
import { flattenQuestionnaireResponseItem } from 'components/Patients/Assessments/CreateAssessment/helpers'
import {
  MedicationInfo,
  MedicationStatus,
  MedicationUsageStatus
} from 'components/Common/Medication/types'
import { SocAssessmentQuestionnaireItem } from '../SocAssessment/types/questionnaire'
import { RecertAssessmentQuestionnaireItem } from '../RecertAssessment/types/questionnaire'
import { RevisionOfParAssessmentQuestionnaireItem } from '../RevisionOfParAssessment/types/questionnaire'
import { PatAssessmentQuestionnaireItem } from '../PatAssessment/types/questionnaire'
import { getAnswerValuesFromItems, InterventionInfoWithDuration } from '../helpers'
import { DischargeAssessmentQuestionnaireItem } from '../DischargeAssessment/types/questionnaire'
import { RocAssessmentQuestionnaireItem } from '../RocAssessment/types/questionnaire'
import { SupervisoryAssessmentQuestionnaireItem } from '../SupervisoryAssessment/types/questionnaire'
import { ORDERS_FOR_TREATMENT_HEADER } from './useNarrative'

type SharedCareQuestionnaireItemKey = keyof typeof SocAssessmentQuestionnaireItem &
  keyof typeof RecertAssessmentQuestionnaireItem &
  keyof typeof RevisionOfParAssessmentQuestionnaireItem &
  keyof typeof RocAssessmentQuestionnaireItem

export type SharedCareQuestionnaireItemType =
  | SocAssessmentQuestionnaireItem
  | RecertAssessmentQuestionnaireItem
  | RevisionOfParAssessmentQuestionnaireItem
  | RocAssessmentQuestionnaireItem

export type AllQuestionnaireItemType =
  | PatAssessmentQuestionnaireItem
  | SocAssessmentQuestionnaireItem
  | RecertAssessmentQuestionnaireItem
  | RevisionOfParAssessmentQuestionnaireItem
  | DischargeAssessmentQuestionnaireItem
  | RocAssessmentQuestionnaireItem
  | SupervisoryAssessmentQuestionnaireItem

// A shared questionnaire item to help us retrieve keys that are common to both
// SOC and Recerts, Revision of PAR and ROC
export const SharedCareQuestionnaireItem = (
  key: SharedCareQuestionnaireItemKey
): SharedCareQuestionnaireItemType => {
  // Since the key is shared, we can use either questionnaire item to retrieve the key.
  // In our case, we use the SOC questionnaire item.
  return SocAssessmentQuestionnaireItem[key]
}

// Returns the linkId for a soc assessment item
export const SocQuestionnaireItem = (
  key: keyof typeof SocAssessmentQuestionnaireItem
): SocAssessmentQuestionnaireItem => {
  return SocAssessmentQuestionnaireItem[key]
}

// Returns the linkId for a recert assessment item
export const RecertQuestionnaireItem = (
  key: keyof typeof RecertAssessmentQuestionnaireItem
): RecertAssessmentQuestionnaireItem => {
  return RecertAssessmentQuestionnaireItem[key]
}

export const getLatestResponseFromQuestionnaire = (
  questionnairesWithResponseList: BaseQuestionnaireWithResponseListFragment[],
  { currentAssessmentId }: { currentAssessmentId?: string } = {}
): BaseQuestionnaireResponseFragment | null => {
  const allQuestionnairesWithLatestResponse = questionnairesWithResponseList.map(
    (questionnaire) => {
      // Filters out the questionnaire response that is the assessment we're currently editing.
      const fullyCompletedQuestionnaireResponseList =
        questionnaire?.QuestionnaireResponseList?.filter((r) => {
          if (r?.id === currentAssessmentId) return false

          const assessmentItems = r?.item as QuestionnaireResponseItem[] | undefined
          if (!assessmentItems) return false

          const flatAnswers = flattenQuestionnaireResponseItem(assessmentItems)
          const assessmentType = questionnaire.identifier?.find((i) => i?.system === 'type')?.value

          if (assessmentType === AssessmentType.REVISION_OF_PAR) {
            // Check if there have been any changes in the care hours
            const [[revisedHours], [isRevisingCareHours], [currentHours]] = [
              RevisionOfParAssessmentQuestionnaireItem.AUTHORIZATION_AUTHORIZATION_REVISED_CARE_HOURS,
              RevisionOfParAssessmentQuestionnaireItem.AUTHORIZATION_AUTHORIZATION_IS_REVISING_CARE_HOURS,
              RevisionOfParAssessmentQuestionnaireItem.AUTHORIZATION_AUTHORIZATION_CURRENT_HOURS
            ].map((linkId) => getAnswerValuesFromItems(Object.values(flatAnswers), linkId))

            const hasHoursChanged =
              isRevisingCareHours && revisedHours && currentHours && revisedHours !== currentHours
            return !hasHoursChanged
          }

          return true
        }) ?? []

      const latestValidQuestionnaireResponse = fullyCompletedQuestionnaireResponseList
        ?.sort((a, b) => dayjs(b?.authored ?? 0).valueOf() - dayjs(a?.authored ?? 0).valueOf())
        ?.find((q) => !!q)

      return latestValidQuestionnaireResponse
    }
  )
  const latestResponse = allQuestionnairesWithLatestResponse?.find((q) => !!q)

  return latestResponse ?? null
}

export interface InterventionInfo {
  id?: number
  category?: string
  interventionGoal?: string
  task?: string
  indication?: string
  type?: string
  instruction?: string
  patientCenteredGoal?: string
  additionalDetails?: string
  visitNumbers?: number[]
  frequency?: string[]
}

export interface OtherAppointmentInfo {
  name?: string
  periodStart?: string
  periodEnd?: string
  repeatsOnDays?: string[]
}

export interface VisitInfo {
  periodStart?: string
  periodEnd?: string
  periodCaregiver?: string
}

export interface InterventionEditDropdownParams {
  interventionId?: number
  interventionGoal?: string
  type?: string
  indication?: string
  instruction?: string
  patientCenteredGoal?: string
  additionalDetails?: string
  visitNumbers?: number[]
  frequency?: string[]
}

export interface InterventionForVisit {
  intervention: InterventionInfo
  index: number
}

export const TASK_FREQUENCIES = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
]

export const getInterventionsForVisit = (
  interventions: InterventionInfo[],
  visitNumber: number
): InterventionForVisit[] => {
  return interventions
    .map((intervention, index) => ({ intervention, index }))
    .filter(
      ({ intervention }) =>
        !intervention.visitNumbers ||
        intervention.visitNumbers.length === 0 ||
        intervention.visitNumbers.includes(visitNumber)
    )
}

export const getFrequencyForIntervention = (intervention: InterventionInfo): string => {
  return intervention.frequency && intervention.frequency.length > 0
    ? intervention.frequency.join('/')
    : 'Daily'
}

export const formatAssessmentInterventions = (
  flatAnswers: Record<string, QuestionnaireResponseItem>
): InterventionInfo[] => {
  const interventionInfoQuestionnaireAnswer =
    flatAnswers[SharedCareQuestionnaireItem('PLAN_OF_CARE_PATIENT_INTERVENTIONS_INTERVENTION_INFO')]
      ?.answer ?? []

  return interventionInfoQuestionnaireAnswer.map((interventionInfoAnswer): InterventionInfo => {
    const interventionInfoItem = interventionInfoAnswer.item ?? []

    const [
      [id],
      [category],
      [interventionGoal],
      [task],
      [indication],
      [type],
      [instruction],
      [patientCenteredGoal],
      [additionalDetails],
      visitNumbers,
      frequency
    ] = [
      SharedCareQuestionnaireItem(
        'PLAN_OF_CARE_PATIENT_INTERVENTIONS_INTERVENTION_INFO_INTERVENTION'
      ),
      SharedCareQuestionnaireItem('PLAN_OF_CARE_PATIENT_INTERVENTIONS_INTERVENTION_INFO_CATEGORY'),
      SharedCareQuestionnaireItem('PLAN_OF_CARE_PATIENT_INTERVENTIONS_INTERVENTION_INFO_GOAL'),
      SharedCareQuestionnaireItem('PLAN_OF_CARE_PATIENT_INTERVENTIONS_INTERVENTION_INFO_TASK'),
      SharedCareQuestionnaireItem(
        'PLAN_OF_CARE_PATIENT_INTERVENTIONS_INTERVENTION_INFO_INDICATION'
      ),
      SharedCareQuestionnaireItem('PLAN_OF_CARE_PATIENT_INTERVENTIONS_INTERVENTION_INFO_TYPE'),
      SharedCareQuestionnaireItem(
        'PLAN_OF_CARE_PATIENT_INTERVENTIONS_INTERVENTION_INFO_INSTRUCTION'
      ),
      SharedCareQuestionnaireItem(
        'PLAN_OF_CARE_PATIENT_INTERVENTIONS_INTERVENTION_INFO_PATIENT_CENTERED_GOAL'
      ),
      SharedCareQuestionnaireItem(
        'PLAN_OF_CARE_PATIENT_INTERVENTIONS_INTERVENTION_INFO_ADDITIONAL_DETAILS'
      ),
      SharedCareQuestionnaireItem('PLAN_OF_CARE_PATIENT_INTERVENTIONS_INTERVENTION_INFO_VISITS'),
      SharedCareQuestionnaireItem('PLAN_OF_CARE_PATIENT_INTERVENTIONS_INTERVENTION_INFO_FREQUENCY')
    ].map((linkId) => getAnswerValuesFromItems(interventionInfoItem, linkId))

    return {
      id,
      category,
      interventionGoal,
      task,
      indication,
      type,
      instruction,
      patientCenteredGoal,
      additionalDetails,
      visitNumbers,
      frequency
    }
  })
}

export const formatAssessmentMedications = (
  flatAnswers: Record<string, QuestionnaireResponseItem>
): MedicationInfo[] => {
  const medicationInfoQuestionnaireAnswer =
    flatAnswers[SharedCareQuestionnaireItem('MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO')]
      ?.answer ?? []

  return medicationInfoQuestionnaireAnswer.map((medicationInfoAnswer): MedicationInfo => {
    const medicationInfoItem = medicationInfoAnswer.item ?? []

    const [
      [drugbankPcId],
      [name],
      [route],
      [strengthUnit],
      [indications],
      [frequency],
      [dosageUnit],
      [instructions],
      [controlled],
      [liquid],
      [infection],
      [strengthNumber],
      [dosageNumber],
      [usageStatus],
      [startDate],
      [discontinueDate]
    ] = [
      SharedCareQuestionnaireItem('MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_DRUGBANK_PCID'),
      SharedCareQuestionnaireItem('MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_NAME'),
      SharedCareQuestionnaireItem('MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_ROUTE'),
      SharedCareQuestionnaireItem('MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_STRENGTH_UNIT'),
      SharedCareQuestionnaireItem('MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_INDICATIONS'),
      SharedCareQuestionnaireItem('MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_FREQUENCY'),
      SharedCareQuestionnaireItem('MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_DOSAGE_UNIT'),
      SharedCareQuestionnaireItem('MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_INSTRUCTIONS'),
      SharedCareQuestionnaireItem('MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_CONTROLLED'),
      SharedCareQuestionnaireItem('MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_LIQUID'),
      SharedCareQuestionnaireItem('MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_INFECTION'),
      SharedCareQuestionnaireItem('MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_STRENGTH_NUMBER'),
      SharedCareQuestionnaireItem('MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_DOSAGE_NUMBER'),
      SharedCareQuestionnaireItem('MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_USAGE_STATUS'),
      SharedCareQuestionnaireItem('MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_START_DATE'),
      SharedCareQuestionnaireItem('MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_DISCONTINUE_DATE')
    ].map((linkId) => getAnswerValuesFromItems(medicationInfoItem, linkId))

    return {
      productConceptId: drugbankPcId,
      status: discontinueDate?.isBefore(dayjs())
        ? MedicationStatus.INACTIVE
        : MedicationStatus.ACTIVE,
      name,
      route,
      strength: {
        number: strengthNumber,
        unit: strengthUnit
      },
      indications,
      frequency,
      dosage: {
        number: dosageNumber,
        unit: dosageUnit
      },
      instructions,
      usageStatus,
      startDate,
      discontinueDate,
      controlled,
      liquid,
      infection
    }
  })
}

export const MULTIPLE_VALUE_SEPARATOR = '  |  '
export const formatDiagnosesFormValues = ({
  code,
  description,
  startDate,
  endDate
}: {
  code: string
  description: string
  startDate: Dayjs
  endDate: Dayjs | null
}): string => {
  return [
    `Code: ${code}`,
    `Code Description: ${description}`,
    `Diagnosis Date: ${startDate?.format('YYYY-MM-DD')}${
      endDate ? ` - ${endDate.format('YYYY-MM-DD')}` : ''
    }`
  ].join(MULTIPLE_VALUE_SEPARATOR)
}

export const generateMedicationsFormValues = (
  flatAnswers: Record<string, QuestionnaireResponseItem>
): string => {
  const medications = formatAssessmentMedications(flatAnswers)
  if (medications.length === 0) return ''

  const activeMedications = medications.filter(
    (medication) => medication.status === MedicationStatus.ACTIVE
  )

  const joinedMedicationRows = activeMedications
    .map((medication, i) => {
      const medicationRow: string[] = []

      let medicationLabel = `Name: ${medication.name}`
      if (
        medication.usageStatus === MedicationUsageStatus.NEW ||
        medication.usageStatus === MedicationUsageStatus.CHANGED
      ) {
        medicationLabel = `(${medication.usageStatus.toUpperCase()}) ${medicationLabel}`
      }
      medicationRow.push(medicationLabel)

      if (medication.dosage?.unit && medication.dosage?.number) {
        medicationRow.push(
          `Dosage: ${medication.dosage.number.toString()} ${medication.dosage.unit}`
        )
      }
      if (medication.frequency) {
        medicationRow.push(`Frequency: ${medication.frequency}`)
      }
      if (medication.route) {
        medicationRow.push(`Route: ${medication.route}`)
      }

      if (medication.instructions) {
        medicationRow.push(`Instructions: ${medication.instructions}`)
      }

      if (medication.indications) {
        medicationRow.push(`Indications: ${medication.indications}`)
      }

      if (medication.startDate) {
        medicationRow.push(
          `Start Date: ${medication.startDate ? medication.startDate.format('YYYY-MM-DD') : null}`
        )
      }

      if (medication.discontinueDate) {
        medicationRow.push(
          `Discontinue Date: ${
            medication.discontinueDate ? medication.discontinueDate.format('YYYY-MM-DD') : null
          }`
        )
      }

      return `${i + 1}. ${medicationRow.join(MULTIPLE_VALUE_SEPARATOR)}`
    })
    .join('\n\n')

  return joinedMedicationRows
}

export const formatInterventionInfo = (
  visitNumberMap: Record<string, InterventionInfoWithDuration>,
  areAideTasks: boolean // aide tasks (HHA) are a flexible list of tasks not tied to a visit number or frequency
): string => {
  const orders = Object.keys(visitNumberMap).map((visitNumber) => {
    const interventionsForVisitText = [
      areAideTasks ? 'Aide Tasks' : `Visit ${visitNumber} (${visitNumberMap[visitNumber].duration})`
    ]
    const visitInterventions = visitNumberMap[visitNumber].interventions as InterventionInfo[]
    const interventionsLabels = visitInterventions.map((intervention) => {
      const { task, type, indication, frequency } = intervention

      const taskFrequency =
        frequency && frequency.length > 0 ? ` (${frequency.join(', ')})` : ' (Daily)'
      const frequencyText = areAideTasks ? '' : taskFrequency
      return `\t- Task: ${task}${MULTIPLE_VALUE_SEPARATOR}Type: ${type}${frequencyText}${MULTIPLE_VALUE_SEPARATOR}Indication: ${indication}`
    })

    const totalLines = interventionsLabels.length * 2

    interventionsForVisitText.push(...interventionsLabels)
    return { text: interventionsForVisitText.join('\n'), length: totalLines }
  })

  return orders.map((order) => order.text).join('\n\n')
}

// Separates orders for treatment section from care coordination
export function formatCareCoordinationAnswer(answer?: string): {
  ordersForTreatment: string | null
  careCoordination: string | null
} {
  if (!answer) return { ordersForTreatment: null, careCoordination: null }
  const answerLines = answer.split(ORDERS_FOR_TREATMENT_HEADER)
  return {
    ordersForTreatment: `${ORDERS_FOR_TREATMENT_HEADER}${answerLines.at(1)}` ?? '',
    careCoordination: answerLines.at(0) ?? ''
  }
}
