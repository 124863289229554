import { ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE } from 'fhir/constants'

export const QUESTIONNAIRE_API_ENDPOINT_EXTENSION_URL = `${ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE}/api-endpoint`
export const QUESTIONNAIRE_UI_COMPONENT_TYPE_EXTENSION_URL = `${ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE}/questionnaire-component`
export const QUESTIONNAIRE_DATA_REFERENCE_EXTENSION_URL = `${ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE}/data-reference`
export const QUESTIONNAIRE_DATA_FORMAT_EXTENSION_URL = `${ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE}/data-format`
export const QUESTIONNAIRE_API_SEARCH_PARAMS_EXTENSION_URL = `${ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE}/api-search-param`

export const QUESTIONNAIRE_HELPER_TEXT_EXTENSION_URL = `${ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE}/helper-text`
// An extension to define the component type for assessment questions
export const QUESTIONNAIRE_COMPONENT_EXTENSION_URL = `${ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE}/questionnaire-component`
// An extension to label questions whose answers depends on another section/question
export const QUESTIONNAIRE_GENERATE_ANSWER_EXTENSION_URL = `${ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE}/generate-answer`
// An extension to define a condition to disable a target input
export const QUESTIONNAIRE_DISABLE_INPUT_CONDITION_EXTENSION_URL = `${ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE}/disable-input-condition`
