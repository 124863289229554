import React, { type ReactElement } from 'react'
import { AccessPolicyProvider } from 'contexts/AccessPolicyContext'
import MainWrapper from '../components/Layout/MainWrapper'

const AppLayout = ({ children }): JSX.Element => {
  return (
    <AccessPolicyProvider>
      <MainWrapper>{children}</MainWrapper>
    </AccessPolicyProvider>
  )
}

export const withAppLayout = (WrappedComponent): any => {
  const WithLayout = (props): any => <WrappedComponent {...props} />

  WithLayout.getLayout = function getLayout(page: ReactElement) {
    return <AppLayout>{page}</AppLayout>
  }

  return WithLayout
}

export default AppLayout
