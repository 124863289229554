import { StateCreator } from 'zustand'
import { UsePatientChartQuery } from 'generated/graphql'
import { RootSlice } from './rootType'

export interface ChartSlice {
  chart: {
    byPatientId: Record<string, UsePatientChartQuery>
    updatePatientChart: (patientId: string, data: UsePatientChartQuery) => void
  }
}

const defaultState: Pick<ChartSlice['chart'], 'byPatientId'> = {
  byPatientId: {}
}

export const createChartSlice: StateCreator<
  RootSlice,
  // Type `never` is used here to follow the instructions on Zustand docs
  // reference: https://github.com/pmndrs/zustand/blob/main/docs/guides/typescript.md#middlewares-and-their-mutators-reference
  // see index.ts for middlewares being used
  [['zustand/devtools', never], ['zustand/immer', never]],
  [],
  ChartSlice
> = (set) => ({
  chart: {
    ...defaultState,

    updatePatientChart: (patientId: string, data: UsePatientChartQuery) => {
      set((state) => {
        state.chart.byPatientId[patientId] = data
      })
    }
  }
})
