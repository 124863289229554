import { StateCreator } from 'zustand'
import { MedplumClient } from '@medplum/core'
import {
  BaseQuestionnaireResponseFragment,
  BaseQuestionnaireWithResponseListFragment,
  Get_Latest_Questionnaire_ResponseQuery
} from 'generated/graphql'
import { GET_LATEST_QUESTIONNAIRE_RESPONSE } from 'components/Assessments/getLatestQuestionnaireResponse.query'
import { AssessmentType } from 'components/Clinical/Assessments/constants'
import { AssessmentStatus } from 'fhir/QuestionnaireResponse/constants'
import { getLatestResponseFromQuestionnaire } from 'components/Assessments/hooks/helpers'
import { RootSlice } from './rootType'

interface LoadPreviousAssessmentOptions {
  currentAssessmentId?: string
  shouldFetchUnfinished?: boolean
}

export interface AssessmentSlice {
  assessment: {
    pat: {
      list: BaseQuestionnaireResponseFragment[]
      previous: BaseQuestionnaireResponseFragment | null
      isLoading: boolean
      error: string | null
    }
    soc: {
      previous: BaseQuestionnaireResponseFragment | null
      isLoading: boolean
      error: string | null
    }
    recert: {
      previous: BaseQuestionnaireResponseFragment | null
      isLoading: boolean
      error: string | null
    }
    revisionOfPAR: {
      previous: BaseQuestionnaireResponseFragment | null
      isLoading: boolean
      error: string | null
    }
    roc: {
      previous: BaseQuestionnaireResponseFragment | null
      isLoading: boolean
      error: string | null
    }
    resetState: () => void
    savePATList: (data: Get_Latest_Questionnaire_ResponseQuery) => void
    loadPreviousSOC: (
      medplum: MedplumClient,
      patientId: string,
      options?: LoadPreviousAssessmentOptions
    ) => Promise<BaseQuestionnaireResponseFragment | null>
    loadPreviousRecert: (
      medplum: MedplumClient,
      patientId: string,
      options?: LoadPreviousAssessmentOptions
    ) => Promise<BaseQuestionnaireResponseFragment | null>
    loadPreviousRevisionOfPAR: (
      medplum: MedplumClient,
      patientId: string,
      options?: LoadPreviousAssessmentOptions
    ) => Promise<BaseQuestionnaireResponseFragment | null>
    loadPreviousROC: (
      medplum: MedplumClient,
      patientId: string,
      options?: LoadPreviousAssessmentOptions
    ) => Promise<BaseQuestionnaireResponseFragment | null>
  }
}

// TODO (Jason): check
export const createAssessmentSlice: StateCreator<
  RootSlice,
  // Type `never` is used here to follow the instructions on Zustand docs
  // reference: https://github.com/pmndrs/zustand/blob/main/docs/guides/typescript.md#middlewares-and-their-mutators-reference
  // see index.ts for middlewares being used
  [['zustand/devtools', never], ['zustand/immer', never]],
  [],
  AssessmentSlice
> = (set) => ({
  assessment: {
    pat: {
      list: [],
      previous: null,
      isLoading: false,
      error: null
    },
    soc: {
      previous: null,
      isLoading: false,
      error: null
    },
    recert: {
      previous: null,
      isLoading: false,
      error: null
    },
    revisionOfPAR: {
      previous: null,
      isLoading: false,
      error: null
    },
    roc: {
      previous: null,
      isLoading: false,
      error: null
    },
    resetState: () => {
      set((state) => {
        state.assessment.pat = {
          list: [],
          previous: null,
          isLoading: false,
          error: null
        }
        state.assessment.soc = {
          previous: null,
          isLoading: false,
          error: null
        }
        state.assessment.recert = {
          previous: null,
          isLoading: false,
          error: null
        }
        state.assessment.revisionOfPAR = {
          previous: null,
          isLoading: false,
          error: null
        }
        state.assessment.roc = {
          previous: null,
          isLoading: false,
          error: null
        }
      })
    },
    savePATList: async (data: Get_Latest_Questionnaire_ResponseQuery) => {
      const allAssessments =
        data.QuestionnaireList?.flatMap(
          (questionnaire) =>
            questionnaire?.QuestionnaireResponseList?.filter(
              (questionnaireResponse): questionnaireResponse is BaseQuestionnaireResponseFragment =>
                !!questionnaireResponse
            ) ?? []
        ) ?? []

      set((state) => {
        state.assessment.pat.list = allAssessments
      })
    },
    loadPreviousSOC: async (medplum, patientId, options) => {
      try {
        set((state) => {
          state.assessment.soc.previous = null
          state.assessment.soc.isLoading = true
        })

        const { shouldFetchUnfinished, currentAssessmentId } = options ?? {}

        const params = {
          patientRef: `Patient/${patientId}`,
          identifier: AssessmentType.SOC,
          status: shouldFetchUnfinished ? undefined : AssessmentStatus.COMPLETED
        }
        const {
          data: result,
          errors
        }: { data: Get_Latest_Questionnaire_ResponseQuery; errors: any } = await medplum.graphql(
          GET_LATEST_QUESTIONNAIRE_RESPONSE,
          null,
          params
        )

        if (!result && errors) {
          throw new Error(errors)
        }

        const questionnaireList =
          result.QuestionnaireList?.filter(
            (q): q is BaseQuestionnaireWithResponseListFragment => !!q
          ) ?? []

        const latestResponse = getLatestResponseFromQuestionnaire(questionnaireList, {
          currentAssessmentId
        })

        set((state) => {
          state.assessment.soc.previous = latestResponse
        })

        return latestResponse
      } catch (e) {
        set((state) => {
          state.assessment.soc.error = e
        })

        throw new Error('Error loading the last updated SOC questionnaire', { cause: e })
      } finally {
        set((state) => {
          state.assessment.soc.isLoading = false
        })
      }
    },
    loadPreviousRecert: async (medplum, patientId, options) => {
      try {
        set((state) => {
          state.assessment.recert.previous = null
          state.assessment.recert.isLoading = true
        })

        const { shouldFetchUnfinished, currentAssessmentId } = options ?? {}

        const params = {
          patientRef: `Patient/${patientId}`,
          identifier: AssessmentType.RECERT,
          status: shouldFetchUnfinished ? undefined : AssessmentStatus.COMPLETED
        }

        const {
          data: result,
          errors
        }: { data: Get_Latest_Questionnaire_ResponseQuery; errors: any } = await medplum.graphql(
          GET_LATEST_QUESTIONNAIRE_RESPONSE,
          null,
          params
        )

        if (!result && errors) {
          throw new Error(errors)
        }

        const questionnaireList =
          result.QuestionnaireList?.filter(
            (q): q is BaseQuestionnaireWithResponseListFragment => !!q
          ) ?? []

        const latestResponse = getLatestResponseFromQuestionnaire(questionnaireList, {
          currentAssessmentId
        })

        set((state) => {
          state.assessment.recert.previous = latestResponse
        })

        return latestResponse
      } catch (e) {
        set((state) => {
          state.assessment.recert.error = e
        })

        throw new Error('Error loading the last updated Recert questionnaire', { cause: e })
      } finally {
        set((state) => {
          state.assessment.recert.isLoading = false
        })
      }
    },
    loadPreviousRevisionOfPAR: async (medplum, patientId, options) => {
      try {
        set((state) => {
          state.assessment.revisionOfPAR.previous = null
          state.assessment.revisionOfPAR.isLoading = true
        })

        const { shouldFetchUnfinished, currentAssessmentId } = options ?? {}

        const params = {
          patientRef: `Patient/${patientId}`,
          identifier: AssessmentType.REVISION_OF_PAR,
          status: shouldFetchUnfinished ? undefined : AssessmentStatus.COMPLETED
        }
        const {
          data: result,
          errors
        }: { data: Get_Latest_Questionnaire_ResponseQuery; errors: any } = await medplum.graphql(
          GET_LATEST_QUESTIONNAIRE_RESPONSE,
          null,
          params
        )

        if (!result && errors) {
          throw new Error(errors)
        }

        const questionnaireList =
          result.QuestionnaireList?.filter(
            (q): q is BaseQuestionnaireWithResponseListFragment => !!q
          ) ?? []

        const latestResponse = getLatestResponseFromQuestionnaire(questionnaireList, {
          currentAssessmentId
        })

        set((state) => {
          state.assessment.revisionOfPAR.previous = latestResponse
        })

        return latestResponse
      } catch (e) {
        set((state) => {
          state.assessment.revisionOfPAR.error = e
        })

        throw new Error('Error loading the last updated Revision of PAR questionnaire', {
          cause: e
        })
      } finally {
        set((state) => {
          state.assessment.revisionOfPAR.isLoading = false
        })
      }
    },
    loadPreviousROC: async (medplum, patientId, options) => {
      try {
        set((state) => {
          state.assessment.roc.previous = null
          state.assessment.roc.isLoading = true
        })

        const { shouldFetchUnfinished, currentAssessmentId } = options ?? {}

        const params = {
          patientRef: `Patient/${patientId}`,
          identifier: AssessmentType.ROC,
          status: shouldFetchUnfinished ? undefined : AssessmentStatus.COMPLETED
        }
        const {
          data: result,
          errors
        }: { data: Get_Latest_Questionnaire_ResponseQuery; errors: any } = await medplum.graphql(
          GET_LATEST_QUESTIONNAIRE_RESPONSE,
          null,
          params
        )

        if (!result && errors) {
          throw new Error(errors)
        }

        const questionnaireList =
          result.QuestionnaireList?.filter(
            (q): q is BaseQuestionnaireWithResponseListFragment => !!q
          ) ?? []

        const latestResponse = getLatestResponseFromQuestionnaire(questionnaireList, {
          currentAssessmentId
        })

        set((state) => {
          state.assessment.roc.previous = latestResponse
        })

        return latestResponse
      } catch (e) {
        set((state) => {
          state.assessment.roc.error = e
        })

        throw new Error('Error loading the last updated Resumption of Care questionnaire', {
          cause: e
        })
      } finally {
        set((state) => {
          state.assessment.roc.isLoading = false
        })
      }
    }
  }
})
