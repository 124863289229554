export const breakpoints = {
  mobile: '768px',
  tablet: '992px',
  desktop: '1200px',
  largeDesktop: '1440px'
}

export const mediaQueries = {
  aboveMobile: `(min-width: ${breakpoints.mobile})`,
  aboveTablet: `(min-width: ${breakpoints.tablet})`,
  aboveDesktop: `(min-width: ${breakpoints.desktop})`,
  belowMobile: `(max-width: ${breakpoints.mobile})`,
  belowTablet: `(max-width: ${breakpoints.tablet})`,
  belowDesktop: `(max-width: ${breakpoints.desktop})`
}
