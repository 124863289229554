export enum DischargeAssessmentQuestionnaireItem {
  // Assessment overview
  ASSESSMENT_OVERVIEW_APPOINTMENT_TIME = 'assessment-overview/appointment-time',
  ASSESSMENT_OVERVIEW_APPOINTMENT_TIME_START = 'assessment-overview/appointment-time/start',
  ASSESSMENT_OVERVIEW_APPOINTMENT_TIME_END = 'assessment-overview/appointment-time/end',

  // Patient overview
  PATIENT_OVERVIEW_LEGAL_NAME = 'patient-overview/legal-name',
  PATIENT_OVERVIEW_PREFERRED_NAME = 'patient-overview/preferred-name',
  PATIENT_OVERVIEW_DATE_OF_BIRTH = 'patient-overview/date-of-birth',
  PATIENT_OVERVIEW_ADDRESS = 'patient-overview/address',
  PATIENT_OVERVIEW_GENDER = 'patient-overview/gender',
  PATIENT_OVERVIEW_SEX = 'patient-overview/sex',
  PATIENT_OVERVIEW_ORDERING_PRACTITIONER = 'patient-overview/ordering-practitioner',
  PATIENT_OVERVIEW_DISCHARGE_ASSESSMENT_STATUS = 'patient-overview/discharge-assessment-status',

  // Medications
  MEDICATIONS = 'medications',
  MEDICATIONS_MEDICATION_LIST = 'medications/medication-list',
  MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO = 'medications/medication-list/medication-info',
  MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_DRUGBANK_PCID = 'medications/medication-list/medication-info/drugbank-product-concept-id',
  MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_NAME = 'medications/medication-list/medication-info/name',
  MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_ROUTE = 'medications/medication-list/medication-info/route',
  MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_STRENGTH_NUMBER = 'medications/medication-list/medication-info/strength-number',
  MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_STRENGTH_UNIT = 'medications/medication-list/medication-info/strength-unit',
  MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_INDICATIONS = 'medications/medication-list/medication-info/indications',
  MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_FREQUENCY = 'medications/medication-list/medication-info/frequency',
  MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_DOSAGE_NUMBER = 'medications/medication-list/medication-info/dosage-number',
  MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_DOSAGE_UNIT = 'medications/medication-list/medication-info/dosage-unit',
  MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_INSTRUCTIONS = 'medications/medication-list/medication-info/instructions',
  MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_USAGE_STATUS = 'medications/medication-list/medication-info/usage-status',
  MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_START_DATE = 'medications/medication-list/medication-info/start-date',
  MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_DISCONTINUE_DATE = 'medications/medication-list/medication-info/discontinue-date',
  MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_CONTROLLED = 'medications/medication-list/medication-info/controlled',
  MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_LIQUID = 'medications/medication-list/medication-info/liquid',
  MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_INFECTION = 'medications/medication-list/medication-info/infection',
  MEDICATIONS_MEDICATION_SUMMARY = 'medications/medication-summary',

  // Medical background
  MEDICAL_BACKGROUND_BACKGROUND_PRIMARY_DIAGNOSIS = 'medical-background/background/primary-diagnosis',
  MEDICAL_BACKGROUND_BACKGROUND_OTHER_DIAGNOSIS = 'medical-background/background/other-diagnosis',
  MEDICAL_BACKGROUND_BACKGROUND_EQUIPMENT = 'medical-background/background/equipment',
  MEDICAL_BACKGROUND_BACKGROUND_DME_PROVIDER = 'medical-background/background/dme-provider',
  MEDICAL_BACKGROUND_BACKGROUND_ALLERGIES = 'medical-background/background/allergies',
  MEDICAL_BACKGROUND_BACKGROUND_ALLERGIES_NOTES = 'medical-background/background/allergies-notes',
  MEDICAL_BACKGROUND_BACKGROUND_WAIVERS = 'medical-background/background/waivers',
  MEDICAL_BACKGROUND_BACKGROUND_ADDITIONAL_SERVICES = 'medical-background/background/additional-services',
  MEDICAL_BACKGROUND_BACKGROUND_PROGNOSIS = 'medical-background/background/prognosis',
  MEDICAL_BACKGROUND_BACKGROUND_DNR = 'medical-background/background/dnr',
  MEDICAL_BACKGROUND_BACKGROUND_ADVANCE_DIRECTIVES = 'medical-background/background/advance-directives',
  MEDICAL_BACKGROUND_BACKGROUND_ADVANCE_DIRECTIVES_NOTES = 'medical-background/background/advance-directives-notes',
  MEDICAL_BACKGROUND_HISTORY_60_DAY_ER = 'medical-background/history/60-day-er',
  MEDICAL_BACKGROUND_HISTORY_ER_NOTES = 'medical-background/history/er-notes',
  MEDICAL_BACKGROUND_HISTORY_60_DAY_HOSPITAL = 'medical-background/history/60-day-hospital',
  MEDICAL_BACKGROUND_HISTORY_HOSPITAL_NOTES = 'medical-background/history/hospital-notes',
  MEDICAL_BACKGROUND_HISTORY_60_DAY_INJURIES = 'medical-background/history/60-day-injuries',
  MEDICAL_BACKGROUND_HISTORY_INJURY_NOTES = 'medical-background/history/injury-notes',
  MEDICAL_BACKGROUND_HISTORY_60_DAY_FALLS = 'medical-background/history/60-day-falls',
  MEDICAL_BACKGROUND_HISTORY_FALL_NOTES = 'medical-background/history/fall-notes',
  MEDICAL_BACKGROUND_HISTORY_60_DAY_INFECTIONS = 'medical-background/history/60-day-infections',
  MEDICAL_BACKGROUND_HISTORY_INFECTION_NOTES = 'medical-background/history/infection-notes',
  MEDICAL_BACKGROUND_HISTORY_60_DAY_MD = 'medical-background/history/60-day-md',
  MEDICAL_BACKGROUND_HISTORY_MD_NOTES = 'medical-background/history/md-notes',
  MEDICAL_BACKGROUND_HISTORY_FUNCTIONAL_LIMITATIONS = 'medical-background/history/functional-limitations',
  MEDICAL_BACKGROUND_HISTORY_ACTIVITIES_PERMITTED = 'medical-background/history/activities-permitted',
  MEDICAL_BACKGROUND_HISTORY_MEDICAL_HISTORY = 'medical-background/history/medical-history',
  MEDICAL_BACKGROUND_HISTORY_SURGICAL_HISTORY = 'medical-background/history/surgical-history',
  MEDICAL_BACKGROUND_IMMUNIZATIONS_HEP_B = 'medical-background/immunizations/hep-b',
  MEDICAL_BACKGROUND_IMMUNIZATIONS_HEP_B_HEP_B = 'medical-background/immunizations/hep-b/hep-b',
  MEDICAL_BACKGROUND_IMMUNIZATIONS_HEP_B_DATE = 'medical-background/immunizations/hep-b/date',
  MEDICAL_BACKGROUND_IMMUNIZATIONS_MENINGOCOCCAL = 'medical-background/immunizations/meningococcal',
  MEDICAL_BACKGROUND_IMMUNIZATIONS_MENINGOCOCCAL_MENINGOCOCCAL = 'medical-background/immunizations/meningococcal/meningococcal',
  MEDICAL_BACKGROUND_IMMUNIZATIONS_MENINGOCOCCAL_DATE = 'medical-background/immunizations/meningococcal/date',
  MEDICAL_BACKGROUND_IMMUNIZATIONS_COVID_19 = 'medical-background/immunizations/covid-19',
  MEDICAL_BACKGROUND_IMMUNIZATIONS_COVID_19_COVID_19 = 'medical-background/immunizations/covid-19/covid-19',
  MEDICAL_BACKGROUND_IMMUNIZATIONS_COVID_19_DATE = 'medical-background/immunizations/covid-19/date',
  MEDICAL_BACKGROUND_IMMUNIZATIONS_DTAP = 'medical-background/immunizations/dtap',
  MEDICAL_BACKGROUND_IMMUNIZATIONS_DTAP_DTAP = 'medical-background/immunizations/dtap/dtap',
  MEDICAL_BACKGROUND_IMMUNIZATIONS_DTAP_DATE = 'medical-background/immunizations/dtap/date',
  MEDICAL_BACKGROUND_IMMUNIZATIONS_RSV = 'medical-background/immunizations/rsv',
  MEDICAL_BACKGROUND_IMMUNIZATIONS_RSV_RSV = 'medical-background/immunizations/rsv/rsv',
  MEDICAL_BACKGROUND_IMMUNIZATIONS_RSV_DATE = 'medical-background/immunizations/rsv/date',
  MEDICAL_BACKGROUND_IMMUNIZATIONS_HPV = 'medical-background/immunizations/hpv',
  MEDICAL_BACKGROUND_IMMUNIZATIONS_HPV_HPV = 'medical-background/immunizations/hpv/hpv',
  MEDICAL_BACKGROUND_IMMUNIZATIONS_HPV_DATE = 'medical-background/immunizations/hpv/date',
  MEDICAL_BACKGROUND_IMMUNIZATIONS_NOTES = 'medical-background/immunizations/notes',
  MEDICAL_BACKGROUND_RISK_ASSESSMENT_HOSPITALIZATION_RISK = 'medical-background/risk-assessment/hospitalization-risk',
  MEDICAL_BACKGROUND_RISK_ASSESSMENT_HOSPITALIZATION_RISK_FACTORS = 'medical-background/risk-assessment/hospitalization-risk-factors',
  MEDICAL_BACKGROUND_RISK_ASSESSMENT_TRIAGE_CODE = 'medical-background/risk-assessment/triage-code',
  MEDICAL_BACKGROUND_RISK_ASSESSMENT_EMERGENCY_PLAN = 'medical-background/risk-assessment/emergency-plan',
  MEDICAL_BACKGROUND_RISK_ASSESSMENT_SAFE_ROOM = 'medical-background/risk-assessment/safe-room',
  MEDICAL_BACKGROUND_RISK_ASSESSMENT_FIRE_ASSESSMENT_OXYGEN = 'medical-background/risk-assessment/fire-assessment-oxygen',
  MEDICAL_BACKGROUND_RISK_ASSESSMENT_FIRE_SAFETY_PRECAUTIONS = 'medical-background/risk-assessment/fire-safety-precautions',
  MEDICAL_BACKGROUND_RISK_ASSESSMENT_HOMEBOUND_STATUS = 'medical-background/risk-assessment/homebound-status',
  MEDICAL_BACKGROUND_RISK_ASSESSMENT_INFECTION_RISK = 'medical-background/risk-assessment/infection-risk',
  MEDICAL_BACKGROUND_RISK_ASSESSMENT_INFECTION_RISK_FACTORS = 'medical-background/risk-assessment/infection-risk-factors',
  MEDICAL_BACKGROUND_RISK_ASSESSMENT_FALL_RISK = 'medical-background/risk-assessment/fall-risk',
  MEDICAL_BACKGROUND_RISK_ASSESSMENT_FALL_RISK_FACTORS = 'medical-background/risk-assessment/fall-risk-factors',
  MEDICAL_BACKGROUND_RISK_ASSESSMENT_FALL_RISK_ASSESSMENT = 'medical-background/risk-assessment/fall-risk-assessment',
  MEDICAL_BACKGROUND_RISK_ASSESSMENT_FINANCE_RISK = 'medical-background/risk-assessment/finance-risk',
  MEDICAL_BACKGROUND_RISK_ASSESSMENT_ENVIRONMENT_SAFETY = 'medical-background/risk-assessment/environment-safety',
  MEDICAL_BACKGROUND_RISK_ASSESSMENT_SAFETY_MEASURES = 'medical-background/risk-assessment/safety-measures',
  MEDICAL_BACKGROUND_RISK_ASSESSMENT_NOTES = 'medical-background/risk-assessment/notes',
  MEDICAL_BACKGROUND_NEURO_HEADACHES = 'medical-background/neuro/headaches',
  MEDICAL_BACKGROUND_NEURO_HEADACHE_TYPE = 'medical-background/neuro/headache-type',
  MEDICAL_BACKGROUND_NEURO_SEIZURES = 'medical-background/neuro/seizures',
  MEDICAL_BACKGROUND_NEURO_SEIZURE_DATE = 'medical-background/neuro/seizure-date',
  MEDICAL_BACKGROUND_NEURO_SEIZURE_TYPE = 'medical-background/neuro/seizure-type',
  MEDICAL_BACKGROUND_NEURO_SEIZURE_PRECAUTIONS = 'medical-background/neuro/seizure-precautions',
  MEDICAL_BACKGROUND_NEURO_NEURO_STATUS = 'medical-background/neuro/neuro-status',
  MEDICAL_BACKGROUND_NEURO_ALTERED_AFFECT = 'medical-background/neuro/altered-affect',
  MEDICAL_BACKGROUND_NEURO_ALTERED_AFFECT_FACTORS = 'medical-background/neuro/altered-affect-factors',
  MEDICAL_BACKGROUND_NEURO_PHQ2_SCREENING = 'medical-background/neuro/phq2-screening',
  MEDICAL_BACKGROUND_NEURO_PHQ2_NOTES = 'medical-background/neuro/phq2-notes',
  MEDICAL_BACKGROUND_NEURO_INTEREST = 'medical-background/neuro/interest',
  MEDICAL_BACKGROUND_NEURO_DEPRESSED = 'medical-background/neuro/depressed',
  MEDICAL_BACKGROUND_NEURO_CAREGIVER_DIFFICULTY = 'medical-background/neuro/caregiver-difficulty',
  MEDICAL_BACKGROUND_NEURO_SYSTEM_SUMMARY = 'medical-background/neuro/system-summary',
  MEDICAL_BACKGROUND_COGNITIVE_PSYCHOSOCIAL_DEV_COMMUNICATION_ASSESSMENT = 'medical-background/cognitive-psychosocial-dev/communication-assessment',
  MEDICAL_BACKGROUND_COGNITIVE_PSYCHOSOCIAL_DEV_COGNITIVE_ASSESSMENT = 'medical-background/cognitive-psychosocial-dev/cognitive-assessment',
  MEDICAL_BACKGROUND_COGNITIVE_PSYCHOSOCIAL_DEV_HISTORY_SUBSTANCE_ABUSE = 'medical-background/cognitive-psychosocial-dev/history-substance-abuse',
  MEDICAL_BACKGROUND_COGNITIVE_PSYCHOSOCIAL_DEV_SUBSTANCE_ABUSE_NOTES = 'medical-background/cognitive-psychosocial-dev/substance-abuse-notes',
  MEDICAL_BACKGROUND_COGNITIVE_PSYCHOSOCIAL_DEV_SYSTEM_SUMMARY = 'medical-background/cognitive-psychosocial-dev/system-summary',
  MEDICAL_BACKGROUND_MUSCULOSKELETAL_MUSCULOSKELETAL_ASSESSMENT = 'medical-background/musculoskeletal/musculoskeletal-assessment',
  MEDICAL_BACKGROUND_MUSCULOSKELETAL_SYSTEM_SUMMARY = 'medical-background/musculoskeletal/system-summary',
  MEDICAL_BACKGROUND_LINES_DRAINS_SYSTEM_SUMMARY = 'medical-background/lines-drains/system-summary',
  MEDICAL_BACKGROUND_THERAPIES_THERAPIES = 'medical-background/therapies/therapies',
  MEDICAL_BACKGROUND_THERAPIES_THERAPY_PROVIDER = 'medical-background/therapies/therapy-provider',
  MEDICAL_BACKGROUND_THERAPIES_HOME_EXERCISE_PLAN = 'medical-background/therapies/home-exercise-plan',
  MEDICAL_BACKGROUND_THERAPIES_HOME_EXERCISE_DURATION = 'medical-background/therapies/home-exercise-duration',
  MEDICAL_BACKGROUND_THERAPIES_HOME_EXERCISE_FREQUENCY = 'medical-background/therapies/home-exercise-frequency',
  MEDICAL_BACKGROUND_SENSORY_SENSORY_STATUS_EYES = 'medical-background/sensory/sensory-status-eyes',
  MEDICAL_BACKGROUND_SENSORY_SENSORY_STATUS_EARS = 'medical-background/sensory/sensory-status-ears',
  MEDICAL_BACKGROUND_SENSORY_SENSORY_STATUS_NOSE = 'medical-background/sensory/sensory-status-nose',
  MEDICAL_BACKGROUND_SENSORY_SENSORY_STATUS_TOUCH = 'medical-background/sensory/sensory-status-touch',
  MEDICAL_BACKGROUND_SENSORY_SENSORY_STATUS_TASTE = 'medical-background/sensory/sensory-status-taste',
  MEDICAL_BACKGROUND_SENSORY_SYSTEM_SUMMARY = 'medical-background/sensory/system-summary',
  MEDICAL_BACKGROUND_PAIN_STATUS = 'medical-background/pain/status',
  MEDICAL_BACKGROUND_PAIN_GOAL = 'medical-background/pain/goal',
  MEDICAL_BACKGROUND_PAIN_FLACC_SCALE_FACE = 'medical-background/pain/flacc-scale-face',
  MEDICAL_BACKGROUND_PAIN_FLACC_SCALE_LEGS = 'medical-background/pain/flacc-scale-legs',
  MEDICAL_BACKGROUND_PAIN_FLACC_SCALE_ACTIVITY = 'medical-background/pain/flacc-scale-activity',
  MEDICAL_BACKGROUND_PAIN_FLACC_SCALE_CRY = 'medical-background/pain/flacc-scale-cry',
  MEDICAL_BACKGROUND_PAIN_FLACC_SCALE_CONSOLABILITY = 'medical-background/pain/flacc-scale-consolability',
  MEDICAL_BACKGROUND_PAIN_SYSTEM_SUMMARY = 'medical-background/pain/system-summary',
  MEDICAL_BACKGROUND_CARDIAC_CARDIOVASCULAR_WNL = 'medical-background/cardiac/cardiovascular-wnl',
  MEDICAL_BACKGROUND_CARDIAC_CARDIOVASCULAR_FACTORS = 'medical-background/cardiac/cardiac-factors',
  MEDICAL_BACKGROUND_CARDIAC_HEART_SOUNDS = 'medical-background/cardiac/heart-sounds',
  MEDICAL_BACKGROUND_CARDIAC_SYSTEM_SUMMARY = 'medical-background/cardiac/system-summary',
  MEDICAL_BACKGROUND_RESPIRATORY_RESPIRATORY_WNL = 'medical-background/respiratory/respiratory-wnl',
  MEDICAL_BACKGROUND_RESPIRATORY_COUGH = 'medical-background/respiratory/cough',
  MEDICAL_BACKGROUND_RESPIRATORY_OXYGEN = 'medical-background/respiratory/oxygen',
  MEDICAL_BACKGROUND_RESPIRATORY_LUNG_SOUNDS = 'medical-background/respiratory/lung-sounds',
  MEDICAL_BACKGROUND_RESPIRATORY_SYSTEM_SUMMARY = 'medical-background/respiratory/system-summary',
  MEDICAL_BACKGROUND_ENDOCRINE_ENDOCRINE_WNL = 'medical-background/endocrine/endocrine-wnl',
  MEDICAL_BACKGROUND_ENDOCRINE_MENSTRUAL_CYCLE = 'medical-background/endocrine/menstrual-cycle',
  MEDICAL_BACKGROUND_ENDOCRINE_MENSTRUAL_DATE = 'medical-background/endocrine/menstrual-date',
  MEDICAL_BACKGROUND_ENDOCRINE_ENDOCRINE_NOTES = 'medical-background/endocrine/endocrine-notes',
  MEDICAL_BACKGROUND_ENDOCRINE_SYSTEM_SUMMARY = 'medical-background/endocrine/system-summary',
  MEDICAL_BACKGROUND_GASTROINTESTINAL_GI_ASSESSMENT = 'medical-background/gastrointestinal/gi-assessment',
  MEDICAL_BACKGROUND_GASTROINTESTINAL_BM_DATE = 'medical-background/gastrointestinal/bm-date',
  MEDICAL_BACKGROUND_GASTROINTESTINAL_GU_ASSESSMENT = 'medical-background/gastrointestinal/gu-assessment',
  MEDICAL_BACKGROUND_GASTROINTESTINAL_CATHETER = 'medical-background/gastrointestinal/catheter',
  MEDICAL_BACKGROUND_GASTROINTESTINAL_CATHETER_TYPE = 'medical-background/gastrointestinal/catheter-type',
  MEDICAL_BACKGROUND_GASTROINTESTINAL_FEEDING_TUBE = 'medical-background/gastrointestinal/feeding-tube',
  MEDICAL_BACKGROUND_GASTROINTESTINAL_FEEDING_TUBE_AMOUNT = 'medical-background/gastrointestinal/feeding-tube-amount',
  MEDICAL_BACKGROUND_GASTROINTESTINAL_FEEDING_TOTAL_NUMBER = 'medical-background/gastrointestinal/feeding-total-number',
  MEDICAL_BACKGROUND_GASTROINTESTINAL_FEEDING_TUBE_DURATION = 'medical-background/gastrointestinal/feeding-tube-duration',
  MEDICAL_BACKGROUND_GASTROINTESTINAL_FEEDING_TUBE_TYPE = 'medical-background/gastrointestinal/feeding-tube-type',
  MEDICAL_BACKGROUND_GASTROINTESTINAL_FEEDING_TUBE_SITE = 'medical-background/gastrointestinal/feeding-tube-site',
  MEDICAL_BACKGROUND_GASTROINTESTINAL_FEEDING_TUBE_SIZE = 'medical-background/gastrointestinal/feeding-tube-size',
  MEDICAL_BACKGROUND_GASTROINTESTINAL_FEEDING_TUBE_NOTES = 'medical-background/gastrointestinal/feeding-tube-notes',
  MEDICAL_BACKGROUND_GASTROINTESTINAL_NUTRITION_WNL = 'medical-background/gastrointestinal/nutrition-wnl',
  MEDICAL_BACKGROUND_GASTROINTESTINAL_NUTRITIONAL_FACTORS = 'medical-background/gastrointestinal/nutritional-factors',
  MEDICAL_BACKGROUND_GASTROINTESTINAL_DIET_REQUIREMENTS = 'medical-background/gastrointestinal/diet-requirements',
  MEDICAL_BACKGROUND_GASTROINTESTINAL_NUTRITION_NOTES = 'medical-background/gastrointestinal/nutrition-notes',
  MEDICAL_BACKGROUND_GASTROINTESTINAL_NUTRITIONAL_SCREENING = 'medical-background/gastrointestinal/nutritional-screening',
  MEDICAL_BACKGROUND_GASTROINTESTINAL_GASTROINTESTINAL_HISTORY_NOTES = 'medical-background/gastrointestinal/gastrointestinal-history-notes',
  MEDICAL_BACKGROUND_GASTROINTESTINAL_SYSTEM_SUMMARY = 'medical-background/gastrointestinal/system-summary',
  MEDICAL_BACKGROUND_INTEGUMENTARY_SKIN_COLOR = 'medical-background/integumentary/skin-color',
  MEDICAL_BACKGROUND_INTEGUMENTARY_SKIN_ISSUES = 'medical-background/integumentary/skin-issues',
  MEDICAL_BACKGROUND_INTEGUMENTARY_NAILS = 'medical-background/integumentary/nails',
  MEDICAL_BACKGROUND_INTEGUMENTARY_IDENTIFIED_WOUNDS = 'medical-background/integumentary/identified-wounds',
  MEDICAL_BACKGROUND_INTEGUMENTARY_WOUND_NOTES = 'medical-background/integumentary/wound-notes',
  MEDICAL_BACKGROUND_INTEGUMENTARY_SYSTEM_SUMMARY = 'medical-background/integumentary/system-summary',
  MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_TEMP = 'medical-background/physician-notification/temp',
  MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_TEMP_GREATER = 'medical-background/physician-notification/temp/greater',
  MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_TEMP_LESS = 'medical-background/physician-notification/temp/less',
  MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_PULSE = 'medical-background/physician-notification/pulse',
  MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_PULSE_GREATER = 'medical-background/physician-notification/pulse/greater',
  MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_PULSE_LESS = 'medical-background/physician-notification/pulse/less',
  MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_RESPIRATIONS = 'medical-background/physician-notification/respirations',
  MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_RESPIRATIONS_GREATER = 'medical-background/physician-notification/respirations/greater',
  MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_RESPIRATIONS_LESS = 'medical-background/physician-notification/respirations/less',
  MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_BP = 'medical-background/physician-notification/bp',
  MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_BP_GREATER = 'medical-background/physician-notification/bp/greater',
  MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_BP_LESS = 'medical-background/physician-notification/bp/less',
  MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_WEIGHT = 'medical-background/physician-notification/weight',
  MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_WEIGHT_GREATER = 'medical-background/physician-notification/weight/greater',
  MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_WEIGHT_LESS = 'medical-background/physician-notification/weight/less',
  MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_ETCO2_SATURATION = 'medical-background/physician-notification/etco2-saturation',
  MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_ETCO2_SATURATION_GREATER = 'medical-background/physician-notification/etco2-saturation/greater',
  MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_ETCO2_SATURATION_LESS = 'medical-background/physician-notification/etco2-saturation/less',
  MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_O2_SATURATION = 'medical-background/physician-notification/o2-saturation',
  MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_O2_SATURATION_GREATER = 'medical-background/physician-notification/o2-saturation/greater',
  MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_O2_SATURATION_LESS = 'medical-background/physician-notification/o2-saturation/less',
  MEDICAL_BACKGROUND_VITALS_TEMPERATURE = 'medical-background/vitals/temperature',
  MEDICAL_BACKGROUND_VITALS_PULSE = 'medical-background/vitals/pulse',
  MEDICAL_BACKGROUND_VITALS_RESPIRATIONS = 'medical-background/vitals/respirations',
  MEDICAL_BACKGROUND_VITALS_O2_SATURATION = 'medical-background/vitals/o2-saturation',
  MEDICAL_BACKGROUND_VITALS_BLOOD_PRESSURE = 'medical-background/vitals/blood-pressure',
  MEDICAL_BACKGROUND_VITALS_HEIGHT = 'medical-background/vitals/height',
  MEDICAL_BACKGROUND_VITALS_BLOOD_SUGAR = 'medical-background/vitals/blood-sugar',
  MEDICAL_BACKGROUND_VITALS_WEIGHT = 'medical-background/vitals/weight',

  // Discharge summary
  DISCHARGE_SUMMARY = 'discharge-summary',
  DISCHARGE_SUMMARY_TYPES_OF_SERVICES = 'discharge-summary/types-of-services',
  DISCHARGE_SUMMARY_SERVICE_FREQUENCY = 'discharge-summary/service-frequency',
  DISCHARGE_SUMMARY_LAST_DATE_OF_SERVICE = 'discharge-summary/last-date-of-service',
  DISCHARGE_SUMMARY_DISCHARGE_CONDITION = 'discharge-summary/discharge-condition',
  DISCHARGE_SUMMARY_REASON_FOR_DISCHARGE = 'discharge-summary/reason-for-discharge',
  DISCHARGE_SUMMARY_LABORATORY_DATA = 'discharge-summary/laboratory-data',
  DISCHARGE_SUMMARY_PLAN_OF_CARE_OUTCOMES = 'discharge-summary/plan-of-care-outcomes',
  DISCHARGE_SUMMARY_DISCHARGE_INSTRUCTIONS = 'discharge-summary/discharge-instructions',
  DISCHARGE_SUMMARY_POST_DISCHARGE_TREATMENT = 'discharge-summary/post-discharge-treatment',
  DISCHARGE_SUMMARY_POST_DISCHARGE_GOALS = 'discharge-summary/post-discharge-goals',

  // Final steps
  FINAL_STEPS_CURRENT_CERT_PERIOD = 'final-steps/current-cert-period',
  FINAL_STEPS_DISCHARGE_COMMUNICATION = 'final-steps/discharge-communication',
  FINAL_STEPS_RESOURCES_PROVIDED = 'final-steps/resources-provided',
  FINAL_STEPS_CAREGIVER_RESPONSE = 'final-steps/caregiver-response',
  FINAL_STEPS_DATE_PHYSICIAN_NOTIFIED = 'final-steps/date-physician-notified',
  FINAL_STEPS_DISCHARGE_NARRATIVE = 'final-steps/discharge-narrative',
  FINAL_STEPS_INTERNAL_NOTES = 'final-steps/internal-notes'
}
