import gql from 'gql-tag'
import {
  PRACTITIONER_RACE_EXTENSION,
  PRACTITIONER_REFERRAL_LINK_EXTENSION_URL,
  PRACTITIONER_TIMEZONE_EXTENSION_URL,
  PRACTITIONER_US_CORE_BIRTHSEX_EXTENSION
} from 'fhir/Practitioner/constants'

export const BASE_PRACTITIONER_FRAGMENT = gql`
  fragment BasePractitioner on Practitioner {
    resourceType
    id
    birthDate
    active
    gender
    name {
      prefix
      given
      family
      suffix
      use
      text
    }
    telecom {
      system
      value
    }
    address {
      use
      type
      text
      line
      city
      state
      postalCode
    }
    qualification {
      identifier {
        id
        system
        value
        use
      }
      code {
        coding {
          id
          code
          display
          system
        }
      }
      issuer {
        reference
      }
    }
    identifier {
      id
      type {
        coding {
          system
          display
          code
        }
      }
      value
      system
    }
    birthSexExtension: extension(
      url: "${PRACTITIONER_US_CORE_BIRTHSEX_EXTENSION}"
    ) {
      valueCodeableConcept {
        coding {
          system
          code
        }
      }
    }
    ethnicityExtension: extension(
      url: "${PRACTITIONER_RACE_EXTENSION}"
    ) {
      valueString
    }
    timezoneExtension: extension(
      url: "${PRACTITIONER_TIMEZONE_EXTENSION_URL}"
    ) {
      valueCoding {
        system
        code
      }
    }
    referralLinkExtension: extension(
      url: "${PRACTITIONER_REFERRAL_LINK_EXTENSION_URL}"
    ) {
      valueString
    }
  }
`

// ————————————————————————————————————————————————————————————————————————————————
// Nested types used for helper functions
// ————————————————————————————————————————————————————————————————————————————————

export const BASE_PRACTITIONER_WITH_ROLES_FRAGMENT = gql`
  fragment BasePractitionerWithRoles on Practitioner {
    ...BasePractitioner
    PractitionerRoleList(_reference: practitioner) {
      ...BasePractitionerRole
    }
  }
`

export const BASE_PRACTITIONER_WITH_ROLES_ORG_FRAGMENT = gql`
  fragment BasePractitionerWithRolesOrg on Practitioner {
    ...BasePractitioner
    PractitionerRoleList(_reference: practitioner) {
      ...BasePractitionerRoleWithOrganization
    }
  }
`
