import {
  ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE,
  ABBYCARE_TERMINOLOGY_CODES_BASE
} from 'fhir/constants'

export enum CommunicationStatus {
  PREPARATION = 'preparation',
  IN_PROGRESS = 'in-progress',
  NOT_DONE = 'not-done',
  ON_HOLD = 'on-hold',
  STOPPED = 'stopped',
  COMPLETED = 'completed',
  ENTERED_IN_ERROR = 'entered-in-error',
  UNKNOWN = 'unknown'
}

export const PHYSICIAN_NOTIFIED_COMMUNICATION_EXTENSION = `${ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE}/physician-notified`
export const RESUMPTION_ORDER_RECEIVED_COMMUNICATION_EXTENSION = `${ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE}/resumption-order-received`

export const COMMUNICATION_IDENTIFIER_SYSTEM = `${ABBYCARE_TERMINOLOGY_CODES_BASE}/communication-identifier`

export enum CommunicationIdentifierType {
  CLINICAL = 'clinical',
  GENERAL = 'general'
}

export const COMMUNICATION_CATEGORY_CODE_SYSTEM = `${ABBYCARE_TERMINOLOGY_CODES_BASE}/communication-category`

export enum CommunicationCategory {
  NOTE = 'note'
}

export const COMMUNICATION_NOTE_REPORTER_CATEGORY_CODE_SYSTEM = `${ABBYCARE_TERMINOLOGY_CODES_BASE}/communication-note-reporter-category`

export enum NoteReporterCategory {
  CAREGIVER = 'caregiver',
  CLINICAL_TEAM = 'clinical-team'
}

export const COMMUNICATION_NOTE_CATEGORY_CODE_SYSTEM = `${ABBYCARE_TERMINOLOGY_CODES_BASE}/communication-note-category`

export enum CommunicationNoteCategory {
  CLINICAL = 'clinical',
  GENERAL = 'general'
}

export const COMMUNICATION_NOTE_CLINICAL_CATEGORY_CODE_SYSTEM = `${ABBYCARE_TERMINOLOGY_CODES_BASE}/communication-note-clinical-category`

export enum ClinicalNoteTypes {
  HOSPITALIZATION = 'hospitalization',
  MEDICAL_PROCEDURE = 'medical-procedure',
  ER_VISIT = 'er-visit',
  INFECTION = 'infection',
  INJURY = 'injury',
  FALL = 'fall',
  CARE_COORDINATION = 'care-coordination',
  FLU = 'flu',
  COVID = 'covid'
}

export const COMMUNICATION_NOTE_GENERAL_CATEGORY_CODE_SYSTEM = `${ABBYCARE_TERMINOLOGY_CODES_BASE}/communication-note-general-category`

export enum GeneralNoteTypes {
  GENERAL = 'general',
  GRIEVANCE_COMPLAINT = 'grievance-complaint'
}
