import { StateCreator } from 'zustand'
import { Task } from '@medplum/fhirtypes'
import { MedplumClient } from '@medplum/core'
import { TaskCode } from 'fhir/Task/constants'
import { captureException, SentrySources } from 'utils/sentry'
import { getErrorInstance } from 'utils/helpers'
import { RootSlice } from './rootType'

export interface TaskSlice {
  task: {
    list: Task[]
    isLoading: boolean
    idToTaskMapping: Record<string, Task>
    getAllTasks: (medplum: MedplumClient, count: number) => void
    getTasks: (medplum: MedplumClient, query: URLSearchParams) => Promise<Task[]>
    getCNAQATasksWithOptionalIds: (
      medplum: MedplumClient,
      { patientId, practitionerId }: { patientId?: string; practitionerId?: string }
    ) => Promise<void>
  }
}

export const createTaskSlice: StateCreator<
  RootSlice,
  [['zustand/devtools', never], ['zustand/immer', never]],
  [],
  TaskSlice
> = (set, get) => ({
  task: {
    list: [],
    isLoading: false,
    idToTaskMapping: {},
    getTasks: async (medplum, query: URLSearchParams): Promise<Task[]> => {
      return await medplum.searchResources('Task', query)
    },
    getAllTasks: async (medplum, count = 1000) => {
      set((state) => {
        state.task.isLoading = true
      })
      try {
        const searchParams = new URLSearchParams({
          _count: count.toString()
        })
        const tasks = await get().task?.getTasks(medplum, searchParams)
        set((state) => {
          if (tasks && state.task) {
            state.task.list = [...state.task.list, ...tasks]
          }
        })
      } catch (error) {
        captureException(getErrorInstance(error), {
          tags: { source: SentrySources.TASKS }
        })
      } finally {
        set((state) => {
          state.task.isLoading = false
        })
      }
    },
    getCNAQATasksWithOptionalIds: async (
      medplum: MedplumClient,
      { patientId, practitionerId }: { patientId?: string; practitionerId?: string }
    ) => {
      set((state) => {
        state.task.isLoading = true
      })
      try {
        const searchParams = new URLSearchParams({
          _sort: '-_lastUpdated',
          _count: '1000',
          code: TaskCode.CNA_VISIT_QA
        })
        practitionerId && searchParams.append('owner', `Practitioner/${practitionerId}`)
        patientId && searchParams.append('patient', `Patient/${patientId}`)

        const tasks = await medplum.searchResources('Task', searchParams)
        const mapping = {}
        tasks.forEach((task) => {
          if (task.id) {
            mapping[task.id] = task
          }
        })
        set((state) => {
          state.task.idToTaskMapping = { ...state.task.idToTaskMapping, ...mapping }
        })
      } catch (error) {
        captureException(getErrorInstance(error), {
          tags: { source: SentrySources.TASKS },
          extras: { params: { patientId, practitionerId } }
        })
      } finally {
        set((state) => {
          state.task.isLoading = false
        })
      }
    }
  }
})
