export enum QuestionnaireAnswerType {
  STRING = 'string',
  BOOLEAN = 'boolean',
  DATE = 'date',
  DATE_TIME = 'dateTime',
  TIME = 'time',
  DECIMAL = 'decimal',
  INTEGER = 'integer',
  CODING = 'coding',
  REFERENCE = 'reference',
  GROUP = 'group'
}
