import { ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE } from 'fhir/constants'

export const INSURANCE_PLAN_TYPE_URL =
  'http://hl7.org/fhir/us/davinci-pdex-plan-net/STU1.1/CodeSystem-InsuranceProductTypeCS.html'

export const MCO_PLAN_TYPE_URL =
  'http://hl7.org/fhir/us/davinci-pdex-plan-net/STU1.1/CodeSystem-OrgTypeCS.html'

export const MCO_POLICY_GROUP_NUMBER_EXTENSION_URL = `${ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE}/mco-policy-group-number`

export const MCO_CONTACT_INFORMATION_EXTENSION_URL = `${ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE}/mco-contact-information`

export enum InsurancePlanType {
  Medicaid = 'medi',
  MCO = 'mco'
}

export enum CoverageStatus {
  ACTIVE = 'active',
  CANCELLED = 'cancelled',
  DRAFT = 'draft',
  ENTERED_IN_ERROR = 'entered-in-error'
}

export type InsurancePlanCode = `${InsurancePlanType}`

export const INSURANCE_PLAN_DISPLAY_MAP: Record<string, InsurancePlanType> = {
  Medicaid: InsurancePlanType.Medicaid
}

export const INSURANCE_PLAN_OPTIONS = Object.keys(INSURANCE_PLAN_DISPLAY_MAP)
