import gql from 'gql-tag'
import { BASE_ORGANIZATION_FRAGMENT } from 'fragments/organization.query'

export const GET_ORGANIZATION_LIST = gql`
  query UseOrganizationList {
    OrganizationList(_count: 1000) {
      ...BaseOrganization
    }
  }
  ${BASE_ORGANIZATION_FRAGMENT}
`
