import { Select } from 'antd'
import React, { useCallback, useEffect } from 'react'
import { SelectProps } from 'antd'
import { sortBy, uniqBy } from 'lodash'
import useOrganizationList from 'hooks/useOrganizationList'
import { BaseOrganizationFragment } from 'generated/graphql'
import { formatPhoneNumber, formatPhoneNumberToSave } from 'utils/phoneNumbers'

type OrgListSelectProps = {
  searchContactInfo?: boolean
  contactInfoType?: 'fax' | 'email'
} & Omit<SelectProps<string>, 'options'>

const OrgListSelect = ({
  searchContactInfo = false,
  contactInfoType = 'fax',
  ...props
}: OrgListSelectProps): JSX.Element => {
  const { orgList } = useOrganizationList()
  const [orgListOptions, setOrgListOptions] = React.useState<SelectProps['options']>([])

  const generateOrgListForContactInfo = useCallback((): void => {
    // Use contact information as the unique identifier
    // If there are duplicate organizations with duplicate contact information,
    // this will cause a bug in the Select component
    const uniqueOrgList = uniqBy(orgList, (org: BaseOrganizationFragment) => {
      if (org.telecom) {
        const contactInfo = org.telecom.find((telecom) => telecom?.system === contactInfoType)
        return contactInfo?.value ?? ''
      }
    })
    const formattedOptions = uniqueOrgList
      .map((org) => {
        const contactInfo = org?.telecom?.find((telecom) => telecom?.system === contactInfoType)
        const formattedContactToDisplay =
          contactInfoType === 'fax' && contactInfo?.value
            ? formatPhoneNumber(contactInfo.value)
            : contactInfo?.value
        const formattedContactValue =
          contactInfoType === 'fax' && contactInfo?.value
            ? formatPhoneNumberToSave(contactInfo.value)
            : contactInfo?.value
        const orgNameWithContactInfo =
          org?.orgName && formattedContactToDisplay
            ? `${org?.orgName} - ${formattedContactToDisplay}`
            : ''
        return { label: orgNameWithContactInfo, value: formattedContactValue ?? '' }
      })
      .filter((org) => org.label !== '' && org.value !== '')

    const sortedFormattedOptions = sortBy(formattedOptions, 'label')

    setOrgListOptions(sortedFormattedOptions)
  }, [orgList, contactInfoType])

  const generateOrgList = useCallback((): void => {
    const formattedOptions = orgList
      ?.map((org) => {
        return {
          label: org?.orgName ?? '',
          value: `${org?.orgName}/${org?.id}` ?? ''
        }
      })
      .filter((org) => org.label !== '' && org.value !== '')
    const sortedFormattedOptions = sortBy(formattedOptions, 'label')

    setOrgListOptions(sortedFormattedOptions)
  }, [orgList])

  useEffect(() => {
    if (orgList && orgList.length > 0) {
      searchContactInfo ? generateOrgListForContactInfo() : generateOrgList()
    }
  }, [orgList, searchContactInfo, generateOrgListForContactInfo, generateOrgList])

  return (
    <Select
      options={orgListOptions}
      filterOption={(input, option: { value: string; label: string }) =>
        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      showSearch
      {...props}
    />
  )
}

export default OrgListSelect
