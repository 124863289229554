import gql from 'gql-tag'
import { ConsentTypeCode } from 'fhir/Consent/constants'
import { EpisodeOfCareTypeEnum } from 'fhir/EpisodeofCare/constants'
import { BASE_CARE_PLAN_FRAGMENT } from 'fragments/carePlan.query'
import { BASE_CARETEAM_FRAGMENT } from 'fragments/careTeam.query'
import { BASE_CARETEAM_PARTICIPANT_FRAGMENT } from 'fragments/careTeamParticipant.query'
import { BASE_CONSENT_FRAGMENT } from 'fragments/consent.query'
import { BASE_COVERAGE_FRAGMENT } from 'fragments/coverage.query'
import { BASE_EPISODE_OF_CARE_FRAGMENT } from 'fragments/episodeOfCare.query'
import { BASE_PATIENT_FRAGMENT } from 'fragments/patient.query'
import { BASE_PRACTITIONER_FRAGMENT } from 'fragments/practitioner.query'
import { BASE_ORGANIZATION_FRAGMENT } from 'fragments/organization.query'
import { BASE_CONDITION_FRAGMENT } from 'fragments/condition.query'
import { BASE_PRACTITIONER_ROLE_FRAGMENT } from 'fragments/practitionerRole.query'

const PATIENT_GENERAL_PRACTITIONER_FRAGMENT = gql`
  fragment PatientData_GeneralPractitioner on Patient {
    generalPractitioner {
      resource {
        ...BaseOrganization
        ...BasePractitioner
      }
    }
  }
`

const PATIENT_CONSENT_PERFORMER_ORGANIZATION_FRAGMENT = gql`
  fragment PatientData_ConsentWithPerformerOrganization on Consent {
    performer {
      resource {
        ...BaseOrganization
      }
    }
  }
`

const PATIENT_CONSENT_ACTOR_PROVISION_FRAGMENT = gql`
  fragment PatientData_ConsentWithActor on Consent {
    provision {
      actor {
        role {
          coding {
            system
            code
          }
        }
        reference {
          resource {
            ...BasePractitioner
            ...BaseOrganization
            ...BasePatient
          }
        }
      }
    }
  }
`

const PATIENT_CONSENT_FRAGMENT = gql`
  fragment PatientData_Consent on Consent {
    ...BaseConsent
    ...PatientData_ConsentWithPerformerOrganization
    ...PatientData_ConsentWithActor
  }
  ${BASE_CONSENT_FRAGMENT}
  ${PATIENT_CONSENT_PERFORMER_ORGANIZATION_FRAGMENT}
  ${PATIENT_CONSENT_ACTOR_PROVISION_FRAGMENT}
`
// Care Team Fragments

const CARE_TEAM_PRACTITIONER_ROLE_FRAGMENT = gql`
  fragment PatientData_PractitionerRole on PractitionerRole {
    ...BasePractitionerRole
    organization {
      resource {
        ...BaseOrganization
      }
    }
  }
`

const CARE_TEAM_PRACTITIONER_FRAGMENT = gql`
  fragment PatientData_Practitioner on Practitioner {
    ...BasePractitioner
    PractitionerRoleList(_reference: practitioner) {
      ...PatientData_PractitionerRole
    }
  }
`

const CARE_TEAM_PARTICIPANT_FRAGMENT = gql`
  fragment PatientData_CareTeamParticipant on CareTeamParticipant {
    ...BaseCareTeamParticipant
    member {
      resource {
        ...PatientData_Practitioner
      }
    }
  }
`

const CARE_TEAM_LIST_FRAGMENT = gql`
  fragment PatientData_CareTeam on CareTeam {
    ...BaseCareTeam
    managingOrganization {
      resource {
        ...BaseOrganization
      }
    }
    participant {
      ...PatientData_CareTeamParticipant
    }
  }
`

const CARETEAM_LIST_FRAGMENT = gql`
  fragment PatientData_CareTeamList on CareTeam {
    ...PatientData_CareTeam
  }
  ${CARE_TEAM_LIST_FRAGMENT}
  ${BASE_CARETEAM_FRAGMENT}
  ${CARE_TEAM_PARTICIPANT_FRAGMENT}
  ${CARE_TEAM_PRACTITIONER_FRAGMENT}
  ${CARE_TEAM_PRACTITIONER_ROLE_FRAGMENT}
  ${BASE_PRACTITIONER_ROLE_FRAGMENT}
`

const GET_PATIENT_CARE_DATA_FRAGMENT = gql`
  fragment PatientData_PatientCareData on Patient {
    ...BasePatient
    ...PatientData_GeneralPractitioner
    CareTeamList(_reference: patient) {
      ...PatientData_CareTeamList
    }
    EpisodeOfCareList(_reference: patient, type: "${EpisodeOfCareTypeEnum.CERT_PERIOD_SKILLED_CARE}", _sort: "-_lastUpdated") {
      ...BaseEpisodeOfCare
    }
    ConsentList(_reference: patient, category: "${ConsentTypeCode.CERT_PERIOD},${ConsentTypeCode.AUTHORIZATION}", _sort: "-_lastUpdated") {
      ...PatientData_Consent
    }
    CarePlanList(_reference: subject, _sort: "-_lastUpdated") {
      ...BaseCarePlan
    }
    conditions: ConditionList(_reference: patient) {
      ...BaseCondition
    }
    carePlanConsents: ConsentList(_reference: patient, category: "${ConsentTypeCode.CAREPLAN_APPROVAL}", _sort: "-_lastUpdated") {
      ...BaseConsent
    }
    CoverageList(_reference: patient) {
      ...BaseCoverage
    }
    extension {
      url
      valueReference {
        id
        type
        reference
      }
    }
  }
  ${BASE_EPISODE_OF_CARE_FRAGMENT}
  ${BASE_PRACTITIONER_FRAGMENT}
  ${BASE_COVERAGE_FRAGMENT}
  ${BASE_CARE_PLAN_FRAGMENT}
  ${PATIENT_CONSENT_FRAGMENT}
  ${BASE_ORGANIZATION_FRAGMENT}
  ${BASE_CONDITION_FRAGMENT}
  ${PATIENT_GENERAL_PRACTITIONER_FRAGMENT}
`

export const GET_PATIENT_DATA_PATIENT = gql`
  query PatientData_GetPatient($id: ID!) {
    Patient(id: $id) {
      ...PatientData_PatientCareData
    }
  }
  ${BASE_PATIENT_FRAGMENT}
  ${CARETEAM_LIST_FRAGMENT}
  ${BASE_CARETEAM_PARTICIPANT_FRAGMENT}
  ${GET_PATIENT_CARE_DATA_FRAGMENT}
`

export const GET_PATIENT_DATA_PATIENTS_LIST = gql`
  query PatientData_GetPatientsList($count: Int = 1000, $offset: Int) {
    PatientList(_count: $count, _offset: $offset) {
      ...PatientData_PatientCareData
    }
  }
  ${BASE_PATIENT_FRAGMENT}
  ${CARETEAM_LIST_FRAGMENT}
  ${BASE_CARETEAM_PARTICIPANT_FRAGMENT}
  ${GET_PATIENT_CARE_DATA_FRAGMENT}
`

export const GET_PATIENTS_BY_NAME = gql`
  query PatientData_GetPatientsByName($name: String!) {
    PatientList(_count: 1000, _filter: $name) {
      ...PatientData_PatientCareData
    }
  }
  ${BASE_PATIENT_FRAGMENT}
  ${CARETEAM_LIST_FRAGMENT}
  ${BASE_CARETEAM_PARTICIPANT_FRAGMENT}
  ${GET_PATIENT_CARE_DATA_FRAGMENT}
`

export const GET_PATIENT_DATA_CONNECTION = gql`
  query PatientData_GetPatientsConnection($count: Int, $offset: Int, $filter: String) {
    PatientConnection(_count: $count, _offset: $offset, _filter: $filter) {
      count
      edges {
        resource {
          id
          ...PatientData_PatientCareData
        }
      }
    }
  }
  ${BASE_PATIENT_FRAGMENT}
  ${CARETEAM_LIST_FRAGMENT}
  ${BASE_CARETEAM_PARTICIPANT_FRAGMENT}
  ${GET_PATIENT_CARE_DATA_FRAGMENT}
`
