import { OrganizationTypeCodes } from 'fhir/Organization/constants'
import { getValidatedFragment } from 'fhir/utils'
import {
  BaseOrganizationFragment,
  BasePractitionerFragment,
  BasePractitionerRoleFragment,
  BasePractitionerRoleWithOrganizationFragment,
  BasePractitionerWithRolesOrgFragment,
  PractitionerData_GetPractitionerQuery,
  PractitionerRole
} from 'generated/graphql'
import { findValueBySystem } from 'utils/helpers'
import { PractitionerListSchema, PractitionerWithRoleInfoSchema } from 'store/practitioner'
import { PractitionerRoleInHealthcareService } from 'fhir/PractitionerRole/constants'
import { isPractitionerFragment } from 'components/Schedule/utils/typeguards'
import { captureException, SentrySources } from 'utils/sentry'
import {
  NPPES_PROVIDER_TAXONOMY_SYSTEM,
  PRACTITIONER_TITLE_SYSTEM,
  ProviderIdentifierType
} from './constants'

export const selectPrimaryPractitionerOrg = (
  practitioner: BasePractitionerWithRolesOrgFragment
): BaseOrganizationFragment | null => {
  const roles =
    practitioner?.PractitionerRoleList?.filter(
      (role): role is BasePractitionerRoleWithOrganizationFragment => !!role
    ) ?? []

  if (roles.length === 0) {
    return null
  }

  const organizations = roles
    .map((role) => getValidatedFragment<BaseOrganizationFragment>(role.organization?.resource))
    .filter((o): o is BaseOrganizationFragment => !!o)

  const organization = organizations[0]
  if (!organization) {
    return null
  }

  return organization
}

export const getPractitionerSex = (practitionerData: BasePractitionerFragment): string | null => {
  const practitionerSexCode = practitionerData.birthSexExtension
    ?.flatMap((e) => e?.valueCodeableConcept?.coding?.map((c) => c?.code))
    ?.find((code) => !!code)

  return practitionerSexCode ?? null
}

export const getPractitionerPhone = (
  practitionerData: BasePractitionerFragment
): string | undefined => {
  const practitionerPhone = practitionerData.telecom
    ? findValueBySystem(practitionerData.telecom, 'phone')
    : undefined

  return practitionerPhone
}

export interface PractitionerWithRoleList extends BasePractitionerFragment {
  PractitionerRoleList: BasePractitionerRoleFragment[]
}

export const isAbbyCarePractitioner = (
  roles: PractitionerRole[] | Array<BasePractitionerRoleFragment | null>
): boolean => {
  return roles.some((role) =>
    role?.organization?.resource?.type?.some((type) =>
      type?.coding?.some((coding) => coding?.code === OrganizationTypeCodes.PROVIDER)
    )
  )
}

export const findAbbyCareRegisteredNurse = (
  registeredNurses: PractitionerWithRoleList[]
): PractitionerWithRoleList[] => {
  return (
    registeredNurses?.filter((nurse) => isAbbyCarePractitioner(nurse.PractitionerRoleList)) ?? []
  )
}

export const getPractitionerTimezone = (
  practitioner: PractitionerData_GetPractitionerQuery['Practitioner']
): string | undefined => {
  if (!practitioner) return undefined
  if ('timezoneExtension' in practitioner) {
    return (
      practitioner.timezoneExtension?.find((extension) => extension.valueCoding?.code)?.valueCoding
        ?.code ?? undefined
    )
  }
}

/**
 * Filters practitioners by their associated organization.
 */

const selectAbbyCarePractitioners = (
  practitioners: PractitionerListSchema
): PractitionerListSchema => {
  return practitioners?.filter((practitioner) => {
    const shouldKeep = isEmployeeOfAbbyCare(practitioner)
    return shouldKeep
  })
}

const isEmployeeOfAbbyCare = (practitioner: PractitionerWithRoleInfoSchema): boolean => {
  const practitionerBelongsToAbbyCare = isAbbyCarePractitioner(
    practitioner?.PractitionerRoleList ?? []
  )
  return Boolean(practitionerBelongsToAbbyCare)
}

export const getUniquePractitioners = (
  practitioners: PractitionerListSchema
): PractitionerListSchema => {
  const seen = new Set<string>()
  return practitioners?.filter((practitioner) => {
    if (isPractitionerFragment(practitioner) && practitioner.id) {
      const uniqueKey = practitioner.id
      if (seen.has(uniqueKey)) {
        return false
      }
      seen.add(uniqueKey)
      return true
    }
    return false
  })
}

/**
 * Filters practitioners by their role.
 */

const selectPractitionersByRole = (
  practitioners: PractitionerListSchema,
  practitionerRole: PractitionerRoleInHealthcareService
): PractitionerListSchema => {
  return (
    practitioners?.filter((practitioner) => {
      if (!practitioner?.PractitionerRoleList?.length) {
        captureException(new Error('Practitioner has no PractitionerRole'), {
          tags: { source: SentrySources.SCHEDULING },
          extras: { params: { practitionerId: practitioner?.id } }
        })
        return false
      }
      return practitioner?.PractitionerRoleList?.some((role) => {
        // TODO: consolidate this check to use id vs identifier.id once data inconsistencies for references are resolved
        const hasValidHealthcareServiceAndRole = role?.healthcareService?.some(
          (serviceRole) => serviceRole?.display === practitionerRole
        )
        if (!role?.healthcareService?.length) {
          captureException(new Error('Practitioner has corrupted healthcareService'), {
            tags: { source: SentrySources.SCHEDULING },
            extras: { params: { practitionerId: practitioner?.id, role } }
          })
        }
        return hasValidHealthcareServiceAndRole
      })
    }) || []
  )
}

export const getAbbyCarePractitioners = (
  practitioners: PractitionerListSchema
): PractitionerListSchema => {
  const abbyCarePractitioners = selectAbbyCarePractitioners(practitioners)

  const rns = selectPractitionersByRole(
    abbyCarePractitioners,
    PractitionerRoleInHealthcareService.RN
  )

  // TODO: [ENG-2158] to filter based on the service type (home-health-care) instead of the role name
  const caregivers = selectPractitionersByRole(
    abbyCarePractitioners,
    PractitionerRoleInHealthcareService.CAREGIVER
  )

  const filteredPractitioners = getUniquePractitioners([...rns, ...caregivers])

  return filteredPractitioners
}

export type PractitionerLicenseInfo = {
  licenseNumber: string | null
  licenseType: string | null
  providerTitle: { code?: string | null; display?: string | null }
}

export const getPractitionerLicenseInfo = (
  practitioner: BasePractitionerFragment | null | undefined
): PractitionerLicenseInfo => {
  const providerLicenseQualification =
    practitioner?.qualification?.find((q) =>
      q.identifier?.find((id) => id.system === ProviderIdentifierType.LICENSE)
    ) ?? null
  const providerTitleQualification =
    practitioner?.qualification?.find((q) =>
      q.code?.coding?.find((c) => c.system === PRACTITIONER_TITLE_SYSTEM)
    ) ?? null
  const licenseNumber =
    providerLicenseQualification?.identifier?.find(
      (id) => id.system === ProviderIdentifierType.LICENSE
    )?.value ?? null
  const licenseType =
    providerLicenseQualification?.code?.coding?.find(
      (c) => c.system === NPPES_PROVIDER_TAXONOMY_SYSTEM
    )?.display ?? null
  const providerTitleCode =
    providerTitleQualification?.code?.coding?.find((c) => c.system === PRACTITIONER_TITLE_SYSTEM) ??
    null
  const providerTitle = { code: providerTitleCode?.code, display: providerTitleCode?.display }
  return { licenseNumber, licenseType, providerTitle }
}
