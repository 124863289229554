import gql from 'gql-tag'
import {
  CARE_PLAN_ACTIVITY_GOAL_EXTENSION_URL,
  CARE_PLAN_ACTIVITY_INTERVENTION_EXTENSION_URL,
  CARE_PLAN_CERT_PERIOD_EXTENSION_URL
} from 'fhir/CarePlan/constants'

export const BASE_CARE_PLAN_ACTIVITY_DETAIL_FRAGMENT = gql`
  fragment BaseCarePlanActivityDetail on CarePlanActivityDetail {
    status
    code {
      coding {
        system
        code
        display
      }
    }
    description
    scheduledTiming {
      repeat {
        dayOfWeek
        when
      }
    }
    intervention: extension(
      url: "${CARE_PLAN_ACTIVITY_INTERVENTION_EXTENSION_URL}"
    ) {
      url
      valueCoding {
        display
        code
      }
    }
    goal: extension(url: "${CARE_PLAN_ACTIVITY_GOAL_EXTENSION_URL}") {
      url
      valueCoding {
        display
        code
      }
    }
  }
`

export const BASE_CARE_PLAN_ACTIVITY_FRAGMENT = gql`
  fragment BaseCarePlanActivity on CarePlanActivity {
    id
    detail {
      ...BaseCarePlanActivityDetail
    }
  }
  ${BASE_CARE_PLAN_ACTIVITY_DETAIL_FRAGMENT}
`

export const BASE_CARE_PLAN_FRAGMENT = gql`
  fragment BaseCarePlan on CarePlan {
    resourceType
    id
    status
    title
    description
    subject {
      display
      reference
    }
    activity {
      ...BaseCarePlanActivity
    }
    certPeriod: extension(
      url: "${CARE_PLAN_CERT_PERIOD_EXTENSION_URL}"
    ) {
      url
      valueReference {
        reference
      }
    }
  }
  ${BASE_CARE_PLAN_ACTIVITY_FRAGMENT}
`
