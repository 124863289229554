import gql from 'gql-tag'
import { BASE_QUESTIONNAIRE_FRAGMENT } from 'fragments/questionnaire.query'
import {
  BASE_QUESTIONNAIRE_RESPONSE_FRAGMENT,
  BASE_QUESTIONNAIRE_RESPONSE_ITEM_ANSWER_FRAGMENT,
  BASE_QUESTIONNAIRE_RESPONSE_ITEM_FRAGMENT
} from 'fragments/questionnaireResponse.query'

export const GET_LATEST_QUESTIONNAIRE_RESPONSE = gql`
  query GET_LATEST_QUESTIONNAIRE_RESPONSE(
    $identifier: String!
    $patientRef: String!
    $status: String
  ) {
    QuestionnaireList(identifier: $identifier) {
      ...BaseQuestionnaire
      QuestionnaireResponseList(
        _reference: questionnaire
        subject: $patientRef
        status: $status
        _sort: "-_lastUpdated"
      ) {
        ...BaseQuestionnaireResponse
      }
    }
  }
  ${BASE_QUESTIONNAIRE_FRAGMENT}
  ${BASE_QUESTIONNAIRE_RESPONSE_FRAGMENT}
  ${BASE_QUESTIONNAIRE_RESPONSE_ITEM_FRAGMENT}
  ${BASE_QUESTIONNAIRE_RESPONSE_ITEM_ANSWER_FRAGMENT}
`
