import { StateCreator } from 'zustand'
import { HealthcareService, Organization } from '@medplum/fhirtypes'
import { RootSlice } from './rootType'

export interface ServiceTypeSlice {
  serviceType: {
    byId: Record<string, HealthcareService>
    byState: Record<string, HealthcareService[]>
    list: HealthcareService[]
    isLoading: boolean
    error: Error | null

    setServiceTypeList: (serviceTypes: HealthcareService[], organizations: Organization[]) => void
  }
}

const defaultState: Pick<
  ServiceTypeSlice['serviceType'],
  'list' | 'byId' | 'byState' | 'isLoading' | 'error'
> = {
  list: [],
  byId: {},
  byState: {},
  isLoading: false,
  error: null
}

export const createServiceTypeSlice: StateCreator<
  RootSlice,
  // Type `never` is used here to follow the instructions on Zustand docs
  // reference: https://github.com/pmndrs/zustand/blob/main/docs/guides/typescript.md#middlewares-and-their-mutators-reference
  // see index.ts for middlewares being used
  [['zustand/devtools', never], ['zustand/immer', never]],
  [],
  ServiceTypeSlice
> = (set) => ({
  serviceType: {
    ...defaultState,

    setServiceTypeList: (serviceTypes, organizations) => {
      const serviceTypesByState = serviceTypes.reduce((acc, serviceType) => {
        const organization = organizations.find(
          (org) => `Organization/${org.id}` === serviceType.providedBy?.reference
        )
        const state = organization?.address?.find((address) => address?.state)?.state
        if (!state) return acc

        if (!acc[state]) {
          acc[state] = []
        }

        acc[state].push(serviceType)
        return acc
      }, {} as Record<string, HealthcareService[]>)

      const serviceTypesById = serviceTypes.reduce((acc, serviceType) => {
        acc[serviceType.id as string] = serviceType
        return acc
      }, {} as Record<string, HealthcareService>)

      set((state) => {
        state.serviceType.list = serviceTypes
        state.serviceType.byState = serviceTypesByState
        state.serviceType.byId = serviceTypesById
      })
    }
  }
})
