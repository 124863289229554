import { Tooltip } from 'antd'
import { RenderFunction, TooltipPlacement, type TooltipProps } from 'antd/es/tooltip'
import React, { FC } from 'react'
import styled from 'styled-components'

interface InputLabelProps {
  children: React.ReactNode
  label: string
  optional?: boolean
  styles?: Record<string, string>
  icon?: JSX.Element
  iconTooltipText?: TooltipProps['title']
  iconTooltipPlacement?: TooltipProps['placement']
  labelLeftAlign?: boolean
}

const OptionalLabel = styled.span`
  color: #98a2b3;
`

const LabelOptional = (): JSX.Element => <OptionalLabel>(optional)</OptionalLabel>

const LabelIcon: FC<{
  iconTooltipPlacement?: TooltipPlacement
  iconTooltipText: React.ReactNode | RenderFunction
  children: JSX.Element
}> = ({ iconTooltipPlacement, iconTooltipText, children }) => {
  return children && iconTooltipText ? (
    <Tooltip
      title={iconTooltipText}
      placement={iconTooltipPlacement ?? 'top'}
      style={{ cursor: 'pointer' }}
    >
      {children}
    </Tooltip>
  ) : (
    children
  )
}

export const InputLabel: FC<InputLabelProps> = ({
  children,
  label,
  icon,
  optional = false,
  styles = { margin: '0 0 5px 0' },
  iconTooltipText,
  iconTooltipPlacement,
  labelLeftAlign
}) => {
  return (
    <div style={styles}>
      <Label labelLeftAlign={labelLeftAlign}>
        <LabelText labelLeftAlign={labelLeftAlign}>
          {label}

          {icon && (
            <LabelIcon
              iconTooltipPlacement={iconTooltipPlacement}
              iconTooltipText={iconTooltipText}
            >
              {icon}
            </LabelIcon>
          )}

          {optional && <LabelOptional />}
        </LabelText>

        {labelLeftAlign && children}
      </Label>

      {!labelLeftAlign && children}
    </div>
  )
}

const LabelText = styled.div<{ labelLeftAlign?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 4px;

  ${(p) => p.labelLeftAlign && 'width: 50%;'}

  // Used to offset the top of the child inputs
  ${(p) => p.labelLeftAlign && 'margin-top: 5px;'}
`

const Label = styled.label<{ labelLeftAlign?: boolean }>`
  display: flex;
  flex-direction: row;

  gap: 16px;

  ${(p) => (p.labelLeftAlign ? 'align-items: flex-start;' : 'align-items: center;')}
  ${(p) => p.labelLeftAlign && 'justify-content: space-between;'}
  ${(p) => p.labelLeftAlign && 'width: 100%;'}
`
