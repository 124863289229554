import dayjs, { Dayjs } from 'dayjs'

export const formatDate = (date: string | null | undefined): string | null => {
  return date ? dayjs(date).format('L') : null
}

export const formatUTCDate = (
  date: string | null | undefined,
  timezone: string,
  format?: string
): string | null => {
  return date
    ? dayjs
        .utc(date)
        .tz(timezone)
        .format(format ?? 'L')
    : null
}

export const calculateAgeInYears = (birthdate: Dayjs): number => {
  const today = dayjs()
  const birthDate = dayjs(birthdate)

  const age = today.diff(birthDate, 'year')
  const nextBirthday = birthDate.add(age, 'year')

  if (today.isBefore(nextBirthday, 'day')) {
    return age - 1
  }

  return age
}

export const calculateAgeInMonths = (birthdate: Dayjs): number => {
  const today = dayjs()
  const birthDate = dayjs(birthdate)

  const ageInMonths = today.diff(birthDate, 'month')
  const nextBirthday = birthDate.add(ageInMonths, 'month')

  if (today.isBefore(nextBirthday, 'day')) {
    return ageInMonths - 1
  }

  return ageInMonths
}

export const getAgeString = (birthdate: Dayjs): string => {
  const age = calculateAgeInYears(birthdate)

  if (age > 0) {
    return `${age} year old`
  } else {
    const ageInMonths = calculateAgeInMonths(birthdate)
    return `${ageInMonths} month old`
  }
}

export const getLastPeriod = (periods: [Dayjs, Dayjs][]): [Dayjs, Dayjs] | undefined => {
  const latestPeriod = [...periods].sort((a, b) => a[1].unix() - b[1].unix()).pop()
  return latestPeriod
}

export const periodIsContained = (period: [Dayjs, Dayjs], otherPeriod: [Dayjs, Dayjs]): boolean => {
  const [periodStart, periodEnd] = period
  const [otherPeriodStart, otherPeriodEnd] = otherPeriod
  // Check intersection of the dates
  const hasIntersections =
    dayjs(periodStart).isBetween(otherPeriodStart, otherPeriodEnd) &&
    dayjs(periodEnd).isBetween(otherPeriodStart, otherPeriodEnd)

  return hasIntersections
}

export const periodIsIntersecting = (
  period: [Dayjs, Dayjs],
  otherPeriod: [Dayjs, Dayjs],
  includeEndpoints = true
): boolean => {
  const [periodStart, periodEnd] = period
  const [otherPeriodStart, otherPeriodEnd] = otherPeriod

  const endpointsMatch = includeEndpoints
    ? dayjs(periodStart).isSame(otherPeriodStart) ||
      dayjs(periodStart).isSame(otherPeriodEnd) ||
      dayjs(periodEnd).isSame(otherPeriodStart) ||
      dayjs(periodEnd).isSame(otherPeriodEnd)
    : false

  // Check intersection of the dates
  const hasIntersections =
    // If they're intersecting (between the two intervals)...
    dayjs(periodStart).isBetween(otherPeriodStart, otherPeriodEnd) ||
    dayjs(periodEnd).isBetween(otherPeriodStart, otherPeriodEnd) ||
    dayjs(otherPeriodStart).isBetween(periodStart, periodEnd) ||
    dayjs(otherPeriodEnd).isBetween(periodStart, periodEnd) ||
    // Or they're the endpoints of the interval
    endpointsMatch

  return hasIntersections
}

export const zuluToZeroOffset = (zuluDateString: string): string => {
  return zuluDateString.replace(/\.\d+Z$/, '+00:00')
}

export const TIME_FORMAT = 'h:mm A'

export const formatTime = (date: string | null | Date | undefined | Dayjs): string | null => {
  if (!date) return null

  return dayjs(date).format(TIME_FORMAT)
}

export const transformToTimezoneOrUtc = (
  date: string | Dayjs,
  timezone?: string,
  startOfDay = true
): Dayjs => {
  const isUtc = dayjs(date).isUTC()
  if (timezone) {
    return startOfDay ? dayjs(date).tz(timezone).startOf('day') : dayjs(date).tz(timezone)
  } else if (isUtc) {
    return startOfDay ? dayjs(date).startOf('day') : dayjs(date)
  } else {
    return startOfDay ? dayjs(date).utc().startOf('day') : dayjs(date).utc()
  }
}

export const isTimePeriodInEffect = (
  timezone?: string,
  start?: string | null,
  end?: string | null
): boolean => {
  const today = transformToTimezoneOrUtc(dayjs().format(), timezone)
  const startDate = start ? transformToTimezoneOrUtc(start, timezone) : null
  const endDate = end ? transformToTimezoneOrUtc(end, timezone) : null
  let isCurrentlyActive = false
  if (startDate && endDate) {
    isCurrentlyActive = today.isBetween(startDate, endDate, 'day', '[]')
  } else if (startDate) {
    isCurrentlyActive = today.isSame(startDate, 'day') || today.isAfter(startDate, 'day')
  }
  return isCurrentlyActive
}
