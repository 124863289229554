export enum RecertAssessmentQuestionnaireItem {
  // Assessment overview
  ASSESSMENT_OVERVIEW_APPOINTMENT_TIME = 'assessment-overview/appointment-time',
  ASSESSMENT_OVERVIEW_APPOINTMENT_TIME_START = 'assessment-overview/appointment-time/start',
  ASSESSMENT_OVERVIEW_APPOINTMENT_TIME_END = 'assessment-overview/appointment-time/end',

  // Certification period recap
  CERT_PERIOD_RECAP = 'cert-period-recap',
  CERT_PERIOD_RECAP_CONSENT = 'cert-period-recap/consent',
  CERT_PERIOD_RECAP_CARE_STATUS = 'cert-period-recap/care-status',
  CERT_PERIOD_RECAP_CARE_STATUS_60_DAY_ER = 'cert-period-recap/care_status/60-day-er',
  CERT_PERIOD_RECAP_CARE_STATUS_60_DAY_ER_NOTES = 'cert-period-recap/care_status/60-day-er-notes',
  CERT_PERIOD_RECAP_CARE_STATUS_60_DAY_HOSPITAL = 'cert-period-recap/care_status/60-day-hospital',
  CERT_PERIOD_RECAP_CARE_STATUS_60_DAY_HOSPITAL_NOTES = 'cert-period-recap/care_status/60-day-hospital-notes',
  CERT_PERIOD_RECAP_CARE_STATUS_60_DAY_INJURIES = 'cert-period-recap/care_status/60-day-injuries',
  CERT_PERIOD_RECAP_CARE_STATUS_60_DAY_INJURIES_NOTES = 'cert-period-recap/care_status/60-day-injuries-notes',
  CERT_PERIOD_RECAP_CARE_STATUS_60_DAY_FALLS = 'cert-period-recap/care_status/60-day-falls',
  CERT_PERIOD_RECAP_CARE_STATUS_60_DAY_FALLS_NOTES = 'cert-period-recap/care_status/60-day-falls-notes',
  CERT_PERIOD_RECAP_CARE_STATUS_60_DAY_INFECTIONS = 'cert-period-recap/care_status/60-day-infections',
  CERT_PERIOD_RECAP_CARE_STATUS_60_DAY_INFECTIONS_NOTES = 'cert-period-recap/care_status/60-day-infections-notes',
  CERT_PERIOD_RECAP_CARE_STATUS_60_DAY_MD = 'cert-period-recap/care_status/60-day-md',
  CERT_PERIOD_RECAP_CARE_STATUS_60_DAY_MD_NOTES = 'cert-period-recap/care_status/60-day-md-notes',
  CERT_PERIOD_RECAP_NOTES = 'cert-period-recap/notes',
  CERT_PERIOD_RECAP_NOTES_NOTE = 'cert-period-recap/notes/note',
  CERT_PERIOD_RECAP_GOALS = 'cert-period-recap/goals',
  CERT_PERIOD_RECAP_GOALS_GOAL_ID = 'cert-period-recap/goals/goal-id',
  CERT_PERIOD_RECAP_GOALS_GOAL_STATUS = 'cert-period-recap/goals/goal-status',
  CERT_PERIOD_RECAP_INITIALS_AUTHORIZATION = 'cert-period-recap/initials-authorization',

  // Skilled needs
  SKILLED_NEEDS = 'skilled-needs',
  SKILLED_NEEDS_NEED = 'skilled-needs/need',
  SKILLED_NEEDS_NEED_NAME = 'skilled-needs/need/name',
  SKILLED_NEEDS_NEED_SCORE = 'skilled-needs/need/score',
  SKILLED_NEEDS_NEED_PREVIOUS_NOTE = 'skilled-needs/need/previous-note',
  SKILLED_NEEDS_NEED_NOTE = 'skilled-needs/need/note',
  SKILLED_NEED_INITIALS_AUTHORIZATION = 'skilled-needs/initials-authorization',

  // Discharge planning
  DISCHARGE_PLANNING = 'discharge-planning',
  DISCHARGE_PLANNING_DISCHARGE_PLANS = 'discharge-planning/discharge-plans',
  DISCHARGE_PLANNING_REHABILITATION_POTENTIAL = 'discharge-planning/rehabilitation-potential',
  DISCHARGE_PLANNING_NOTES = 'discharge-planning/notes',

  // Authorizations
  AUTHORIZATION = 'authorization',
  AUTHORIZATION_AUTHORIZATION = 'authorization/authorization',
  AUTHORIZATION_AUTHORIZATION_AUTHORIZATION_SOURCE = 'authorization/authorization/authorization-source',
  AUTHORIZATION_AUTHORIZATION_CURRENT_HOURS = 'authorization/authorization/current-hours',
  AUTHORIZATION_AUTHORIZATION_IS_REVISING_CARE_HOURS = 'authorization/authorization/is-revising-care-hours',
  AUTHORIZATION_AUTHORIZATION_REVISED_CARE_HOURS = 'authorization/authorization/revised-care-hours',
  AUTHORIZATION_AUTHORIZATION_REASON_FOR_REVISION = 'authorization/authorization/reason-for-revision',
  AUTHORIZATION_AUTHORIZATION_ADDITIONAL_DETAILS = 'authorization/authorization/additional-details',
  AUTHORIZATION_AUTHORIZATION_ORDERING_PHYSICIAN = 'authorization/authorization/ordering-physician',
  AUTHORIZATION_AUTHORIZATION_PCP = 'authorization/authorization/pcp',

  // Plan of care
  PLAN_OF_CARE = 'plan-of-care',
  PLAN_OF_CARE_GOALS = 'plan-of-care/goals',
  PLAN_OF_CARE_GOALS_GOAL = 'plan-of-care/goals/goal',
  PLAN_OF_CARE_PATIENT_INTERVENTIONS = 'plan-of-care/patient-interventions',
  PLAN_OF_CARE_PATIENT_INTERVENTIONS_INTERVENTION_INFO = 'plan-of-care/patient-interventions/intervention-info',
  PLAN_OF_CARE_PATIENT_INTERVENTIONS_INTERVENTION_INFO_INTERVENTION = 'plan-of-care/patient-interventions/intervention-info/intervention',
  PLAN_OF_CARE_PATIENT_INTERVENTIONS_INTERVENTION_INFO_CATEGORY = 'plan-of-care/patient-interventions/intervention-info/category',
  PLAN_OF_CARE_PATIENT_INTERVENTIONS_INTERVENTION_INFO_GOAL = 'plan-of-care/patient-interventions/intervention-info/goal',
  PLAN_OF_CARE_PATIENT_INTERVENTIONS_INTERVENTION_INFO_TASK = 'plan-of-care/patient-interventions/intervention-info/task',
  PLAN_OF_CARE_PATIENT_INTERVENTIONS_INTERVENTION_INFO_INDICATION = 'plan-of-care/patient-interventions/intervention-info/indication',
  PLAN_OF_CARE_PATIENT_INTERVENTIONS_INTERVENTION_INFO_TYPE = 'plan-of-care/patient-interventions/intervention-info/type',
  PLAN_OF_CARE_PATIENT_INTERVENTIONS_INTERVENTION_INFO_INSTRUCTION = 'plan-of-care/patient-interventions/intervention-info/instruction',
  PLAN_OF_CARE_PATIENT_INTERVENTIONS_INTERVENTION_INFO_PATIENT_CENTERED_GOAL = 'plan-of-care/patient-interventions/intervention-info/patient-centered-goal',
  PLAN_OF_CARE_PATIENT_INTERVENTIONS_INTERVENTION_INFO_ADDITIONAL_DETAILS = 'plan-of-care/patient-interventions/intervention-info/additional-details',
  PLAN_OF_CARE_PATIENT_INTERVENTIONS_INTERVENTION_INFO_VISITS = 'plan-of-care/patient-interventions/intervention-info/visits',
  PLAN_OF_CARE_PATIENT_INTERVENTIONS_INTERVENTION_INFO_FREQUENCY = 'plan-of-care/patient-interventions/intervention-info/frequency',
  PLAN_OF_CARE_INITIALS_AUTHORIZATION = 'plan-of-care/initials-authorization',

  // Visit scheduling
  VISIT_SCHEDULING = 'visit-scheduling',
  VISIT_SCHEDULING_PATIENT_SCHEDULE = 'visit-scheduling/patient-schedule',
  VISIT_SCHEDULING_PATIENT_SCHEDULE_WAKEUP_TIME = 'visit-scheduling/patient-schedule/wakeup-time',
  VISIT_SCHEDULING_PATIENT_SCHEDULE_BEDTIME = 'visit-scheduling/patient-schedule/bedtime',
  VISIT_SCHEDULING_PATIENT_SCHEDULE_SCHOOL_TIMES = 'visit-scheduling/patient-schedule/school-times',
  VISIT_SCHEDULING_PATIENT_SCHEDULE_SCHOOL_TIMES_DAY = 'visit-scheduling/patient-schedule/school-times/day',
  VISIT_SCHEDULING_PATIENT_SCHEDULE_SCHOOL_TIMES_PERIOD = 'visit-scheduling/patient-schedule/school-times/period',
  VISIT_SCHEDULING_PATIENT_SCHEDULE_SCHOOL_TIMES_PERIOD_START = 'visit-scheduling/patient-schedule/school-times/period/start',
  VISIT_SCHEDULING_PATIENT_SCHEDULE_SCHOOL_TIMES_PERIOD_END = 'visit-scheduling/patient-schedule/school-times/period/end',
  VISIT_SCHEDULING_PATIENT_SCHEDULE_OTHER_APPOINTMENTS = 'visit-scheduling/patient-schedule/other-appointments',
  VISIT_SCHEDULING_PATIENT_SCHEDULE_OTHER_APPOINTMENTS_NAME = 'visit-scheduling/patient-schedule/other-appointments/name',
  VISIT_SCHEDULING_PATIENT_SCHEDULE_OTHER_APPOINTMENTS_PERIOD = 'visit-scheduling/patient-schedule/other-appointments/period',
  VISIT_SCHEDULING_PATIENT_SCHEDULE_OTHER_APPOINTMENTS_PERIOD_START = 'visit-scheduling/patient-schedule/other-appointments/period/start',
  VISIT_SCHEDULING_PATIENT_SCHEDULE_OTHER_APPOINTMENTS_PERIOD_END = 'visit-scheduling/patient-schedule/other-appointments/period/end',
  VISIT_SCHEDULING_PATIENT_SCHEDULE_OTHER_APPOINTMENTS_REPEATS_ON_DAY = 'visit-scheduling/patient-schedule/other-appointments/repeats-on-day',
  VISIT_SCHEDULING_CUSTOM_SCHEDULE_VALID = 'visit-scheduling/custom-schedule/valid',
  VISIT_SCHEDULING_CUSTOM_SCHEDULE_VISITS = 'visit-scheduling/custom-schedule/visits',
  VISIT_SCHEDULING_CUSTOM_SCHEDULE_VISITS_PERIOD = 'visit-scheduling/custom-schedule/visits/period',
  VISIT_SCHEDULING_CUSTOM_SCHEDULE_VISITS_PERIOD_START = 'visit-scheduling/custom-schedule/visits/period/start',
  VISIT_SCHEDULING_CUSTOM_SCHEDULE_VISITS_PERIOD_END = 'visit-scheduling/custom-schedule/visits/period/end',
  VISIT_SCHEDULING_CUSTOM_SCHEDULE_VISITS_PERIOD_CAREGIVER = 'visit-scheduling/custom-schedule/visits/period/caregiver',
  VISIT_SCHEDULING_CUSTOM_SCHEDULE_CHANGE_REASON = 'visit-scheduling/custom-schedule/change-reason',
  VISIT_SCHEDULING_CUSTOM_SCHEDULE_ADDITIONAL_NOTES = 'visit-scheduling/custom-schedule/additional-notes',
  VISIT_SCHEDULING_INITIALS_AUTHORIZATION = 'visit-scheduling/initials-authorization',

  // Medications
  MEDICATIONS = 'medications',
  MEDICATIONS_MEDICATION_LIST = 'medications/medication-list',
  MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO = 'medications/medication-list/medication-info',
  MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_DRUGBANK_PCID = 'medications/medication-list/medication-info/drugbank-product-concept-id',
  MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_NAME = 'medications/medication-list/medication-info/name',
  MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_ROUTE = 'medications/medication-list/medication-info/route',
  MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_STRENGTH_NUMBER = 'medications/medication-list/medication-info/strength-number',
  MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_STRENGTH_UNIT = 'medications/medication-list/medication-info/strength-unit',
  MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_INDICATIONS = 'medications/medication-list/medication-info/indications',
  MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_FREQUENCY = 'medications/medication-list/medication-info/frequency',
  MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_DOSAGE_NUMBER = 'medications/medication-list/medication-info/dosage-number',
  MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_DOSAGE_UNIT = 'medications/medication-list/medication-info/dosage-unit',
  MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_INSTRUCTIONS = 'medications/medication-list/medication-info/instructions',
  MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_USAGE_STATUS = 'medications/medication-list/medication-info/usage-status',
  MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_START_DATE = 'medications/medication-list/medication-info/start-date',
  MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_DISCONTINUE_DATE = 'medications/medication-list/medication-info/discontinue-date',
  MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_CONTROLLED = 'medications/medication-list/medication-info/controlled',
  MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_LIQUID = 'medications/medication-list/medication-info/liquid',
  MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_INFECTION = 'medications/medication-list/medication-info/infection',
  MEDICATIONS_MEDICATION_SUMMARY = 'medications/medication-summary',
  MEDICATIONS_INITIALS_AUTHORIZATION = 'medications/initials-authorization',

  // Vitals
  VITALS = 'vitals',
  VITALS_TEMPERATURE = 'vitals/temperature',
  VITALS_PULSE = 'vitals/pulse',
  VITALS_RESPIRATIONS = 'vitals/respirations',
  VITALS_O2_SATURATION = 'vitals/o2-saturation',
  VITALS_BLOOD_PRESSURE = 'vitals/blood-pressure',
  VITALS_HEIGHT = 'vitals/height',
  VITALS_BLOOD_SUGAR = 'vitals/blood-sugar',
  VITALS_WEIGHT = 'vitals/weight',
  VITALS_INITIALS_AUTHORIZATION = 'vitals/initials-authorization',

  // Medical background
  MEDICAL_BACKGROUND = 'medical-background',
  MEDICAL_BACKGROUND_BACKGROUND = 'medical-background/background',
  MEDICAL_BACKGROUND_BACKGROUND_PRIMARY_DIAGNOSIS = 'medical-background/background/primary-diagnosis',
  MEDICAL_BACKGROUND_BACKGROUND_OTHER_DIAGNOSIS = 'medical-background/background/other-diagnosis',
  MEDICAL_BACKGROUND_BACKGROUND_EQUIPMENT = 'medical-background/background/equipment',
  MEDICAL_BACKGROUND_BACKGROUND_DME_PROVIDER = 'medical-background/background/dme-provider',
  MEDICAL_BACKGROUND_BACKGROUND_ALLERGIES = 'medical-background/background/allergies',
  MEDICAL_BACKGROUND_BACKGROUND_ALLERGIES_NOTES = 'medical-background/background/allergies-notes',
  MEDICAL_BACKGROUND_BACKGROUND_WAIVERS = 'medical-background/background/waivers',
  MEDICAL_BACKGROUND_BACKGROUND_ADDITIONAL_SERVICES = 'medical-background/background/additional-services',
  MEDICAL_BACKGROUND_BACKGROUND_PROGNOSIS = 'medical-background/background/prognosis',
  MEDICAL_BACKGROUND_BACKGROUND_DNR = 'medical-background/background/dnr',
  MEDICAL_BACKGROUND_BACKGROUND_ADVANCE_DIRECTIVES = 'medical-background/background/advance-directives',
  MEDICAL_BACKGROUND_BACKGROUND_ADVANCE_DIRECTIVES_NOTES = 'medical-background/background/advance-directives-notes',
  MEDICAL_BACKGROUND_HISTORY_FUNCTIONAL_LIMITATIONS = 'medical-background/history/functional-limitations',
  MEDICAL_BACKGROUND_HISTORY_ACTIVITIES_PERMITTED = 'medical-background/history/activities-permitted',
  MEDICAL_BACKGROUND_HISTORY_MEDICAL_HISTORY = 'medical-background/history/medical-history',
  MEDICAL_BACKGROUND_HISTORY_SURGICAL_HISTORY = 'medical-background/history/surgical-history',
  MEDICAL_BACKGROUND_IMMUNIZATIONS_HEP_B = 'medical-background/immunizations/hep-b',
  MEDICAL_BACKGROUND_IMMUNIZATIONS_HEP_B_HEP_B = 'medical-background/immunizations/hep-b/hep-b',
  MEDICAL_BACKGROUND_IMMUNIZATIONS_HEP_B_DATE = 'medical-background/immunizations/hep-b/date',
  MEDICAL_BACKGROUND_IMMUNIZATIONS_MENINGOCOCCAL = 'medical-background/immunizations/meningococcal',
  MEDICAL_BACKGROUND_IMMUNIZATIONS_MENINGOCOCCAL_MENINGOCOCCAL = 'medical-background/immunizations/meningococcal/meningococcal',
  MEDICAL_BACKGROUND_IMMUNIZATIONS_MENINGOCOCCAL_DATE = 'medical-background/immunizations/meningococcal/date',
  MEDICAL_BACKGROUND_IMMUNIZATIONS_COVID_19 = 'medical-background/immunizations/covid-19',
  MEDICAL_BACKGROUND_IMMUNIZATIONS_COVID_19_COVID_19 = 'medical-background/immunizations/covid-19/covid-19',
  MEDICAL_BACKGROUND_IMMUNIZATIONS_COVID_19_DATE = 'medical-background/immunizations/covid-19/date',
  MEDICAL_BACKGROUND_IMMUNIZATIONS_DTAP = 'medical-background/immunizations/dtap',
  MEDICAL_BACKGROUND_IMMUNIZATIONS_DTAP_DTAP = 'medical-background/immunizations/dtap/dtap',
  MEDICAL_BACKGROUND_IMMUNIZATIONS_DTAP_DATE = 'medical-background/immunizations/dtap/date',
  MEDICAL_BACKGROUND_IMMUNIZATIONS_RSV = 'medical-background/immunizations/rsv',
  MEDICAL_BACKGROUND_IMMUNIZATIONS_RSV_RSV = 'medical-background/immunizations/rsv/rsv',
  MEDICAL_BACKGROUND_IMMUNIZATIONS_RSV_DATE = 'medical-background/immunizations/rsv/date',
  MEDICAL_BACKGROUND_IMMUNIZATIONS_HPV = 'medical-background/immunizations/hpv',
  MEDICAL_BACKGROUND_IMMUNIZATIONS_HPV_HPV = 'medical-background/immunizations/hpv/hpv',
  MEDICAL_BACKGROUND_IMMUNIZATIONS_HPV_DATE = 'medical-background/immunizations/hpv/date',
  MEDICAL_BACKGROUND_IMMUNIZATIONS_NOTES = 'medical-background/immunizations/notes',
  MEDICAL_BACKGROUND_RISK_ASSESSMENT_HOSPITALIZATION_RISK = 'medical-background/risk-assessment/hospitalization-risk',
  MEDICAL_BACKGROUND_RISK_ASSESSMENT_HOSPITALIZATION_RISK_FACTORS = 'medical-background/risk-assessment/hospitalization-risk-factors',
  MEDICAL_BACKGROUND_RISK_ASSESSMENT_TRIAGE_CODE = 'medical-background/risk-assessment/triage-code',
  MEDICAL_BACKGROUND_RISK_ASSESSMENT_EMERGENCY_PLAN = 'medical-background/risk-assessment/emergency-plan',
  MEDICAL_BACKGROUND_RISK_ASSESSMENT_SAFE_ROOM = 'medical-background/risk-assessment/safe-room',
  MEDICAL_BACKGROUND_RISK_ASSESSMENT_FIRE_ASSESSMENT_OXYGEN = 'medical-background/risk-assessment/fire-assessment-oxygen',
  MEDICAL_BACKGROUND_RISK_ASSESSMENT_FIRE_SAFETY_PRECAUTIONS = 'medical-background/risk-assessment/fire-safety-precautions',
  MEDICAL_BACKGROUND_RISK_ASSESSMENT_HOMEBOUND_STATUS = 'medical-background/risk-assessment/homebound-status',
  MEDICAL_BACKGROUND_RISK_ASSESSMENT_INFECTION_RISK = 'medical-background/risk-assessment/infection-risk',
  MEDICAL_BACKGROUND_RISK_ASSESSMENT_INFECTION_RISK_FACTORS = 'medical-background/risk-assessment/infection-risk-factors',
  MEDICAL_BACKGROUND_RISK_ASSESSMENT_FALL_RISK = 'medical-background/risk-assessment/fall-risk',
  MEDICAL_BACKGROUND_RISK_ASSESSMENT_FALL_RISK_FACTORS = 'medical-background/risk-assessment/fall-risk-factors',
  MEDICAL_BACKGROUND_RISK_ASSESSMENT_FALL_RISK_ASSESSMENT = 'medical-background/risk-assessment/fall-risk-assessment',
  MEDICAL_BACKGROUND_RISK_ASSESSMENT_FINANCE_RISK = 'medical-background/risk-assessment/finance-risk',
  MEDICAL_BACKGROUND_RISK_ASSESSMENT_ENVIRONMENT_SAFETY = 'medical-background/risk-assessment/environment-safety',
  MEDICAL_BACKGROUND_RISK_ASSESSMENT_SAFETY_MEASURES = 'medical-background/risk-assessment/safety-measures',
  MEDICAL_BACKGROUND_RISK_ASSESSMENT_NOTES = 'medical-background/risk-assessment/notes',
  MEDICAL_BACKGROUND_NEURO_HEADACHES = 'medical-background/neuro/headaches',
  MEDICAL_BACKGROUND_NEURO_HEADACHE_TYPE = 'medical-background/neuro/headache-type',
  MEDICAL_BACKGROUND_NEURO_SEIZURES = 'medical-background/neuro/seizures',
  MEDICAL_BACKGROUND_NEURO_SEIZURE_DATE = 'medical-background/neuro/seizure-date',
  MEDICAL_BACKGROUND_NEURO_SEIZURE_TYPE = 'medical-background/neuro/seizure-type',
  MEDICAL_BACKGROUND_NEURO_SEIZURE_PRECAUTIONS = 'medical-background/neuro/seizure-precautions',
  MEDICAL_BACKGROUND_NEURO_NEURO_STATUS = 'medical-background/neuro/neuro-status',
  MEDICAL_BACKGROUND_NEURO_ALTERED_AFFECT = 'medical-background/neuro/altered-affect',
  MEDICAL_BACKGROUND_NEURO_ALTERED_AFFECT_FACTORS = 'medical-background/neuro/altered-affect-factors',
  MEDICAL_BACKGROUND_NEURO_PHQ2_SCREENING = 'medical-background/neuro/phq2-screening',
  MEDICAL_BACKGROUND_NEURO_PHQ2_NOTES = 'medical-background/neuro/phq2-notes',
  MEDICAL_BACKGROUND_NEURO_INTEREST = 'medical-background/neuro/interest',
  MEDICAL_BACKGROUND_NEURO_DEPRESSED = 'medical-background/neuro/depressed',
  MEDICAL_BACKGROUND_NEURO_CAREGIVER_DIFFICULTY = 'medical-background/neuro/caregiver-difficulty',
  MEDICAL_BACKGROUND_NEURO_SYSTEM_SUMMARY = 'medical-background/neuro/system-summary',
  MEDICAL_BACKGROUND_COGNITIVE_PSYCHOSOCIAL_DEV_COMMUNICATION_ASSESSMENT = 'medical-background/cognitive-psychosocial-dev/communication-assessment',
  MEDICAL_BACKGROUND_COGNITIVE_PSYCHOSOCIAL_DEV_COGNITIVE_ASSESSMENT = 'medical-background/cognitive-psychosocial-dev/cognitive-assessment',
  MEDICAL_BACKGROUND_COGNITIVE_PSYCHOSOCIAL_DEV_HISTORY_SUBSTANCE_ABUSE = 'medical-background/cognitive-psychosocial-dev/history-substance-abuse',
  MEDICAL_BACKGROUND_COGNITIVE_PSYCHOSOCIAL_DEV_SUBSTANCE_ABUSE_NOTES = 'medical-background/cognitive-psychosocial-dev/substance-abuse-notes',
  MEDICAL_BACKGROUND_COGNITIVE_PSYCHOSOCIAL_DEV_SYSTEM_SUMMARY = 'medical-background/cognitive-psychosocial-dev/system-summary',
  MEDICAL_BACKGROUND_MUSCULOSKELETAL_MUSCULOSKELETAL_ASSESSMENT = 'medical-background/musculoskeletal/musculoskeletal-assessment',
  MEDICAL_BACKGROUND_MUSCULOSKELETAL_SYSTEM_SUMMARY = 'medical-background/musculoskeletal/system-summary',
  MEDICAL_BACKGROUND_LINES_DRAINS_SYSTEM_SUMMARY = 'medical-background/lines-drains/system-summary',
  MEDICAL_BACKGROUND_THERAPIES_THERAPIES = 'medical-background/therapies/therapies',
  MEDICAL_BACKGROUND_THERAPIES_THERAPY_PROVIDER = 'medical-background/therapies/therapy-provider',
  MEDICAL_BACKGROUND_THERAPIES_HOME_EXERCISE_PLAN = 'medical-background/therapies/home-exercise-plan',
  MEDICAL_BACKGROUND_THERAPIES_HOME_EXERCISE_DURATION = 'medical-background/therapies/home-exercise-duration',
  MEDICAL_BACKGROUND_THERAPIES_HOME_EXERCISE_FREQUENCY = 'medical-background/therapies/home-exercise-frequency',
  MEDICAL_BACKGROUND_SENSORY_SENSORY_STATUS_EYES = 'medical-background/sensory/sensory-status-eyes',
  MEDICAL_BACKGROUND_SENSORY_SENSORY_STATUS_EARS = 'medical-background/sensory/sensory-status-ears',
  MEDICAL_BACKGROUND_SENSORY_SENSORY_STATUS_NOSE = 'medical-background/sensory/sensory-status-nose',
  MEDICAL_BACKGROUND_SENSORY_SENSORY_STATUS_TOUCH = 'medical-background/sensory/sensory-status-touch',
  MEDICAL_BACKGROUND_SENSORY_SENSORY_STATUS_TASTE = 'medical-background/sensory/sensory-status-taste',
  MEDICAL_BACKGROUND_SENSORY_SYSTEM_SUMMARY = 'medical-background/sensory/system-summary',
  MEDICAL_BACKGROUND_PAIN_STATUS = 'medical-background/pain/status',
  MEDICAL_BACKGROUND_PAIN_GOAL = 'medical-background/pain/goal',
  MEDICAL_BACKGROUND_PAIN_FLACC_SCALE_FACE = 'medical-background/pain/flacc-scale-face',
  MEDICAL_BACKGROUND_PAIN_FLACC_SCALE_LEGS = 'medical-background/pain/flacc-scale-legs',
  MEDICAL_BACKGROUND_PAIN_FLACC_SCALE_ACTIVITY = 'medical-background/pain/flacc-scale-activity',
  MEDICAL_BACKGROUND_PAIN_FLACC_SCALE_CRY = 'medical-background/pain/flacc-scale-cry',
  MEDICAL_BACKGROUND_PAIN_FLACC_SCALE_CONSOLABILITY = 'medical-background/pain/flacc-scale-consolability',
  MEDICAL_BACKGROUND_PAIN_SYSTEM_SUMMARY = 'medical-background/pain/system-summary',
  MEDICAL_BACKGROUND_CARDIAC_CARDIOVASCULAR_WNL = 'medical-background/cardiac/cardiovascular-wnl',
  MEDICAL_BACKGROUND_CARDIAC_CARDIOVASCULAR_FACTORS = 'medical-background/cardiac/cardiac-factors',
  MEDICAL_BACKGROUND_CARDIAC_HEART_SOUNDS = 'medical-background/cardiac/heart-sounds',
  MEDICAL_BACKGROUND_CARDIAC_SYSTEM_SUMMARY = 'medical-background/cardiac/system-summary',
  MEDICAL_BACKGROUND_RESPIRATORY_RESPIRATORY_WNL = 'medical-background/respiratory/respiratory-wnl',
  MEDICAL_BACKGROUND_RESPIRATORY_COUGH = 'medical-background/respiratory/cough',
  MEDICAL_BACKGROUND_RESPIRATORY_OXYGEN = 'medical-background/respiratory/oxygen',
  MEDICAL_BACKGROUND_RESPIRATORY_LUNG_SOUNDS = 'medical-background/respiratory/lung-sounds',
  MEDICAL_BACKGROUND_RESPIRATORY_SYSTEM_SUMMARY = 'medical-background/respiratory/system-summary',
  MEDICAL_BACKGROUND_ENDOCRINE_ENDOCRINE_WNL = 'medical-background/endocrine/endocrine-wnl',
  MEDICAL_BACKGROUND_ENDOCRINE_MENSTRUAL_CYCLE = 'medical-background/endocrine/menstrual-cycle',
  MEDICAL_BACKGROUND_ENDOCRINE_MENSTRUAL_DATE = 'medical-background/endocrine/menstrual-date',
  MEDICAL_BACKGROUND_ENDOCRINE_ENDOCRINE_NOTES = 'medical-background/endocrine/endocrine-notes',
  MEDICAL_BACKGROUND_ENDOCRINE_SYSTEM_SUMMARY = 'medical-background/endocrine/system-summary',
  MEDICAL_BACKGROUND_GASTROINTESTINAL_GI_ASSESSMENT = 'medical-background/gastrointestinal/gi-assessment',
  MEDICAL_BACKGROUND_GASTROINTESTINAL_BM_DATE = 'medical-background/gastrointestinal/bm-date',
  MEDICAL_BACKGROUND_GASTROINTESTINAL_GU_ASSESSMENT = 'medical-background/gastrointestinal/gu-assessment',
  MEDICAL_BACKGROUND_GASTROINTESTINAL_CATHETER = 'medical-background/gastrointestinal/catheter',
  MEDICAL_BACKGROUND_GASTROINTESTINAL_CATHETER_TYPE = 'medical-background/gastrointestinal/catheter-type',
  MEDICAL_BACKGROUND_GASTROINTESTINAL_FEEDING_TUBE = 'medical-background/gastrointestinal/feeding-tube',
  MEDICAL_BACKGROUND_GASTROINTESTINAL_FEEDING_TUBE_AMOUNT = 'medical-background/gastrointestinal/feeding-tube-amount',
  MEDICAL_BACKGROUND_GASTROINTESTINAL_FEEDING_TOTAL_NUMBER = 'medical-background/gastrointestinal/feeding-total-number',
  MEDICAL_BACKGROUND_GASTROINTESTINAL_FEEDING_TUBE_DURATION = 'medical-background/gastrointestinal/feeding-tube-duration',
  MEDICAL_BACKGROUND_GASTROINTESTINAL_FEEDING_TUBE_TYPE = 'medical-background/gastrointestinal/feeding-tube-type',
  MEDICAL_BACKGROUND_GASTROINTESTINAL_FEEDING_TUBE_SITE = 'medical-background/gastrointestinal/feeding-tube-site',
  MEDICAL_BACKGROUND_GASTROINTESTINAL_FEEDING_TUBE_SIZE = 'medical-background/gastrointestinal/feeding-tube-size',
  MEDICAL_BACKGROUND_GASTROINTESTINAL_FEEDING_TUBE_NOTES = 'medical-background/gastrointestinal/feeding-tube-notes',
  MEDICAL_BACKGROUND_GASTROINTESTINAL_NUTRITION_WNL = 'medical-background/gastrointestinal/nutrition-wnl',
  MEDICAL_BACKGROUND_GASTROINTESTINAL_NUTRITIONAL_FACTORS = 'medical-background/gastrointestinal/nutritional-factors',
  MEDICAL_BACKGROUND_GASTROINTESTINAL_DIET_REQUIREMENTS = 'medical-background/gastrointestinal/diet-requirements',
  MEDICAL_BACKGROUND_GASTROINTESTINAL_NUTRITION_NOTES = 'medical-background/gastrointestinal/nutrition-notes',
  MEDICAL_BACKGROUND_GASTROINTESTINAL_NUTRITIONAL_SCREENING = 'medical-background/gastrointestinal/nutritional-screening',
  MEDICAL_BACKGROUND_GASTROINTESTINAL_GASTROINTESTINAL_HISTORY_NOTES = 'medical-background/gastrointestinal/gastrointestinal-history-notes',
  MEDICAL_BACKGROUND_GASTROINTESTINAL_SYSTEM_SUMMARY = 'medical-background/gastrointestinal/system-summary',
  MEDICAL_BACKGROUND_INTEGUMENTARY_SKIN_COLOR = 'medical-background/integumentary/skin-color',
  MEDICAL_BACKGROUND_INTEGUMENTARY_SKIN_ISSUES = 'medical-background/integumentary/skin-issues',
  MEDICAL_BACKGROUND_INTEGUMENTARY_NAILS = 'medical-background/integumentary/nails',
  MEDICAL_BACKGROUND_INTEGUMENTARY_IDENTIFIED_WOUNDS = 'medical-background/integumentary/identified-wounds',
  MEDICAL_BACKGROUND_INTEGUMENTARY_WOUND_NOTES = 'medical-background/integumentary/wound-notes',
  MEDICAL_BACKGROUND_INTEGUMENTARY_SYSTEM_SUMMARY = 'medical-background/integumentary/system-summary',
  MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_TEMP = 'medical-background/physician-notification/temp',
  MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_TEMP_GREATER = 'medical-background/physician-notification/temp/greater',
  MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_TEMP_LESS = 'medical-background/physician-notification/temp/less',
  MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_PULSE = 'medical-background/physician-notification/pulse',
  MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_PULSE_GREATER = 'medical-background/physician-notification/pulse/greater',
  MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_PULSE_LESS = 'medical-background/physician-notification/pulse/less',
  MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_RESPIRATIONS = 'medical-background/physician-notification/respirations',
  MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_RESPIRATIONS_GREATER = 'medical-background/physician-notification/respirations/greater',
  MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_RESPIRATIONS_LESS = 'medical-background/physician-notification/respirations/less',
  MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_BP = 'medical-background/physician-notification/bp',
  MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_BP_GREATER = 'medical-background/physician-notification/bp/greater',
  MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_BP_LESS = 'medical-background/physician-notification/bp/less',
  MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_WEIGHT = 'medical-background/physician-notification/weight',
  MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_WEIGHT_GREATER = 'medical-background/physician-notification/weight/greater',
  MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_WEIGHT_LESS = 'medical-background/physician-notification/weight/less',
  MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_ETCO2_SATURATION = 'medical-background/physician-notification/etco2-saturation',
  MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_ETCO2_SATURATION_GREATER = 'medical-background/physician-notification/etco2-saturation/greater',
  MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_ETCO2_SATURATION_LESS = 'medical-background/physician-notification/etco2-saturation/less',
  MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_O2_SATURATION = 'medical-background/physician-notification/o2-saturation',
  MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_O2_SATURATION_GREATER = 'medical-background/physician-notification/o2-saturation/greater',
  MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_O2_SATURATION_LESS = 'medical-background/physician-notification/o2-saturation/less',
  MEDICAL_BACKGROUND_CARE_COORDINATION_PREVIOUS_APPOINTMENTS = 'medical-background/care-coordination/previous-appointments',
  MEDICAL_BACKGROUND_CARE_COORDINATION_FUTURE_APPOINTMENTS = 'medical-background/care-coordination/future-appointments',
  MEDICAL_BACKGROUND_CARE_COORDINATION_CARE_TEAM_COORDINATION = 'medical-background/care-coordination/care-team-coordination',
  MEDICAL_BACKGROUND_CARE_COORDINATION_ORDERS_FOR_TREATMENT = 'medical-background/care-coordination/orders-for-treatment',
  MEDICAL_BACKGROUND_INITIALS_AUTHORIZATION = 'medical-background/initials-authorization',

  // Supervisory review
  SUPERVISORY_REVIEW_ID_BADGE = 'supervisory-review/supervisory-review/id-badge',
  SUPERVISORY_REVIEW_HONORING_RIGHTS = 'supervisory-review/supervisory-review/honoring-rights',
  SUPERVISORY_REVIEW_EMERGENCY_PROTOCOL = 'supervisory-review/supervisory-review/emergency-protocol',
  SUPERVISORY_REVIEW_HYGIENE_PRECAUTIONS = 'supervisory-review/supervisory-review/hygiene-precautions',
  SUPERVISORY_REVIEW_REPORTING_CHANGES = 'supervisory-review/supervisory-review/reporting-changes',
  SUPERVISORY_REVIEW_GRIEVANCE_COMPLAINT = 'supervisory-review/supervisory-review/grievance-complaint',
  SUPERVISORY_REVIEW_POC_DOC_REVIEWED_WITH_CAREGIVER = 'supervisory-review/supervisory-review/poc-doc-reviewed-with-caregiver',
  SUPERVISORY_REVIEW_PPE_ACCESS = 'supervisory-review/supervisory-review/ppe-access',
  SUPERVISORY_REVIEW_SUPERVISION_OBSERVED = 'supervisory-review/supervisory-review/supervision-observed',
  SUPERVISORY_REVIEW_TASK_OBSERVED = 'supervisory-review/supervisory-review/task-observed',
  SUPERVISORY_REVIEW_PATIENT_STATUS = 'supervisory-review/supervisory-review/patient-status',
  SUPERVISORY_REVIEW_COMMENTS = 'supervisory-review/supervisory-review/comments',
  SUPERVISORY_REVIEW_EDUCATION = 'supervisory-review/education',
  SUPERVISORY_REVIEW_EDUCATION_CAREGIVER_EDUCATION_TOPIC = 'supervisory-review/education/caregiver-education-topic',
  SUPERVISORY_REVIEW_EDUCATION_EDUCATION_PROVIDED = 'supervisory-review/education/education-provided',
  SUPERVISORY_REVIEW_INITIALS_AUTHORIZATION = 'supervisory-review/initials-authorization',

  // Narrative
  NARRATIVE_BACKGROUND = 'narrative/background',
  NARRATIVE_SYSTEM_SUMMARIES = 'narrative/system-summaries',
  NARRATIVE_CLINICAL_OVERVIEW = 'narrative/clinical-overview',
  NARRATIVE_SKILLED_NEEDS = 'narrative/skilled-needs',
  NARRATIVE_PATIENT_SERVICES = 'narrative/patient-services',
  NARRATIVE_CARE_COORDINATION = 'narrative/care-coordination',
  NARRATIVE_PATIENT_GOALS = 'narrative/patient-goals',
  NARRATIVE_CLINICAL_EDUCATION = 'narrative/clinical-education',
  NARRATIVE_FINAL_NOTES = 'narrative/final-notes',

  // Final steps
  FINAL_STEPS_PENDING_REQUIRED_DOCUMENTATION = 'final-steps/pending-required-documentation',
  FINAL_STEPS_INTERNAL_NOTES = 'final-steps/internal-notes'
}
