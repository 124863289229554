import gql from 'gql-tag'

export const BASE_TASK_FRAGMENT = gql`
  fragment BaseTask on Task {
    resourceType
    id
    status
    businessStatus {
      coding {
        system
        code
        display
      }
    }
    statusReason {
      coding {
        system
        code
        display
      }
    }
    executionPeriod {
      start
      end
    }
    code {
      coding {
        system
        code
        display
      }
    }
    description
    owner {
      id
      display
      reference
      identifier {
        id
      }
      type
    }
    requester {
      id
      display
      reference
      identifier {
        id
      }
      type
    }
    note {
      text
    }
    output {
      type {
        coding {
          system
          code
          display
        }
      }
      valueSignature {
        who {
          reference
          display
        }
        sigFormat
        when
      }
    }
    extension {
      url
      valueString
      valueCoding {
        code
        display
      }
    }
    meta {
      lastUpdated
    }
  }
`
