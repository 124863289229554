import { createValueCodingExtension } from 'fhir/utils'
import { ORGANIZATION_ORDER_PREFERENCES_EXTENSION } from './constants'

export enum OrganizationPreferencesCode {
  ACCEPTS_VERBAL_ORDERS = 'accepts_verbal_orders',
  FAX = 'fax',
  EMAIL = 'email',
  VERBAL_NOT_ACCEPTED = 'verbal_not_accepted',
  MEDICATION_RECONCILIATION = 'medication_reconciliation'
}

export const ORG_PREFERENCES_EXTENSION_DISPLAY_FOR_CODE = {
  [OrganizationPreferencesCode.ACCEPTS_VERBAL_ORDERS]: 'Accepts verbal orders',
  [OrganizationPreferencesCode.FAX]: 'Fax',
  [OrganizationPreferencesCode.EMAIL]: 'E-mail',
  [OrganizationPreferencesCode.VERBAL_NOT_ACCEPTED]: 'Verbal not accepted',
  [OrganizationPreferencesCode.MEDICATION_RECONCILIATION]: 'Medication reconciliation'
}

export const EXTENSION_ACCEPTS_VERBAL_ORDERS = createValueCodingExtension({
  url: ORGANIZATION_ORDER_PREFERENCES_EXTENSION,
  system: ORGANIZATION_ORDER_PREFERENCES_EXTENSION,
  valueCodingCode: OrganizationPreferencesCode.ACCEPTS_VERBAL_ORDERS
})

export const EXTENSION_FAX = createValueCodingExtension({
  url: ORGANIZATION_ORDER_PREFERENCES_EXTENSION,
  system: ORGANIZATION_ORDER_PREFERENCES_EXTENSION,
  valueCodingCode: OrganizationPreferencesCode.FAX
})

export const EXTENSION_EMAIL = createValueCodingExtension({
  url: ORGANIZATION_ORDER_PREFERENCES_EXTENSION,
  system: ORGANIZATION_ORDER_PREFERENCES_EXTENSION,
  valueCodingCode: OrganizationPreferencesCode.EMAIL
})

export const EXTENSION_VERBAL_NOT_ACCEPTED = createValueCodingExtension({
  url: ORGANIZATION_ORDER_PREFERENCES_EXTENSION,
  system: ORGANIZATION_ORDER_PREFERENCES_EXTENSION,
  valueCodingCode: OrganizationPreferencesCode.VERBAL_NOT_ACCEPTED
})

export const EXTENSION_MEDICATION_RECONCILIATION = createValueCodingExtension({
  url: ORGANIZATION_ORDER_PREFERENCES_EXTENSION,
  system: ORGANIZATION_ORDER_PREFERENCES_EXTENSION,
  valueCodingCode: OrganizationPreferencesCode.MEDICATION_RECONCILIATION
})
