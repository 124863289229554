import { Button } from 'antd'
import React, { type CSSProperties, type ReactElement, type ReactNode } from 'react'

// General purpose Icon Button Component
type ButtonType = 'default' | 'link' | 'text' | 'ghost' | 'primary' | 'dashed'

interface IconButtonProps {
  children: ReactNode
  icon: ReactElement
  onClick?: () => void | Promise<void>
  color?: string
  type?: ButtonType
  style?: CSSProperties
  disabled?: boolean
}

const IconButton = ({
  onClick,
  children,
  icon,
  color,
  type = 'default',
  style,
  disabled,
  ...props
}: IconButtonProps): JSX.Element => {
  return (
    <Button
      type={type}
      disabled={disabled}
      onClick={onClick}
      style={{ display: 'flex', alignItems: 'center', ...style }}
      {...props}
    >
      {React.cloneElement(icon, { style: { marginRight: '8px', color }, ...icon.props })}
      {children}
    </Button>
  )
}

export default IconButton
