import { Button, Result } from 'antd'
import { useRouter } from 'next/router'
import React, { ErrorInfo, ReactNode, useEffect, useState } from 'react'
import Router from 'next/router'
import { captureException } from 'utils/sentry'
import { AbbyCareFrame } from 'public/assets/icons'

interface ErrorBoundaryInnerProps {
  source: string
  children: ReactNode
  hasError: boolean
  setHasError: (hasError: boolean) => void
  goBackPath?: string
}

interface ErrorBoundaryState {
  hasError: boolean
  error: Error | null
}

class ErrorBoundaryInner extends React.Component<ErrorBoundaryInnerProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryInnerProps) {
    super(props)
    this.state = { hasError: false, error: null }
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error }
  }

  componentDidUpdate(prevProps, _previousState): void {
    if (!this.props.hasError && prevProps.hasError) {
      this.setState({ hasError: false, error: null })
    }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.props.setHasError(true)
    captureException(error, {
      tags: { source: this.props.source ?? '' },
      extras: {
        info: {
          componentStack: errorInfo.componentStack,
          cause: error?.cause
        }
      }
    })
  }

  backToASafePlace = (): void => {
    if (this.props.goBackPath) {
      Router.push(this.props.goBackPath)
    } else {
      Router.back()
    }
  }

  render(): ReactNode {
    if (this.state.hasError) {
      // custom fallback UI for unknown errors
      return (
        <Result
          icon={<AbbyCareFrame />}
          title="Uh oh! There was a problem loading this page."
          style={{ width: '100%' }}
          extra={
            <Button type="primary" key="console" onClick={this.backToASafePlace}>
              Go Back
            </Button>
          }
        />
      )
    }
    return this.props.children
  }
}

interface ErrorBoundaryProps {
  source: string
  goBackPath?: string
  children: ReactNode
}

/*
  You may be thinking, "Why are we combining a class component with a functional component?"
  the reason is simple, ErrorBoundary is a old class component intruduced in React 16.6.0
  with no recent updates

  That's reason number one, the second reason is that next/router in this case is under the
  ErrorBoundary component, and we need to reset the hasError state when the route changes
  so we allow users to navigate to other pages without the error message blocking the view
  and the only way to do that is by using a useEffect hook, which is not possible in a class
*/
const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({
  children,
  source,
  goBackPath
}): JSX.Element => {
  const [hasError, setHasError] = useState(false)
  const router = useRouter()

  useEffect(() => {
    if (hasError) {
      setHasError(false)
    }
  }, [hasError, router.pathname])

  return (
    <ErrorBoundaryInner
      source={source}
      goBackPath={goBackPath}
      hasError={hasError}
      setHasError={setHasError}
    >
      {children}
    </ErrorBoundaryInner>
  )
}

export default ErrorBoundary
