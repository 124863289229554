import gql from 'gql-tag'

export const BASE_QUESTIONNAIRE_RESPONSE_ITEM_ANSWER_FRAGMENT = gql`
  fragment BaseQuestionnaireResponseItemAnswer on QuestionnaireResponseItemAnswer {
    valueDate
    valueDateTime
    valueTime
    valueString
    valueBoolean
    valueDecimal
    valueInteger
    valueCoding {
      code
      system
      display
    }
    valueReference {
      display
      reference
    }
  }
`

// Fragments can't reference themselves, so unfortunately I have to manually nest
// answers and items like below. We need it nested like this because we can have
// answers that are an array of items that have answers, and sometimes an array of
// items of items of answers...
export const BASE_QUESTIONNAIRE_RESPONSE_ITEM_FRAGMENT = gql`
  fragment BaseQuestionnaireResponseItem on QuestionnaireResponseItem {
    linkId
    text
    answer {
      ...BaseQuestionnaireResponseItemAnswer
      item {
        linkId
        text
        answer {
          ...BaseQuestionnaireResponseItemAnswer
          item {
            linkId
            text
            answer {
              ...BaseQuestionnaireResponseItemAnswer
            }
            item {
              linkId
              text
              answer {
                ...BaseQuestionnaireResponseItemAnswer
              }
            }
          }
        }
        item {
          linkId
          text
          answer {
            ...BaseQuestionnaireResponseItemAnswer
            item {
              linkId
              text
              answer {
                ...BaseQuestionnaireResponseItemAnswer
              }
              item {
                linkId
                text
                answer {
                  ...BaseQuestionnaireResponseItemAnswer
                }
              }
            }
          }
          item {
            linkId
            text
            answer {
              ...BaseQuestionnaireResponseItemAnswer
              item {
                linkId
                text
                answer {
                  ...BaseQuestionnaireResponseItemAnswer
                }
                item {
                  linkId
                  text
                  answer {
                    ...BaseQuestionnaireResponseItemAnswer
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const BASE_QUESTIONNAIRE_RESPONSE_FRAGMENT = gql`
  fragment BaseQuestionnaireResponse on QuestionnaireResponse {
    resourceType
    id
    questionnaire
    status
    subject {
      reference
      display
    }
    source {
      reference
      display
    }
    meta {
      lastUpdated
    }
    author {
      display
      reference
    }
    authored
    item {
      ...BaseQuestionnaireResponseItem
      item {
        ...BaseQuestionnaireResponseItem
        item {
          ...BaseQuestionnaireResponseItem
          item {
            ...BaseQuestionnaireResponseItem
            item {
              ...BaseQuestionnaireResponseItem
              item {
                ...BaseQuestionnaireResponseItem
              }
            }
          }
        }
      }
    }
  }
`
