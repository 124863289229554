import React from 'react'
import styled from 'styled-components'
import AbbyCareLogo from 'public/assets/logo.svg'
import AbbyCareIcon from 'public/assets/icons/abby-care.svg'
import Navigation from './Navigation'

export const Sidebar = ({ isCollapsed }): JSX.Element => {
  return (
    <>
      {isCollapsed ? (
        <IconContainer>
          <AbbyCareIcon />
        </IconContainer>
      ) : (
        <LogoContainer>
          <AbbyCareLogo height={26} width={50} />
        </LogoContainer>
      )}
      <Navigation />
    </>
  )
}

const LogoContainer = styled.div`
  margin: 16px 16px 32px 25px;
`

const IconContainer = styled.div`
  margin: 24px 16px 32px 25px;
`
