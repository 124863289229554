import {
  ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE,
  ABBYCARE_TERMINOLOGY_CODES_BASE
} from 'fhir/constants'

export const ENCOUNTER_VISIT_NUMBER_CODE_SYSTEM = `${ABBYCARE_TERMINOLOGY_CODES_BASE}/encounter-visit-number`

export const ENCOUNTER_REASON_CODE_SYSTEM = `${ABBYCARE_TERMINOLOGY_CODES_BASE}/reason`

export enum EncounterStatus {
  PLANNED = 'planned',
  ARRIVED = 'arrived',
  TRIAGED = 'triaged',
  IN_PROGRESS = 'in-progress',
  ON_LEAVE = 'onleave',
  FINISHED = 'finished',
  CANCELLED = 'cancelled',
  ENTERED_IN_ERROR = 'entered-in-error'
}

export const AUTHORIZED_ENCOUNTER_STATUSES = [
  EncounterStatus.PLANNED,
  EncounterStatus.ARRIVED,
  EncounterStatus.TRIAGED,
  EncounterStatus.IN_PROGRESS,
  EncounterStatus.ON_LEAVE,
  EncounterStatus.FINISHED,
  EncounterStatus.CANCELLED
]

export const cancelledEncounterStatuses = [EncounterStatus.CANCELLED, EncounterStatus.ON_LEAVE]
export const chartedEncounterStatuses = [EncounterStatus.FINISHED]
export const chartableEncounterStatuses = [
  EncounterStatus.PLANNED,
  EncounterStatus.ARRIVED,
  EncounterStatus.TRIAGED,
  EncounterStatus.IN_PROGRESS
]

export const ENCOUNTER_PARTICIPANT_TYPE_CODE_SYSTEM = `${ABBYCARE_TERMINOLOGY_CODES_BASE}/encounter-participant-type`

export enum EncounterParticipantType {
  CAREGIVER = 'caregiver'
}

export enum CNAVisitEncounterStatus {
  SCHEDULED = 'scheduled',
  SUBMITTED = 'submitted',
  MISSED = 'missed',
  LATE = 'late',
  APPROVED = 'approved',
  DENIED = 'denied'
}

export const ENCOUNTER_CLASS_CODE_SYSTEM = `${ABBYCARE_TERMINOLOGY_CODES_BASE}/encounter-class`

export enum EncounterClassCode {
  CNA_VISIT = 'cna-visit'
}

const ENCOUNTER_EVV_STATUS_LOCATION = `${ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE}/encounter-evv-status-location`
const ENCOUNTER_EVV_STATUS_TIME = `${ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE}/encounter-evv-status-time`
const ENCOUNTER_EVV_STATUS_SIGNATURE = `${ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE}/encounter-evv-status-signature`
const ENCOUNTER_EVV_STATUS_TASK_COMPLETION = `${ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE}/encounter-evv-status-task-completion`

export const ENCOUNTER_REASON_FOR_DELAYED_CHARTING = `${ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE}/reason-for-delayed-charting`
export const ENCOUNTER_BETTER_SUPPORT = `${ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE}/feedback-for-better-support`

export enum EncounterEVVExtensionTypes {
  TIME = 'time',
  SIGNATURE = 'signature',
  TASK_COMPLETION = 'task-completion'
}

export type EncounterEVVExtensions =
  | typeof ENCOUNTER_EVV_STATUS_LOCATION
  | typeof ENCOUNTER_EVV_STATUS_TIME
  | typeof ENCOUNTER_EVV_STATUS_SIGNATURE
  | typeof ENCOUNTER_EVV_STATUS_TASK_COMPLETION

export const EncounterEVVExtensionsMap: Record<EncounterEVVExtensionTypes, EncounterEVVExtensions> =
  {
    [EncounterEVVExtensionTypes.TIME]: ENCOUNTER_EVV_STATUS_TIME,
    [EncounterEVVExtensionTypes.SIGNATURE]: ENCOUNTER_EVV_STATUS_SIGNATURE,
    [EncounterEVVExtensionTypes.TASK_COMPLETION]: ENCOUNTER_EVV_STATUS_TASK_COMPLETION
  }

export enum CNAVisitEncounterEVVStatus {
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  INDETERMINATE = 'indeterminate'
}

export const ENCOUNTER_CARE_PLAN_EXTENSION_URL = `${ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE}/encounter-care-plan`
