import { ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE, HL7_TERMINOLOGY_CODES_BASE } from 'fhir/constants'

export const CONSENT_POLICY_RULE_URL_CODE_SYSTEM = `${ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE}/consent-policy-rule-url`
export const CONSENT_SCOPE_CODE_SYSTEM = `${HL7_TERMINOLOGY_CODES_BASE}/consentscope`

export const CONSENT_ACTION_CODE_SYSTEM = `${HL7_TERMINOLOGY_CODES_BASE}/consentaction`
export enum ConsentActionCode {
  EXECUTE_TREATMENT = 'execute-treatment',
  DISCHARGE = 'discharge'
}

export const CONSENT_CATEGORY_CODE_SYSTEM = `${ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE}/consent-category`
export enum ConsentCategoryCode {
  AUTHORIZATION = 'authorization',
  CERT_PERIOD = 'cert-period',
  CAREPLAN_APPROVAL = 'careplan-approval',
  HOME_HEALTH = 'home-health-care'
}

export const CONSENT_ACTOR_ROLE_CODE_SYSTEM = `${HL7_TERMINOLOGY_CODES_BASE}/provenance-participant-type`
export enum ConsentActorRoleCode {
  AUTHOR = 'author',
  DATA_SUBJECT = 'datasubject',
  PERFORMER = 'performer'
}

export const CONSENT_TYPE_CODE_SYSTEM = `${ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE}/consent-type`
export enum ConsentTypeCode {
  AUTHORIZATION = 'authorization',
  CERT_PERIOD = 'cert-period',
  CAREPLAN_APPROVAL = 'careplan-approval'
}

export enum ConsentStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  PROPOSED = 'proposed',
  REJECTED = 'rejected',
  ENTERED_IN_ERROR = 'entered-in-error'
}

export enum ProvisionType {
  PERMIT = 'permit',
  DENY = 'deny'
}

export const CONSENT_EPISODE_OF_CARE_EXTENSION_URL = `${ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE}/consent-episode-of-care`
export const CONSENT_CAREPLAN_EXTENSION_URL = `${ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE}/consent-careplan`
export const CONSENT_CARE_HOURS_EXTENSION_URL = `${ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE}/consent-care-hours`
