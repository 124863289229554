import React, { FC, useRef, useState } from 'react'
import { Layout, theme } from 'antd'
import styled from 'styled-components'
import { ScrollProvider } from 'contexts/ScrollContext'
import { mediaQueries } from 'utils/breakpoints'
import { SentrySources } from 'utils/sentry'
import { Sidebar } from './Sidebar/Sidebar'
import { Head } from './Header/Header'
import ContentWrapper from './Page/ContentWrapper'
import ErrorBoundary from './ErrorBoundary'

const { Header, Sider } = Layout

interface MainWrapperProps {
  children?: React.ReactNode
}

export const NAVBAR_HEIGHT = 64
export const HEADER_Z_INDEX = 999

const MainWrapper: FC<MainWrapperProps> = ({ children }) => {
  const [isCollapsed, setIsCollapsed] = useState(false)
  const contentScrollDiv = useRef<HTMLDivElement>(null)

  const {
    token: { colorBgContainer }
  } = theme.useToken()

  return (
    <Layout style={{ height: '100vh' }}>
      {/* Desktop Version */}
      <ResponsiveSider
        collapsed={isCollapsed}
        style={{ background: colorBgContainer, borderRight: '1px solid #F2F4F7' }}
        width={260}
        theme={'light'}
      >
        <Sidebar isCollapsed={isCollapsed} />
      </ResponsiveSider>

      {/* Mobile Version */}
      <MobileSider
        collapsed={true}
        style={{ background: colorBgContainer, borderRight: '1px solid #F2F4F7' }}
        width={80}
        theme={'light'}
      >
        <Sidebar isCollapsed={true} />
      </MobileSider>
      <Layout
        ref={contentScrollDiv}
        id="content-container"
        className="site-layout"
        style={{
          background: colorBgContainer,
          overflowY: 'auto',
          scrollbarGutter: 'stable'
        }}
      >
        <Header
          style={{
            position: 'sticky',
            zIndex: HEADER_Z_INDEX,
            top: 0,
            padding: 0,
            height: NAVBAR_HEIGHT,
            background: colorBgContainer,
            borderBottom: '1px solid #EAECF0'
          }}
        >
          <Head
            onClick={() => {
              setIsCollapsed(!isCollapsed)
            }}
          />
        </Header>
        <ErrorBoundary source={SentrySources.MAIN_WRAPPER}>
          <ScrollProvider scrollContainerRef={contentScrollDiv}>
            <ContentWrapper>{children}</ContentWrapper>
          </ScrollProvider>
        </ErrorBoundary>
      </Layout>
    </Layout>
  )
}

export default MainWrapper

const ResponsiveSider = styled(Sider)`
  @media ${mediaQueries.belowDesktop} {
    display: none;
  }
`

const MobileSider = styled(Sider)`
  display: none;

  @media ${mediaQueries.belowDesktop} {
    display: block;
  }
`
