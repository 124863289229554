import { Dayjs } from 'dayjs'

export type InteractionSeverity = 'major' | 'moderate' | 'minor'

export enum MedicationUsageStatus {
  NEW = 'new',
  CHANGED = 'changed',
  STANDING = 'standing'
}

export enum MedicationStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive'
}

export interface MedicationInfo {
  id?: string
  status?: MedicationStatus
  name?: string
  indications?: string
  route?: string
  strength?: {
    number?: number
    unit?: string
  }
  frequency?: string
  dosage?: {
    number?: number
    unit?: string
  }
  instructions?: string
  startDate?: Dayjs | null
  discontinueDate?: Dayjs | null
  usageStatus?: string
  controlled?: boolean
  liquid?: boolean
  infection?: boolean
  productConceptId?: string
}

// ———————————————————————————————————————————————————————————————
// Below is generated types via ChatGPT from the backend Pydantic
// models.
// ———————————————————————————————————————————————————————————————

export interface RxnormConcept {
  name: string
  RXCUI: string
}

export interface Drug {
  name: string
  drugbank_id: string
}

export interface ExactIngredient {
  name: string
  drugbank_id: string
}

export interface Strength {
  amount: string
  per: string
  units: string
}

export interface Ingredient {
  name: string
  drug?: Drug
  exact_ingredient?: ExactIngredient
  strength?: Strength
}

export interface Condition {
  name: string
  drugbank_id: string
  meddra_id?: string
  snomed_id?: string
  icd10_id?: string
}

export interface ProductStrength {
  number: string
  unit: string
}

export interface ProductIngredient {
  name: string
  drugbank_id: string
  strength?: ProductStrength
}

export interface TherapeuticCategory {
  drugbank_id: string
  name: string
  mesh_id?: string
  mesh_tree_numbers?: string[]
  atc_code?: string
  atc_level?: number
  synonyms?: string[]
  description?: string
}

export interface Labeller {
  name: string
}

export interface DrugbankMedicationSearchResult {
  name: string
  display_name?: string
  drugbank_pcid: string
  brand?: string
  level: number
  route?: string
  form?: string
  strengths?: string
  standing: string
  standing_updated_at?: string
  standing_active_since: string
  rxnorm_concepts: RxnormConcept[]
  ingredients: Ingredient[]
}

export interface DrugbankMedicationIndication {
  kind: string
  off_label?: boolean
  otc_use?: boolean
  adjunct_use?: boolean
  route?: string[]
  dose_form?: string[]
  dose_strength?: string[]
  timeline?: string
  drug: Drug
  regions?: string
  condition?: Condition
  condition_associated_with?: Condition[]
}

export interface DrugbankMedicationInteraction {
  product_concept_id: string
  product_concept_name: string
  product_ingredient: ProductIngredient
  affected_product_concept_id: string
  affected_product_concept_name: string
  affected_product_ingredient: ProductIngredient
  description?: string
  extended_description?: string
  action?: string
  severity: InteractionSeverity
  subject_dosage?: string
  affected_dosage?: string
  evidence_level?: string
  management?: string
}

export interface DrugbankProduct {
  ndc_product_code: string
  originator_ndc_product_code: string
  dpd_id?: string
  ema_product_code?: string
  ema_ma_number?: string
  pzn?: string
  co_product_id?: string
  tr_product_id?: string
  it_product_id?: string
  my_product_id?: string
  at_product_id?: string
  idn_product_id?: string
  th_product_id?: string
  sg_product_id?: string
  name: string
  prescribable_name?: string
  rx_norm_prescribable_name?: string
  started_marketing_on?: string
  ended_marketing_on?: string
  approved_on?: string
  schedule?: string
  dosage_form: string
  route: string
  application_number?: string
  generic: boolean
  otc: boolean
  approved: boolean
  country?: string
  mixture: boolean
  allergenic: boolean
  cosmetic: boolean
  vaccine: boolean
  ingredients: ProductIngredient[]
  therapeutic_categories?: TherapeuticCategory[]
  labeller?: Labeller
  images: any[]
}
