import {
  ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE,
  ABBYCARE_TERMINOLOGY_CODES_BASE
} from 'fhir/constants'

// What task is acting on
export const TASK_FOCUS_LINK_EXTENSION = `${ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE}/task-focus-link`
export const TASK_FOCUS_LABEL_EXTENSION = `${ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE}/task-focus-label`
export const TASK_QUESTIONNAIRE_QA_STATUS_EXTENSION = `${ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE}/task-questionnaire-qa-status`
export const TASK_QUESTIONNAIRE_STATUS_EXTENSION = `${ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE}/task-questionnaire-status`
export const TASK_CAREGIVER_TASK_INFO_CODE_SYSTEM = `${ABBYCARE_TERMINOLOGY_CODES_BASE}/task-information`

export enum TaskPriorities {
  ROUTINE = 'routine',
  URGENT = 'urgent',
  ASAP = 'asap',
  STAT = 'stat'
}

export enum TaskStatus {
  ACCEPTED = 'accepted',
  COMPLETED = 'completed',
  NOT_COMPLETED = 'not-complete',
  COMPLETED_IN_ANOTHER_VISIT = 'completed-in-another-visit',
  CANCELLED = 'cancelled',
  DRAFT = 'draft',
  REQUESTED = 'requested',
  RECEIVED = 'received',
  REJECTED = 'rejected',
  READY = 'ready',
  IN_PROGRESS = 'in-progress',
  ON_HOLD = 'on-hold',
  FAILED = 'failed',
  ENTERED_IN_ERROR = 'entered-in-error'
}

export enum FlowStageStatus {
  PENDING = 'pending',
  READY = 'ready',
  COMPLETED = 'completed'
}

export const AUTHORIZED_TASK_STATUSES = [
  TaskStatus.ACCEPTED,
  TaskStatus.COMPLETED,
  TaskStatus.NOT_COMPLETED,
  TaskStatus.COMPLETED_IN_ANOTHER_VISIT,
  TaskStatus.CANCELLED,
  TaskStatus.DRAFT,
  TaskStatus.REQUESTED,
  TaskStatus.RECEIVED,
  TaskStatus.REJECTED,
  TaskStatus.READY,
  TaskStatus.IN_PROGRESS,
  TaskStatus.ON_HOLD,
  TaskStatus.FAILED
]

export enum DocumentUploadTypes {
  SIGNED_CARE_PLAN = 'Signed Care Plan',
  THERAPY_NOTES = 'Therapy Notes',
  DOCS_FOR_ADDITIONAL_POINTS = 'Docs for Additional Points',
  EQUIPMENT_NOTES = 'Equipment Notes',
  ABBA_NOTES = 'ABA Notes',
  MEDICATION_LIST = 'Medication List',
  DIAGNOSIS_LIST = 'Diagnosis List',
  HISTORY_AND_PHYSICAL = 'History and Physical',
  SPEECH_NOTES = 'Speech Notes',
  F2F_SUMMARY = 'F2F Summary',
  EQUIPMENT_LIST = 'Equipment List',
  GENERAL_ORDER = 'General Order',
  PRIOR_AUTH_APPROVALS = 'Prior Auth Approvals'
}

export enum TaskType {
  DOCUMENT_AUDIT = 'document-audit',
  SIGNED_DOCUMENT_UPLOAD = 'signed-document-upload',
  ASSESSMENT_QA = 'assessment-qa',
  ASSESSMENT_QA_EDITS = 'assessment-qa-edits',
  ASSESSMENT_DOCUMENT_UPLOAD = 'assessment-document-upload',
  CONTACT_PCP = 'contact-pcp',
  SCHEDULE_APPOINTMENT_WITH_PCP = 'schedule-appointment-with-pcp',
  DOCUMENT_SEND = 'document-send',
  DOCUMENT_UPLOAD = 'document-upload',
  CAREGIVER_CALL = 'caregiver-call',
  CNA_VISIT = 'cna-visit',
  CNA_VISIT_QA = 'cna-visit-qa',
  COMPLETE_RELIAS_VIDEOS = 'complete-relias-videos',
  COMPLETE_RIPPLING = 'complete-rippling',
  VERIFY_PATIENT_MEDICAID_COVERAGE = 'verify-patient-medicaid-coverage',
  VERIFY_EMPLOYEE_CNA_LICENSE = 'verify-employee-cna-license',
  CHARTING_TRAINING = 'charting-training',
  F2F_SCHEDULING = 'f2f-scheduling',
  MEDICATION_INTERACTION = 'Medication Interaction',
  PARTIALLY_APPROVED_HOURS = 'partially-approved-hours'
}

// This descriptions relates to the shape of a task.description
export const TASK_DESCRIPTIONS_BY_TYPE = {
  [TaskType.DOCUMENT_AUDIT]: 'Document Audit',
  [TaskType.SIGNED_DOCUMENT_UPLOAD]: 'Upload Signed Document',
  [TaskType.ASSESSMENT_QA]: 'Assessment QA',
  [TaskType.ASSESSMENT_QA_EDITS]: 'Assessment QA Edits',
  [TaskType.ASSESSMENT_DOCUMENT_UPLOAD]: 'Upload Assessment Document',
  [TaskType.CONTACT_PCP]: 'Contact PCP',
  [TaskType.SCHEDULE_APPOINTMENT_WITH_PCP]: 'Schedule Appointment with PCP',
  [TaskType.DOCUMENT_UPLOAD]: 'Upload Document',
  [TaskType.DOCUMENT_SEND]: 'Send Document',
  [TaskType.CAREGIVER_CALL]: 'Call Caregiver',
  [TaskType.CNA_VISIT]: 'CNA Visit',
  [TaskType.CNA_VISIT_QA]: 'CNA Visit QA',
  [TaskType.PARTIALLY_APPROVED_HOURS]: 'Partially Approved Hours'
}

// This code text relates to the shape of a task.code.text field
// that's a codeable concept and filterable field
// FYI: someones are similar to their descriptions but is not always the case
export const TASK_CODE_TEXT_TYPE = {
  [TaskType.DOCUMENT_AUDIT]: 'Clinical document',
  [TaskType.ASSESSMENT_QA]: 'Nursing care quality assurance procedure',
  [TaskType.COMPLETE_RELIAS_VIDEOS]: 'COMPLETE_RELIAS_VIDEOS_TASK',
  [TaskType.COMPLETE_RIPPLING]: 'COMPLETE_RIPPLING_TASK',
  [TaskType.VERIFY_PATIENT_MEDICAID_COVERAGE]: 'MEDICAID_CHECK_TASK',
  [TaskType.VERIFY_EMPLOYEE_CNA_LICENSE]: 'CNA_LICENSE_CHECK_TASK',
  [TaskType.CHARTING_TRAINING]: 'Charting Training',
  [TaskType.F2F_SCHEDULING]: 'Face-to-face for Start of Care',
  [TaskType.MEDICATION_INTERACTION]: 'Medication Interaction',
  [TaskType.PARTIALLY_APPROVED_HOURS]: 'Partially Approved Hours'
}

export enum FLOW_STAGE_TYPES {
  DRAFT = 'draft',
  PENDING_QA = 'pending-qa',
  PENDING_QA_EDITS = 'pending-qa-edits',
  PENDING_AUDIT = 'pending-audit',
  FAXED = 'faxed',
  UPLOAD_SIGNED_485 = 'upload-signed-485'
}

export enum TaskPriority {
  ROUTINE = 'routine',
  URGENT = 'urgent',
  ASAP = 'asap',
  STAT = 'stat'
}

export const TASK_PRIORITY_WEIGHT = {
  [TaskPriority.ROUTINE]: 1,
  [TaskPriority.URGENT]: 2,
  [TaskPriority.ASAP]: 3,
  [TaskPriority.STAT]: 4
}

export enum CNAVisitTaskStatus {
  COMPLETE = 'complete',
  NOT_COMPLETE = 'not-complete',
  COMPLETED_IN_ANOTHER_VISIT = 'completed-in-another-visit'
}

export const TASK_CNA_VISIT_STATUS_REASON_CODE_SYSTEM = `${ABBYCARE_TERMINOLOGY_CODES_BASE}/task-cna-visit-status-reason`

export enum CNAVisitTaskIncompleteReason {
  VERBAL_REFUSAL = 'verbal-refusal',
  MEDICAL_CONDITION = 'medical-condition',
  ENVIRONMENT_LIMIT = 'environment-limit',
  NOT_APPLICABLE = 'not-applicable'
}

export const CNA_VISIT_TASK_INCOMPLETE_REASONS: Record<CNAVisitTaskIncompleteReason, string> = {
  [CNAVisitTaskIncompleteReason.VERBAL_REFUSAL]: 'Patient verbally refused',
  [CNAVisitTaskIncompleteReason.MEDICAL_CONDITION]: 'Not attempted due to medical condition',
  [CNAVisitTaskIncompleteReason.ENVIRONMENT_LIMIT]: 'Not attempted due to environment limit',
  [CNAVisitTaskIncompleteReason.NOT_APPLICABLE]: 'Not applicable'
}

export enum CNAVisitTaskCompletedInAnotherVisitTime {
  MORNING = 'morning',
  AFTERNOON = 'afternoon',
  EVENING = 'evening'
}

export const CNA_VISIT_TASK_COMPLETED_IN_ANOTHER_VISIT_TIMES: Record<
  CNAVisitTaskCompletedInAnotherVisitTime,
  string
> = {
  [CNAVisitTaskCompletedInAnotherVisitTime.MORNING]: 'Morning',
  [CNAVisitTaskCompletedInAnotherVisitTime.AFTERNOON]: 'Afternoon',
  [CNAVisitTaskCompletedInAnotherVisitTime.EVENING]: 'Evening'
}

export const CNA_VISIT_TASK_STATUS_ALL_REASONS: Record<string, string> = {
  ...CNA_VISIT_TASK_INCOMPLETE_REASONS,
  ...CNA_VISIT_TASK_COMPLETED_IN_ANOTHER_VISIT_TIMES
}

export const TASK_CODE_CODE_SYSTEM = `${ABBYCARE_TERMINOLOGY_CODES_BASE}/task-code`

export enum TaskCode {
  CNA_VISIT_QA = 'cna-visit-qa',
  REMOVE_CAREGIVER_HOLD = 'remove-caregiver-hold'
}

// SNOMED code for nursing care quality assurance procecure
export const SNOMED_NURSE_QA_CODE = '22815002'

export const TASK_CODE_DISPLAY_BY_CODE = {
  [TaskCode.CNA_VISIT_QA]: 'CNA Visit QA',
  [TaskCode.REMOVE_CAREGIVER_HOLD]: 'Remove Caregiver Hold'
}

export const TASK_IDENTIFIER_CODE_SYSTEM = `${ABBYCARE_TERMINOLOGY_CODES_BASE}/task-identifier`

export enum TaskIdentifier {
  CNA_VISIT = 'cna-visit',
  CAREGIVER_TASK = 'caregiver-task'
}

export const TASK_CAREGIVER_TASK_TYPE_CODE_SYSTEM = `${ABBYCARE_TERMINOLOGY_CODES_BASE}/caregiver-task-type`

export enum TaskCaregiverTaskType {
  CAREPLAN_APPROVAL = 'careplan-approval'
}

export const TASK_VISIT_QA_OUTCOME_CODE_SYSTEM = `${ABBYCARE_TERMINOLOGY_CODES_BASE}/visit-qa-outcome`

export enum CNAVisitQAOutcome {
  APPROVED = 'approved',
  DENIED = 'denied'
}

export const QA_VISIT_OUTCOME_DISPLAY_BY_CODE: Record<CNAVisitQAOutcome, string> = {
  [CNAVisitQAOutcome.APPROVED]: 'Approved',
  [CNAVisitQAOutcome.DENIED]: 'Denied'
}

export const TASK_ASSOCIATED_INTERVENTION_EXTENSION_URL = `${ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE}/task-associated-intervention`

export const FLOW_STAGE_CODE_SYSTEM = `${ABBYCARE_TERMINOLOGY_CODES_BASE}/workflow-stage-type`
