import { AssessmentStatus } from 'fhir/QuestionnaireResponse/constants'

export const fuseOptionKeys = [
  'name',
  'source.display',
  'author.display',
  'subject.display',
  'jursidiction.coding.display'
]

export const routeMyAssessments = '/clinical/assessments/my-assessments'
export const routeAllAssessments = '/clinical/assessments/all-assessments'

export enum AssessmentType {
  SOC = 'soc',
  PAT = 'pat',
  RECERT = 'recert',
  REVISION_OF_PAR = 'revision-of-par',
  SUPERVISORY = 'supervisory',
  // DELEGATION = 'delegation',
  // ACUTE = 'acute',
  DISCHARGE = 'discharge',
  ROC = 'roc'
}

export const AssessmentStatusLabel = {
  [AssessmentStatus.IN_PROGRESS]: 'Draft',
  [AssessmentStatus.PENDING_QA]: 'Pending QA',
  [AssessmentStatus.PENDING_QA_EDITS]: 'Pending QA Edits',
  [AssessmentStatus.COMPLETED]: 'Completed'
}

export const statusColorMap = {
  draft: { bg: '#F4F3FF', text: '#5925DC' },
  'pending qa': { bg: '#FFFAEB', text: '#B54708' },
  'pending qa edits': { bg: '#FFFAEB', text: '#B54708' },
  completed: { bg: '#ECFDF3' }
}
