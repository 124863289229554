import { PatchOperation } from '@medplum/core'
import { Observation } from '@medplum/fhirtypes'
import { useMedplum } from '@medplum/react'

interface ObservationRepository {
  createObservation(observation: CreateObservation): Promise<Observation>
  getObservations(query: URLSearchParams): Promise<Observation[]>
  updateObservation(observation: UpdateObservation): Promise<Observation>
}

interface CreateObservation {
  status: Observation['status']
  category: Observation['category']
  code: Observation['code']
  subject: Observation['subject']
  note?: Observation['note']
  valueString?: Observation['valueString']
}

interface UpdateObservation {
  observationId: string
  status?: Observation['status']
}

export function useObservationRepository(): ObservationRepository {
  const medplum = useMedplum()

  async function updateObservation({
    observationId,
    status
  }: UpdateObservation): Promise<Observation> {
    if (!observationId) {
      return Promise.reject('Observation ID is required')
    }
    const operations: PatchOperation[] = []
    if (status) {
      operations.push({
        op: 'add',
        path: '/status',
        value: status
      })
    }
    return await medplum.patchResource('Observation', observationId, operations)
  }

  async function createObservation({
    status,
    category,
    code,
    subject,
    note,
    valueString
  }: CreateObservation): Promise<Observation> {
    return await medplum.createResource({
      resourceType: 'Observation',
      status,
      subject,
      category,
      code,
      note,
      valueString
    })
  }

  async function getObservations(query: URLSearchParams): Promise<Observation[]> {
    return await medplum.searchResources('Observation', query, {
      cache: 'reload'
    })
  }

  return {
    createObservation,
    getObservations,
    updateObservation
  }
}
