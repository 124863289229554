import gql from 'gql-tag'
import {
  MCO_CONTACT_INFORMATION_EXTENSION_URL,
  MCO_POLICY_GROUP_NUMBER_EXTENSION_URL
} from 'fhir/Coverage/constants'

export const BASE_COVERAGE_FRAGMENT = gql`
  fragment BaseCoverage on Coverage {
    id
    subscriberId
    type {
      coding {
        system
        code
        display
      }
    }
    status
    payor {
      reference
      id
    }
    beneficiary {
      reference
    }
    period {
      start
      end
    }
    mcoPolicyGroupNumber: extension(
      url: "${MCO_POLICY_GROUP_NUMBER_EXTENSION_URL}"
    ) {
      valueString
    }
    mcoContactInformation: extension(
      url: "${MCO_CONTACT_INFORMATION_EXTENSION_URL}"
    ) {
      valueString
    }
  }
`
