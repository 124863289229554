import { PatchOperation } from '@medplum/core'
import { Encounter, EncounterHospitalization, Period } from '@medplum/fhirtypes'
import { useMedplum } from '@medplum/react'
import { EncounterStatus } from 'fhir/Encounter/constants'

export interface EncounterRepository {
  updateEncounter(encounter: UpdateEncounter): Promise<Encounter>
  createEncounter(encounter: CreateEncounter): Promise<Encounter>
  getEncounter(id: string): Promise<Encounter>
  getEncounters(query: URLSearchParams): Promise<Encounter[]>
  completeEncounter(encounterId: string): Promise<Encounter>
}
interface UpdateEncounter {
  encounterId: string
  status?: EncounterStatus
}

interface CreateEncounter {
  type: Encounter['type']
  classCode: Encounter['class']
  subject: Encounter['subject']
  admissionDate: Period['start']
  dischargeDate: Period['end']
  reason: Encounter['reasonCode']
  serviceProvider?: Encounter['serviceProvider']
  outcome?: EncounterHospitalization['dischargeDisposition']
}

export function useEncounterRepository(): EncounterRepository {
  const medplum = useMedplum()
  async function updateEncounter({ encounterId, status }: UpdateEncounter): Promise<Encounter> {
    const operations: PatchOperation[] = []

    if (status) {
      operations.push({
        op: 'replace',
        path: '/status',
        value: status
      })
    }
    return await medplum.patchResource('Encounter', encounterId as string, operations)
  }

  async function createEncounter({
    type,
    classCode,
    subject,
    admissionDate,
    dischargeDate,
    reason,
    serviceProvider,
    outcome
  }: CreateEncounter): Promise<Encounter> {
    return await medplum.createResource({
      resourceType: 'Encounter',
      status: 'in-progress',
      class: classCode,
      type,
      period: {
        start: admissionDate,
        end: dischargeDate
      },
      reasonCode: reason,
      serviceProvider,
      hospitalization: outcome ? { dischargeDisposition: outcome } : undefined,
      subject
    })
  }

  async function getEncounters(query: URLSearchParams): Promise<Encounter[]> {
    return await medplum.searchResources('Encounter', query, {
      cache: 'reload'
    })
  }
  async function getEncounter(id: string): Promise<Encounter> {
    return await medplum.readResource('Encounter', id)
  }

  async function completeEncounter(encounterId: string): Promise<Encounter> {
    return await updateEncounter({
      encounterId,
      status: EncounterStatus.FINISHED
    })
  }

  return {
    createEncounter,
    getEncounters,
    getEncounter,
    completeEncounter,
    updateEncounter
  }
}
