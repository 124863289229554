import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import { RootSlice } from './rootType'
import { createEncounterSlice } from './encounter'
import { createPatientSlice } from './patient'
import { createLocationSlice } from './location'
import { createTaskSlice } from './task'
import { createVisitSlice } from './visit'
import { createPractitionerSlice } from './practitioner'
import { createOrderSlice } from './order'
import { createStaticSlice } from './static'
import { createCareTeamSlice } from './careTeam'
import { createChartSlice } from './chart'
import { createAssessmentSlice } from './assessment'
import { createPatientDataSlice } from './patientData'
import { createServiceTypeSlice } from './serviceType'

export const useStore = create<RootSlice>()(
  immer(
    devtools((...a) => ({
      ...createPatientSlice(...a),
      ...createEncounterSlice(...a),
      ...createTaskSlice(...a),
      ...createLocationSlice(...a),
      ...createPractitionerSlice(...a),
      ...createVisitSlice(...a),
      ...createOrderSlice(...a),
      ...createStaticSlice(...a),
      ...createCareTeamSlice(...a),
      ...createChartSlice(...a),
      ...createAssessmentSlice(...a),
      ...createPatientDataSlice(...a),
      ...createServiceTypeSlice(...a)
    }))
  )
)
