import {
  BaseOrganizationFragment,
  UseOrganizationListQuery,
  UseOrganizationListQueryVariables
} from 'generated/graphql'
import { GET_ORGANIZATION_LIST } from './useOrganizationList.query'
import { useQuery } from './useQuery'

export interface OrganizationList {
  orgList: (BaseOrganizationFragment | null)[] | null | undefined
  orgListIsLoading: boolean
  orgListError: string | null
}

const useOrganizationList = (): OrganizationList => {
  const {
    data: orgListData,
    isLoading: orgListIsLoading,
    error: orgListError
  } = useQuery<UseOrganizationListQuery, UseOrganizationListQueryVariables>({
    query: GET_ORGANIZATION_LIST
  })

  const orgList = orgListData && orgListData.OrganizationList

  return {
    orgList,
    orgListIsLoading,
    orgListError
  }
}

export default useOrganizationList
