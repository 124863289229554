import { useFlags } from 'launchdarkly-react-client-sdk'

export enum FeatureFlagKey {
  AbbyAidOnboarding = 'abbyaid_onboarding',
  AbbyosVisitBilling = 'abbyos_visit_billing',
  DebugLogs = 'debug-logs',
  livePatientRecord = 'live-patient-record',
  Flows = 'flows',
  Shifts = 'shifts'
}

export type FeatureFlagName = keyof FeatureFlags

export interface FeatureFlags {
  abbyaidOnboarding: boolean
  abbyosVisitBilling: boolean
  debugLogs: boolean
  livePatientRecord: boolean
  flows: boolean
  shifts: boolean
}

const useFeatureFlags = (): FeatureFlags => {
  const flags = useFlags()

  const featureFlags = {
    abbyaidOnboarding: flags[FeatureFlagKey.AbbyAidOnboarding],
    abbyosVisitBilling: flags[FeatureFlagKey.AbbyosVisitBilling],
    debugLogs: flags[FeatureFlagKey.DebugLogs],
    livePatientRecord: flags[FeatureFlagKey.livePatientRecord],
    flows: flags[FeatureFlagKey.Flows],
    shifts: flags[FeatureFlagKey.Shifts]
  }

  return featureFlags
}

export default useFeatureFlags
