import gql from 'gql-tag'

export const BASE_APPOINTMENT_FRAGMENT = gql`
  fragment BaseAppointment on Appointment {
    id
    start
    end
    status
    participant {
      actor {
        reference
        display
      }
    }
    appointmentType {
      coding {
        system
        code
        display
      }
    }
    extension {
      url
      valueCode
    }
  }
`
