export class UserError extends Error {
  public readonly errorCode?: number

  constructor(message: string, cause?: string, errorCode?: number) {
    super(message, { cause }) // Call the parent class (Error) constructor
    this.name = 'UserError' // Custom name for the error type
    this.errorCode = errorCode // Additional property
    Object.setPrototypeOf(this, new.target.prototype) // Restore prototype chain
  }
}
