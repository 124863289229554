import { ClinicalNoteTypes, GeneralNoteTypes } from 'fhir/Communication/constants'

export const CLINICAL_NOTE_TYPE_MAP: Record<
  | ClinicalNoteTypes.HOSPITALIZATION
  | ClinicalNoteTypes.MEDICAL_PROCEDURE
  | ClinicalNoteTypes.INFECTION
  | ClinicalNoteTypes.INJURY
  | ClinicalNoteTypes.FALL
  | ClinicalNoteTypes.ER_VISIT
  | ClinicalNoteTypes.CARE_COORDINATION,
  { label: string }
> = {
  [ClinicalNoteTypes.HOSPITALIZATION]: { label: 'Hospitalization' },
  [ClinicalNoteTypes.MEDICAL_PROCEDURE]: { label: 'Medical Procedure' },
  [ClinicalNoteTypes.INFECTION]: { label: 'Infection' },
  [ClinicalNoteTypes.INJURY]: { label: 'Injury' },
  [ClinicalNoteTypes.FALL]: { label: 'Fall' },
  [ClinicalNoteTypes.ER_VISIT]: { label: 'ER/Urgent Care Visit' },
  [ClinicalNoteTypes.CARE_COORDINATION]: { label: 'Care Coordination' }
}

export const GENERAL_NOTE_TYPE_MAP: Record<
  GeneralNoteTypes.GENERAL | GeneralNoteTypes.GRIEVANCE_COMPLAINT,
  { label: string }
> = {
  [GeneralNoteTypes.GENERAL]: { label: 'General' },
  [GeneralNoteTypes.GRIEVANCE_COMPLAINT]: { label: 'Grievance Complaint' }
}

export const PATIENT_CLIENT_NOTE_TYPE_MAP: Record<
  | ClinicalNoteTypes.HOSPITALIZATION
  | ClinicalNoteTypes.MEDICAL_PROCEDURE
  | ClinicalNoteTypes.INFECTION
  | ClinicalNoteTypes.INJURY
  | ClinicalNoteTypes.FALL
  | ClinicalNoteTypes.ER_VISIT
  | ClinicalNoteTypes.CARE_COORDINATION
  | GeneralNoteTypes.GENERAL
  | GeneralNoteTypes.GRIEVANCE_COMPLAINT,
  { label: string }
> = { ...CLINICAL_NOTE_TYPE_MAP, ...GENERAL_NOTE_TYPE_MAP }

export const EMPLOYEE_NOTE_TYPE_MAP: Record<
  ClinicalNoteTypes.COVID | ClinicalNoteTypes.FLU,
  { label: string }
> = {
  [ClinicalNoteTypes.COVID]: { label: 'COVID' },
  [ClinicalNoteTypes.FLU]: { label: 'Flu' }
}

export const ALL_NOTE_TYPE_MAP = { ...PATIENT_CLIENT_NOTE_TYPE_MAP, ...EMPLOYEE_NOTE_TYPE_MAP }

export enum NoteStatuses {
  ACTIVE = 'active',
  CLOSED = 'closed'
}

export type NoteStatus = `${NoteStatuses}`

export const NOTE_STATUS_MAP = {
  ['in-progress']: NoteStatuses.ACTIVE,
  ['completed']: NoteStatuses.CLOSED
}

export enum NoteType {
  ALL = 'all',
  CLINICAL = 'clinical',
  GENERAL = 'general'
}
