import dayjs, { Dayjs } from 'dayjs'
import { BaseConsentFragment, BaseEpisodeOfCareFragment } from 'generated/graphql'
import { isTimePeriodInEffect } from 'utils/dates'
import { EpisodeOfCareStatusEnum } from './constants'

export type BaseEpisodeOfCareWithConsentFragment = BaseEpisodeOfCareFragment & {
  consent?: BaseConsentFragment
}

// TODO (Jason): add consent to this to make sure cert period has been consented to
export const isCertPeriodActive = (
  episodeOfCare: BaseEpisodeOfCareFragment | null | undefined
): boolean => {
  if (!episodeOfCare) return false

  const statusFromHistory = getEpisodeOfCareStatusFromStatusHistory(episodeOfCare)
  const statusFromHistoryIsNotInactive =
    !statusFromHistory || statusFromHistory === EpisodeOfCareStatusEnum.ACTIVE

  return episodeOfCare.status === EpisodeOfCareStatusEnum.ACTIVE && statusFromHistoryIsNotInactive
}

export const getEpisodeOfCareStatusFromStatusHistory = (
  certPeriodResource: BaseEpisodeOfCareFragment,
  timezone?: string
): EpisodeOfCareStatusEnum => {
  const statusHistoryEntries = certPeriodResource.statusHistory
  const certPeriodStatus = certPeriodResource.status as EpisodeOfCareStatusEnum
  if (!statusHistoryEntries || statusHistoryEntries.length === 0) return certPeriodStatus

  const statusHistoryInProgress = statusHistoryEntries.find((entry) => {
    const { start, end } = entry.period

    const isCurrentlyActive = isTimePeriodInEffect(timezone, start, end)

    return isCurrentlyActive
  })

  return (statusHistoryInProgress?.status as EpisodeOfCareStatusEnum) ?? certPeriodStatus
}

export const sortCertPeriods = (
  a: BaseEpisodeOfCareWithConsentFragment,
  b: BaseEpisodeOfCareWithConsentFragment
): number => {
  const aStart = a.period?.start
  const bStart = b.period?.start
  if (!bStart) return -1
  if (!aStart) return 1

  return dayjs(aStart).diff(dayjs(bStart))
}

// A helper funcion that iterates through a list of certification periods and returns the period that contains the given date
export const findCertPeriodForDate = (
  periods: (BaseEpisodeOfCareWithConsentFragment | null)[] | null | undefined,
  date: Dayjs
): BaseEpisodeOfCareWithConsentFragment | null => {
  if (!periods) return null
  return (
    periods.find((period) => {
      if (!period) return false
      const periodStart = period.period?.start ? dayjs.utc(period.period.start) : null
      const periodEnd = period.period?.end ? dayjs.utc(period.period.end) : null
      return periodStart && periodEnd
        ? date.utc().isBetween(periodStart, periodEnd, 'day', '[]')
        : false
    }) ?? null
  )
}
