import React, { createContext, type RefObject, useState } from 'react'

interface ScrollContextProps {
  scrollContainerRef: RefObject<HTMLDivElement> | null
  stickySubheaders: Array<RefObject<HTMLElement>>
  setStickySubheaders: React.Dispatch<React.SetStateAction<Array<RefObject<HTMLElement>>>>
}

const ScrollContext = createContext<ScrollContextProps>({
  scrollContainerRef: null,
  stickySubheaders: [],
  setStickySubheaders: () => {}
})

type ScrollProviderProps = Pick<ScrollContextProps, 'scrollContainerRef'> & {
  children: React.ReactNode
}

export const ScrollProvider: React.FC<ScrollProviderProps> = ({ scrollContainerRef, children }) => {
  const [stickySubheaders, setStickySubheaders] = useState<Array<RefObject<HTMLElement>>>([])

  return (
    <ScrollContext.Provider value={{ scrollContainerRef, stickySubheaders, setStickySubheaders }}>
      {children}
    </ScrollContext.Provider>
  )
}

export default ScrollContext
