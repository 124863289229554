// ————————————————————————————————————————————————————————————————————————————————
//                              FHIR Base Definitions
// ————————————————————————————————————————————————————————————————————————————————
//
// FHIR base definitions are defined here for certain code systems and extension structure
// definition bases.
//
// Generally the structure for all systems / extension URLs should be:
// <RESOURCE_TYPE>_<EXTENSION_VALUE>_<EXTENSION|SYSTEM>
// e.g. PATIENT_BIRTHSEX_EXTENSION

// Example of a full URL:
// https://fhir.loinc.org/CodeSystem/$lookup?system=http://loinc.org&code=21176-3
// Another useful link: https://loinc.org/LL5251-5
export const LOINC_CODE_SYSTEM = `https://fhir.loinc.org/CodeSystem`

export const FHIR_DEFINITION_BASE = `http://hl7.org/fhir`

export const FHIR_STRUCTURE_DEFINITION_BASE = `${FHIR_DEFINITION_BASE}/StructureDefinition`
export const FHIR_US_CORE_STRUCTURE_DEFINITION_BASE = `${FHIR_DEFINITION_BASE}/us/core/StructureDefinition`
export const HL7_TERMINOLOGY_CODES_BASE = `http://terminology.hl7.org/CodeSystem`

export const FHIR_TIMEZONE_URL = `${FHIR_STRUCTURE_DEFINITION_BASE}/timezone`

// https://build.fhir.org/structuredefinition.html#structuredefinition
export const ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE = `https://abbycare.org/fhir/StructureDefinition`
export const ABBYCARE_TERMINOLOGY_CODES_BASE = `https://terminology.abbycare.org/CodeSystem`
export const ABBYCARE_TERMINOLOGY_IDENTIFIERS_BASE = 'https://terminology.abbycare.org/Identifiers'

export const ETHNICITY_OPTIONS = [
  'White',
  'Native Hawaiian or Other Pacific Islander',
  'Hispanic or Latino',
  'Black or African American',
  'Asian',
  'American Indian or Alaska Native'
]

export const HL7_TERMINOLOGY_CODES_LICENSES = HL7_TERMINOLOGY_CODES_BASE + '/v2-0360'

export const LICENSE_OPTIONS = [
  { value: 'DO', formValue: 'DO/Doctor of Osteopathy', label: 'Doctor of Osteopathy' },
  { value: 'MD', formValue: 'MD/Doctor of Medicine', label: 'Doctor of Medicine' },
  { value: 'RN', formValue: 'RN/Registered Nurse', label: 'Registered Nurse' },
  { value: 'NP', formValue: 'NP/Nurse Practitioner', label: 'Nurse Practitioner' },
  { value: 'CNS', formValue: 'CNS/Clinical Nurse Specialist', label: 'Clinical Nurse Specialist' },
  { value: 'PA', formValue: 'PA/Physician Assistant', label: 'Physician Assistant' },
  {
    value: 'CNA',
    formValue: 'CNA/Certified Nursing Assistant',
    label: 'Certified Nursing Assistant'
  },
  { value: 'HHA', formValue: 'HHA/Home Health Aide', label: 'Home Health Aide' }
]

export const TIMEZONE_URL = 'https://www.iana.org/time-zones'
export const TIMEZONE_OPTIONS = [
  {
    label: 'America/New York',
    value: 'America/New_York'
  },
  {
    label: 'America/Chicago',
    value: 'America/Chicago'
  },
  {
    label: 'America/Denver',
    value: 'America/Denver'
  },
  {
    label: 'America/Los Angeles',
    value: 'America/Los_Angeles'
  }
]
