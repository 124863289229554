import gql from 'gql-tag'

export const BASE_QUESTIONNAIRE_FRAGMENT = gql`
  fragment BaseQuestionnaire on Questionnaire {
    name
    identifier {
      system
      value
    }
  }
`

// ————————————————————————————————————————————————————————————————————————————————
// Nested types used for helper functions
// ————————————————————————————————————————————————————————————————————————————————

export const BASE_QUESTIONNAIRE_WITH_RESPONSE_LIST_FRAGMENT = gql`
  fragment BaseQuestionnaireWithResponseList on Questionnaire {
    ...BaseQuestionnaire
    QuestionnaireResponseList(_reference: questionnaire) {
      ...BaseQuestionnaireResponse
    }
  }
`
