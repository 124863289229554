import { OrganizationFragment, PatientFragment, PractitionerFragment } from 'generated/graphql'

export const isPractitionerFragment = (obj: any): obj is PractitionerFragment => {
  return !!obj && 'PractitionerRoleList' in obj
}

export const isOrganizationResource = (obj: any): obj is OrganizationFragment => {
  return obj && (obj.name !== undefined || obj.id !== undefined)
}

export const isPatientFragment = (obj: any): obj is PatientFragment => {
  return !!obj && 'id' in obj
}
