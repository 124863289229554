import { StateCreator } from 'zustand'
import { UsePatientCareTeam_CareTeamFragment, UsePatientCareTeamQuery } from 'generated/graphql'
import { RootSlice } from './rootType'

export interface CareTeamSlice {
  careTeam: {
    byPatientId: Record<string, UsePatientCareTeam_CareTeamFragment[]>
    updateCareTeamWithPatientId: (patientId: string, data: UsePatientCareTeamQuery) => void
  }
}

const defaultState: Pick<CareTeamSlice['careTeam'], 'byPatientId'> = {
  byPatientId: {}
}

export const createCareTeamSlice: StateCreator<
  RootSlice,
  [['zustand/devtools', never], ['zustand/immer', never]],
  [],
  CareTeamSlice
> = (set) => ({
  careTeam: {
    ...defaultState,
    updateCareTeamWithPatientId: (patientId: string, data: UsePatientCareTeamQuery) => {
      set((state) => {
        const careTeamList =
          data?.CareTeamList?.filter((c): c is UsePatientCareTeam_CareTeamFragment => !!c) ?? []

        state.careTeam.byPatientId[patientId] = careTeamList
      })
    }
  }
})
