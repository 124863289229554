import {
  ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE,
  ABBYCARE_TERMINOLOGY_CODES_BASE
} from 'fhir/constants'

export const CERT_PERIOD_DAYS = 60

export const EPISODE_OF_CARE_TYPE_CODE_SYSTEM_URL = `${ABBYCARE_TERMINOLOGY_CODES_BASE}/episode-of-care-type`

export enum EpisodeOfCareTypeEnum {
  CERT_PERIOD_SKILLED_CARE = 'cert-period-skilled-care'
}

export enum EpisodeOfCareStatusEnum {
  PLANNED = 'planned',
  WAITLIST = 'waitlist',
  ACTIVE = 'active',
  ON_HOLD = 'onhold',
  FINISHED = 'finished',
  CANCELLED = 'cancelled',
  ENTERED_IN_ERROR = 'entered-in-error'
}

export const EPISODE_OF_CARE_QUALIFIED_HOURS_EXTENSION_URL = `${ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE}/episode-of-care-qualified-hours`

export const EPISODE_OF_CARE_STATUS_REASON_EXTENSION_URL = `${ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE}/episode-of-care-status-reason`
