import {
  ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE,
  FHIR_DEFINITION_BASE,
  FHIR_STRUCTURE_DEFINITION_BASE,
  HL7_TERMINOLOGY_CODES_BASE
} from 'fhir/constants'

export const PATIENT_BIRTH_SEX_EXTENSION = `${FHIR_STRUCTURE_DEFINITION_BASE}/patient-birthsex`
export const PATIENT_ETHNICITY_EXTENSION = `${FHIR_STRUCTURE_DEFINITION_BASE}/patient-ethnicity`
export const PATIENT_ETHNICITY_TYPE_CODE_SYSTEM = `${HL7_TERMINOLOGY_CODES_BASE}/v3-Ethnicity`
export const PATIENT_RELIGION_EXTENSION = `${FHIR_STRUCTURE_DEFINITION_BASE}/patient-religion`
export const PATIENT_RELIGION_TYPE_CODE_SYSTEM = `${HL7_TERMINOLOGY_CODES_BASE}/v3-ReligiousAffiliation`
export const PATIENT_SSN_SYSTEM = `${FHIR_DEFINITION_BASE}/sid/us-ssn`
export const PATIENT_IDENTIFIER_TYPE_CODE_SYSTEM = `${HL7_TERMINOLOGY_CODES_BASE}/v2-0203`
export const PATIENT_ADMINISTRATIVE_GENDER_SYSTEM = `${FHIR_DEFINITION_BASE}/administrative-gender`
export const PATIENT_SOC_DATE_EXTENSION = `${ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE}/soc-date`
export const PATIENT_F2F_DATE_EXTENSION = `${ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE}/f2f-date`
export const PATIENT_SERVICE_TYPE_EXTENSION = `${ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE}/service-type`
export const PATIENT_ADMISSION_STATUS_EXTENSION = `${ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE}/admission-status`

export const PRIMARY_LANGUAGE_OPTIONS = [
  'English',
  'American Sign Language (ASL)',
  'Spanish',
  'Vietnamese',
  'Chinese (Mandarin)',
  'Chinese (Cantonese)',
  'Korean',
  'Arabic',
  'Russian',
  'German',
  'French',
  'Italian',
  'Japanese',
  'Tagalog',
  'Amharic',
  'Somali',
  'Nepali',
  'Hindi',
  'Gujarati',
  'Bengali',
  'French Creole',
  'Polish',
  'Romanian',
  'Ukranian',
  'Navajo',
  'Apache',
  'Lakota',
  'Cheyenne',
  'Arapaho',
  'Ute',
  'Comanche',
  'Other'
]

export const PATIENT_STATUS_OPTIONS = [
  'Active',
  'On Hold',
  'Re-Admit',
  'Inactive',
  'Discharge',
  'Discharge - Do not re-admit'
]

export enum TelecomSystem {
  EMAIL = 'email',
  FAX = 'fax'
}
