import { Input } from 'antd'
import { Rule } from 'antd/es/form'
import { InputLabel } from 'components/Common/Input/InputLabel'
import { FormItem } from 'components/Common/FormItem/FormItem'

const NoteFormItem = (fieldData: Partial<Record<string, any>>, key?: string): JSX.Element => {
  const {
    component: componentFromMetadata,
    label,
    labelLeftAlign,
    name,
    required = true,
    optional = false,
    horizontal = false,
    ...metadata
  } = fieldData ?? {}

  const Component = componentFromMetadata || Input

  const rules: Rule[] = []

  if (required) {
    rules.push({
      required: true,
      message: 'Field required'
    })
  }
  if (componentFromMetadata === Input.TextArea) {
    rules.push({
      message: 'Please remove whitespace',
      whitespace: true
    })
  }
  return (
    <InputLabel
      key={key}
      label={label}
      optional={optional}
      labelLeftAlign={labelLeftAlign}
      styles={{ margin: '10px 0', width: horizontal ? '50%' : 'auto' }}
    >
      <FormItem name={name} rules={rules} style={{ margin: 0 }} required={required}>
        <Component style={{ width: '100%' }} {...metadata} />
      </FormItem>
    </InputLabel>
  )
}

export default NoteFormItem
