import gql from 'gql-tag'

export const BASE_CARETEAM_PARTICIPANT_FRAGMENT = gql`
  fragment BaseCareTeamParticipant on CareTeamParticipant {
    role {
      text
      coding {
        system
        code
        display
      }
    }
    member {
      id
      identifier {
        id
      }
      display
      reference
    }
  }
`

// ————————————————————————————————————————————————————————————————————————————————
// Nested types used for helper functions
// ————————————————————————————————————————————————————————————————————————————————

export const BASE_CARETEAM_PARTICIPANT_WITH_PRACTITIONER_FRAGMENT = gql`
  fragment BaseCareTeamParticipantWithPractitioner on CareTeamParticipant {
    ...BaseCareTeamParticipant
    member {
      resource {
        ...BasePractitioner
      }
    }
  }
`
