import gql from 'gql-tag'
import { AppointmentType } from 'fhir/Appointment/constants'
import { TaskCode } from 'fhir/Task/constants'
import { BASE_APPOINTMENT_FRAGMENT } from 'fragments/appointment.query'
import { BASE_ENCOUNTER_FRAGMENT } from 'fragments/encounter.query'
import { BASE_PATIENT_FRAGMENT } from 'fragments/patient.query'
import { BASE_PRACTITIONER_FRAGMENT } from 'fragments/practitioner.query'
import { BASE_TASK_FRAGMENT } from 'fragments/task.query'

export const GET_PATIENT_VISITS_VISIT_FRAGMENT = gql`
  fragment GetPatientVisits_Visit on Encounter {
    ...BaseEncounter
    visitQATasks: TaskList(_reference: focus, code: "${TaskCode.CNA_VISIT_QA}", _count: 1000, _sort: "-_lastUpdated") {
      ...BaseTask
    }
  }
`

export const GET_PATIENT_VISITS = gql`
  query GetPatientVisits($patientRef: String, $dateFilter: String, $status: String, $count: Int, $offset: Int) {
    AppointmentList(appointment_type: "${AppointmentType.CNA_VISIT}", patient: $patientRef, status: $status, _filter: $dateFilter, _count: $count, _offset: $offset, _sort: "-_lastUpdated") {
      ...BaseAppointment
      EncounterList(_reference: appointment) {
        ...GetPatientVisits_Visit
      }
    }
  }
  ${GET_PATIENT_VISITS_VISIT_FRAGMENT}
  ${BASE_ENCOUNTER_FRAGMENT}
  ${BASE_APPOINTMENT_FRAGMENT}
  ${BASE_PRACTITIONER_FRAGMENT}
  ${BASE_PATIENT_FRAGMENT}
  ${BASE_TASK_FRAGMENT}
`
