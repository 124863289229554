import gql from 'gql-tag'
import {
  PRACTITIONER_ROLE_PAY_RATE_EXTENSION,
  PRACTITIONER_ROLE_SUPERVISOR_PRACTITIONER_EXTENSION
} from 'fhir/PractitionerRole/constants'

export const BASE_PRACTITIONER_ROLE_FRAGMENT = gql`
  fragment BasePractitionerRole on PractitionerRole {
    resourceType
    id
    active
    period {
      start
      end
    }
    healthcareService {
      id
      display
    }
    telecom {
      system
      value
    }
    supervisorExtension: extension(
      url: "${PRACTITIONER_ROLE_SUPERVISOR_PRACTITIONER_EXTENSION}"
    ) {
      valueReference {
        reference
      }
    }
    payRateExtension: extension(url: "${PRACTITIONER_ROLE_PAY_RATE_EXTENSION}") {
      valueString
    }
    organization {
      display
      reference
      identifier {
        id
      }
      resource {
        ...BaseOrganization
      }
      id
    }
  }
`

// ————————————————————————————————————————————————————————————————————————————————
// Nested types used for helper functions
// ————————————————————————————————————————————————————————————————————————————————

export const BASE_PRACTITIONER_ROLE_WITH_ORGANIZATION_FRAGMENT = gql`
  fragment BasePractitionerRoleWithOrganization on PractitionerRole {
    ...BasePractitionerRole
    organization {
      resource {
        ...BaseOrganization
      }
    }
  }
`
