import gql from 'gql-tag'

export const BASE_CONDITION_FRAGMENT = gql`
  fragment BaseCondition on Condition {
    id
    code {
      coding {
        system
        code
        display
      }
    }
    clinicalStatus {
      coding {
        system
        code
      }
    }
    identifier {
      value
      system
    }
  }
`
