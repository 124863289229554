import gql from 'gql-tag'
import {
  CONSENT_CARE_HOURS_EXTENSION_URL,
  ConsentStatus,
  ConsentTypeCode
} from 'fhir/Consent/constants'

export const BASE_CONSENT_FRAGMENT = gql`
  fragment BaseConsent on Consent {
    id
    status
    scope {
      coding {
        system
        code
      }
    }
    category {
      coding {
        system
        code
        display
      }
    }
    patient {
      reference
      display
    }
    performer {
      reference
      display
    }
    provision {
      period {
        start
        end
      }
      actor {
        role {
          coding {
            system
            code
          }
        }
        reference {
          reference
          display
        }
      }
      nestedProvision: provision {
        type
        period {
          start
          end
        }
        action {
          coding {
            system
            code
            display
          }
        }
      }
    }
    careHours: extension(
      url: "${CONSENT_CARE_HOURS_EXTENSION_URL}"
    ) {
      url
      valueDecimal
    }
    extension {
      url
      valueReference {
        reference
      }
    }
  }
`

export const GET_PATIENT_AUTHORIZATIONS = gql`
  query GetPatientAuthorizations($patientRef: String) {
    ConsentList(
      patient: $patientRef,
      category: "${ConsentTypeCode.AUTHORIZATION}",
      status: "${ConsentStatus.ACTIVE}",
      _sort: "-_lastUpdated"
    ) {
      ...BaseConsent
    }
  }
  ${BASE_CONSENT_FRAGMENT}
`
