import { Annotation, CodeableConcept, Communication } from '@medplum/fhirtypes'
import { Dayjs } from 'dayjs'
import { useCallback } from 'react'
import { startCase } from 'lodash'
import {
  COMMUNICATION_CATEGORY_CODE_SYSTEM,
  COMMUNICATION_NOTE_CATEGORY_CODE_SYSTEM,
  COMMUNICATION_NOTE_REPORTER_CATEGORY_CODE_SYSTEM,
  CommunicationCategory,
  CommunicationNoteCategory,
  NoteReporterCategory
} from 'fhir/Communication/constants'
import { createCodeableConcept } from 'fhir/utils'
import { useCommunicationRepository } from './useCommunicationRepository'

export interface CreateNoteParams {
  recipient: Communication['recipient']
  sender?: Communication['sender']
  subject: Communication['subject']
  outcome?: Communication['statusReason']
  reason?: Communication['reasonCode']
  extension?: Communication['extension']
  noteType: CodeableConcept
  encounter?: Communication['encounter']
  reasonReference?: Communication['reasonReference']
  identifier?: Communication['identifier']
  comment?: Annotation
  category: CommunicationNoteCategory
}

interface AddNoteParams {
  communicationId: string
  noteText: string
  hasExistingNotes: boolean
  author: Communication['sender']
}

interface CommunicationService {
  createNote(note: CreateNoteParams): Promise<Communication>
  resolveNoteStatus(communicationId: string): Promise<Communication>
  addCommentToNote(note: AddNoteParams): Promise<Communication>
  getAllPatientNotes(
    patientId: string,
    period?: { start: Dayjs; end: Dayjs }
  ): Promise<Communication[]>
  getClinicalPatientNotes(patientId: string): Promise<Communication[]>
}

export const useCommunicationService = (): CommunicationService => {
  const { createCommunication, updateCommunication, getCommunications } =
    useCommunicationRepository()

  const createNote = useCallback(
    async ({
      sender,
      subject,
      recipient,
      outcome,
      reason,
      comment,
      extension,
      noteType,
      encounter,
      identifier,
      reasonReference,
      category
    }: CreateNoteParams): Promise<Communication> => {
      const categoryCodes = [
        createCodeableConcept({
          system: COMMUNICATION_CATEGORY_CODE_SYSTEM,
          code: CommunicationCategory.NOTE,
          text: startCase(CommunicationCategory.NOTE)
        }),
        createCodeableConcept({
          system: COMMUNICATION_NOTE_REPORTER_CATEGORY_CODE_SYSTEM,
          code: NoteReporterCategory.CLINICAL_TEAM,
          text: 'Clinical Team'
        }),
        createCodeableConcept({
          code: category,
          text: startCase(category),
          system: COMMUNICATION_NOTE_CATEGORY_CODE_SYSTEM
        }),
        noteType
      ]

      return await createCommunication({
        recipient,
        sender,
        statusReason: outcome,
        reasonCode: reason,
        note: comment ?? undefined,
        subject,
        topic: noteType,
        category: categoryCodes,
        encounter,
        identifier,
        reasonReference,
        extension
      })
    },
    [createCommunication]
  )

  const resolveNoteStatus = useCallback(
    async (communicationId: string): Promise<Communication> => {
      return await updateCommunication({
        communicationId,
        status: 'completed'
      })
    },
    [updateCommunication]
  )

  const addCommentToNote = useCallback(
    async ({ communicationId, noteText, author, hasExistingNotes }): Promise<Communication> => {
      return await updateCommunication({
        communicationId,
        hasExistingNotes,
        note: { text: noteText, authorReference: author, time: new Date().toISOString() }
      })
    },
    [updateCommunication]
  )

  const getAllPatientNotes = useCallback(
    async (patientId: string, period?: { start: Dayjs; end: Dayjs }): Promise<Communication[]> => {
      return await getCommunications(
        new URLSearchParams({
          subject: `Patient/${patientId}`,
          category: `${COMMUNICATION_CATEGORY_CODE_SYSTEM}|${CommunicationCategory.NOTE}`,
          _count: '1000',
          _sort: '-_lastUpdated',
          ...(period
            ? {
                _filter: `sent ge '${period.start.toISOString()}' and sent le '${period.end.toISOString()}'`
              }
            : {})
        })
      )
    },
    [getCommunications]
  )

  const getClinicalPatientNotes = useCallback(
    async (patientId: string): Promise<Communication[]> => {
      return await getCommunications(
        new URLSearchParams({
          subject: `Patient/${patientId}`,
          category: `${COMMUNICATION_NOTE_CATEGORY_CODE_SYSTEM}|${CommunicationNoteCategory.CLINICAL}`,
          _count: '1000',
          _sort: '-_lastUpdated'
        })
      )
    },
    [getCommunications]
  )

  return {
    createNote,
    resolveNoteStatus,
    addCommentToNote,
    getAllPatientNotes,
    getClinicalPatientNotes
  }
}
