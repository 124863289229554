import { createContext, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import {
  QuestionnaireResponse,
  QuestionnaireResponseItem,
  QuestionnaireResponseItemAnswer
} from '@medplum/fhirtypes'
import { extractAnswers, updateObjectByLinkId } from 'components/Assessments/helpers'
import { removeNullValues } from 'utils/helpers'
import { rebuildResponseFromFlat } from 'components/Patients/Assessments/CreateAssessment/helpers'
import { SocAssessmentQuestionnaireItem } from 'components/Assessments/SocAssessment/types/questionnaire'
import { AssessmentStatus } from 'fhir/QuestionnaireResponse/constants'
import { AssessmentType } from 'components/Clinical/Assessments/constants'
import useFeatureFlags from 'hooks/useFeatureFlags'
import AssessmentLayoutContext from './AssessmentLayoutContext'
import { useQAAssessmentContext } from './QAAssessmentContext'
import AccessPolicyContext from './AccessPolicyContext'

export interface LinkIdWithItem {
  linkId: string
  item: QuestionnaireResponseItem
}

interface AssessmentContextProps {
  children: React.ReactNode
  assessmentId: string
  assessmentType: string
  assessmentStatus: string
  initialSavedResponse: QuestionnaireResponse
  initialFlatAnswers: Record<string, QuestionnaireResponseItem>
}

interface AssessmentContextType {
  assessmentId: string | null
  assessmentType: AssessmentType | null
  assessmentStatus: string | null
  cantEditAssessmentFields: boolean
  response: QuestionnaireResponse | null
  setResponse: React.Dispatch<React.SetStateAction<QuestionnaireResponse | null>>
  answers: Record<string, QuestionnaireResponseItemAnswer>
  setResponseItems: (newResponseItems: QuestionnaireResponseItem[]) => void
  flatAnswers: Record<string, QuestionnaireResponseItem>
  updateFlatAnswers: (...linkIdsWithAnswers: LinkIdWithItem[]) => void
}

const AssessmentContext = createContext<AssessmentContextType>({
  assessmentId: null,
  assessmentType: null,
  assessmentStatus: null,
  cantEditAssessmentFields: false,
  response: null, // This will hold the entire QuestionnaireResponse state
  setResponse: () => {}, // Function to update the response
  answers: {}, // This will hold the questionnaire answers
  setResponseItems: () => {}, // Function that grabs each new item and updates both response and answers accordingly
  flatAnswers: {}, // Structured as linkId: answer for constant-time update
  updateFlatAnswers: () => {} // Updates the flatAnswers structure
})

export const useAssessmentContext = (): AssessmentContextType => {
  return useContext(AssessmentContext)
}

export const AssessmentContextProvider: FC<AssessmentContextProps> = ({
  children,
  assessmentId,
  assessmentType,
  assessmentStatus,
  initialSavedResponse,
  initialFlatAnswers
}: {
  children: React.ReactNode
  assessmentId: string
  assessmentType: AssessmentType
  assessmentStatus: AssessmentStatus
  initialSavedResponse: QuestionnaireResponse
  initialFlatAnswers: Record<string, QuestionnaireResponseItem>
}) => {
  const { canReadWrite } = useContext(AccessPolicyContext)
  const { canWrite } = canReadWrite('QuestionnaireResponse')

  const { debugLogs } = useFeatureFlags()

  const {
    assessmentQAStatus: { inQA }
  } = useQAAssessmentContext()

  const { hasRoleToEdit } = useContext(AssessmentLayoutContext)

  const cantEditAssessmentFields =
    !canWrite || inQA || !hasRoleToEdit || assessmentStatus === AssessmentStatus.COMPLETED

  // When response is updated it will trigger an autosave in the Assessment specific controller (i.e. PATAssessmentController / SOCAssessmentController)
  const [response, setResponse] = useState<QuestionnaireResponse | null>(initialSavedResponse)

  const [flatAnswers, setFlatAnswers] =
    useState<Record<SocAssessmentQuestionnaireItem, QuestionnaireResponseItem>>(initialFlatAnswers)

  const answers = useMemo(() => {
    return removeNullValues(extractAnswers(response ?? undefined)) as Record<
      string,
      QuestionnaireResponseItemAnswer
    >
  }, [response])

  const setResponseItems = useCallback(
    (newResponseItems: QuestionnaireResponseItem[]): void => {
      if (cantEditAssessmentFields) return

      setResponse((prevResponse: QuestionnaireResponse) => {
        const updatedResponse = newResponseItems.reduce(
          (acc, item) => {
            if (acc.item && typeof item.linkId === 'string') {
              const updatedItem = updateObjectByLinkId(acc.item, item.linkId, item)
              return { ...acc, item: updatedItem }
            }
            return acc
          },
          {
            ...prevResponse,
            resourceType: 'QuestionnaireResponse' as const // to workaround the janky fhir type system
          }
        )

        return updatedResponse
      })
    },
    [cantEditAssessmentFields]
  )

  // SOC version
  const updateFlatAnswers = useCallback(
    (...linkIdsWithItems: LinkIdWithItem[]) => {
      if (cantEditAssessmentFields || linkIdsWithItems.length === 0) return

      if (debugLogs) {
        console.warn(
          '[AssessmentContext] Updating flat answers with link IDs with items',
          linkIdsWithItems
        )
      }

      const valuesByLinkId = Object.fromEntries(
        linkIdsWithItems.map(({ linkId, item }) => [linkId, item])
      )

      setFlatAnswers((prevFlatAnswers) => {
        return { ...prevFlatAnswers, ...valuesByLinkId }
      })
      setResponse((prevResponse) => {
        if (!prevResponse) {
          return response
        }

        const nestedAnswers = rebuildResponseFromFlat(flatAnswers)
        return { ...prevResponse, item: nestedAnswers }
      })
    },
    [cantEditAssessmentFields, debugLogs, flatAnswers, response]
  )

  useEffect(() => {
    if (debugLogs) {
      console.warn('[AssessmentContext] Assessment context is reloading...')
    }
  }, [debugLogs])

  return (
    <AssessmentContext.Provider
      value={{
        assessmentId,
        assessmentType,
        assessmentStatus,
        cantEditAssessmentFields,
        response,
        setResponse,
        answers,
        setResponseItems,
        flatAnswers,
        updateFlatAnswers
      }}
    >
      {children}
    </AssessmentContext.Provider>
  )
}

export default AssessmentContext
