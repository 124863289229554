import { StateCreator } from 'zustand'
import { ServiceRequest } from '@medplum/fhirtypes'
import { MedplumClient } from '@medplum/core'
import { captureException, SentrySources } from 'utils/sentry'
import { getErrorInstance } from 'utils/helpers'
import { RootSlice } from './rootType'

export interface OrderSlice {
  order: {
    byId: Record<string, ServiceRequest>
    list: ServiceRequest[]
    isLoading: boolean
    error: Error | null

    getOrderById: (medplum: MedplumClient, id: string) => Promise<ServiceRequest | void>
    setOrderList: (orders: ServiceRequest[]) => void
  }
}

const defaultState: Pick<OrderSlice['order'], 'list' | 'byId' | 'isLoading' | 'error'> = {
  list: [],
  byId: {},
  isLoading: false,
  error: null
}

export const createOrderSlice: StateCreator<
  RootSlice,
  // Type `never` is used here to follow the instructions on Zustand docs
  // reference: https://github.com/pmndrs/zustand/blob/main/docs/guides/typescript.md#middlewares-and-their-mutators-reference
  // see index.ts for middlewares being used
  [['zustand/devtools', never], ['zustand/immer', never]],
  [],
  OrderSlice
> = (set) => ({
  order: {
    ...defaultState,

    getOrderById: async (medplum, id) => {
      set((state) => {
        state.order.isLoading = true
      })

      try {
        const order = await medplum.readResource('ServiceRequest', id)
        if (order) {
          set((state) => {
            if (order?.id) {
              state.order.byId[order.id] = order
            }
          })

          return order
        }
      } catch (error) {
        captureException(getErrorInstance(error), {
          tags: { source: SentrySources.ORDERS_SLICE },
          extras: { params: { id } }
        })
        set((state) => {
          state.order.error = error
        })
      }

      set((state) => {
        state.order.isLoading = false
      })
    },

    setOrderList: (orders) => {
      set((state) => {
        state.order.list = orders
      })
    }
  }
})
