import { QuestionnaireAnswerType } from 'components/Assessments/types'
import { DischargeAssessmentQuestionnaireItem } from './questionnaire'

export const DISCHARGE_QUESTIONNAIRE_ITEM_TO_ANSWER_TYPE = {
  // Assessment overview
  [DischargeAssessmentQuestionnaireItem.ASSESSMENT_OVERVIEW_APPOINTMENT_TIME_START]:
    QuestionnaireAnswerType.DATE_TIME,
  [DischargeAssessmentQuestionnaireItem.ASSESSMENT_OVERVIEW_APPOINTMENT_TIME_END]:
    QuestionnaireAnswerType.DATE_TIME,

  // Patient overview
  [DischargeAssessmentQuestionnaireItem.PATIENT_OVERVIEW_LEGAL_NAME]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.PATIENT_OVERVIEW_PREFERRED_NAME]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.PATIENT_OVERVIEW_DATE_OF_BIRTH]:
    QuestionnaireAnswerType.DATE,
  [DischargeAssessmentQuestionnaireItem.PATIENT_OVERVIEW_ADDRESS]: QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.PATIENT_OVERVIEW_GENDER]: QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.PATIENT_OVERVIEW_SEX]: QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.PATIENT_OVERVIEW_ORDERING_PRACTITIONER]:
    QuestionnaireAnswerType.REFERENCE,
  [DischargeAssessmentQuestionnaireItem.PATIENT_OVERVIEW_DISCHARGE_ASSESSMENT_STATUS]:
    QuestionnaireAnswerType.STRING,

  // Medications
  [DischargeAssessmentQuestionnaireItem.MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_DRUGBANK_PCID]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO]:
    QuestionnaireAnswerType.GROUP,
  [DischargeAssessmentQuestionnaireItem.MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_NAME]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_ROUTE]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_STRENGTH_NUMBER]:
    QuestionnaireAnswerType.DECIMAL,
  [DischargeAssessmentQuestionnaireItem.MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_STRENGTH_UNIT]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_INDICATIONS]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_FREQUENCY]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_DOSAGE_NUMBER]:
    QuestionnaireAnswerType.DECIMAL,
  [DischargeAssessmentQuestionnaireItem.MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_DOSAGE_UNIT]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_INSTRUCTIONS]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_USAGE_STATUS]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_START_DATE]:
    QuestionnaireAnswerType.DATE,
  [DischargeAssessmentQuestionnaireItem.MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_DISCONTINUE_DATE]:
    QuestionnaireAnswerType.DATE,
  [DischargeAssessmentQuestionnaireItem.MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_CONTROLLED]:
    QuestionnaireAnswerType.BOOLEAN,
  [DischargeAssessmentQuestionnaireItem.MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_LIQUID]:
    QuestionnaireAnswerType.BOOLEAN,
  [DischargeAssessmentQuestionnaireItem.MEDICATIONS_MEDICATION_LIST_MEDICATION_INFO_INFECTION]:
    QuestionnaireAnswerType.BOOLEAN,
  [DischargeAssessmentQuestionnaireItem.MEDICATIONS_MEDICATION_SUMMARY]:
    QuestionnaireAnswerType.STRING,

  // Medical background
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_BACKGROUND_PRIMARY_DIAGNOSIS]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_BACKGROUND_OTHER_DIAGNOSIS]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_BACKGROUND_EQUIPMENT]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_BACKGROUND_DME_PROVIDER]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_BACKGROUND_ALLERGIES]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_BACKGROUND_ALLERGIES_NOTES]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_BACKGROUND_WAIVERS]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_BACKGROUND_ADDITIONAL_SERVICES]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_BACKGROUND_PROGNOSIS]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_BACKGROUND_DNR]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_BACKGROUND_ADVANCE_DIRECTIVES]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_BACKGROUND_ADVANCE_DIRECTIVES_NOTES]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_HISTORY_60_DAY_ER]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_HISTORY_ER_NOTES]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_HISTORY_60_DAY_HOSPITAL]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_HISTORY_HOSPITAL_NOTES]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_HISTORY_60_DAY_INJURIES]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_HISTORY_INJURY_NOTES]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_HISTORY_60_DAY_FALLS]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_HISTORY_FALL_NOTES]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_HISTORY_60_DAY_INFECTIONS]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_HISTORY_INFECTION_NOTES]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_HISTORY_60_DAY_MD]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_HISTORY_MD_NOTES]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_HISTORY_FUNCTIONAL_LIMITATIONS]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_HISTORY_ACTIVITIES_PERMITTED]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_HISTORY_MEDICAL_HISTORY]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_HISTORY_SURGICAL_HISTORY]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_IMMUNIZATIONS_HEP_B]:
    QuestionnaireAnswerType.GROUP,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_IMMUNIZATIONS_HEP_B_HEP_B]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_IMMUNIZATIONS_HEP_B_DATE]:
    QuestionnaireAnswerType.DATE,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_IMMUNIZATIONS_MENINGOCOCCAL]:
    QuestionnaireAnswerType.GROUP,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_IMMUNIZATIONS_MENINGOCOCCAL_MENINGOCOCCAL]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_IMMUNIZATIONS_MENINGOCOCCAL_DATE]:
    QuestionnaireAnswerType.DATE,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_IMMUNIZATIONS_COVID_19]:
    QuestionnaireAnswerType.GROUP,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_IMMUNIZATIONS_COVID_19_COVID_19]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_IMMUNIZATIONS_COVID_19_DATE]:
    QuestionnaireAnswerType.DATE,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_IMMUNIZATIONS_DTAP]:
    QuestionnaireAnswerType.GROUP,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_IMMUNIZATIONS_DTAP_DTAP]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_IMMUNIZATIONS_DTAP_DATE]:
    QuestionnaireAnswerType.DATE,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_IMMUNIZATIONS_HPV]:
    QuestionnaireAnswerType.GROUP,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_IMMUNIZATIONS_HPV_HPV]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_IMMUNIZATIONS_HPV_DATE]:
    QuestionnaireAnswerType.DATE,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_IMMUNIZATIONS_NOTES]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_IMMUNIZATIONS_RSV]:
    QuestionnaireAnswerType.GROUP,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_IMMUNIZATIONS_RSV_RSV]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_IMMUNIZATIONS_RSV_DATE]:
    QuestionnaireAnswerType.DATE,

  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_RISK_ASSESSMENT_HOSPITALIZATION_RISK]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_RISK_ASSESSMENT_HOSPITALIZATION_RISK_FACTORS]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_RISK_ASSESSMENT_TRIAGE_CODE]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_RISK_ASSESSMENT_EMERGENCY_PLAN]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_RISK_ASSESSMENT_SAFE_ROOM]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_RISK_ASSESSMENT_FIRE_ASSESSMENT_OXYGEN]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_RISK_ASSESSMENT_FIRE_SAFETY_PRECAUTIONS]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_RISK_ASSESSMENT_HOMEBOUND_STATUS]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_RISK_ASSESSMENT_INFECTION_RISK]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_RISK_ASSESSMENT_INFECTION_RISK_FACTORS]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_RISK_ASSESSMENT_FALL_RISK]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_RISK_ASSESSMENT_FALL_RISK_FACTORS]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_RISK_ASSESSMENT_FALL_RISK_ASSESSMENT]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_RISK_ASSESSMENT_FINANCE_RISK]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_RISK_ASSESSMENT_ENVIRONMENT_SAFETY]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_RISK_ASSESSMENT_SAFETY_MEASURES]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_RISK_ASSESSMENT_NOTES]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_NEURO_HEADACHES]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_NEURO_HEADACHE_TYPE]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_NEURO_SEIZURES]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_NEURO_SEIZURE_DATE]:
    QuestionnaireAnswerType.DATE,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_NEURO_SEIZURE_TYPE]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_NEURO_SEIZURE_PRECAUTIONS]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_NEURO_NEURO_STATUS]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_NEURO_ALTERED_AFFECT]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_NEURO_ALTERED_AFFECT_FACTORS]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_NEURO_PHQ2_SCREENING]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_NEURO_PHQ2_NOTES]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_NEURO_INTEREST]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_NEURO_DEPRESSED]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_NEURO_CAREGIVER_DIFFICULTY]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_NEURO_SYSTEM_SUMMARY]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_COGNITIVE_PSYCHOSOCIAL_DEV_COMMUNICATION_ASSESSMENT]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_COGNITIVE_PSYCHOSOCIAL_DEV_COGNITIVE_ASSESSMENT]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_COGNITIVE_PSYCHOSOCIAL_DEV_HISTORY_SUBSTANCE_ABUSE]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_COGNITIVE_PSYCHOSOCIAL_DEV_SUBSTANCE_ABUSE_NOTES]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_COGNITIVE_PSYCHOSOCIAL_DEV_SYSTEM_SUMMARY]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_MUSCULOSKELETAL_MUSCULOSKELETAL_ASSESSMENT]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_MUSCULOSKELETAL_SYSTEM_SUMMARY]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_LINES_DRAINS_SYSTEM_SUMMARY]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_THERAPIES_THERAPIES]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_THERAPIES_THERAPY_PROVIDER]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_THERAPIES_HOME_EXERCISE_PLAN]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_THERAPIES_HOME_EXERCISE_DURATION]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_THERAPIES_HOME_EXERCISE_FREQUENCY]:
    QuestionnaireAnswerType.INTEGER,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_SENSORY_SENSORY_STATUS_EYES]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_SENSORY_SENSORY_STATUS_EARS]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_SENSORY_SENSORY_STATUS_NOSE]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_SENSORY_SENSORY_STATUS_TOUCH]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_SENSORY_SENSORY_STATUS_TASTE]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_PAIN_STATUS]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_PAIN_GOAL]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_PAIN_FLACC_SCALE_FACE]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_PAIN_FLACC_SCALE_LEGS]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_PAIN_FLACC_SCALE_ACTIVITY]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_PAIN_FLACC_SCALE_CRY]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_PAIN_FLACC_SCALE_CONSOLABILITY]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_CARDIAC_CARDIOVASCULAR_WNL]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_CARDIAC_CARDIOVASCULAR_FACTORS]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_CARDIAC_HEART_SOUNDS]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_CARDIAC_SYSTEM_SUMMARY]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_RESPIRATORY_RESPIRATORY_WNL]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_RESPIRATORY_COUGH]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_RESPIRATORY_OXYGEN]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_RESPIRATORY_LUNG_SOUNDS]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_RESPIRATORY_SYSTEM_SUMMARY]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_ENDOCRINE_ENDOCRINE_WNL]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_ENDOCRINE_MENSTRUAL_CYCLE]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_ENDOCRINE_MENSTRUAL_DATE]:
    QuestionnaireAnswerType.DATE,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_ENDOCRINE_ENDOCRINE_NOTES]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_GASTROINTESTINAL_GI_ASSESSMENT]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_GASTROINTESTINAL_BM_DATE]:
    QuestionnaireAnswerType.DATE,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_GASTROINTESTINAL_GU_ASSESSMENT]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_GASTROINTESTINAL_CATHETER]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_GASTROINTESTINAL_CATHETER_TYPE]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_GASTROINTESTINAL_FEEDING_TUBE]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_GASTROINTESTINAL_FEEDING_TUBE_AMOUNT]:
    QuestionnaireAnswerType.INTEGER,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_GASTROINTESTINAL_FEEDING_TOTAL_NUMBER]:
    QuestionnaireAnswerType.INTEGER,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_GASTROINTESTINAL_FEEDING_TUBE_DURATION]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_GASTROINTESTINAL_FEEDING_TUBE_TYPE]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_GASTROINTESTINAL_FEEDING_TUBE_SITE]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_GASTROINTESTINAL_FEEDING_TUBE_SIZE]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_GASTROINTESTINAL_FEEDING_TUBE_NOTES]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_GASTROINTESTINAL_NUTRITION_WNL]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_GASTROINTESTINAL_NUTRITIONAL_FACTORS]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_GASTROINTESTINAL_DIET_REQUIREMENTS]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_GASTROINTESTINAL_NUTRITION_NOTES]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_GASTROINTESTINAL_NUTRITIONAL_SCREENING]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_GASTROINTESTINAL_GASTROINTESTINAL_HISTORY_NOTES]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_INTEGUMENTARY_SKIN_COLOR]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_INTEGUMENTARY_SKIN_ISSUES]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_INTEGUMENTARY_NAILS]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_INTEGUMENTARY_IDENTIFIED_WOUNDS]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_INTEGUMENTARY_WOUND_NOTES]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_TEMP_GREATER]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_TEMP_LESS]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_PULSE_GREATER]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_PULSE_LESS]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_RESPIRATIONS_GREATER]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_RESPIRATIONS_LESS]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_BP_GREATER]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_BP_LESS]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_WEIGHT_GREATER]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_WEIGHT_LESS]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_ETCO2_SATURATION_GREATER]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_ETCO2_SATURATION_LESS]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_O2_SATURATION_GREATER]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_PHYSICIAN_NOTIFICATION_O2_SATURATION_LESS]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_VITALS_TEMPERATURE]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_VITALS_PULSE]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_VITALS_RESPIRATIONS]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_VITALS_O2_SATURATION]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_VITALS_BLOOD_PRESSURE]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_VITALS_HEIGHT]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_VITALS_BLOOD_SUGAR]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.MEDICAL_BACKGROUND_VITALS_WEIGHT]:
    QuestionnaireAnswerType.STRING,

  // Discharge Planning
  [DischargeAssessmentQuestionnaireItem.DISCHARGE_SUMMARY]: QuestionnaireAnswerType.GROUP,
  [DischargeAssessmentQuestionnaireItem.DISCHARGE_SUMMARY_TYPES_OF_SERVICES]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.DISCHARGE_SUMMARY_SERVICE_FREQUENCY]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.DISCHARGE_SUMMARY_LAST_DATE_OF_SERVICE]:
    QuestionnaireAnswerType.DATE,
  [DischargeAssessmentQuestionnaireItem.DISCHARGE_SUMMARY_DISCHARGE_CONDITION]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.DISCHARGE_SUMMARY_REASON_FOR_DISCHARGE]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.DISCHARGE_SUMMARY_LABORATORY_DATA]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.DISCHARGE_SUMMARY_PLAN_OF_CARE_OUTCOMES]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.DISCHARGE_SUMMARY_DISCHARGE_INSTRUCTIONS]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.DISCHARGE_SUMMARY_POST_DISCHARGE_TREATMENT]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.DISCHARGE_SUMMARY_POST_DISCHARGE_GOALS]:
    QuestionnaireAnswerType.STRING,

  // Final steps
  [DischargeAssessmentQuestionnaireItem.FINAL_STEPS_CURRENT_CERT_PERIOD]:
    QuestionnaireAnswerType.REFERENCE,
  [DischargeAssessmentQuestionnaireItem.FINAL_STEPS_DISCHARGE_COMMUNICATION]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.FINAL_STEPS_RESOURCES_PROVIDED]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.FINAL_STEPS_CAREGIVER_RESPONSE]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.FINAL_STEPS_DATE_PHYSICIAN_NOTIFIED]:
    QuestionnaireAnswerType.DATE,
  [DischargeAssessmentQuestionnaireItem.FINAL_STEPS_DISCHARGE_NARRATIVE]:
    QuestionnaireAnswerType.STRING,
  [DischargeAssessmentQuestionnaireItem.FINAL_STEPS_INTERNAL_NOTES]: QuestionnaireAnswerType.STRING
}
