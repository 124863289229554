export enum SupervisoryAssessmentQuestionnaireItem {
  // Assessment overview
  ASSESSMENT_OVERVIEW_APPOINTMENT_TIME = 'assessment-overview/appointment-time',
  ASSESSMENT_OVERVIEW_APPOINTMENT_TIME_START = 'assessment-overview/appointment-time/start',
  ASSESSMENT_OVERVIEW_APPOINTMENT_TIME_END = 'assessment-overview/appointment-time/end',

  // Patient overview
  PATIENT_OVERVIEW_DEMOGRAPHICS_LEGAL_NAME = 'patient-overview/demographics/legal-name',
  PATIENT_OVERVIEW_DEMOGRAPHICS_PREFERRED_NAME = 'patient-overview/demographics/preferred-name',
  PATIENT_OVERVIEW_DEMOGRAPHICS_DATE_OF_BIRTH = 'patient-overview/demographics/date-of-birth',
  PATIENT_OVERVIEW_DEMOGRAPHICS_ADDRESS = 'patient-overview/demographics/address',
  PATIENT_OVERVIEW_DEMOGRAPHICS_GENDER = 'patient-overview/demographics/gender',
  PATIENT_OVERVIEW_DEMOGRAPHICS_SEX = 'patient-overview/demographics/sex',
  PATIENT_OVERVIEW_DEMOGRAPHICS_MEDICAID_ID = 'patient-overview/demographics/medicaid-id',
  PATIENT_OVERVIEW_CARE_TEAM_CAREGIVER = 'patient-overview/care-team/caregiver',
  PATIENT_OVERVIEW_CARE_TEAM_CAREGIVER_NAME = 'patient-overview/care-team/caregiver/caregiver-name',
  PATIENT_OVERVIEW_CARE_TEAM_CAREGIVER_RELATION_TO_PATIENT = 'patient-overview/care-team/caregiver/relation-to-patient',
  PATIENT_OVERVIEW_CARE_TEAM_LEGAL_GUARDIAN = 'patient-overview/care-team/legal-guardian',
  PATIENT_OVERVIEW_CARE_TEAM_LEGAL_GUARDIAN_NAME = 'patient-overview/care-team/legal-guardian/name',
  PATIENT_OVERVIEW_CARE_TEAM_LEGAL_GUARDIAN_RELATION_TO_PATIENT = 'patient-overview/care-team/legal-guardian/relation-to-patient',
  PATIENT_OVERVIEW_CARE_TEAM_EMERGENCY_CONTACT = 'patient-overview/care-team/emergency-contact',
  PATIENT_OVERVIEW_CARE_TEAM_EMERGENCY_CONTACT_NAME = 'patient-overview/care-team/emergency-contact/name',
  PATIENT_OVERVIEW_CARE_TEAM_EMERGENCY_CONTACT_PHONE = 'patient-overview/care-team/emergency-contact/phone',
  PATIENT_OVERVIEW_CARE_TEAM_EMERGENCY_CONTACT_ADDRESS = 'patient-overview/care-team/emergency-contact/address',
  PATIENT_OVERVIEW_CARE_TEAM_ALTERNATE_CAREGIVER = 'patient-overview/care-team/alternate-caregiver',
  PATIENT_OVERVIEW_CARE_TEAM_ALTERNATE_CAREGIVER_NAME = 'patient-overview/care-team/alternate-caregiver/name',
  PATIENT_OVERVIEW_CARE_TEAM_ALTERNATE_CAREGIVER_PHONE = 'patient-overview/care-team/alternate-caregiver/phone',
  PATIENT_OVERVIEW_CARE_TEAM_ALTERNATE_CAREGIVER_ADDRESS = 'patient-overview/care-team/alternate-caregiver/address',

  // Supervisory review
  SUPERVISORY_REVIEW_EMPLOYEE = 'supervisory-review/supervisory-review/employee',
  SUPERVISORY_REVIEW_ID_BADGE = 'supervisory-review/supervisory-review/id-badge',
  SUPERVISORY_REVIEW_HONORING_RIGHTS = 'supervisory-review/supervisory-review/honoring-rights',
  SUPERVISORY_REVIEW_EMERGENCY_PROTOCOL = 'supervisory-review/supervisory-review/emergency-protocol',
  SUPERVISORY_REVIEW_HYGIENE_PRECAUTIONS = 'supervisory-review/supervisory-review/hygiene-precautions',
  SUPERVISORY_REVIEW_REPORTING_CHANGES = 'supervisory-review/supervisory-review/reporting-changes',
  SUPERVISORY_REVIEW_GRIEVANCE_COMPLAINT = 'supervisory-review/supervisory-review/grievance-complaint',
  SUPERVISORY_REVIEW_POC_DOC_REVIEWED_WITH_CAREGIVER = 'supervisory-review/supervisory-review/poc-doc-reviewed-with-caregiver',
  SUPERVISORY_REVIEW_PPE_ACCESS = 'supervisory-review/supervisory-review/ppe-access',
  SUPERVISORY_REVIEW_SUPERVISION_OBSERVED = 'supervisory-review/supervisory-review/supervision-observed',
  SUPERVISORY_REVIEW_EDUCATION = 'supervisory-review/supervisory-review/education',
  SUPERVISORY_REVIEW_VERBALLY_UNDERSTOOD = 'supervisory-review/supervisory-review/verbally-understood',
  SUPERVISORY_REVIEW_REPEATED_BACK = 'supervisory-review/supervisory-review/repeated-back',
  SUPERVISORY_REVIEW_DEMONSTRATED_SKILL_CORRECTNESS = 'supervisory-review/supervisory-review/demonstrated-skill-correctness',
  SUPERVISORY_REVIEW_DOCUMENTATION = 'supervisory-review/supervisory-review/documentation',
  SUPERVISORY_REVIEW_ADHERED_TO_CARE_PLAN = 'supervisory-review/supervisory-review/adhered-to-care-plan',
  SUPERVISORY_REVIEW_PROFESSIONALISM = 'supervisory-review/supervisory-review/professionalism',
  SUPERVISORY_REVIEW_SATISFACTION = 'supervisory-review/supervisory-review/satisfaction',
  SUPERVISORY_REVIEW_COMMENTS = 'supervisory-review/supervisory-review/comments',

  // Final steps
  FINAL_STEPS_INTERNAL_NOTES = 'final-steps/internal-notes'
}
