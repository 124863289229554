import { Condition, Patient } from '@medplum/fhirtypes'
import dayjs from 'dayjs'
import { selectProviderOrderPreferences } from 'fhir/Organization/helpers'
import {
  BaseEpisodeOfCareWithConsentFragment,
  getPatientActiveAuthorization,
  getPatientCurrentCertPeriods,
  getPatientIdentifiers,
  getPatientSex,
  getPrimaryCareProvider,
  getPrimaryLanguage,
  isCertPeriodActive
} from 'fhir/Patient/helpers'
import {
  BaseConsentFragment,
  PatientData_CareTeamListFragment,
  PatientData_PatientCareDataFragment
} from 'generated/graphql'
import { PatientSchema } from 'store/patient'
import { formatAddress, getPrimaryAddress } from 'utils/address'
import { formatPrimaryName } from 'utils/names'
import { captureException } from 'utils/sentry'
import { selectCareTeamPractitionersWithRole, selectPrimaryCareTeam } from 'fhir/CareTeam/helpers'
import { CareTeamMemberRole } from 'fhir/CareTeam/constants'
import { getPractitionerTimezone } from 'fhir/Practitioner/helpers'
import { transformToTimezoneOrUtc } from 'utils/dates'
import { DEFAULT_PRACTITIONER_TIMEZONE } from 'fhir/Practitioner/constants'

export const formatPatientData = (
  patient: PatientData_PatientCareDataFragment
): PatientSchema | undefined => {
  const patientId = patient.id
  const name = formatPrimaryName(patient.name)
  const patientAddress = getPrimaryAddress(patient.address ?? []) ?? undefined
  const addressDisplay = formatAddress(patientAddress)
  const preferredName = formatPrimaryName(patient.name, { type: 'usual' })
  const identifiers = getPatientIdentifiers(patient)
  const primaryLanguage = getPrimaryLanguage(patient)
  const birthSexExtension = getPatientSex(patient)
  const ethnicityExtension = patient.ethnicityExtension?.[0]?.valueString ?? null
  const careLocation1 = patient.authorizedLocation1?.[0]?.valueReference?.id ?? null
  const careLocation2 = patient.authorizedLocation2?.[0]?.valueReference?.id ?? null
  const serviceType = patient.serviceType?.[0]?.valueReference?.display ?? null
  const managingOrganization = patient?.managingOrganization ?? null
  const careTeamList =
    patient?.CareTeamList?.filter((c): c is PatientData_CareTeamListFragment => !!c) ?? []

  const careTeam = selectPrimaryCareTeam(careTeamList)
  const caregivers = careTeam
    ? selectCareTeamPractitionersWithRole(careTeam, CareTeamMemberRole.Caregiver)
    : []

  // this is bad, we should enforce caregiver timezone from the source of truth, doing this before we prioritize data quality.
  const caregiverTimezone =
    getPractitionerTimezone(caregivers.at(0)) ?? DEFAULT_PRACTITIONER_TIMEZONE

  const conditions = patient?.conditions?.filter((c): c is Partial<Condition> => !!c) ?? []
  const primaryCareProvider = patient.generalPractitioner
    ? getPrimaryCareProvider(patient.generalPractitioner as Patient['generalPractitioner'])
    : null
  const providerOrderPreferences = selectProviderOrderPreferences(primaryCareProvider)
  const activeAuthorization = getPatientActiveAuthorization(
    patient,
    caregiverTimezone
  ) as BaseConsentFragment | null

  if (!patientId) {
    captureException(new Error('Patient ID not found'), { extras: { info: patient } })
    return
  }
  if (!name) {
    captureException(new Error(`Patient name not found for patient ID:${patientId}`), {
      extras: { info: patient }
    })
    return
  }

  if (!identifiers?.mrn) {
    captureException(new Error(`Patient MRN not found for patient ID:${patientId}`), {
      extras: { resources: { patient } }
    })
    return
  }

  const formattedData: PatientSchema = {
    id: patientId,
    name,
    dateOfBirth: patient.birthDate ? dayjs(patient.birthDate) : null,
    gender: patient?.gender ?? null,
    ssn: identifiers?.ssn || null,
    mrn: identifiers?.mrn,
    primaryCareProvider: primaryCareProvider,
    providerOrderPreferences,
    primaryLanguage,
    preferredName,
    patientStatus: patient.active ? 'active' : 'inactive',
    admissionStatus: patient.admissionStatusExtension?.[0]?.valueString ?? 'N/A',
    conditions,
    sex: birthSexExtension,
    ethnicity: ethnicityExtension,
    addressLine: patientAddress?.line?.[0] ?? null,
    addressLine2: patientAddress?.line?.[1] ?? null,
    addressCity: patientAddress?.city ?? null,
    addressState: patientAddress?.state ?? null,
    addressPostalCode: patientAddress?.postalCode ?? null,
    addressDisplay: addressDisplay ?? null,
    careLocation1,
    careLocation2,
    activeAuthorization,
    currentCertPeriods: getPatientCurrentCertPeriods(patient, caregiverTimezone),
    careTeam: careTeamList,
    caregiverTimezone,
    serviceType,
    managingOrganization
  }
  return formattedData
}

// Helper functions that take data from the patient schema and extract information
// Eventually should be moved to fhir/Patient/helpers.ts once we have fully migrated
// the app to use zustand

export const getCurrentCertPeriod = (
  patient: PatientSchema,
  isActive = true,
  checkTimePeriod = true
): BaseEpisodeOfCareWithConsentFragment | null => {
  const { currentCertPeriods, caregiverTimezone } = patient
  const currentCertPeriod =
    currentCertPeriods?.find((certPeriod) => {
      const periodStart = certPeriod.period?.start
        ? transformToTimezoneOrUtc(certPeriod.period.start, caregiverTimezone)
        : null
      const periodEnd = certPeriod.period?.end
        ? transformToTimezoneOrUtc(certPeriod.period?.end, caregiverTimezone)
        : null
      const today = transformToTimezoneOrUtc(dayjs().format(), caregiverTimezone)
      let todayIsWithinPeriod = true
      if (checkTimePeriod && periodStart && periodEnd) {
        todayIsWithinPeriod =
          periodStart?.isValid() && periodEnd?.isValid()
            ? today.isBetween(periodStart, periodEnd, 'day', '[]')
            : false
      } else if (checkTimePeriod && periodStart) {
        // if periodEnd is not available, we will check if the current date is after the start date
        todayIsWithinPeriod = periodStart?.isValid()
          ? today.isSame(periodStart, 'day') || today.isAfter(periodStart, 'day')
          : false
      } else if (checkTimePeriod) {
        todayIsWithinPeriod = false
      }
      const certPeriodActive = isCertPeriodActive(certPeriod, caregiverTimezone)

      const isCurrentCertPeriod = periodStart && periodEnd && todayIsWithinPeriod
      return isActive ? isCurrentCertPeriod && certPeriodActive : isCurrentCertPeriod
    }) ?? null

  return currentCertPeriod
}

export const getNextCertPeriod = (
  patient: PatientSchema
): BaseEpisodeOfCareWithConsentFragment | null => {
  const { currentCertPeriods, caregiverTimezone } = patient
  const currentCertPeriod = getCurrentCertPeriod(patient)
  if (!currentCertPeriods || !currentCertPeriod || !currentCertPeriod.period?.end) {
    return null
  }

  const currentCertPeriodEnd = currentCertPeriod.period?.end

  const nextCertPeriod =
    currentCertPeriods.find((certPeriod) => {
      const periodStart = certPeriod.period?.start
      const periodEnd = certPeriod.period?.end
      const isStartOfNextOnSameDayAsCurrent = periodStart
        ? transformToTimezoneOrUtc(periodStart, caregiverTimezone).isSame(
            transformToTimezoneOrUtc(currentCertPeriodEnd, caregiverTimezone),
            'day'
          )
        : false
      return isStartOfNextOnSameDayAsCurrent && periodEnd
    }) ?? null
  return nextCertPeriod
}
