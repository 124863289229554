import gql from 'gql-tag'
import { BASE_PRACTITIONER_FRAGMENT } from 'fragments/practitioner.query'
import { BASE_PRACTITIONER_ROLE_FRAGMENT } from 'fragments/practitionerRole.query'
import { BASE_ORGANIZATION_FRAGMENT } from 'fragments/organization.query'

const PRACTITIONER_WITH_ROLE_FRAGMENT = gql`
  fragment PractitionerData_PractitionerWithRole on Practitioner {
    ...BasePractitioner
    PractitionerRoleList(_reference: practitioner) {
      ...BasePractitionerRole
    }
  }
  ${BASE_PRACTITIONER_FRAGMENT}
  ${BASE_PRACTITIONER_ROLE_FRAGMENT}
  ${BASE_ORGANIZATION_FRAGMENT}
`

export const GET_PRACTITIONER_DATA_PRACTITIONERS_LIST = gql`
  query PractitionerData_GetPractitionersList($offset: Int) {
    PractitionerList(_count: 1000, _offset: $offset) {
      ...PractitionerData_PractitionerWithRole
    }
  }
  ${PRACTITIONER_WITH_ROLE_FRAGMENT}
`

export const GET_PRACTITIONER_DATA_PRACTITIONER = gql`
  query PractitionerData_GetPractitioner($id: ID!) {
    Practitioner(id: $id) {
      ...PractitionerData_PractitionerWithRole
    }
  }
  ${PRACTITIONER_WITH_ROLE_FRAGMENT}
`

export const GET_PRACTITIONERS_BY_NAME = gql`
  query PractitionerData_GetPractitionersByName($name: String!) {
    PractitionerList(_count: 1000, _filter: $name) {
      ...PractitionerData_PractitionerWithRole
    }
  }
  ${PRACTITIONER_WITH_ROLE_FRAGMENT}
`
