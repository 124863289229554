import {
  Annotation,
  CodeableConcept,
  Encounter,
  HealthcareService,
  Identifier,
  Observation,
  Organization,
  Patient,
  Practitioner,
  Reference
} from '@medplum/fhirtypes'
import { useMedplum } from '@medplum/react'
import { useContext, useState } from 'react'
import { capitalize } from 'lodash'
import { createAnnotation, createCodeableConcept, createReference } from 'fhir/utils'
import {
  CreateNoteParams,
  useCommunicationService
} from 'hooks/Communication/useCommunicationService'
import PatientContext from 'contexts/PatientContext'
import { formatPrimaryName } from 'utils/names'
import { HEALTHCARE_TEAM_REFERENCES } from 'fhir/HealthcareService/constants'
import { CreateEncounterParams, useEncounterService } from 'hooks/Encounter/useEncounterService'
import {
  CreateObservationParams,
  useObservationService
} from 'hooks/Observation/useObservationService'
import {
  GRIEVANCE_COMPLAINT_OUTCOME_COMMUNICATION_EXTENSION,
  INFECTION_TREATMENT_OBSERVATION_EXTENSION,
  INJURY_CAUSE_OBSERVATION_EXTENSION,
  LOSS_CONSCIOUSNESS_OBSERVATION_EXTENSION,
  SLURRED_SPEECH_OBSERVATION_EXTENSION,
  SYMPTOMS_OBSERVATION_EXTENSION,
  TRAUMA_OBSERVATION_EXTENSION
} from 'fhir/Observation/constants'
import { NotificationContext } from 'contexts/NotificationContext'
import { ALL_NOTE_TYPE_MAP, NoteType } from 'utils/staticLists/Notes/notes'
import { LOINC_CODE_SYSTEM } from 'fhir/constants'
import { findAbbyCareRegisteredNurse, PractitionerWithRoleList } from 'fhir/Practitioner/helpers'
import usePatientCareTeam from 'hooks/usePatientCareTeam'
import {
  ClinicalNoteTypes,
  COMMUNICATION_NOTE_CLINICAL_CATEGORY_CODE_SYSTEM,
  COMMUNICATION_NOTE_GENERAL_CATEGORY_CODE_SYSTEM,
  CommunicationNoteCategory,
  GeneralNoteTypes
} from 'fhir/Communication/constants'
import { captureException, TransactionNames } from 'utils/sentry'
import {
  PHYSICIAN_NOTIFIED_COMMUNICATION_EXTENSION,
  RESUMPTION_ORDER_RECEIVED_COMMUNICATION_EXTENSION
} from 'fhir/Communication/constants'
import {
  CreateCareCoordinationNoteParams,
  CreateERVisitNoteParams,
  CreateFallNoteParams,
  CreateGeneralNoteParams,
  CreateGrievanceComplaintNoteParams,
  CreateHospitalizationNoteParams,
  CreateInfectionNoteParams,
  CreateInjuryNoteParams,
  CreateMedicalProcedureNoteParams
} from '../types'

interface CreateNote {
  isLoading: boolean
  createHospitalizationNote(note: CreateHospitalizationNoteParams): Promise<string | undefined>
  createMedicalProcedurenNote(note: CreateMedicalProcedureNoteParams): Promise<string | undefined>
  createERVisitNote(note: CreateERVisitNoteParams): Promise<string | undefined>
  createInfectionNote(note: CreateInfectionNoteParams): Promise<string | undefined>
  createInjuryNote(note: CreateInjuryNoteParams): Promise<string | undefined>
  createFallNote(note: CreateFallNoteParams): Promise<string | undefined>
  createCareCoordinationNote(note: CreateCareCoordinationNoteParams): Promise<string | undefined>
  createGeneralNote(note: CreateGeneralNoteParams): Promise<string | undefined>
  createGrievanceComplaintNote(
    note: CreateGrievanceComplaintNoteParams
  ): Promise<string | undefined>
}

const useCreateClinicalNote = (source: string): CreateNote => {
  const { notify } = useContext(NotificationContext)
  const communicationService = useCommunicationService()
  const encounterService = useEncounterService()
  const observationService = useObservationService()
  const [isLoading, setIsLoading] = useState(false)
  const medplum = useMedplum()
  const { patientId: contextPatientId, displayName } = useContext(PatientContext)
  const { registeredNurses } = usePatientCareTeam()

  // Abby care RN is the default recipient for clinical/general notes
  const abbyCareRegisteredNurse = findAbbyCareRegisteredNurse(
    registeredNurses as PractitionerWithRoleList[]
  )[0]
  const abbyCareRnRef = createReference<Practitioner>({
    id: abbyCareRegisteredNurse?.id ?? '',
    reference: `${abbyCareRegisteredNurse?.resourceType}/${abbyCareRegisteredNurse?.id}`,
    type: abbyCareRegisteredNurse?.resourceType,
    display: formatPrimaryName(abbyCareRegisteredNurse?.name) ?? ''
  })

  // The logged in user is the default sender for clinical/general notes
  const profile = medplum.getProfile()
  const sender = profile
    ? createReference<Practitioner>({
        id: profile.id ?? '',
        reference: `${profile.resourceType}/${profile.id}`,
        type: profile.resourceType,
        display: formatPrimaryName(profile.name) ?? ''
      })
    : undefined

  const handleCreateNoteError = (
    e: Error,
    source: string,
    params: any,
    message = 'An error occurred while creating the note'
  ): void => {
    notify.error({ message })
    captureException(e, {
      tags: { source },
      extras: { params },
      transactionName: TransactionNames.CREATE_NOTE
    })
  }

  const getBaseCommunicationValues = (
    noteType: GeneralNoteTypes | ClinicalNoteTypes,
    annotationText?: string
  ): {
    recipient: Reference<Practitioner | HealthcareService>[]
    noteCode: CodeableConcept
    annotation?: Annotation
    identifier?: Identifier[]
  } => {
    const recipient = [abbyCareRnRef ?? HEALTHCARE_TEAM_REFERENCES.Clinical]
    const isClinicalNote = Object.values(ClinicalNoteTypes).includes(noteType as ClinicalNoteTypes)
    const noteCode = createCodeableConcept({
      system: isClinicalNote
        ? COMMUNICATION_NOTE_CLINICAL_CATEGORY_CODE_SYSTEM
        : COMMUNICATION_NOTE_GENERAL_CATEGORY_CODE_SYSTEM,
      code: noteType,
      text: capitalize(ALL_NOTE_TYPE_MAP[noteType]?.label ?? '')
    })
    let annotation: Annotation | undefined = undefined
    let identifier: Identifier[] | undefined = undefined
    if (annotationText) {
      const annotationParams = { text: annotationText, authorReference: sender }
      try {
        annotation = createAnnotation(annotationParams)
        identifier = [{ value: isClinicalNote ? NoteType.CLINICAL : NoteType.GENERAL }]
      } catch (e) {
        throw new Error('Unable to create annotation for the note')
      }
    }
    return { recipient, noteCode, annotation, identifier }
  }

  const handleCreateNoteSuccess = (message = 'Note created successfully'): void => {
    notify.success({ message })
  }

  const getSubjectReference = (id): Reference<Patient> => {
    return createReference<Patient>({
      id: id ?? '',
      reference: `Patient/${id}`,
      type: 'Patient',
      display: displayName ?? ''
    })
  }

  const getCodeableConcept = (reason: string): CodeableConcept =>
    createCodeableConcept({
      code: reason,
      text: reason,
      system: LOINC_CODE_SYSTEM
    })

  const formatNoteExtension = (
    url: string,
    valueString: string
  ): { url: string; valueString: string } => ({
    url,
    valueString
  })

  const createHospitalizationNote = async ({
    reason,
    facility,
    outcome,
    admissionDate,
    dischargeDate,
    resumptionOrderReceived,
    comment,
    patientId = undefined
  }: CreateHospitalizationNoteParams): Promise<string | undefined> => {
    const subject = getSubjectReference(patientId ?? contextPatientId)
    const reasonCode = getCodeableConcept(reason)
    const [orgName, orgId] = facility.split('/')
    const facilityRef = createReference<Organization>({
      id: orgId,
      reference: `Organization/${orgId}`,
      type: 'Organization',
      display: orgName
    })

    setIsLoading(true)

    const outcomeCode = outcome ? getCodeableConcept(outcome) : undefined
    const encounterParams = {
      subject,
      admissionDate: admissionDate || '',
      dischargeDate: dischargeDate || '',
      reason: [reasonCode],
      facility: facilityRef,
      outcome: outcomeCode
    }
    const resumptionOrderReceivedExtension = formatNoteExtension(
      RESUMPTION_ORDER_RECEIVED_COMMUNICATION_EXTENSION,
      resumptionOrderReceived
    )
    const noteParams = {
      sender,
      subject,
      reason: [reasonCode],
      extension: [resumptionOrderReceivedExtension]
    }
    try {
      const { recipient, noteCode, annotation, identifier } = await getBaseCommunicationValues(
        ClinicalNoteTypes.HOSPITALIZATION,
        comment
      )

      encounterParams['type'] = [noteCode]
      encounterParams['classCode'] = noteCode.coding?.[0]

      const encounterResource = await encounterService.createEncounter(
        encounterParams as CreateEncounterParams
      )

      if (!encounterResource) {
        throw new Error('Unable to create encounter associated with the hospitalization note')
      }

      const encounterReference = createReference<Encounter>({
        id: encounterResource.id ?? '',
        reference: `Encounter/${encounterResource.id}`,
        type: 'Encounter',
        display: `Encounter/${encounterResource.id}`
      })

      noteParams['recipient'] = recipient
      noteParams['noteType'] = noteCode
      noteParams['comment'] = annotation
      noteParams['identifier'] = identifier
      noteParams['encounter'] = encounterReference
      noteParams['category'] = CommunicationNoteCategory.CLINICAL

      const result = await communicationService.createNote(noteParams as CreateNoteParams)

      if (result) {
        handleCreateNoteSuccess()
        return result.id
      } else {
        throw new Error('Unable to create note')
      }
    } catch (e) {
      handleCreateNoteError(e, source, { encounterParams, noteParams })
    } finally {
      setIsLoading(false)
    }
  }

  const createMedicalProcedurenNote = async ({
    medicalProcedureType,
    facility,
    outcome,
    admissionDate,
    dischargeDate,
    comment,
    patientId = undefined
  }: CreateMedicalProcedureNoteParams): Promise<string | undefined> => {
    const subject = getSubjectReference(patientId ?? contextPatientId)
    const medicalProcedureTypeCode = getCodeableConcept(medicalProcedureType)
    const [orgName, orgId] = facility.split('/')
    const facilityRef = createReference<Organization>({
      id: orgId,
      reference: `Organization/${orgId}`,
      type: 'Organization',
      display: orgName
    })

    setIsLoading(true)

    const outcomeCode = outcome ? getCodeableConcept(outcome) : undefined
    const encounterParams = {
      subject,
      admissionDate: admissionDate || '',
      dischargeDate: dischargeDate || '',
      reason: [medicalProcedureTypeCode],
      facility: facilityRef,
      outcome: outcomeCode
    }
    const noteParams = {
      sender,
      subject,
      reason: [medicalProcedureTypeCode]
    }
    try {
      const { recipient, noteCode, annotation, identifier } = await getBaseCommunicationValues(
        ClinicalNoteTypes.MEDICAL_PROCEDURE,
        comment
      )

      encounterParams['type'] = [noteCode]
      encounterParams['classCode'] = noteCode.coding?.[0]

      const encounterResource = await encounterService.createEncounter(
        encounterParams as CreateEncounterParams
      )

      if (!encounterResource) {
        throw new Error('Unable to create encounter associated with the medical procedure note')
      }

      const encounterReference = createReference<Encounter>({
        id: encounterResource.id ?? '',
        reference: `Encounter/${encounterResource.id}`,
        type: 'Encounter',
        display: `Encounter/${encounterResource.id}`
      })

      noteParams['recipient'] = recipient
      noteParams['noteType'] = noteCode
      noteParams['comment'] = annotation
      noteParams['identifier'] = identifier
      noteParams['encounter'] = encounterReference
      noteParams['category'] = CommunicationNoteCategory.CLINICAL

      const result = await communicationService.createNote(noteParams as CreateNoteParams)

      if (result) {
        handleCreateNoteSuccess()
        return result.id
      } else {
        throw new Error('Unable to create note')
      }
    } catch (e) {
      handleCreateNoteError(e, source, { encounterParams, noteParams })
    } finally {
      setIsLoading(false)
    }
  }

  const createERVisitNote = async ({
    reason,
    outcome,
    admissionDate,
    physicianNotified,
    comment,
    patientId = undefined
  }: CreateERVisitNoteParams): Promise<string | undefined> => {
    const subject = getSubjectReference(patientId ?? contextPatientId)
    const reasonCode = getCodeableConcept(reason)
    const outcomeCode = getCodeableConcept(outcome)

    setIsLoading(true)

    const encounterParams = {
      subject,
      admissionDate: admissionDate || '',
      dischargeDate: '',
      reason: [reasonCode],
      outcome: outcomeCode
    }
    const physicianNotifiedExtension = formatNoteExtension(
      PHYSICIAN_NOTIFIED_COMMUNICATION_EXTENSION,
      physicianNotified
    )
    const noteParams = {
      sender,
      subject,
      reason: [reasonCode],
      extension: [physicianNotifiedExtension]
    }
    try {
      const { recipient, noteCode, annotation, identifier } = await getBaseCommunicationValues(
        ClinicalNoteTypes.ER_VISIT,
        comment
      )

      encounterParams['type'] = [noteCode]
      encounterParams['classCode'] = noteCode.coding?.[0]

      const encounterResource = await encounterService.createEncounter(
        encounterParams as CreateEncounterParams
      )

      if (!encounterResource) {
        throw new Error('Unable to create encounter associated with the ER visit note')
      }

      const encounterReference = createReference<Encounter>({
        id: encounterResource.id ?? '',
        reference: `Encounter/${encounterResource.id}`,
        type: 'Encounter',
        display: `Encounter/${encounterResource.id}`
      })

      noteParams['recipient'] = recipient
      noteParams['noteType'] = noteCode
      noteParams['comment'] = annotation
      noteParams['identifier'] = identifier
      noteParams['encounter'] = encounterReference
      noteParams['category'] = CommunicationNoteCategory.CLINICAL

      const noteResource = await communicationService.createNote(noteParams as CreateNoteParams)
      if (noteResource) {
        handleCreateNoteSuccess()
        return noteResource.id
      }
    } catch (e) {
      handleCreateNoteError(e, source, { encounterParams, noteParams })
    } finally {
      setIsLoading(false)
    }
  }

  const createInfectionNote = async ({
    infectionType,
    symptoms,
    physicianNotified,
    infectionTreatment,
    comment,
    patientId = undefined
  }: CreateInfectionNoteParams): Promise<string | undefined> => {
    const subject = getSubjectReference(patientId ?? contextPatientId)
    const infectionTypeCode = getCodeableConcept(infectionType)
    const symptomAnnotations =
      symptoms.map(
        (symptom) =>
          symptom &&
          createAnnotation({
            extension: [{ url: SYMPTOMS_OBSERVATION_EXTENSION, valueString: symptom }],
            text: symptom
          })
      ) ?? []

    const infectionTreatmentNote = createAnnotation({
      extension: [
        { url: INFECTION_TREATMENT_OBSERVATION_EXTENSION, valueString: infectionTreatment }
      ],
      text: infectionTreatment
    })

    setIsLoading(true)

    const observationParams = {
      code: infectionTypeCode,
      status: 'preliminary',
      subject,
      note: [infectionTreatmentNote, ...symptomAnnotations] as Annotation[]
    }
    const physicianNotifiedExtension = formatNoteExtension(
      PHYSICIAN_NOTIFIED_COMMUNICATION_EXTENSION,
      physicianNotified
    )
    const noteParams = {
      sender,
      subject,
      extension: [physicianNotifiedExtension]
    }
    try {
      const { recipient, noteCode, annotation, identifier } = await getBaseCommunicationValues(
        ClinicalNoteTypes.INFECTION,
        comment
      )

      observationParams['category'] = [noteCode]

      const observationResource = await observationService.createObservation(
        observationParams as CreateObservationParams
      )

      if (!observationResource) {
        throw new Error('Unable to create observation associated with the infection note')
      }

      const observationReference = createReference<Observation>({
        id: observationResource.id ?? '',
        reference: `Observation/${observationResource.id}`,
        type: 'Observation',
        display: `Observation/${observationResource.id}`
      })

      noteParams['recipient'] = recipient
      noteParams['noteType'] = noteCode
      noteParams['comment'] = annotation
      noteParams['identifier'] = identifier
      noteParams['reasonReference'] = [observationReference]
      noteParams['category'] = CommunicationNoteCategory.CLINICAL

      const noteResource = await communicationService.createNote(noteParams as CreateNoteParams)
      if (noteResource) {
        handleCreateNoteSuccess()
        return noteResource.id
      } else {
        throw new Error('Unable to create note')
      }
    } catch (e) {
      handleCreateNoteError(e, source, { observationParams, noteParams })
    } finally {
      setIsLoading(false)
    }
  }

  const createInjuryNote = async ({
    injuryType,
    injuryCause,
    physicianNotified,
    outcome,
    comment,
    injuryTrauma,
    patientId = undefined
  }: CreateInjuryNoteParams): Promise<string | undefined> => {
    const subject = getSubjectReference(patientId ?? contextPatientId)
    const injuryTypeCode = getCodeableConcept(injuryType)

    setIsLoading(true)

    const injuryCauseNote = createAnnotation({
      extension: [{ url: INJURY_CAUSE_OBSERVATION_EXTENSION, valueString: injuryCause }],
      text: injuryCause
    })

    const injuryTraumaNote = createAnnotation({
      extension: [{ url: TRAUMA_OBSERVATION_EXTENSION, valueString: injuryTrauma }],
      text: injuryTrauma
    })

    const observationParams = {
      code: injuryTypeCode,
      status: 'preliminary' as const,
      subject,
      valueString: outcome,
      note: [injuryCauseNote, injuryTraumaNote]
    }
    const physicianNotifiedExtension = formatNoteExtension(
      PHYSICIAN_NOTIFIED_COMMUNICATION_EXTENSION,
      physicianNotified
    )
    const noteParams = {
      sender,
      subject,
      extension: [physicianNotifiedExtension]
    }
    try {
      const { recipient, noteCode, annotation, identifier } = await getBaseCommunicationValues(
        ClinicalNoteTypes.INJURY,
        comment
      )

      observationParams['category'] = [noteCode]

      const observationResource = await observationService.createObservation(
        observationParams as CreateObservationParams
      )
      if (!observationResource) {
        throw new Error('Unable to create observation associated with the injury note')
      }

      const observationReference = createReference<Observation>({
        id: observationResource.id ?? '',
        reference: `Observation/${observationResource.id}`,
        type: 'Observation',
        display: `Observation/${observationResource.id}`
      })

      noteParams['recipient'] = recipient
      noteParams['noteType'] = noteCode
      noteParams['comment'] = annotation
      noteParams['identifier'] = identifier
      noteParams['reasonReference'] = [observationReference]
      noteParams['category'] = CommunicationNoteCategory.CLINICAL

      const noteResource = await communicationService.createNote(noteParams as CreateNoteParams)
      if (noteResource) {
        handleCreateNoteSuccess()
        return noteResource.id
      } else {
        throw new Error('Unable to create note')
      }
    } catch (e) {
      handleCreateNoteError(e, source, { observationParams, noteParams })
    } finally {
      setIsLoading(false)
    }
  }

  const createFallNote = async ({
    fallCause,
    fallTrauma,
    fallLossOfConsciousness,
    fallSlurredSpeech,
    physicianNotified,
    outcome,
    comment,
    patientId = undefined
  }: CreateFallNoteParams): Promise<string | undefined> => {
    const subject = getSubjectReference(patientId ?? contextPatientId)
    const fallCauseCode = getCodeableConcept(fallCause)

    setIsLoading(true)

    const fallTraumaNote = createAnnotation({
      extension: [{ url: TRAUMA_OBSERVATION_EXTENSION, valueString: fallTrauma }],
      text: fallTrauma
    })

    const fallLossOfConsciousnessNote = createAnnotation({
      extension: [
        { url: LOSS_CONSCIOUSNESS_OBSERVATION_EXTENSION, valueString: fallLossOfConsciousness }
      ],
      text: fallLossOfConsciousness
    })

    const fallSlurredSpeechNote = createAnnotation({
      extension: [{ url: SLURRED_SPEECH_OBSERVATION_EXTENSION, valueString: fallSlurredSpeech }],
      text: fallSlurredSpeech
    })

    const observationParams = {
      code: fallCauseCode,
      status: 'preliminary' as const,
      subject,
      valueString: outcome,
      note: [fallTraumaNote, fallLossOfConsciousnessNote, fallSlurredSpeechNote]
    }
    const physicianNotifiedExtension = formatNoteExtension(
      PHYSICIAN_NOTIFIED_COMMUNICATION_EXTENSION,
      physicianNotified
    )
    const noteParams = {
      sender,
      subject,
      extension: [physicianNotifiedExtension]
    }
    try {
      const { recipient, noteCode, annotation, identifier } = await getBaseCommunicationValues(
        ClinicalNoteTypes.FALL,
        comment
      )

      observationParams['category'] = [noteCode]

      const observationResource = await observationService.createObservation(
        observationParams as CreateObservationParams
      )
      if (!observationResource) {
        throw new Error('Unable to create observation associated with the fall note')
      }

      const observationReference = createReference<Observation>({
        id: observationResource.id ?? '',
        reference: `Observation/${observationResource.id}`,
        type: 'Observation',
        display: `Observation/${observationResource.id}`
      })

      noteParams['recipient'] = recipient
      noteParams['noteType'] = noteCode
      noteParams['comment'] = annotation
      noteParams['identifier'] = identifier
      noteParams['reasonReference'] = [observationReference]
      noteParams['category'] = CommunicationNoteCategory.CLINICAL

      const noteResource = await communicationService.createNote(noteParams as CreateNoteParams)
      if (noteResource) {
        handleCreateNoteSuccess()
        return noteResource.id
      } else {
        throw new Error('Unable to create note')
      }
    } catch (e) {
      handleCreateNoteError(e, source, { observationParams, noteParams })
    } finally {
      setIsLoading(false)
    }
  }

  const createCareCoordinationNote = async ({
    reason,
    physicianNotified,
    comment,
    patientId = undefined
  }: CreateCareCoordinationNoteParams): Promise<string | undefined> => {
    const subject = getSubjectReference(patientId ?? contextPatientId)
    const reasonCode = getCodeableConcept(reason)

    setIsLoading(true)

    const physicianNotifiedExtension = formatNoteExtension(
      PHYSICIAN_NOTIFIED_COMMUNICATION_EXTENSION,
      physicianNotified
    )
    const noteParams = {
      sender,
      subject,
      extension: [physicianNotifiedExtension],
      reason: [reasonCode]
    }
    try {
      const { recipient, noteCode, annotation, identifier } = await getBaseCommunicationValues(
        ClinicalNoteTypes.CARE_COORDINATION,
        comment
      )

      noteParams['recipient'] = recipient
      noteParams['noteType'] = noteCode
      noteParams['comment'] = annotation
      noteParams['identifier'] = identifier
      noteParams['category'] = CommunicationNoteCategory.CLINICAL

      const noteResource = await communicationService.createNote(noteParams as CreateNoteParams)
      if (noteResource) {
        handleCreateNoteSuccess()
        return noteResource.id
      } else {
        throw new Error('Unable to create note')
      }
    } catch (e) {
      handleCreateNoteError(e, source, { noteParams })
    } finally {
      setIsLoading(false)
    }
  }

  const createGeneralNote = async ({
    comment,
    patientId = undefined
  }: CreateGeneralNoteParams): Promise<string | undefined> => {
    const subject = getSubjectReference(patientId ?? contextPatientId)

    setIsLoading(true)

    const noteParams = {
      sender,
      subject
    }
    try {
      const { recipient, noteCode, annotation, identifier } = await getBaseCommunicationValues(
        GeneralNoteTypes.GENERAL,
        comment
      )

      noteParams['recipient'] = recipient
      noteParams['noteType'] = noteCode
      noteParams['comment'] = annotation
      noteParams['identifier'] = identifier
      noteParams['category'] = CommunicationNoteCategory.GENERAL

      const noteResource = await communicationService.createNote(noteParams as CreateNoteParams)
      if (noteResource) {
        handleCreateNoteSuccess()
        return noteResource.id
      } else {
        throw new Error('Unable to create note')
      }
    } catch (e) {
      handleCreateNoteError(e, source, { noteParams })
    } finally {
      setIsLoading(false)
    }
  }

  const createGrievanceComplaintNote = async ({
    outcome,
    comment,
    patientId = undefined
  }: CreateGrievanceComplaintNoteParams): Promise<string | undefined> => {
    const subject = getSubjectReference(patientId ?? contextPatientId)

    setIsLoading(true)

    const noteParams = {
      sender,
      subject
    }

    if (outcome) {
      const grievanceComplaintOutcomeExtension = formatNoteExtension(
        GRIEVANCE_COMPLAINT_OUTCOME_COMMUNICATION_EXTENSION,
        outcome
      )
      noteParams['extension'] = [grievanceComplaintOutcomeExtension]
    }

    try {
      const { recipient, noteCode, annotation, identifier } = await getBaseCommunicationValues(
        GeneralNoteTypes.GRIEVANCE_COMPLAINT,
        comment
      )

      noteParams['recipient'] = recipient
      noteParams['noteType'] = noteCode
      noteParams['comment'] = annotation
      noteParams['identifier'] = identifier
      noteParams['category'] = CommunicationNoteCategory.GENERAL

      const noteResource = await communicationService.createNote(noteParams as CreateNoteParams)
      if (noteResource) {
        handleCreateNoteSuccess()
        return noteResource.id
      } else {
        throw new Error('Unable to create note')
      }
    } catch (e) {
      handleCreateNoteError(e, source, { noteParams })
    } finally {
      setIsLoading(false)
    }
  }
  return {
    createHospitalizationNote,
    createMedicalProcedurenNote,
    createERVisitNote,
    createInfectionNote,
    createInjuryNote,
    createFallNote,
    createCareCoordinationNote,
    createGeneralNote,
    createGrievanceComplaintNote,
    isLoading
  }
}

export default useCreateClinicalNote
