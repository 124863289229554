import gql from 'gql-tag'

export const GET_PATIENTS_BY_NAME = gql`
  query GET_PATIENTS_BY_NAME($name: String!) {
    PatientList(_filter: $name) {
      id
      name {
        prefix
        given
        family
        suffix
        use
        text
      }
    }
  }
`
