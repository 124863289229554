import React, { createContext } from 'react'

export enum AutosaveStatus {
  SUCCESS,
  SAVING,
  ERROR
}

interface AssessmentLayoutContextType {
  autosaveDate?: Date
  setAutosaveDate: React.Dispatch<React.SetStateAction<Date>>
  autosaveStatus: AutosaveStatus
  setAutosaveStatus: React.Dispatch<React.SetStateAction<AutosaveStatus>>
  submit: () => void
  setSubmit: React.Dispatch<React.SetStateAction<() => void>>
  hasRoleToEdit: boolean
  setHasRoleToEdit: React.Dispatch<React.SetStateAction<boolean>>
  assessmentIsComplete: boolean
  setAssessmentIsComplete: React.Dispatch<React.SetStateAction<boolean>>
}

const AssessmentLayoutContext = createContext<AssessmentLayoutContextType>({
  setAutosaveDate: () => {}, // Function to update the last autosaved time
  autosaveStatus: AutosaveStatus.SUCCESS,
  setAutosaveStatus: () => {}, // Function to update the autosave progress
  submit: () => {}, // This will hold the submit function for the current form
  setSubmit: () => {}, // Function to update the submit function
  hasRoleToEdit: false,
  setHasRoleToEdit: () => {},
  assessmentIsComplete: false,
  setAssessmentIsComplete: () => {}
})

export default AssessmentLayoutContext
