import gql from 'gql-tag'
import {
  PATIENT_AUTHORIZED_LOCATION_1_EXTENSION,
  PATIENT_AUTHORIZED_LOCATION_2_EXTENSION
} from 'fhir/Location/constants'
import {
  PATIENT_ADMISSION_STATUS_EXTENSION,
  PATIENT_BIRTH_SEX_EXTENSION,
  PATIENT_ETHNICITY_EXTENSION,
  PATIENT_F2F_DATE_EXTENSION,
  PATIENT_SERVICE_TYPE_EXTENSION,
  PATIENT_SOC_DATE_EXTENSION
} from 'fhir/Patient/constants'

export const PATIENT_CONTACT_FRAGMENT = gql`
  fragment PatientContact on Patient {
    contact {
      relationship {
        text
      }
      name {
        prefix
        given
        family
        suffix
        use
        text
      }
      telecom {
        system
        value
      }
      address {
        text
        line
        city
        state
        postalCode
      }
    }
  }
`

export const BASE_PATIENT_FRAGMENT = gql`
  fragment BasePatient on Patient {
    resourceType
    id
    birthDate
    active
    gender
    managingOrganization {
      id
      identifier {
        id
      }
      reference
      display
    }
    name {
      prefix
      given
      family
      suffix
      use
      text
    }
    telecom {
      system
      value
    }
    address {
      text
      line
      city
      state
      postalCode
    }
    ...PatientContact
    communication {
      language {
        coding {
          code
          display
        }
      }
      preferred
    }
    identifier {
      system
      type {
        coding {
          display
          system
          code
        }
      }
      value
    }
    birthSexExtension: extension(url: "${PATIENT_BIRTH_SEX_EXTENSION}") {
      valueCodeableConcept {
        coding {
          system
          code
        }
      }
    }
    serviceType: extension(url: "${PATIENT_SERVICE_TYPE_EXTENSION}") {
      valueReference {
        id
        reference
        display
      }
    }
    admissionStatusExtension: extension(
      url: "${PATIENT_ADMISSION_STATUS_EXTENSION}"
    ) {
      valueString
    }
    socDateExtension: extension(url: "${PATIENT_SOC_DATE_EXTENSION}") {
      valueDate
    }
    f2fDateExtension: extension(url: "${PATIENT_F2F_DATE_EXTENSION}") {
      valueDate
    }
    ethnicityExtension: extension(
      url: "${PATIENT_ETHNICITY_EXTENSION}"
    ) {
      valueString
    }
    authorizedLocation1: extension(url: "${PATIENT_AUTHORIZED_LOCATION_1_EXTENSION}") {
      valueReference {
        id
        reference
      }
    }
    authorizedLocation2: extension(url: "${PATIENT_AUTHORIZED_LOCATION_2_EXTENSION}") {
      valueReference {
        id
        reference
      }
    }
  }
  ${PATIENT_CONTACT_FRAGMENT}
`

// ————————————————————————————————————————————————————————————————————————————————
// Nested types used for helper functions
// ————————————————————————————————————————————————————————————————————————————————

export const BASE_PATIENT_WITH_ALLERGIES_FRAGMENT = gql`
  fragment BasePatientWithAllergy on Patient {
    ...BasePatient
    AllergyIntoleranceList(_reference: patient) {
      ...BaseAllergyIntolerance
    }
  }
`
export const BASE_PATIENT_WITH_DEVICE_FRAGMENT = gql`
  fragment BasePatientWithDevice on Patient {
    ...BasePatient
    DeviceList(_reference: patient) {
      ...BaseDevice
    }
  }
`

export const BASE_PATIENT_WITH_COVERAGE_FRAGMENT = gql`
  fragment BasePatientWithCoverage on Patient {
    ...BasePatient
    CoverageList(_reference: patient) {
      ...BaseCoverage
    }
  }
`

export const BASE_PATIENT_WITH_CONSENT_FRAGMENT = gql`
  fragment BasePatientWithConsent on Patient {
    ...BasePatient
    ConsentList(_reference: patient) {
      ...BaseConsent
    }
  }
`

export const BASE_PATIENT_WITH_EPISODE_OF_CARE_AND_CONSENT_FRAGMENT = gql`
  fragment BasePatientWithEpisodeOfCareAndConsent on Patient {
    ...BasePatient
    EpisodeOfCareList(_reference: patient) {
      ...BaseEpisodeOfCare
    }
    ConsentList(_reference: patient) {
      ...BaseConsent
    }
  }
`
