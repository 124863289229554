import { createContext, useContext } from 'react'
import { SortedItemsMap, TaskOwnerOrRequester } from 'components/QA/Assessments/helpers'
import { BaseTaskFragment } from 'generated/graphql'
import { QAAssessmentUserRole } from 'components/QA/Assessments/QAAssessmentController'

interface QAAssessmentContextProps {
  qaNurse?: TaskOwnerOrRequester
  caseManager?: TaskOwnerOrRequester
  actingRole?: QAAssessmentUserRole
  isFinalRoundQA: boolean
  qaTask?: BaseTaskFragment
  qaTaskList: BaseTaskFragment[]
  qaAssessmentId?: string
  qaAssessmentItems: SortedItemsMap | null
  assessmentQAStatus: { inQA: boolean; inQAEdit: boolean; status?: string }
}

const QAAssessmentContext = createContext<QAAssessmentContextProps>({
  isFinalRoundQA: false,
  qaTaskList: [],
  assessmentQAStatus: { inQA: false, inQAEdit: false },
  qaAssessmentItems: null
})

export const useQAAssessmentContext = (): QAAssessmentContextProps => {
  return useContext(QAAssessmentContext)
}

export default QAAssessmentContext
