export enum HospitalizationReasons {
  CardiacIssues = 'Cardiac issues',
  RespiratoryProblems = 'Respiratory problems',
  SurgicalProcedures = 'Surgical procedures or recovery',
  Infections = 'Infections',
  NeurologicalConditions = 'Neurological conditions',
  InjuriesOrTrauma = 'Injuries or trauma',
  DigestiveSystemConcerns = 'Digestive system concerns',
  KidneyOrUrinaryTractIssues = 'Kidney or urinary tract issues',
  EndocrineDisorders = 'Endocrine disorders',
  MentalHealthConditions = 'Mental health conditions',
  CancerTreatments = 'Cancer treatments or complications',
  BloodRelatedProblems = 'Blood-related problems',
  DiabetesRelatedIssues = 'Diabetes-related issues',
  MaternityOrChildbirth = 'Maternity or childbirth-related care',
  SubstanceAbuseRelatedConcerns = 'Substance abuse-related concerns',
  PalliativeCare = 'Palliative care or end-of-life support',
  UndiagnosedConditions = 'Undiagnosed conditions requiring evaluation',
  AgeRelatedCare = 'Age-related or geriatric care',
  PediatricsRelatedConditions = 'Pediatrics-related conditions',
  RoutineMonitoring = 'Routine monitoring or observation'
}

export enum MedicalProcedureTypes {
  Planned = 'Planned',
  Emergency = 'Emergency'
}

export enum HospitalizationOutcomes {
  DischargedForHomeCare = 'Discharged for home care',
  HospitalAdmission = 'Hospital admission',
  FurtherAssessmentOrObservation = 'Further assessment or observation',
  ReferralToAnotherHealthcareProvider = 'Referral to another healthcare provider',
  DiagnosticTestsOrImagingOrdered = 'Diagnostic tests or imaging ordered',
  MedicationPrescribed = 'Medication prescribed',
  TreatmentProvidedOrProcedurePerformed = 'Treatment provided or procedure performed',
  FollowUpCareArranged = 'Follow-up care arranged',
  CounselingOrEducationGiven = 'Counseling or education given',
  SelfCareInstructionsProvided = 'Self-care instructions provided',
  NoFurtherActionNeeded = 'No further action needed',
  PalliativeOrSupportiveMeasuresInitiated = 'Palliative or supportive measures initiated',
  CommunityResourcesSuggested = 'Community resources suggested',
  OtherUnknown = 'Other/Unknown'
}

export enum ERVisitReasons {
  InjuryOrTrauma = 'Injury or trauma',
  ChestPain = 'Chest pain',
  DifficultyBreathing = 'Difficulty breathing',
  AbdominalPain = 'Abdominal pain',
  PostSurgeryComplication = 'Post-surgery complication',
  FeverOrHighTemperature = 'Fever or high temperature',
  AllergicReactions = 'Allergic reactions',
  SevereHeadache = 'Severe headache',
  VomitingOrNausea = 'Vomiting or nausea',
  LossOfConsciousness = 'Loss of consciousness',
  BurnsOrScalds = 'Burns or scalds',
  Bleeding = 'Bleeding',
  DizzinessOrFainting = 'Dizziness or fainting',
  SeizuresOrConvulsions = 'Seizures or convulsions',
  BrokenBonesOrFractures = 'Broken bones or fractures',
  BackPain = 'Back pain',
  Infections = 'Infections',
  MentalHealthCrisis = 'Mental health crisis',
  SuddenWeaknessOrNumbness = 'Sudden weakness or numbness',
  SuspectedPoisoning = 'Suspected poisoning',
  OtherUnknown = 'Other/Unknown'
}

export enum ERVisitOutcomes {
  DischargedForHomeCare = 'Discharged for home care',
  HospitalAdmission = 'Hospital admission',
  FurtherAssessmentOrObservation = 'Further assessment or observation',
  ReferralToAnotherHealthcareProvider = 'Referral to another healthcare provider',
  DiagnosticTestsOrImagingOrdered = 'Diagnostic tests or imaging ordered',
  MedicationPrescribed = 'Medication prescribed',
  TreatmentProvidedOrProcedurePerformed = 'Treatment provided or procedure performed',
  FollowUpCareArranged = 'Follow-up care arranged',
  CounselingOrEducationGiven = 'Counseling or education given',
  SelfCareInstructionsProvided = 'Self-care instructions provided',
  NoFurtherActionNeeded = 'No further action needed',
  PalliativeOrSupportiveMeasuresInitiated = 'Palliative or supportive measures initiated',
  CommunityResourcesSuggested = 'Community resources suggested',
  OtherUnknown = 'Other/Unknown'
}

export enum InjuryOutcomes {
  DischargedForHomeCare = 'Discharged for home care',
  HospitalAdmission = 'Hospital admission',
  FurtherAssessmentOrObservation = 'Further assessment or observation',
  ReferralToAnotherHealthcareProvider = 'Referral to another healthcare provider',
  DiagnosticTestsOrImagingOrdered = 'Diagnostic tests or imaging ordered',
  MedicationPrescribed = 'Medication prescribed',
  TreatmentProvidedOrProcedurePerformed = 'Treatment provided or procedure performed',
  FollowUpCareArranged = 'Follow-up care arranged',
  CounselingOrEducationGiven = 'Counseling or education given',
  SelfCareInstructionsProvided = 'Self-care instructions provided',
  NoFurtherActionNeeded = 'No further action needed',
  PalliativeOrSupportiveMeasuresInitiated = 'Palliative or supportive measures initiated',
  CommunityResourcesSuggested = 'Community resources suggested',
  OtherUnknown = 'Other/Unknown'
}

// TODO: add the right fall outcomes (ENG-1474)
export enum FallOutcomes {
  DischargedForHomeCare = 'Discharged for home care',
  HospitalAdmission = 'Hospital admission',
  FurtherAssessmentOrObservation = 'Further assessment or observation',
  ReferralToAnotherHealthcareProvider = 'Referral to another healthcare provider',
  DiagnosticTestsOrImagingOrdered = 'Diagnostic tests or imaging ordered',
  MedicationPrescribed = 'Medication prescribed',
  TreatmentProvidedOrProcedurePerformed = 'Treatment provided or procedure performed',
  FollowUpCareArranged = 'Follow-up care arranged',
  CounselingOrEducationGiven = 'Counseling or education given',
  SelfCareInstructionsProvided = 'Self-care instructions provided',
  NoFurtherActionNeeded = 'No further action needed',
  PalliativeOrSupportiveMeasuresInitiated = 'Palliative or supportive measures initiated',
  CommunityResourcesSuggested = 'Community resources suggested',
  OtherUnknown = 'Other/Unknown'
}

// TODO: add the right grievance complaints outcomes (ENG-1474)
export enum GrievanceComplaintOutcomes {
  DischargedForHomeCare = 'Discharged for home care',
  HospitalAdmission = 'Hospital admission',
  FurtherAssessmentOrObservation = 'Further assessment or observation',
  ReferralToAnotherHealthcareProvider = 'Referral to another healthcare provider',
  DiagnosticTestsOrImagingOrdered = 'Diagnostic tests or imaging ordered',
  MedicationPrescribed = 'Medication prescribed',
  TreatmentProvidedOrProcedurePerformed = 'Treatment provided or procedure performed',
  FollowUpCareArranged = 'Follow-up care arranged',
  CounselingOrEducationGiven = 'Counseling or education given',
  SelfCareInstructionsProvided = 'Self-care instructions provided',
  NoFurtherActionNeeded = 'No further action needed',
  PalliativeOrSupportiveMeasuresInitiated = 'Palliative or supportive measures initiated',
  CommunityResourcesSuggested = 'Community resources suggested',
  OtherUnknown = 'Other/Unknown'
}

// TODO: add the right injury causes (ENG-1474)
export enum InjuryCauses {
  SlipOrTrip = 'Slip or trip',
  FallFromHeight = 'Fall from height',
  ManualHandling = 'Manual handling',
  StruckByObject = 'Struck by object',
  StruckAgainstObject = 'Struck against object',
  MachineryAccident = 'Machinery accident',
  VehicleAccident = 'Vehicle accident',
  ChemicalExposure = 'Chemical exposure',
  FireOrExplosion = 'Fire or explosion',
  ElectricalAccident = 'Electrical accident'
}

// TODO: add the right fall causes (ENG-1474)
export enum FallCauses {
  SlipperyUnevenSurfaces = 'Slippery or uneven surfaces',
  PoorLighting = 'Poor lighting',
  LooseRugsCarpets = 'Loose rugs or carpets',
  ClutterObstacles = 'Clutter or obstacles in walking paths',
  PoorFootwear = 'Poor footwear',
  WetSlipperyFloors = 'Wet or slippery floors',
  LackOfHandrails = 'Lack of handrails or grab bars',
  UnevenStepsStairs = 'Uneven steps or stairs',
  PhysicalWeakness = 'Physical weakness or balance issues',
  Medications = 'Medications that affect balance',
  VisionProblems = 'Vision problems',
  AlcoholConsumption = 'Alcohol consumption',
  ChronicConditions = "Chronic conditions like arthritis or Parkinson's disease",
  MuscleWeakness = 'Muscle weakness',
  EnvironmentalFactors = 'Environmental factors like ice or snow'
}

export enum InfectionTypes {
  Bacterial = 'Bacterial infection',
  Viral = 'Viral infection',
  Fungal = 'Fungal infection',
  Parasitic = 'Parasitic infection',
  Skin = 'Skin infection',
  RespiratoryTract = 'Respiratory tract infection',
  UrinaryTract = 'Urinary tract infection (UTI)',
  Gastrointestinal = 'Gastrointestinal infection',
  Ear = 'Ear infection',
  Eye = 'Eye infection',
  SexuallyTransmitted = 'Sexually transmitted infection (STI)',
  Bloodstream = 'Bloodstream infection (Sepsis)',
  Wound = 'Wound infection',
  DentalOrOral = 'Dental or oral infection',
  Systemic = 'Systemic infection',
  CentralNervousSystem = 'Central nervous system infection',
  JointOrBone = 'Joint or bone infection',
  ReproductiveSystem = 'Reproductive system infection',
  TickBorne = 'Tick-borne infection',
  OtherUnknown = 'Other/Unknown'
}

export enum InfectionSymptoms {
  Bleeding = 'Bleeding',
  Blisters = 'Blisters',
  Chills = 'Chills',
  Cold = 'Cold',
  Congestion = 'Congestion',
  Cough = 'Cough',
  Diarrhea = 'Diarrhea',
  EyeDischarge = 'Eye Discharge',
  Fever = 'Fever',
  Inflammation = 'Inflammation',
  Irritability = 'Irritability',
  Itching = 'Itching',
  Lethargy = 'Lethargy',
  MuscleAches = 'Muscle Aches',
  Nausea = 'Nausea',
  None = 'None',
  Pain = 'Pain',
  Rash = 'Rash',
  Redness = 'Redness',
  RunnyNose = 'Runny Nose',
  SOBOrDifficultyBreathing = 'SOB/Difficulty Breathing',
  SoreThroat = 'Sore Throat',
  Vomiting = 'Vomiting'
}

// TODO: add the right infection treatments (ENG-1474)
export enum InfectionTreatments {
  Antibiotics = 'Antibiotics',
  AntiviralDrugs = 'Antiviral drugs',
  AntifungalDrugs = 'Antifungal drugs',
  AntiparasiticDrugs = 'Antiparasitic drugs',
  Antiseptics = 'Antiseptics',
  ImmuneGlobulinTherapy = 'Immune globulin therapy',
  Vaccines = 'Vaccines',
  Probiotics = 'Probiotics',
  SupportiveCare = 'Supportive care',
  Surgery = 'Surgery'
}

export enum InjuryTypes {
  CutOrOpenWound = 'Cut or open wound',
  BruiseOrContusion = 'Bruise or contusion',
  SprainOrStrain = 'Sprain or strain',
  BrokenBoneOrFracture = 'Broken bone or fracture',
  Burn = 'Burn',
  HeadInjury = 'Head injury',
  Dislocation = 'Dislocation',
  BiteOrStingInjury = 'Bite or sting injury',
  EyeInjury = 'Eye injury',
  TraumaOrImpactInjury = 'Trauma or impact injury',
  SoftTissueInjury = 'Soft tissue injury',
  ChemicalOrExposureInjury = 'Chemical or exposure injury',
  OveruseOrRepetitiveStrainInjury = 'Overuse or repetitive strain injury',
  CrushInjury = 'Crush injury',
  OtherUnknown = 'Other/Unknown'
}

export enum CareCoordinationReasons {
  ContinuityOfCare = 'Continuity of care',
  MedicationManagementOrReconciliation = 'Medication management or reconciliation',
  CarePlanAdjustments = 'Care plan adjustments',
  Referral = 'Referral',
  PatientEducationAndSupport = 'Patient education and support',
  PreventativeCareCoordination = 'Preventative care coordination',
  AccessToCommunityResources = 'Access to community resources',
  TransitionBetweenCareSettings = 'Transition between care settings (e.g. hospital to home)',
  OtherUnknown = 'Other/unknown'
}
