import {
  ABBYCARE_TERMINOLOGY_CODES_BASE,
  ABBYCARE_TERMINOLOGY_IDENTIFIERS_BASE,
  FHIR_DEFINITION_BASE,
  FHIR_STRUCTURE_DEFINITION_BASE,
  FHIR_US_CORE_STRUCTURE_DEFINITION_BASE,
  HL7_TERMINOLOGY_CODES_BASE
} from 'fhir/constants'

export const PRACTITIONER_SSN_SYSTEM = `${FHIR_DEFINITION_BASE}/sid/us-ssn`
export const PRACTITIONER_IDENTIFIER_TYPE_CODE_SYSTEM = `${HL7_TERMINOLOGY_CODES_BASE}/v2-0203`
export const PRACTITIONER_US_CORE_BIRTHSEX_EXTENSION = `${FHIR_US_CORE_STRUCTURE_DEFINITION_BASE}/us-core-birthsex`
export const PRACTITIONER_ADMINISTRATIVE_GENDER_SYSTEM = `${FHIR_DEFINITION_BASE}/administrative-gender`
export const PRACTITIONER_RACE_EXTENSION = `${FHIR_US_CORE_STRUCTURE_DEFINITION_BASE}/us-core-race`
export const PRACTITIONER_REFERRAL_LINK_EXTENSION_URL = `${ABBYCARE_TERMINOLOGY_IDENTIFIERS_BASE}/referral-link`

export const PRACTITIONER_IDENTIFIER_SYSTEM = 'https://www.abbycare.org/codes/provider-number'
export const PRACTITIONER_TITLE_SYSTEM = `${ABBYCARE_TERMINOLOGY_CODES_BASE}/provider-title`

export const PRACTITIONER_TIMEZONE_EXTENSION_URL = `${FHIR_STRUCTURE_DEFINITION_BASE}/timezone`
export const DEFAULT_PRACTITIONER_TIMEZONE = 'America/Denver'

// The National Plan and Provider Enumeration System (NPPES) is used to assign unique identifiers to health care providers.
// We use the NPPES API to search for providers and retrieve their information.
// NPI Registry Public Search is a free directory of all active National Provider Identifier (NPI) records (https://npiregistry.cms.hhs.gov/search).
// Healthcare providers acquire their unique 10-digit NPIs to identify themselves in a standard way throughout their industry.

// A reference to the Health Care Provider Taxonomy Code Set from the NPPES, which standardizes practitioner types and codes
export const NPPES_PROVIDER_TAXONOMY_SYSTEM = 'https://taxonomy.nucc.org/'

// The select provider types we use in the application based on the NPPES taxonomy codes.
// Please add more provider types as needed.
export enum NppesProviderLicenseType {
  GENERAL_PRACTICE_PHYSICIAN = 'General Practice Physician',
  REGISTERED_NURSE = 'Registered Nurse',
  NURSE_PRACTITIONER = 'Nurse Practitioner'
}

export enum ProviderTitle {
  DOCTOR_OF_OSTEOPATHY = 'D.O.',
  DOCTOR_OF_MEDICINE = 'M.D.',
  REGISTERED_NURSE = 'R.N.',
  NURSE_PRACTITIONER = 'N.P.',
  CLINICAL_NURSE_SPECIALIST = 'C.N.S.',
  PHYSICIAN_ASSISTANT = 'P.A.'
}

export const providerTitleAndLabelMap = {
  [ProviderTitle.DOCTOR_OF_OSTEOPATHY]: 'Doctor of Osteopathy',
  [ProviderTitle.DOCTOR_OF_MEDICINE]: 'Doctor of Medicine',
  [ProviderTitle.REGISTERED_NURSE]: 'Registered Nurse',
  [ProviderTitle.NURSE_PRACTITIONER]: 'Nurse Practitioner',
  [ProviderTitle.CLINICAL_NURSE_SPECIALIST]: 'Clinical Nurse Specialist',
  [ProviderTitle.PHYSICIAN_ASSISTANT]: 'Physician Assistant'
}

export const NppesProviderLicenseTypeAndCode = {
  [NppesProviderLicenseType.GENERAL_PRACTICE_PHYSICIAN]: '208D00000X',
  [NppesProviderLicenseType.REGISTERED_NURSE]: '163W00000X',
  [NppesProviderLicenseType.NURSE_PRACTITIONER]: '363L00000X'
}

export enum ProviderIdentifierType {
  LICENSE = 'license',
  NPI = 'npi'
}
