import { StateCreator } from 'zustand'
import { Encounter } from '@medplum/fhirtypes'
import { MedplumClient } from '@medplum/core'
import { captureException, SentrySources } from 'utils/sentry'
import { getErrorInstance } from 'utils/helpers'
import { RootSlice } from './rootType'

export interface EncounterSlice {
  encounter: {
    list: Encounter[]
    idToEncounterMapping: Record<string, Encounter>
    isLoading: boolean
    getEncounters: (medplum: MedplumClient, id: string) => Promise<void>
    getEncounter: (medplum: MedplumClient, id: string) => Promise<Encounter | void>
    getEncountersWithOptionalIds: (
      medplum: MedplumClient,
      { patientId, practitionerId }: { patientId?: string; practitionerId?: string }
    ) => Promise<void>
  }
}

export const createEncounterSlice: StateCreator<
  RootSlice,
  // Type `never` is used here to follow the instructions on Zustand docs
  // reference: https://github.com/pmndrs/zustand/blob/main/docs/guides/typescript.md#middlewares-and-their-mutators-reference
  // see index.ts for middlewares being used
  [['zustand/devtools', never], ['zustand/immer', never]],
  [],
  EncounterSlice
> = (set) => ({
  encounter: {
    list: [],
    idToEncounterMapping: {},
    isLoading: false,
    getEncounters: async (medplum, id) => {
      set((state) => {
        if (state.encounter) state.encounter.isLoading = true
      })
      try {
        const searchParams = new URLSearchParams({
          _id: id
        })
        const encounters = await medplum.searchResources('Encounter', searchParams)
        set((state) => {
          if (state.encounter) {
            state.encounter.list = [...state.encounter.list, ...encounters]
            state.encounter.isLoading = false
          }
        })
      } catch (error) {
        captureException(getErrorInstance(error), {
          tags: { source: SentrySources.ENCOUNTER_SLICE },
          extras: { params: { id } }
        })
      } finally {
        set((state) => {
          if (state.encounter) state.encounter.isLoading = false
        })
      }
    },
    getEncounter: async (medplum, id) => {
      set((state) => {
        state.encounter.isLoading = true
      })
      try {
        const encounter = await medplum.readResource('Encounter', id)
        set((state) => {
          state.encounter.idToEncounterMapping = {
            ...state.encounter.idToEncounterMapping,
            [id]: encounter
          }
        })
        return encounter
      } catch (error) {
        captureException(getErrorInstance(error), {
          tags: { source: SentrySources.ENCOUNTER_SLICE },
          extras: { params: { id } }
        })
      } finally {
        set((state) => {
          state.encounter.isLoading = false
        })
      }
    },
    getEncountersWithOptionalIds: async (medplum, { patientId, practitionerId }) => {
      set((state) => {
        state.encounter.isLoading = true
      })
      try {
        const searchParams = new URLSearchParams({
          _sort: '-_lastUpdated',
          _count: '1000'
        })
        patientId && searchParams.append('patient', `Patient/${patientId}`)
        practitionerId && searchParams.append('practitioner', `Practitioner/${practitionerId}`)
        const encounters = await medplum.searchResources('Encounter', searchParams)

        const mapping = {}
        encounters.forEach((encounter) => {
          if (encounter.id) mapping[encounter.id] = encounter
        })

        set((state) => {
          state.encounter.idToEncounterMapping = {
            ...state.encounter.idToEncounterMapping,
            ...mapping
          }
        })
      } catch (error) {
        captureException(getErrorInstance(error), {
          tags: { source: SentrySources.ENCOUNTER_SLICE },
          extras: { params: { patientId, practitionerId } }
        })
      } finally {
        set((state) => {
          state.encounter.isLoading = false
        })
      }
    }
  }
})
