import { BaseOrganizationFragment } from 'generated/graphql'
import { castToEnum } from 'utils/enum'
import {
  ORG_PREFERENCES_EXTENSION_DISPLAY_FOR_CODE,
  OrganizationPreferencesCode
} from './extensions'
import { ORGANIZATION_ORDER_PREFERENCES_EXTENSION } from './constants'

// Fallback value for providerOrderPreferences when no preference is found is temporary until we enable Organization edits
export const defaultProviderOrderPreferences = {
  display: ORG_PREFERENCES_EXTENSION_DISPLAY_FOR_CODE[OrganizationPreferencesCode.FAX],
  code: OrganizationPreferencesCode.FAX
} as ProviderOrderPreferences

export interface ProviderOrderPreferences {
  display: string
  code: string
}

// TODO: these have to be marked with a special URL to denote that it's a order preference
export const selectProviderOrderPreferences = (
  organization?: BaseOrganizationFragment | null
): ProviderOrderPreferences[] => {
  if (!organization) return [defaultProviderOrderPreferences]
  const orderPreferences =
    organization.extension
      ?.filter((e) => e?.url === ORGANIZATION_ORDER_PREFERENCES_EXTENSION)
      ?.map((e) => {
        const extensionCode = e?.valueCoding?.code ?? undefined
        const extensionCodeAsOrgPreferenceCode = extensionCode
          ? castToEnum(OrganizationPreferencesCode, extensionCode)
          : undefined

        return {
          display: extensionCodeAsOrgPreferenceCode
            ? ORG_PREFERENCES_EXTENSION_DISPLAY_FOR_CODE[extensionCodeAsOrgPreferenceCode]
            : undefined,
          code: e?.valueCoding?.code ?? undefined
        }
      })
      .filter((e): e is ProviderOrderPreferences => !!e.display && !!e.code) ?? []
  if (
    !orderPreferences?.find(
      (preference) =>
        preference.code === OrganizationPreferencesCode.FAX ||
        preference.code === OrganizationPreferencesCode.EMAIL
    )
  ) {
    orderPreferences.push(defaultProviderOrderPreferences)
  }

  return orderPreferences
}
