import { createContext } from 'react'

interface PatientContextProps {
  displayName: string | null
  patientId: string | null | undefined
}

const PatientContext = createContext<PatientContextProps>({
  displayName: null,
  patientId: null
})

export default PatientContext
